import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A floating point number that requires more precision than IEEE 754 binary 64 */
  BigFloat: any;
  /**
   * A signed eight-byte integer. The upper big integer values are greater than the
   * max value for a JavaScript number. Therefore all big integers will be output as
   * strings and not numbers.
   */
  BigInt: any;
  /** A location in a connection that can be used for resuming pagination. */
  Cursor: any;
  /** The day, does not include a time. */
  Date: any;
  /**
   * A point in time as described by the [ISO
   * 8601](https://en.wikipedia.org/wiki/ISO_8601) standard. May or may not include a timezone.
   */
  Datetime: any;
  /**
   * A JSON Web Token defined by [RFC 7519](https://tools.ietf.org/html/rfc7519)
   * which securely represents claims between two parties.
   */
  JwtToken: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type Account = {
  __typename?: 'Account';
  delegateEnabled?: Maybe<Scalars['Boolean']>;
  delegateExpireAt?: Maybe<Scalars['Datetime']>;
  delegateIssuedAt?: Maybe<Scalars['Datetime']>;
  delegateToken?: Maybe<Scalars['String']>;
  systemCode?: Maybe<Scalars['String']>;
  tenantBusinessName?: Maybe<Scalars['String']>;
  tenantCreatedAt?: Maybe<Scalars['Datetime']>;
  tenantEmail?: Maybe<Scalars['String']>;
  tenantKey?: Maybe<Scalars['String']>;
  tenantNit?: Maybe<Scalars['BigInt']>;
  tenantPhoneNumber?: Maybe<Scalars['String']>;
};

/** All input for the `activateAccount` mutation. */
export type ActivateAccountInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  token: Scalars['String'];
};

/** The output of our `activateAccount` mutation. */
export type ActivateAccountPayload = {
  __typename?: 'ActivateAccountPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  registration?: Maybe<Registration>;
};

/** All input for the `authenticateByApiKey` mutation. */
export type AuthenticateByApiKeyInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  key: Scalars['String'];
};

/** The output of our `authenticateByApiKey` mutation. */
export type AuthenticateByApiKeyPayload = {
  __typename?: 'AuthenticateByApiKeyPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  jwtToken?: Maybe<Scalars['JwtToken']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `authenticate` mutation. */
export type AuthenticateInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
  username: Scalars['String'];
};

/** The output of our `authenticate` mutation. */
export type AuthenticatePayload = {
  __typename?: 'AuthenticatePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  jwtToken?: Maybe<Scalars['JwtToken']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** A filter to be used against BigFloat fields. All fields are combined with a logical ‘and.’ */
export type BigFloatFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['BigFloat']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['BigFloat']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['BigFloat']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['BigFloat']>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['BigFloat']>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['BigFloat']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['BigFloat']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['BigFloat']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['BigFloat']>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars['BigFloat']>>;
};

/** A filter to be used against BigInt fields. All fields are combined with a logical ‘and.’ */
export type BigIntFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['BigInt']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['BigInt']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['BigInt']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['BigInt']>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['BigInt']>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['BigInt']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['BigInt']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['BigInt']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['BigInt']>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars['BigInt']>>;
};

/** A filter to be used against Boolean fields. All fields are combined with a logical ‘and.’ */
export type BooleanFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['Boolean']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['Boolean']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['Boolean']>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['Boolean']>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['Boolean']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['Boolean']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['Boolean']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['Boolean']>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars['Boolean']>>;
};

/** Sucursal */
export type BranchOffice = Node & {
  __typename?: 'BranchOffice';
  address: Scalars['String'];
  code: Scalars['Int'];
  createdAt: Scalars['Datetime'];
  /** Identificador */
  key: Scalars['String'];
  municipality: Scalars['String'];
  name: Scalars['String'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  phoneNumber?: Maybe<Scalars['String']>;
  /** Reads and enables pagination through a set of `PointOfSale`. */
  pointOfSales: PointOfSalesConnection;
  updatedAt?: Maybe<Scalars['Datetime']>;
};


/** Sucursal */
export type BranchOfficePointOfSalesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

/** An input for mutations affecting `BranchOffice` */
export type BranchOfficeInput = {
  address: Scalars['String'];
  code: Scalars['Int'];
  municipality: Scalars['String'];
  name: Scalars['String'];
  phoneNumber?: InputMaybe<Scalars['String']>;
  pointOfSales?: InputMaybe<FkPointOfSaleToBranchOfficeInverseInput>;
};

/** The globally unique `ID` look up for the row to connect. */
export type BranchOfficeNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `branchOffice` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type BranchOfficeOnPointOfSaleForFkPointOfSaleToBranchOfficeNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `pointOfSale` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `pointOfSale` being updated. */
  patch: PointOfSalePatch;
};

/** The fields on `branchOffice` to look up the row to update. */
export type BranchOfficeOnPointOfSaleForFkPointOfSaleToBranchOfficeUsingPkBranchOfficeUpdate = {
  /** Identificador */
  key: Scalars['String'];
  /** An object where the defined keys will be set on the `branchOffice` being updated. */
  patch: UpdateBranchOfficeOnPointOfSaleForFkPointOfSaleToBranchOfficePatch;
};

/** Represents an update to a `BranchOffice`. Fields that are set will be updated. */
export type BranchOfficePatch = {
  address?: InputMaybe<Scalars['String']>;
  code?: InputMaybe<Scalars['Int']>;
  municipality?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  pointOfSales?: InputMaybe<FkPointOfSaleToBranchOfficeInverseInput>;
};

/** The fields on `branchOffice` to look up the row to connect. */
export type BranchOfficePkBranchOfficeConnect = {
  /** Identificador */
  key: Scalars['String'];
};

/** A `BranchOffice` edge in the connection. */
export type BranchOfficesEdge = {
  __typename?: 'BranchOfficesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `BranchOffice` at the end of the edge. */
  node?: Maybe<BranchOffice>;
};

export type CancelInvoiceInput = {
  key: Scalars['String'];
  reasonCode: Scalars['Int'];
};

export type CancelInvoicePayload = {
  __typename?: 'CancelInvoicePayload';
  invoiceCancellation?: Maybe<InvoiceCancellation>;
  message?: Maybe<Scalars['String']>;
};

/** Cliente */
export type Client = Node & {
  __typename?: 'Client';
  createdAt: Scalars['Datetime'];
  editable: Scalars['Boolean'];
  email?: Maybe<Scalars['String']>;
  /** Reads a single `ClientHomologation` that is related to this `Client`. */
  homologation?: Maybe<ClientHomologation>;
  /**
   * Reads and enables pagination through a set of `ClientHomologation`.
   * @deprecated Please use homologation instead
   */
  homologations: ClientHomologationsConnection;
  key: Scalars['String'];
  name: Scalars['String'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  observation?: Maybe<Scalars['String']>;
  /** Reads and enables pagination through a set of `Order`. */
  orders: OrdersConnection;
  phoneNumber?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
};


/** Cliente */
export type ClientHomologationsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


/** Cliente */
export type ClientOrdersArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

/** Homologación de Cliente para el SIN */
export type ClientHomologation = Node & {
  __typename?: 'ClientHomologation';
  /** [SIN] codigoExcepcion */
  allowInvalidNit: Scalars['Boolean'];
  /** Reads a single `Client` that is related to this `ClientHomologation`. */
  client?: Maybe<Client>;
  clientKey: Scalars['String'];
  createdAt: Scalars['Datetime'];
  /** [SIN] complemento */
  documentComplement?: Maybe<Scalars['String']>;
  /** [SIN] numeroDocumento */
  documentNumber: Scalars['String'];
  /** [SIN] codigoTipoDocumentoIdentidad */
  documentTypeCode: Scalars['Int'];
  /** Descripción del tipo de documento */
  documentTypeDescription: Scalars['String'];
  /** Identificador */
  key: Scalars['String'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  updatedAt?: Maybe<Scalars['Datetime']>;
};

/** An input for mutations affecting `ClientHomologation` */
export type ClientHomologationInput = {
  /** [SIN] codigoExcepcion */
  allowInvalidNit?: InputMaybe<Scalars['Boolean']>;
  client?: InputMaybe<FkClientHomologationToClientInput>;
  clientKey?: InputMaybe<Scalars['String']>;
  /** [SIN] complemento */
  documentComplement?: InputMaybe<Scalars['String']>;
  /** [SIN] numeroDocumento */
  documentNumber: Scalars['String'];
  /** [SIN] codigoTipoDocumentoIdentidad */
  documentTypeCode: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to connect. */
export type ClientHomologationNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `clientHomologation` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type ClientHomologationOnClientHomologationForFkClientHomologationToClientNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `client` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `client` being updated. */
  patch: ClientPatch;
};

/** The fields on `clientHomologation` to look up the row to update. */
export type ClientHomologationOnClientHomologationForFkClientHomologationToClientUsingPkClientHomologationUpdate = {
  /** Identificador */
  key: Scalars['String'];
  /** An object where the defined keys will be set on the `clientHomologation` being updated. */
  patch: UpdateClientHomologationOnClientHomologationForFkClientHomologationToClientPatch;
};

/** The fields on `clientHomologation` to look up the row to update. */
export type ClientHomologationOnClientHomologationForFkClientHomologationToClientUsingUkClientHomologationClientIdUpdate = {
  clientKey: Scalars['String'];
  /** An object where the defined keys will be set on the `clientHomologation` being updated. */
  patch: UpdateClientHomologationOnClientHomologationForFkClientHomologationToClientPatch;
};

/** Represents an update to a `ClientHomologation`. Fields that are set will be updated. */
export type ClientHomologationPatch = {
  /** [SIN] codigoExcepcion */
  allowInvalidNit?: InputMaybe<Scalars['Boolean']>;
  client?: InputMaybe<FkClientHomologationToClientInput>;
  clientKey?: InputMaybe<Scalars['String']>;
  /** [SIN] complemento */
  documentComplement?: InputMaybe<Scalars['String']>;
  /** [SIN] numeroDocumento */
  documentNumber?: InputMaybe<Scalars['String']>;
  /** [SIN] codigoTipoDocumentoIdentidad */
  documentTypeCode?: InputMaybe<Scalars['Int']>;
};

/** The fields on `clientHomologation` to look up the row to connect. */
export type ClientHomologationPkClientHomologationConnect = {
  /** Identificador */
  key: Scalars['String'];
};

/** The fields on `clientHomologation` to look up the row to connect. */
export type ClientHomologationUkClientHomologationClientIdConnect = {
  clientKey: Scalars['String'];
};

/** A connection to a list of `ClientHomologation` values. */
export type ClientHomologationsConnection = {
  __typename?: 'ClientHomologationsConnection';
  /** A list of edges which contains the `ClientHomologation` and cursor to aid in pagination. */
  edges: Array<ClientHomologationsEdge>;
  /** A list of `ClientHomologation` objects. */
  nodes: Array<Maybe<ClientHomologation>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ClientHomologation` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ClientHomologation` edge in the connection. */
export type ClientHomologationsEdge = {
  __typename?: 'ClientHomologationsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `ClientHomologation` at the end of the edge. */
  node?: Maybe<ClientHomologation>;
};

/** An input for mutations affecting `Client` */
export type ClientInput = {
  createdAt?: InputMaybe<Scalars['Datetime']>;
  editable?: InputMaybe<Scalars['Boolean']>;
  email?: InputMaybe<Scalars['String']>;
  homologations?: InputMaybe<FkClientHomologationToClientInverseInput>;
  name: Scalars['String'];
  observation?: InputMaybe<Scalars['String']>;
  orders?: InputMaybe<FkOrderToClientInverseInput>;
  phoneNumber?: InputMaybe<Scalars['String']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type ClientNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `client` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type ClientOnClientHomologationForFkClientHomologationToClientNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `clientHomologation` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `clientHomologation` being updated. */
  patch: ClientHomologationPatch;
};

/** The fields on `client` to look up the row to update. */
export type ClientOnClientHomologationForFkClientHomologationToClientUsingPkClientUpdate = {
  key: Scalars['String'];
  /** An object where the defined keys will be set on the `client` being updated. */
  patch: UpdateClientOnClientHomologationForFkClientHomologationToClientPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type ClientOnOrderForFkOrderToClientNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `order` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `order` being updated. */
  patch: OrderPatch;
};

/** The fields on `client` to look up the row to update. */
export type ClientOnOrderForFkOrderToClientUsingPkClientUpdate = {
  key: Scalars['String'];
  /** An object where the defined keys will be set on the `client` being updated. */
  patch: UpdateClientOnOrderForFkOrderToClientPatch;
};

/** Represents an update to a `Client`. Fields that are set will be updated. */
export type ClientPatch = {
  createdAt?: InputMaybe<Scalars['Datetime']>;
  editable?: InputMaybe<Scalars['Boolean']>;
  email?: InputMaybe<Scalars['String']>;
  homologations?: InputMaybe<FkClientHomologationToClientInverseInput>;
  name?: InputMaybe<Scalars['String']>;
  observation?: InputMaybe<Scalars['String']>;
  orders?: InputMaybe<FkOrderToClientInverseInput>;
  phoneNumber?: InputMaybe<Scalars['String']>;
};

/** The fields on `client` to look up the row to connect. */
export type ClientPkClientConnect = {
  key: Scalars['String'];
};

/** A `Client` edge in the connection. */
export type ClientsEdge = {
  __typename?: 'ClientsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Client` at the end of the edge. */
  node?: Maybe<Client>;
};

/** All input for the create `BranchOffice` mutation. */
export type CreateBranchOfficeInput = {
  /** The `BranchOffice` to be created by this mutation. */
  branchOffice: BranchOfficeInput;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** The output of our create `BranchOffice` mutation. */
export type CreateBranchOfficePayload = {
  __typename?: 'CreateBranchOfficePayload';
  /** The `BranchOffice` that was created by this mutation. */
  branchOffice?: Maybe<BranchOffice>;
  /** An edge for our `BranchOffice`. May be used by Relay 1. */
  branchOfficeEdge?: Maybe<BranchOfficesEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the create `ClientHomologation` mutation. */
export type CreateClientHomologationInput = {
  /** The `ClientHomologation` to be created by this mutation. */
  clientHomologation: ClientHomologationInput;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** The output of our create `ClientHomologation` mutation. */
export type CreateClientHomologationPayload = {
  __typename?: 'CreateClientHomologationPayload';
  /** Reads a single `Client` that is related to this `ClientHomologation`. */
  client?: Maybe<Client>;
  /** The `ClientHomologation` that was created by this mutation. */
  clientHomologation?: Maybe<ClientHomologation>;
  /** An edge for our `ClientHomologation`. May be used by Relay 1. */
  clientHomologationEdge?: Maybe<ClientHomologationsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the create `Client` mutation. */
export type CreateClientInput = {
  /** The `Client` to be created by this mutation. */
  client: ClientInput;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** The output of our create `Client` mutation. */
export type CreateClientPayload = {
  __typename?: 'CreateClientPayload';
  /** The `Client` that was created by this mutation. */
  client?: Maybe<Client>;
  /** An edge for our `Client`. May be used by Relay 1. */
  clientEdge?: Maybe<ClientsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the create `ExportServiceOrderDetail` mutation. */
export type CreateExportServiceOrderDetailInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `ExportServiceOrderDetail` to be created by this mutation. */
  exportServiceOrderDetail: ExportServiceOrderDetailInput;
};

/** The output of our create `ExportServiceOrderDetail` mutation. */
export type CreateExportServiceOrderDetailPayload = {
  __typename?: 'CreateExportServiceOrderDetailPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `ExportServiceOrder` that is related to this `ExportServiceOrderDetail`. */
  exportServiceOrder?: Maybe<ExportServiceOrder>;
  /** The `ExportServiceOrderDetail` that was created by this mutation. */
  exportServiceOrderDetail?: Maybe<ExportServiceOrderDetail>;
  /** An edge for our `ExportServiceOrderDetail`. May be used by Relay 1. */
  exportServiceOrderDetailEdge?: Maybe<ExportServiceOrderDetailsEdge>;
  /** Reads a single `Product` that is related to this `ExportServiceOrderDetail`. */
  productService?: Maybe<Product>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our create `ExportServiceOrderDetail` mutation. */
export type CreateExportServiceOrderDetailPayloadExportServiceOrderDetailEdgeArgs = {
  orderBy?: InputMaybe<Array<ExportServiceOrderDetailsOrderBy>>;
};

/** All input for the create `ExportServiceOrder` mutation. */
export type CreateExportServiceOrderInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `ExportServiceOrder` to be created by this mutation. */
  exportServiceOrder: ExportServiceOrderInput;
};

/** The output of our create `ExportServiceOrder` mutation. */
export type CreateExportServiceOrderPayload = {
  __typename?: 'CreateExportServiceOrderPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `ExportServiceOrder` that was created by this mutation. */
  exportServiceOrder?: Maybe<ExportServiceOrder>;
  /** An edge for our `ExportServiceOrder`. May be used by Relay 1. */
  exportServiceOrderEdge?: Maybe<ExportServiceOrdersEdge>;
  /** Reads a single `Order` that is related to this `ExportServiceOrder`. */
  order?: Maybe<Order>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our create `ExportServiceOrder` mutation. */
export type CreateExportServiceOrderPayloadExportServiceOrderEdgeArgs = {
  orderBy?: InputMaybe<Array<ExportServiceOrdersOrderBy>>;
};

/** All input for the create `InsuranceOrderDetail` mutation. */
export type CreateInsuranceOrderDetailInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `InsuranceOrderDetail` to be created by this mutation. */
  insuranceOrderDetail: InsuranceOrderDetailInput;
};

/** The output of our create `InsuranceOrderDetail` mutation. */
export type CreateInsuranceOrderDetailPayload = {
  __typename?: 'CreateInsuranceOrderDetailPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `InsuranceOrder` that is related to this `InsuranceOrderDetail`. */
  insuranceOrder?: Maybe<InsuranceOrder>;
  /** The `InsuranceOrderDetail` that was created by this mutation. */
  insuranceOrderDetail?: Maybe<InsuranceOrderDetail>;
  /** An edge for our `InsuranceOrderDetail`. May be used by Relay 1. */
  insuranceOrderDetailEdge?: Maybe<InsuranceOrderDetailsEdge>;
  /** Reads a single `Product` that is related to this `InsuranceOrderDetail`. */
  productService?: Maybe<Product>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our create `InsuranceOrderDetail` mutation. */
export type CreateInsuranceOrderDetailPayloadInsuranceOrderDetailEdgeArgs = {
  orderBy?: InputMaybe<Array<InsuranceOrderDetailsOrderBy>>;
};

/** All input for the create `InsuranceOrder` mutation. */
export type CreateInsuranceOrderInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `InsuranceOrder` to be created by this mutation. */
  insuranceOrder: InsuranceOrderInput;
};

/** The output of our create `InsuranceOrder` mutation. */
export type CreateInsuranceOrderPayload = {
  __typename?: 'CreateInsuranceOrderPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `InsuranceOrder` that was created by this mutation. */
  insuranceOrder?: Maybe<InsuranceOrder>;
  /** An edge for our `InsuranceOrder`. May be used by Relay 1. */
  insuranceOrderEdge?: Maybe<InsuranceOrdersEdge>;
  /** Reads a single `Order` that is related to this `InsuranceOrder`. */
  order?: Maybe<Order>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our create `InsuranceOrder` mutation. */
export type CreateInsuranceOrderPayloadInsuranceOrderEdgeArgs = {
  orderBy?: InputMaybe<Array<InsuranceOrdersOrderBy>>;
};

/** All input for the create `OrderHomologation` mutation. */
export type CreateOrderHomologationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `OrderHomologation` to be created by this mutation. */
  orderHomologation: OrderHomologationInput;
};

/** The output of our create `OrderHomologation` mutation. */
export type CreateOrderHomologationPayload = {
  __typename?: 'CreateOrderHomologationPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Order` that is related to this `OrderHomologation`. */
  order?: Maybe<Order>;
  /** The `OrderHomologation` that was created by this mutation. */
  orderHomologation?: Maybe<OrderHomologation>;
  /** An edge for our `OrderHomologation`. May be used by Relay 1. */
  orderHomologationEdge?: Maybe<OrderHomologationsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the create `Order` mutation. */
export type CreateOrderInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `Order` to be created by this mutation. */
  order: OrderInput;
};

/** The output of our create `Order` mutation. */
export type CreateOrderPayload = {
  __typename?: 'CreateOrderPayload';
  /** Reads a single `Client` that is related to this `Order`. */
  client?: Maybe<Client>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Order` that was created by this mutation. */
  order?: Maybe<Order>;
  /** An edge for our `Order`. May be used by Relay 1. */
  orderEdge?: Maybe<OrdersEdge>;
  /** Reads a single `PointOfSale` that is related to this `Order`. */
  pointOfSale?: Maybe<PointOfSale>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the create `Person` mutation. */
export type CreatePersonInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `Person` to be created by this mutation. */
  person: PersonInput;
};

/** The output of our create `Person` mutation. */
export type CreatePersonPayload = {
  __typename?: 'CreatePersonPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Person` that was created by this mutation. */
  person?: Maybe<Person>;
  /** An edge for our `Person`. May be used by Relay 1. */
  personEdge?: Maybe<PeopleEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

export type CreatePointOfSaleInput = {
  branchOfficeCode: Scalars['Int'];
  commissionAgentNit?: InputMaybe<Scalars['BigInt']>;
  contractNumber?: InputMaybe<Scalars['String']>;
  description: Scalars['String'];
  finishDate?: InputMaybe<Scalars['Datetime']>;
  modalityCode: Scalars['Int'];
  name: Scalars['String'];
  startDate?: InputMaybe<Scalars['Datetime']>;
  typeCode: Scalars['Int'];
};

export type CreatePointOfSalePayload = {
  __typename?: 'CreatePointOfSalePayload';
  pointOfSale?: Maybe<PointOfSale>;
};

/** All input for the create `PointOfSaleUser` mutation. */
export type CreatePointOfSaleUserInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `PointOfSaleUser` to be created by this mutation. */
  pointOfSaleUser: PointOfSaleUserInput;
};

/** The output of our create `PointOfSaleUser` mutation. */
export type CreatePointOfSaleUserPayload = {
  __typename?: 'CreatePointOfSaleUserPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `PointOfSale` that is related to this `PointOfSaleUser`. */
  pointOfSale?: Maybe<PointOfSale>;
  /** The `PointOfSaleUser` that was created by this mutation. */
  pointOfSaleUser?: Maybe<PointOfSaleUser>;
  /** An edge for our `PointOfSaleUser`. May be used by Relay 1. */
  pointOfSaleUserEdge?: Maybe<PointOfSaleUsersEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `PointOfSaleUser`. */
  user?: Maybe<User>;
};

/** All input for the create `ProductHomologation` mutation. */
export type CreateProductHomologationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `ProductHomologation` to be created by this mutation. */
  productHomologation: ProductHomologationInput;
};

/** The output of our create `ProductHomologation` mutation. */
export type CreateProductHomologationPayload = {
  __typename?: 'CreateProductHomologationPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `ProductHomologation` that was created by this mutation. */
  productHomologation?: Maybe<ProductHomologation>;
  /** An edge for our `ProductHomologation`. May be used by Relay 1. */
  productHomologationEdge?: Maybe<ProductHomologationsEdge>;
  /** Reads a single `Product` that is related to this `ProductHomologation`. */
  productService?: Maybe<Product>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the create `Product` mutation. */
export type CreateProductInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `Product` to be created by this mutation. */
  product: ProductInput;
};

/** The output of our create `Product` mutation. */
export type CreateProductPayload = {
  __typename?: 'CreateProductPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Product` that was created by this mutation. */
  product?: Maybe<Product>;
  /** An edge for our `Product`. May be used by Relay 1. */
  productEdge?: Maybe<ProductsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the create `RentalOrderDetail` mutation. */
export type CreateRentalOrderDetailInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `RentalOrderDetail` to be created by this mutation. */
  rentalOrderDetail: RentalOrderDetailInput;
};

/** The output of our create `RentalOrderDetail` mutation. */
export type CreateRentalOrderDetailPayload = {
  __typename?: 'CreateRentalOrderDetailPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Product` that is related to this `RentalOrderDetail`. */
  productService?: Maybe<Product>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `RentalOrder` that is related to this `RentalOrderDetail`. */
  rentalOrder?: Maybe<RentalOrder>;
  /** The `RentalOrderDetail` that was created by this mutation. */
  rentalOrderDetail?: Maybe<RentalOrderDetail>;
  /** An edge for our `RentalOrderDetail`. May be used by Relay 1. */
  rentalOrderDetailEdge?: Maybe<RentalOrderDetailsEdge>;
};


/** The output of our create `RentalOrderDetail` mutation. */
export type CreateRentalOrderDetailPayloadRentalOrderDetailEdgeArgs = {
  orderBy?: InputMaybe<Array<RentalOrderDetailsOrderBy>>;
};

/** All input for the create `RentalOrder` mutation. */
export type CreateRentalOrderInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `RentalOrder` to be created by this mutation. */
  rentalOrder: RentalOrderInput;
};

/** The output of our create `RentalOrder` mutation. */
export type CreateRentalOrderPayload = {
  __typename?: 'CreateRentalOrderPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Order` that is related to this `RentalOrder`. */
  order?: Maybe<Order>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `RentalOrder` that was created by this mutation. */
  rentalOrder?: Maybe<RentalOrder>;
  /** An edge for our `RentalOrder`. May be used by Relay 1. */
  rentalOrderEdge?: Maybe<RentalOrdersEdge>;
};


/** The output of our create `RentalOrder` mutation. */
export type CreateRentalOrderPayloadRentalOrderEdgeArgs = {
  orderBy?: InputMaybe<Array<RentalOrdersOrderBy>>;
};

/** All input for the create `SaleOrderDetail` mutation. */
export type CreateSaleOrderDetailInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `SaleOrderDetail` to be created by this mutation. */
  saleOrderDetail: SaleOrderDetailInput;
};

/** The output of our create `SaleOrderDetail` mutation. */
export type CreateSaleOrderDetailPayload = {
  __typename?: 'CreateSaleOrderDetailPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Product` that is related to this `SaleOrderDetail`. */
  productService?: Maybe<Product>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `SaleOrder` that is related to this `SaleOrderDetail`. */
  saleOrder?: Maybe<SaleOrder>;
  /** The `SaleOrderDetail` that was created by this mutation. */
  saleOrderDetail?: Maybe<SaleOrderDetail>;
  /** An edge for our `SaleOrderDetail`. May be used by Relay 1. */
  saleOrderDetailEdge?: Maybe<SaleOrderDetailsEdge>;
};

/** All input for the create `SaleOrder` mutation. */
export type CreateSaleOrderInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `SaleOrder` to be created by this mutation. */
  saleOrder: SaleOrderInput;
};

/** The output of our create `SaleOrder` mutation. */
export type CreateSaleOrderPayload = {
  __typename?: 'CreateSaleOrderPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Order` that is related to this `SaleOrder`. */
  order?: Maybe<Order>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `SaleOrder` that was created by this mutation. */
  saleOrder?: Maybe<SaleOrder>;
  /** An edge for our `SaleOrder`. May be used by Relay 1. */
  saleOrderEdge?: Maybe<SaleOrdersEdge>;
};

/** All input for the `createSignificantEvent` mutation. */
export type CreateSignificantEventInput = {
  cafc?: InputMaybe<Scalars['String']>;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  pointOfSaleCode?: InputMaybe<Scalars['Int']>;
  reasonCode?: InputMaybe<Scalars['Int']>;
  sectorDocumentCode?: InputMaybe<Scalars['Int']>;
  startDate?: InputMaybe<Scalars['Datetime']>;
};

/** The output of our `createSignificantEvent` mutation. */
export type CreateSignificantEventPayload = {
  __typename?: 'CreateSignificantEventPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  significantEvent?: Maybe<SignificantEvent>;
};

/** All input for the create `TouristServiceOrderDetail` mutation. */
export type CreateTouristServiceOrderDetailInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `TouristServiceOrderDetail` to be created by this mutation. */
  touristServiceOrderDetail: TouristServiceOrderDetailInput;
};

/** The output of our create `TouristServiceOrderDetail` mutation. */
export type CreateTouristServiceOrderDetailPayload = {
  __typename?: 'CreateTouristServiceOrderDetailPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Product` that is related to this `TouristServiceOrderDetail`. */
  productService?: Maybe<Product>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `TouristServiceOrder` that is related to this `TouristServiceOrderDetail`. */
  touristServiceOrder?: Maybe<TouristServiceOrder>;
  /** The `TouristServiceOrderDetail` that was created by this mutation. */
  touristServiceOrderDetail?: Maybe<TouristServiceOrderDetail>;
  /** An edge for our `TouristServiceOrderDetail`. May be used by Relay 1. */
  touristServiceOrderDetailEdge?: Maybe<TouristServiceOrderDetailsEdge>;
};


/** The output of our create `TouristServiceOrderDetail` mutation. */
export type CreateTouristServiceOrderDetailPayloadTouristServiceOrderDetailEdgeArgs = {
  orderBy?: InputMaybe<Array<TouristServiceOrderDetailsOrderBy>>;
};

/** All input for the create `TouristServiceOrder` mutation. */
export type CreateTouristServiceOrderInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `TouristServiceOrder` to be created by this mutation. */
  touristServiceOrder: TouristServiceOrderInput;
};

/** The output of our create `TouristServiceOrder` mutation. */
export type CreateTouristServiceOrderPayload = {
  __typename?: 'CreateTouristServiceOrderPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Order` that is related to this `TouristServiceOrder`. */
  order?: Maybe<Order>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `TouristServiceOrder` that was created by this mutation. */
  touristServiceOrder?: Maybe<TouristServiceOrder>;
  /** An edge for our `TouristServiceOrder`. May be used by Relay 1. */
  touristServiceOrderEdge?: Maybe<TouristServiceOrdersEdge>;
};


/** The output of our create `TouristServiceOrder` mutation. */
export type CreateTouristServiceOrderPayloadTouristServiceOrderEdgeArgs = {
  orderBy?: InputMaybe<Array<TouristServiceOrdersOrderBy>>;
};

/** All input for the create `User` mutation. */
export type CreateUserInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `User` to be created by this mutation. */
  user: UserInput;
};

/** The output of our create `User` mutation. */
export type CreateUserPayload = {
  __typename?: 'CreateUserPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Person` that is related to this `User`. */
  person?: Maybe<Person>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `User` that was created by this mutation. */
  user?: Maybe<User>;
  /** An edge for our `User`. May be used by Relay 1. */
  userEdge?: Maybe<UsersEdge>;
};

/** All input for the create `ViewBranchOffice` mutation. */
export type CreateViewBranchOfficeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `ViewBranchOffice` to be created by this mutation. */
  viewBranchOffice: ViewBranchOfficeInput;
};

/** The output of our create `ViewBranchOffice` mutation. */
export type CreateViewBranchOfficePayload = {
  __typename?: 'CreateViewBranchOfficePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `ViewBranchOffice` that was created by this mutation. */
  viewBranchOffice?: Maybe<ViewBranchOffice>;
  /** An edge for our `ViewBranchOffice`. May be used by Relay 1. */
  viewBranchOfficeEdge?: Maybe<ViewBranchOfficesEdge>;
};


/** The output of our create `ViewBranchOffice` mutation. */
export type CreateViewBranchOfficePayloadViewBranchOfficeEdgeArgs = {
  orderBy?: InputMaybe<Array<ViewBranchOfficesOrderBy>>;
};

/** All input for the create `ViewDelegatedToken` mutation. */
export type CreateViewDelegatedTokenInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `ViewDelegatedToken` to be created by this mutation. */
  viewDelegatedToken: ViewDelegatedTokenInput;
};

/** The output of our create `ViewDelegatedToken` mutation. */
export type CreateViewDelegatedTokenPayload = {
  __typename?: 'CreateViewDelegatedTokenPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `ViewDelegatedToken` that was created by this mutation. */
  viewDelegatedToken?: Maybe<ViewDelegatedToken>;
  /** An edge for our `ViewDelegatedToken`. May be used by Relay 1. */
  viewDelegatedTokenEdge?: Maybe<ViewDelegatedTokensEdge>;
};


/** The output of our create `ViewDelegatedToken` mutation. */
export type CreateViewDelegatedTokenPayloadViewDelegatedTokenEdgeArgs = {
  orderBy?: InputMaybe<Array<ViewDelegatedTokensOrderBy>>;
};

/** All input for the create `ViewDigitalSignature` mutation. */
export type CreateViewDigitalSignatureInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `ViewDigitalSignature` to be created by this mutation. */
  viewDigitalSignature: ViewDigitalSignatureInput;
};

/** The output of our create `ViewDigitalSignature` mutation. */
export type CreateViewDigitalSignaturePayload = {
  __typename?: 'CreateViewDigitalSignaturePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `ViewDigitalSignature` that was created by this mutation. */
  viewDigitalSignature?: Maybe<ViewDigitalSignature>;
  /** An edge for our `ViewDigitalSignature`. May be used by Relay 1. */
  viewDigitalSignatureEdge?: Maybe<ViewDigitalSignaturesEdge>;
};


/** The output of our create `ViewDigitalSignature` mutation. */
export type CreateViewDigitalSignaturePayloadViewDigitalSignatureEdgeArgs = {
  orderBy?: InputMaybe<Array<ViewDigitalSignaturesOrderBy>>;
};

/** All input for the create `ViewProduct` mutation. */
export type CreateViewProductInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `ViewProduct` to be created by this mutation. */
  viewProduct: ViewProductInput;
};

/** The output of our create `ViewProduct` mutation. */
export type CreateViewProductPayload = {
  __typename?: 'CreateViewProductPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `ViewProduct` that was created by this mutation. */
  viewProduct?: Maybe<ViewProduct>;
  /** An edge for our `ViewProduct`. May be used by Relay 1. */
  viewProductEdge?: Maybe<ViewProductsEdge>;
};


/** The output of our create `ViewProduct` mutation. */
export type CreateViewProductPayloadViewProductEdgeArgs = {
  orderBy?: InputMaybe<Array<ViewProductsOrderBy>>;
};

/** All input for the create `ViewSyncParameter` mutation. */
export type CreateViewSyncParameterInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `ViewSyncParameter` to be created by this mutation. */
  viewSyncParameter: ViewSyncParameterInput;
};

/** The output of our create `ViewSyncParameter` mutation. */
export type CreateViewSyncParameterPayload = {
  __typename?: 'CreateViewSyncParameterPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `ViewSyncParameter` that was created by this mutation. */
  viewSyncParameter?: Maybe<ViewSyncParameter>;
  /** An edge for our `ViewSyncParameter`. May be used by Relay 1. */
  viewSyncParameterEdge?: Maybe<ViewSyncParametersEdge>;
};


/** The output of our create `ViewSyncParameter` mutation. */
export type CreateViewSyncParameterPayloadViewSyncParameterEdgeArgs = {
  orderBy?: InputMaybe<Array<ViewSyncParametersOrderBy>>;
};

/** All input for the create `ViewSyncRequest` mutation. */
export type CreateViewSyncRequestInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `ViewSyncRequest` to be created by this mutation. */
  viewSyncRequest: ViewSyncRequestInput;
};

/** The output of our create `ViewSyncRequest` mutation. */
export type CreateViewSyncRequestPayload = {
  __typename?: 'CreateViewSyncRequestPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `ViewSyncRequest` that was created by this mutation. */
  viewSyncRequest?: Maybe<ViewSyncRequest>;
  /** An edge for our `ViewSyncRequest`. May be used by Relay 1. */
  viewSyncRequestEdge?: Maybe<ViewSyncRequestsEdge>;
};


/** The output of our create `ViewSyncRequest` mutation. */
export type CreateViewSyncRequestPayloadViewSyncRequestEdgeArgs = {
  orderBy?: InputMaybe<Array<ViewSyncRequestsOrderBy>>;
};

export type Cufd = {
  __typename?: 'Cufd';
  address?: Maybe<Scalars['String']>;
  branchOfficeCode?: Maybe<Scalars['Int']>;
  branchOfficeName?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  controlCode?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['Int']>;
  enabled?: Maybe<Scalars['Boolean']>;
  expireAt?: Maybe<Scalars['Datetime']>;
  generatedAt?: Maybe<Scalars['Datetime']>;
  id?: Maybe<Scalars['Int']>;
  key?: Maybe<Scalars['String']>;
  pointOfSaleCode?: Maybe<Scalars['Int']>;
  pointOfSaleName?: Maybe<Scalars['String']>;
  sendingStatus?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['Int']>;
};

export type Cuis = {
  __typename?: 'Cuis';
  branchOfficeCode?: Maybe<Scalars['Int']>;
  branchOfficeName?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['Int']>;
  enabled?: Maybe<Scalars['Boolean']>;
  expireAt?: Maybe<Scalars['Datetime']>;
  id?: Maybe<Scalars['Int']>;
  key?: Maybe<Scalars['String']>;
  pointOfSaleCode?: Maybe<Scalars['Int']>;
  pointOfSaleName?: Maybe<Scalars['String']>;
  sendingStatus?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['Int']>;
};

/** A filter to be used against Date fields. All fields are combined with a logical ‘and.’ */
export type DateFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['Date']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['Date']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['Date']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['Date']>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['Date']>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['Date']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['Date']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['Date']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['Date']>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars['Date']>>;
};

/** A filter to be used against Datetime fields. All fields are combined with a logical ‘and.’ */
export type DatetimeFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['Datetime']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['Datetime']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['Datetime']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['Datetime']>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['Datetime']>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['Datetime']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['Datetime']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['Datetime']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['Datetime']>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars['Datetime']>>;
};

export type DelegatedToken = {
  __typename?: 'DelegatedToken';
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['Int']>;
  delegatedNit?: Maybe<Scalars['BigInt']>;
  enabled?: Maybe<Scalars['Boolean']>;
  expireAt?: Maybe<Scalars['Datetime']>;
  id?: Maybe<Scalars['Int']>;
  issuedAt?: Maybe<Scalars['Datetime']>;
  key?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  nit?: Maybe<Scalars['String']>;
  revoked?: Maybe<Scalars['Boolean']>;
  subsystem?: Maybe<Scalars['String']>;
  systemCode?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['Int']>;
  version?: Maybe<Scalars['String']>;
};

/** All input for the `deleteClient` mutation. */
export type DeleteClientInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  key: Scalars['String'];
};

/** The output of our `deleteClient` mutation. */
export type DeleteClientPayload = {
  __typename?: 'DeleteClientPayload';
  client?: Maybe<Client>;
  /** An edge for our `Client`. May be used by Relay 1. */
  clientEdge?: Maybe<ClientsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `deleteExportServiceOrderByNodeId` mutation. */
export type DeleteExportServiceOrderByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `ExportServiceOrder` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteExportServiceOrderByOrderKey` mutation. */
export type DeleteExportServiceOrderByOrderKeyInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  orderKey: Scalars['String'];
};

/** All input for the `deleteExportServiceOrderDetailByNodeId` mutation. */
export type DeleteExportServiceOrderDetailByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `ExportServiceOrderDetail` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteExportServiceOrderDetail` mutation. */
export type DeleteExportServiceOrderDetailInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  key: Scalars['String'];
};

/** The output of our delete `ExportServiceOrderDetail` mutation. */
export type DeleteExportServiceOrderDetailPayload = {
  __typename?: 'DeleteExportServiceOrderDetailPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedExportServiceOrderDetailNodeId?: Maybe<Scalars['ID']>;
  /** Reads a single `ExportServiceOrder` that is related to this `ExportServiceOrderDetail`. */
  exportServiceOrder?: Maybe<ExportServiceOrder>;
  /** The `ExportServiceOrderDetail` that was deleted by this mutation. */
  exportServiceOrderDetail?: Maybe<ExportServiceOrderDetail>;
  /** An edge for our `ExportServiceOrderDetail`. May be used by Relay 1. */
  exportServiceOrderDetailEdge?: Maybe<ExportServiceOrderDetailsEdge>;
  /** Reads a single `Product` that is related to this `ExportServiceOrderDetail`. */
  productService?: Maybe<Product>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our delete `ExportServiceOrderDetail` mutation. */
export type DeleteExportServiceOrderDetailPayloadExportServiceOrderDetailEdgeArgs = {
  orderBy?: InputMaybe<Array<ExportServiceOrderDetailsOrderBy>>;
};

/** All input for the `deleteExportServiceOrder` mutation. */
export type DeleteExportServiceOrderInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  key: Scalars['String'];
};

/** The output of our delete `ExportServiceOrder` mutation. */
export type DeleteExportServiceOrderPayload = {
  __typename?: 'DeleteExportServiceOrderPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedExportServiceOrderNodeId?: Maybe<Scalars['ID']>;
  /** The `ExportServiceOrder` that was deleted by this mutation. */
  exportServiceOrder?: Maybe<ExportServiceOrder>;
  /** An edge for our `ExportServiceOrder`. May be used by Relay 1. */
  exportServiceOrderEdge?: Maybe<ExportServiceOrdersEdge>;
  /** Reads a single `Order` that is related to this `ExportServiceOrder`. */
  order?: Maybe<Order>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our delete `ExportServiceOrder` mutation. */
export type DeleteExportServiceOrderPayloadExportServiceOrderEdgeArgs = {
  orderBy?: InputMaybe<Array<ExportServiceOrdersOrderBy>>;
};

/** All input for the `deleteInsuranceOrderByNodeId` mutation. */
export type DeleteInsuranceOrderByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `InsuranceOrder` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteInsuranceOrderByOrderKey` mutation. */
export type DeleteInsuranceOrderByOrderKeyInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  orderKey: Scalars['String'];
};

/** All input for the `deleteInsuranceOrderDetailByNodeId` mutation. */
export type DeleteInsuranceOrderDetailByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `InsuranceOrderDetail` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteInsuranceOrderDetail` mutation. */
export type DeleteInsuranceOrderDetailInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  key: Scalars['String'];
};

/** The output of our delete `InsuranceOrderDetail` mutation. */
export type DeleteInsuranceOrderDetailPayload = {
  __typename?: 'DeleteInsuranceOrderDetailPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedInsuranceOrderDetailNodeId?: Maybe<Scalars['ID']>;
  /** Reads a single `InsuranceOrder` that is related to this `InsuranceOrderDetail`. */
  insuranceOrder?: Maybe<InsuranceOrder>;
  /** The `InsuranceOrderDetail` that was deleted by this mutation. */
  insuranceOrderDetail?: Maybe<InsuranceOrderDetail>;
  /** An edge for our `InsuranceOrderDetail`. May be used by Relay 1. */
  insuranceOrderDetailEdge?: Maybe<InsuranceOrderDetailsEdge>;
  /** Reads a single `Product` that is related to this `InsuranceOrderDetail`. */
  productService?: Maybe<Product>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our delete `InsuranceOrderDetail` mutation. */
export type DeleteInsuranceOrderDetailPayloadInsuranceOrderDetailEdgeArgs = {
  orderBy?: InputMaybe<Array<InsuranceOrderDetailsOrderBy>>;
};

/** All input for the `deleteInsuranceOrder` mutation. */
export type DeleteInsuranceOrderInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  key: Scalars['String'];
};

/** The output of our delete `InsuranceOrder` mutation. */
export type DeleteInsuranceOrderPayload = {
  __typename?: 'DeleteInsuranceOrderPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedInsuranceOrderNodeId?: Maybe<Scalars['ID']>;
  /** The `InsuranceOrder` that was deleted by this mutation. */
  insuranceOrder?: Maybe<InsuranceOrder>;
  /** An edge for our `InsuranceOrder`. May be used by Relay 1. */
  insuranceOrderEdge?: Maybe<InsuranceOrdersEdge>;
  /** Reads a single `Order` that is related to this `InsuranceOrder`. */
  order?: Maybe<Order>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our delete `InsuranceOrder` mutation. */
export type DeleteInsuranceOrderPayloadInsuranceOrderEdgeArgs = {
  orderBy?: InputMaybe<Array<InsuranceOrdersOrderBy>>;
};

/** All input for the `deletePointOfSaleUserByNodeId` mutation. */
export type DeletePointOfSaleUserByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `PointOfSaleUser` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deletePointOfSaleUser` mutation. */
export type DeletePointOfSaleUserInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  key: Scalars['String'];
};

/** The output of our delete `PointOfSaleUser` mutation. */
export type DeletePointOfSaleUserPayload = {
  __typename?: 'DeletePointOfSaleUserPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedPointOfSaleUserNodeId?: Maybe<Scalars['ID']>;
  /** Reads a single `PointOfSale` that is related to this `PointOfSaleUser`. */
  pointOfSale?: Maybe<PointOfSale>;
  /** The `PointOfSaleUser` that was deleted by this mutation. */
  pointOfSaleUser?: Maybe<PointOfSaleUser>;
  /** An edge for our `PointOfSaleUser`. May be used by Relay 1. */
  pointOfSaleUserEdge?: Maybe<PointOfSaleUsersEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `PointOfSaleUser`. */
  user?: Maybe<User>;
};

/** All input for the `deleteRentalOrderByNodeId` mutation. */
export type DeleteRentalOrderByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `RentalOrder` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteRentalOrderByOrderKey` mutation. */
export type DeleteRentalOrderByOrderKeyInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  orderKey: Scalars['String'];
};

/** All input for the `deleteRentalOrderDetailByNodeId` mutation. */
export type DeleteRentalOrderDetailByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `RentalOrderDetail` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteRentalOrderDetail` mutation. */
export type DeleteRentalOrderDetailInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  key: Scalars['String'];
};

/** The output of our delete `RentalOrderDetail` mutation. */
export type DeleteRentalOrderDetailPayload = {
  __typename?: 'DeleteRentalOrderDetailPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedRentalOrderDetailNodeId?: Maybe<Scalars['ID']>;
  /** Reads a single `Product` that is related to this `RentalOrderDetail`. */
  productService?: Maybe<Product>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `RentalOrder` that is related to this `RentalOrderDetail`. */
  rentalOrder?: Maybe<RentalOrder>;
  /** The `RentalOrderDetail` that was deleted by this mutation. */
  rentalOrderDetail?: Maybe<RentalOrderDetail>;
  /** An edge for our `RentalOrderDetail`. May be used by Relay 1. */
  rentalOrderDetailEdge?: Maybe<RentalOrderDetailsEdge>;
};


/** The output of our delete `RentalOrderDetail` mutation. */
export type DeleteRentalOrderDetailPayloadRentalOrderDetailEdgeArgs = {
  orderBy?: InputMaybe<Array<RentalOrderDetailsOrderBy>>;
};

/** All input for the `deleteRentalOrder` mutation. */
export type DeleteRentalOrderInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  key: Scalars['String'];
};

/** The output of our delete `RentalOrder` mutation. */
export type DeleteRentalOrderPayload = {
  __typename?: 'DeleteRentalOrderPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedRentalOrderNodeId?: Maybe<Scalars['ID']>;
  /** Reads a single `Order` that is related to this `RentalOrder`. */
  order?: Maybe<Order>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `RentalOrder` that was deleted by this mutation. */
  rentalOrder?: Maybe<RentalOrder>;
  /** An edge for our `RentalOrder`. May be used by Relay 1. */
  rentalOrderEdge?: Maybe<RentalOrdersEdge>;
};


/** The output of our delete `RentalOrder` mutation. */
export type DeleteRentalOrderPayloadRentalOrderEdgeArgs = {
  orderBy?: InputMaybe<Array<RentalOrdersOrderBy>>;
};

/** All input for the `deleteTouristServiceOrderByNodeId` mutation. */
export type DeleteTouristServiceOrderByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `TouristServiceOrder` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteTouristServiceOrderByOrderKey` mutation. */
export type DeleteTouristServiceOrderByOrderKeyInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  orderKey: Scalars['String'];
};

/** All input for the `deleteTouristServiceOrderDetailByNodeId` mutation. */
export type DeleteTouristServiceOrderDetailByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `TouristServiceOrderDetail` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteTouristServiceOrderDetail` mutation. */
export type DeleteTouristServiceOrderDetailInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  key: Scalars['String'];
};

/** The output of our delete `TouristServiceOrderDetail` mutation. */
export type DeleteTouristServiceOrderDetailPayload = {
  __typename?: 'DeleteTouristServiceOrderDetailPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedTouristServiceOrderDetailNodeId?: Maybe<Scalars['ID']>;
  /** Reads a single `Product` that is related to this `TouristServiceOrderDetail`. */
  productService?: Maybe<Product>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `TouristServiceOrder` that is related to this `TouristServiceOrderDetail`. */
  touristServiceOrder?: Maybe<TouristServiceOrder>;
  /** The `TouristServiceOrderDetail` that was deleted by this mutation. */
  touristServiceOrderDetail?: Maybe<TouristServiceOrderDetail>;
  /** An edge for our `TouristServiceOrderDetail`. May be used by Relay 1. */
  touristServiceOrderDetailEdge?: Maybe<TouristServiceOrderDetailsEdge>;
};


/** The output of our delete `TouristServiceOrderDetail` mutation. */
export type DeleteTouristServiceOrderDetailPayloadTouristServiceOrderDetailEdgeArgs = {
  orderBy?: InputMaybe<Array<TouristServiceOrderDetailsOrderBy>>;
};

/** All input for the `deleteTouristServiceOrder` mutation. */
export type DeleteTouristServiceOrderInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  key: Scalars['String'];
};

/** The output of our delete `TouristServiceOrder` mutation. */
export type DeleteTouristServiceOrderPayload = {
  __typename?: 'DeleteTouristServiceOrderPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedTouristServiceOrderNodeId?: Maybe<Scalars['ID']>;
  /** Reads a single `Order` that is related to this `TouristServiceOrder`. */
  order?: Maybe<Order>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `TouristServiceOrder` that was deleted by this mutation. */
  touristServiceOrder?: Maybe<TouristServiceOrder>;
  /** An edge for our `TouristServiceOrder`. May be used by Relay 1. */
  touristServiceOrderEdge?: Maybe<TouristServiceOrdersEdge>;
};


/** The output of our delete `TouristServiceOrder` mutation. */
export type DeleteTouristServiceOrderPayloadTouristServiceOrderEdgeArgs = {
  orderBy?: InputMaybe<Array<TouristServiceOrdersOrderBy>>;
};

/** Firma digital */
export type DigitalSignature = Node & {
  __typename?: 'DigitalSignature';
  createdAt: Scalars['Datetime'];
  enabled: Scalars['Boolean'];
  expireAt: Scalars['Datetime'];
  issuedAt: Scalars['Datetime'];
  issuerCommonName: Scalars['String'];
  issuerCountry: Scalars['String'];
  issuerOrganization: Scalars['String'];
  /** Identificador */
  key: Scalars['String'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  revoked: Scalars['Boolean'];
  subjectBusinessName: Scalars['String'];
  subjectCountry: Scalars['String'];
  subjectDocumentComplement: Scalars['String'];
  subjectDocumentNumber: Scalars['String'];
  subjectDocumentType: Scalars['String'];
  subjectJobTitle: Scalars['String'];
  subjectLegalRepresentative: Scalars['String'];
  subjectNit: Scalars['String'];
  updatedAt?: Maybe<Scalars['Datetime']>;
};

/** Orden de renta. */
export type ExportServiceOrder = Node & {
  __typename?: 'ExportServiceOrder';
  createdAt: Scalars['Datetime'];
  createdBy: Scalars['Int'];
  deletedAt?: Maybe<Scalars['Datetime']>;
  deletedBy?: Maybe<Scalars['Int']>;
  deletion: Scalars['Boolean'];
  /** Reads and enables pagination through a set of `ExportServiceOrderDetail`. */
  exportServiceOrderDetails: ExportServiceOrderDetailsConnection;
  key: Scalars['String'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads a single `Order` that is related to this `ExportServiceOrder`. */
  order?: Maybe<Order>;
  orderKey: Scalars['String'];
  sinCodigoPais: Scalars['Int'];
  sinDireccionComprador: Scalars['String'];
  sinInformacionAdicional?: Maybe<Scalars['String']>;
  sinLugarDestino: Scalars['String'];
  tenantId: Scalars['Int'];
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['Int']>;
};


/** Orden de renta. */
export type ExportServiceOrderExportServiceOrderDetailsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ExportServiceOrderDetailCondition>;
  filter?: InputMaybe<ExportServiceOrderDetailFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ExportServiceOrderDetailsOrderBy>>;
};

/**
 * A condition to be used against `ExportServiceOrder` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type ExportServiceOrderCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `deletedAt` field. */
  deletedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `deletedBy` field. */
  deletedBy?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `deletion` field. */
  deletion?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `key` field. */
  key?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `orderKey` field. */
  orderKey?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `sinCodigoPais` field. */
  sinCodigoPais?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `sinDireccionComprador` field. */
  sinDireccionComprador?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `sinInformacionAdicional` field. */
  sinInformacionAdicional?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `sinLugarDestino` field. */
  sinLugarDestino?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `tenantId` field. */
  tenantId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedBy` field. */
  updatedBy?: InputMaybe<Scalars['Int']>;
};

/** Detalle de venta. */
export type ExportServiceOrderDetail = Node & {
  __typename?: 'ExportServiceOrderDetail';
  createdAt: Scalars['Datetime'];
  createdBy: Scalars['Int'];
  deletedAt?: Maybe<Scalars['Datetime']>;
  deletedBy?: Maybe<Scalars['Int']>;
  deletion: Scalars['Boolean'];
  discount: Scalars['BigFloat'];
  /** Reads a single `ExportServiceOrder` that is related to this `ExportServiceOrderDetail`. */
  exportServiceOrder?: Maybe<ExportServiceOrder>;
  exportServiceOrderKey: Scalars['String'];
  key: Scalars['String'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  price: Scalars['BigFloat'];
  productKey: Scalars['String'];
  /** Reads a single `Product` that is related to this `ExportServiceOrderDetail`. */
  productService?: Maybe<Product>;
  quantity: Scalars['Int'];
  subtotal: Scalars['BigFloat'];
  tenantId: Scalars['Int'];
  total: Scalars['BigFloat'];
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['Int']>;
};

/**
 * A condition to be used against `ExportServiceOrderDetail` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type ExportServiceOrderDetailCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `deletedAt` field. */
  deletedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `deletedBy` field. */
  deletedBy?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `deletion` field. */
  deletion?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `discount` field. */
  discount?: InputMaybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `exportServiceOrderKey` field. */
  exportServiceOrderKey?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `key` field. */
  key?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `price` field. */
  price?: InputMaybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `productKey` field. */
  productKey?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `quantity` field. */
  quantity?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `subtotal` field. */
  subtotal?: InputMaybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `tenantId` field. */
  tenantId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `total` field. */
  total?: InputMaybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedBy` field. */
  updatedBy?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against `ExportServiceOrderDetail` object types. All fields are combined with a logical ‘and.’ */
export type ExportServiceOrderDetailFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ExportServiceOrderDetailFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy?: InputMaybe<IntFilter>;
  /** Filter by the object’s `deletedAt` field. */
  deletedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `deletedBy` field. */
  deletedBy?: InputMaybe<IntFilter>;
  /** Filter by the object’s `deletion` field. */
  deletion?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `discount` field. */
  discount?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `exportServiceOrderKey` field. */
  exportServiceOrderKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `key` field. */
  key?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ExportServiceOrderDetailFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ExportServiceOrderDetailFilter>>;
  /** Filter by the object’s `price` field. */
  price?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `productKey` field. */
  productKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `quantity` field. */
  quantity?: InputMaybe<IntFilter>;
  /** Filter by the object’s `subtotal` field. */
  subtotal?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `tenantId` field. */
  tenantId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `total` field. */
  total?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `updatedBy` field. */
  updatedBy?: InputMaybe<IntFilter>;
};

/** An input for mutations affecting `ExportServiceOrderDetail` */
export type ExportServiceOrderDetailInput = {
  discount: Scalars['BigFloat'];
  exportServiceOrder?: InputMaybe<FkExportServiceOrderDetailToExportServiceOrderInput>;
  exportServiceOrderKey?: InputMaybe<Scalars['String']>;
  price: Scalars['BigFloat'];
  productKey?: InputMaybe<Scalars['String']>;
  productService?: InputMaybe<FkExportServiceOrderDetailToProductInput>;
  quantity: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to connect. */
export type ExportServiceOrderDetailNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `exportServiceOrderDetail` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type ExportServiceOrderDetailNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `exportServiceOrderDetail` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type ExportServiceOrderDetailOnExportServiceOrderDetailForFkExportServiceOrderDetailToExportServiceOrderNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `exportServiceOrder` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `exportServiceOrder` being updated. */
  patch: ExportServiceOrderPatch;
};

/** The fields on `exportServiceOrderDetail` to look up the row to update. */
export type ExportServiceOrderDetailOnExportServiceOrderDetailForFkExportServiceOrderDetailToExportServiceOrderUsingPkExportServiceOrderDetailUpdate = {
  key: Scalars['String'];
  /** An object where the defined keys will be set on the `exportServiceOrderDetail` being updated. */
  patch: UpdateExportServiceOrderDetailOnExportServiceOrderDetailForFkExportServiceOrderDetailToExportServiceOrderPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type ExportServiceOrderDetailOnExportServiceOrderDetailForFkExportServiceOrderDetailToProductNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `product` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `product` being updated. */
  patch: ProductPatch;
};

/** The fields on `exportServiceOrderDetail` to look up the row to update. */
export type ExportServiceOrderDetailOnExportServiceOrderDetailForFkExportServiceOrderDetailToProductUsingPkExportServiceOrderDetailUpdate = {
  key: Scalars['String'];
  /** An object where the defined keys will be set on the `exportServiceOrderDetail` being updated. */
  patch: UpdateExportServiceOrderDetailOnExportServiceOrderDetailForFkExportServiceOrderDetailToProductPatch;
};

/** Represents an update to a `ExportServiceOrderDetail`. Fields that are set will be updated. */
export type ExportServiceOrderDetailPatch = {
  discount?: InputMaybe<Scalars['BigFloat']>;
  exportServiceOrder?: InputMaybe<FkExportServiceOrderDetailToExportServiceOrderInput>;
  exportServiceOrderKey?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['BigFloat']>;
  productKey?: InputMaybe<Scalars['String']>;
  productService?: InputMaybe<FkExportServiceOrderDetailToProductInput>;
  quantity?: InputMaybe<Scalars['Int']>;
};

/** The fields on `exportServiceOrderDetail` to look up the row to connect. */
export type ExportServiceOrderDetailPkExportServiceOrderDetailConnect = {
  key: Scalars['String'];
};

/** The fields on `exportServiceOrderDetail` to look up the row to delete. */
export type ExportServiceOrderDetailPkExportServiceOrderDetailDelete = {
  key: Scalars['String'];
};

/** A connection to a list of `ExportServiceOrderDetail` values. */
export type ExportServiceOrderDetailsConnection = {
  __typename?: 'ExportServiceOrderDetailsConnection';
  /** A list of edges which contains the `ExportServiceOrderDetail` and cursor to aid in pagination. */
  edges: Array<ExportServiceOrderDetailsEdge>;
  /** A list of `ExportServiceOrderDetail` objects. */
  nodes: Array<Maybe<ExportServiceOrderDetail>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ExportServiceOrderDetail` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ExportServiceOrderDetail` edge in the connection. */
export type ExportServiceOrderDetailsEdge = {
  __typename?: 'ExportServiceOrderDetailsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `ExportServiceOrderDetail` at the end of the edge. */
  node?: Maybe<ExportServiceOrderDetail>;
};

/** Methods to use when ordering `ExportServiceOrderDetail`. */
export enum ExportServiceOrderDetailsOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  DeletedAtAsc = 'DELETED_AT_ASC',
  DeletedAtDesc = 'DELETED_AT_DESC',
  DeletedByAsc = 'DELETED_BY_ASC',
  DeletedByDesc = 'DELETED_BY_DESC',
  DeletionAsc = 'DELETION_ASC',
  DeletionDesc = 'DELETION_DESC',
  DiscountAsc = 'DISCOUNT_ASC',
  DiscountDesc = 'DISCOUNT_DESC',
  ExportServiceOrderByExportServiceOrderKeyCreatedAtAsc = 'EXPORT_SERVICE_ORDER_BY_EXPORT_SERVICE_ORDER_KEY__CREATED_AT_ASC',
  ExportServiceOrderByExportServiceOrderKeyCreatedAtDesc = 'EXPORT_SERVICE_ORDER_BY_EXPORT_SERVICE_ORDER_KEY__CREATED_AT_DESC',
  ExportServiceOrderByExportServiceOrderKeyCreatedByAsc = 'EXPORT_SERVICE_ORDER_BY_EXPORT_SERVICE_ORDER_KEY__CREATED_BY_ASC',
  ExportServiceOrderByExportServiceOrderKeyCreatedByDesc = 'EXPORT_SERVICE_ORDER_BY_EXPORT_SERVICE_ORDER_KEY__CREATED_BY_DESC',
  ExportServiceOrderByExportServiceOrderKeyDeletedAtAsc = 'EXPORT_SERVICE_ORDER_BY_EXPORT_SERVICE_ORDER_KEY__DELETED_AT_ASC',
  ExportServiceOrderByExportServiceOrderKeyDeletedAtDesc = 'EXPORT_SERVICE_ORDER_BY_EXPORT_SERVICE_ORDER_KEY__DELETED_AT_DESC',
  ExportServiceOrderByExportServiceOrderKeyDeletedByAsc = 'EXPORT_SERVICE_ORDER_BY_EXPORT_SERVICE_ORDER_KEY__DELETED_BY_ASC',
  ExportServiceOrderByExportServiceOrderKeyDeletedByDesc = 'EXPORT_SERVICE_ORDER_BY_EXPORT_SERVICE_ORDER_KEY__DELETED_BY_DESC',
  ExportServiceOrderByExportServiceOrderKeyDeletionAsc = 'EXPORT_SERVICE_ORDER_BY_EXPORT_SERVICE_ORDER_KEY__DELETION_ASC',
  ExportServiceOrderByExportServiceOrderKeyDeletionDesc = 'EXPORT_SERVICE_ORDER_BY_EXPORT_SERVICE_ORDER_KEY__DELETION_DESC',
  ExportServiceOrderByExportServiceOrderKeyKeyAsc = 'EXPORT_SERVICE_ORDER_BY_EXPORT_SERVICE_ORDER_KEY__KEY_ASC',
  ExportServiceOrderByExportServiceOrderKeyKeyDesc = 'EXPORT_SERVICE_ORDER_BY_EXPORT_SERVICE_ORDER_KEY__KEY_DESC',
  ExportServiceOrderByExportServiceOrderKeyOrderKeyAsc = 'EXPORT_SERVICE_ORDER_BY_EXPORT_SERVICE_ORDER_KEY__ORDER_KEY_ASC',
  ExportServiceOrderByExportServiceOrderKeyOrderKeyDesc = 'EXPORT_SERVICE_ORDER_BY_EXPORT_SERVICE_ORDER_KEY__ORDER_KEY_DESC',
  ExportServiceOrderByExportServiceOrderKeySinCodigoPaisAsc = 'EXPORT_SERVICE_ORDER_BY_EXPORT_SERVICE_ORDER_KEY__SIN_CODIGO_PAIS_ASC',
  ExportServiceOrderByExportServiceOrderKeySinCodigoPaisDesc = 'EXPORT_SERVICE_ORDER_BY_EXPORT_SERVICE_ORDER_KEY__SIN_CODIGO_PAIS_DESC',
  ExportServiceOrderByExportServiceOrderKeySinDireccionCompradorAsc = 'EXPORT_SERVICE_ORDER_BY_EXPORT_SERVICE_ORDER_KEY__SIN_DIRECCION_COMPRADOR_ASC',
  ExportServiceOrderByExportServiceOrderKeySinDireccionCompradorDesc = 'EXPORT_SERVICE_ORDER_BY_EXPORT_SERVICE_ORDER_KEY__SIN_DIRECCION_COMPRADOR_DESC',
  ExportServiceOrderByExportServiceOrderKeySinInformacionAdicionalAsc = 'EXPORT_SERVICE_ORDER_BY_EXPORT_SERVICE_ORDER_KEY__SIN_INFORMACION_ADICIONAL_ASC',
  ExportServiceOrderByExportServiceOrderKeySinInformacionAdicionalDesc = 'EXPORT_SERVICE_ORDER_BY_EXPORT_SERVICE_ORDER_KEY__SIN_INFORMACION_ADICIONAL_DESC',
  ExportServiceOrderByExportServiceOrderKeySinLugarDestinoAsc = 'EXPORT_SERVICE_ORDER_BY_EXPORT_SERVICE_ORDER_KEY__SIN_LUGAR_DESTINO_ASC',
  ExportServiceOrderByExportServiceOrderKeySinLugarDestinoDesc = 'EXPORT_SERVICE_ORDER_BY_EXPORT_SERVICE_ORDER_KEY__SIN_LUGAR_DESTINO_DESC',
  ExportServiceOrderByExportServiceOrderKeyTenantIdAsc = 'EXPORT_SERVICE_ORDER_BY_EXPORT_SERVICE_ORDER_KEY__TENANT_ID_ASC',
  ExportServiceOrderByExportServiceOrderKeyTenantIdDesc = 'EXPORT_SERVICE_ORDER_BY_EXPORT_SERVICE_ORDER_KEY__TENANT_ID_DESC',
  ExportServiceOrderByExportServiceOrderKeyUpdatedAtAsc = 'EXPORT_SERVICE_ORDER_BY_EXPORT_SERVICE_ORDER_KEY__UPDATED_AT_ASC',
  ExportServiceOrderByExportServiceOrderKeyUpdatedAtDesc = 'EXPORT_SERVICE_ORDER_BY_EXPORT_SERVICE_ORDER_KEY__UPDATED_AT_DESC',
  ExportServiceOrderByExportServiceOrderKeyUpdatedByAsc = 'EXPORT_SERVICE_ORDER_BY_EXPORT_SERVICE_ORDER_KEY__UPDATED_BY_ASC',
  ExportServiceOrderByExportServiceOrderKeyUpdatedByDesc = 'EXPORT_SERVICE_ORDER_BY_EXPORT_SERVICE_ORDER_KEY__UPDATED_BY_DESC',
  ExportServiceOrderKeyAsc = 'EXPORT_SERVICE_ORDER_KEY_ASC',
  ExportServiceOrderKeyDesc = 'EXPORT_SERVICE_ORDER_KEY_DESC',
  KeyAsc = 'KEY_ASC',
  KeyDesc = 'KEY_DESC',
  Natural = 'NATURAL',
  PriceAsc = 'PRICE_ASC',
  PriceDesc = 'PRICE_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ProductByProductKeyCodeAsc = 'PRODUCT_BY_PRODUCT_KEY__CODE_ASC',
  ProductByProductKeyCodeDesc = 'PRODUCT_BY_PRODUCT_KEY__CODE_DESC',
  ProductByProductKeyCreatedAtAsc = 'PRODUCT_BY_PRODUCT_KEY__CREATED_AT_ASC',
  ProductByProductKeyCreatedAtDesc = 'PRODUCT_BY_PRODUCT_KEY__CREATED_AT_DESC',
  ProductByProductKeyCreatedByAsc = 'PRODUCT_BY_PRODUCT_KEY__CREATED_BY_ASC',
  ProductByProductKeyCreatedByDesc = 'PRODUCT_BY_PRODUCT_KEY__CREATED_BY_DESC',
  ProductByProductKeyDeletedAtAsc = 'PRODUCT_BY_PRODUCT_KEY__DELETED_AT_ASC',
  ProductByProductKeyDeletedAtDesc = 'PRODUCT_BY_PRODUCT_KEY__DELETED_AT_DESC',
  ProductByProductKeyDeletedByAsc = 'PRODUCT_BY_PRODUCT_KEY__DELETED_BY_ASC',
  ProductByProductKeyDeletedByDesc = 'PRODUCT_BY_PRODUCT_KEY__DELETED_BY_DESC',
  ProductByProductKeyDeletionAsc = 'PRODUCT_BY_PRODUCT_KEY__DELETION_ASC',
  ProductByProductKeyDeletionDesc = 'PRODUCT_BY_PRODUCT_KEY__DELETION_DESC',
  ProductByProductKeyDescriptionAsc = 'PRODUCT_BY_PRODUCT_KEY__DESCRIPTION_ASC',
  ProductByProductKeyDescriptionDesc = 'PRODUCT_BY_PRODUCT_KEY__DESCRIPTION_DESC',
  ProductByProductKeyKeyAsc = 'PRODUCT_BY_PRODUCT_KEY__KEY_ASC',
  ProductByProductKeyKeyDesc = 'PRODUCT_BY_PRODUCT_KEY__KEY_DESC',
  ProductByProductKeyNameAsc = 'PRODUCT_BY_PRODUCT_KEY__NAME_ASC',
  ProductByProductKeyNameDesc = 'PRODUCT_BY_PRODUCT_KEY__NAME_DESC',
  ProductByProductKeyPriceAsc = 'PRODUCT_BY_PRODUCT_KEY__PRICE_ASC',
  ProductByProductKeyPriceDesc = 'PRODUCT_BY_PRODUCT_KEY__PRICE_DESC',
  ProductByProductKeyTenantIdAsc = 'PRODUCT_BY_PRODUCT_KEY__TENANT_ID_ASC',
  ProductByProductKeyTenantIdDesc = 'PRODUCT_BY_PRODUCT_KEY__TENANT_ID_DESC',
  ProductByProductKeyTypeAsc = 'PRODUCT_BY_PRODUCT_KEY__TYPE_ASC',
  ProductByProductKeyTypeDesc = 'PRODUCT_BY_PRODUCT_KEY__TYPE_DESC',
  ProductByProductKeyUpdatedAtAsc = 'PRODUCT_BY_PRODUCT_KEY__UPDATED_AT_ASC',
  ProductByProductKeyUpdatedAtDesc = 'PRODUCT_BY_PRODUCT_KEY__UPDATED_AT_DESC',
  ProductByProductKeyUpdatedByAsc = 'PRODUCT_BY_PRODUCT_KEY__UPDATED_BY_ASC',
  ProductByProductKeyUpdatedByDesc = 'PRODUCT_BY_PRODUCT_KEY__UPDATED_BY_DESC',
  ProductKeyAsc = 'PRODUCT_KEY_ASC',
  ProductKeyDesc = 'PRODUCT_KEY_DESC',
  QuantityAsc = 'QUANTITY_ASC',
  QuantityDesc = 'QUANTITY_DESC',
  SubtotalAsc = 'SUBTOTAL_ASC',
  SubtotalDesc = 'SUBTOTAL_DESC',
  TenantIdAsc = 'TENANT_ID_ASC',
  TenantIdDesc = 'TENANT_ID_DESC',
  TotalAsc = 'TOTAL_ASC',
  TotalDesc = 'TOTAL_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UpdatedByAsc = 'UPDATED_BY_ASC',
  UpdatedByDesc = 'UPDATED_BY_DESC'
}

/** A filter to be used against `ExportServiceOrder` object types. All fields are combined with a logical ‘and.’ */
export type ExportServiceOrderFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ExportServiceOrderFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy?: InputMaybe<IntFilter>;
  /** Filter by the object’s `deletedAt` field. */
  deletedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `deletedBy` field. */
  deletedBy?: InputMaybe<IntFilter>;
  /** Filter by the object’s `deletion` field. */
  deletion?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `key` field. */
  key?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ExportServiceOrderFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ExportServiceOrderFilter>>;
  /** Filter by the object’s `orderKey` field. */
  orderKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `sinCodigoPais` field. */
  sinCodigoPais?: InputMaybe<IntFilter>;
  /** Filter by the object’s `sinDireccionComprador` field. */
  sinDireccionComprador?: InputMaybe<StringFilter>;
  /** Filter by the object’s `sinInformacionAdicional` field. */
  sinInformacionAdicional?: InputMaybe<StringFilter>;
  /** Filter by the object’s `sinLugarDestino` field. */
  sinLugarDestino?: InputMaybe<StringFilter>;
  /** Filter by the object’s `tenantId` field. */
  tenantId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `updatedBy` field. */
  updatedBy?: InputMaybe<IntFilter>;
};

/** An input for mutations affecting `ExportServiceOrder` */
export type ExportServiceOrderInput = {
  deletion?: InputMaybe<Scalars['Boolean']>;
  exportServiceOrderDetails?: InputMaybe<FkExportServiceOrderDetailToExportServiceOrderInverseInput>;
  order?: InputMaybe<FkExportServiceOrderToOrderInput>;
  orderKey?: InputMaybe<Scalars['String']>;
  sinCodigoPais: Scalars['Int'];
  sinDireccionComprador: Scalars['String'];
  sinInformacionAdicional?: InputMaybe<Scalars['String']>;
  sinLugarDestino: Scalars['String'];
};

/** The globally unique `ID` look up for the row to connect. */
export type ExportServiceOrderNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `exportServiceOrder` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type ExportServiceOrderNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `exportServiceOrder` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type ExportServiceOrderOnExportServiceOrderDetailForFkExportServiceOrderDetailToExportServiceOrderNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `exportServiceOrderDetail` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `exportServiceOrderDetail` being updated. */
  patch: ExportServiceOrderDetailPatch;
};

/** The fields on `exportServiceOrder` to look up the row to update. */
export type ExportServiceOrderOnExportServiceOrderDetailForFkExportServiceOrderDetailToExportServiceOrderUsingPkExportServiceOrderUpdate = {
  key: Scalars['String'];
  /** An object where the defined keys will be set on the `exportServiceOrder` being updated. */
  patch: UpdateExportServiceOrderOnExportServiceOrderDetailForFkExportServiceOrderDetailToExportServiceOrderPatch;
};

/** The fields on `exportServiceOrder` to look up the row to update. */
export type ExportServiceOrderOnExportServiceOrderDetailForFkExportServiceOrderDetailToExportServiceOrderUsingUkExportServiceOrderOrderKeyUpdate = {
  orderKey: Scalars['String'];
  /** An object where the defined keys will be set on the `exportServiceOrder` being updated. */
  patch: UpdateExportServiceOrderOnExportServiceOrderDetailForFkExportServiceOrderDetailToExportServiceOrderPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type ExportServiceOrderOnExportServiceOrderForFkExportServiceOrderToOrderNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `order` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `order` being updated. */
  patch: OrderPatch;
};

/** The fields on `exportServiceOrder` to look up the row to update. */
export type ExportServiceOrderOnExportServiceOrderForFkExportServiceOrderToOrderUsingPkExportServiceOrderUpdate = {
  key: Scalars['String'];
  /** An object where the defined keys will be set on the `exportServiceOrder` being updated. */
  patch: UpdateExportServiceOrderOnExportServiceOrderForFkExportServiceOrderToOrderPatch;
};

/** The fields on `exportServiceOrder` to look up the row to update. */
export type ExportServiceOrderOnExportServiceOrderForFkExportServiceOrderToOrderUsingUkExportServiceOrderOrderKeyUpdate = {
  orderKey: Scalars['String'];
  /** An object where the defined keys will be set on the `exportServiceOrder` being updated. */
  patch: UpdateExportServiceOrderOnExportServiceOrderForFkExportServiceOrderToOrderPatch;
};

/** Represents an update to a `ExportServiceOrder`. Fields that are set will be updated. */
export type ExportServiceOrderPatch = {
  deletion?: InputMaybe<Scalars['Boolean']>;
  exportServiceOrderDetails?: InputMaybe<FkExportServiceOrderDetailToExportServiceOrderInverseInput>;
  order?: InputMaybe<FkExportServiceOrderToOrderInput>;
  orderKey?: InputMaybe<Scalars['String']>;
  sinCodigoPais?: InputMaybe<Scalars['Int']>;
  sinDireccionComprador?: InputMaybe<Scalars['String']>;
  sinInformacionAdicional?: InputMaybe<Scalars['String']>;
  sinLugarDestino?: InputMaybe<Scalars['String']>;
};

/** The fields on `exportServiceOrder` to look up the row to connect. */
export type ExportServiceOrderPkExportServiceOrderConnect = {
  key: Scalars['String'];
};

/** The fields on `exportServiceOrder` to look up the row to delete. */
export type ExportServiceOrderPkExportServiceOrderDelete = {
  key: Scalars['String'];
};

/** The fields on `exportServiceOrder` to look up the row to connect. */
export type ExportServiceOrderUkExportServiceOrderOrderKeyConnect = {
  orderKey: Scalars['String'];
};

/** The fields on `exportServiceOrder` to look up the row to delete. */
export type ExportServiceOrderUkExportServiceOrderOrderKeyDelete = {
  orderKey: Scalars['String'];
};

/** A connection to a list of `ExportServiceOrder` values. */
export type ExportServiceOrdersConnection = {
  __typename?: 'ExportServiceOrdersConnection';
  /** A list of edges which contains the `ExportServiceOrder` and cursor to aid in pagination. */
  edges: Array<ExportServiceOrdersEdge>;
  /** A list of `ExportServiceOrder` objects. */
  nodes: Array<Maybe<ExportServiceOrder>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ExportServiceOrder` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ExportServiceOrder` edge in the connection. */
export type ExportServiceOrdersEdge = {
  __typename?: 'ExportServiceOrdersEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `ExportServiceOrder` at the end of the edge. */
  node?: Maybe<ExportServiceOrder>;
};

/** Methods to use when ordering `ExportServiceOrder`. */
export enum ExportServiceOrdersOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  DeletedAtAsc = 'DELETED_AT_ASC',
  DeletedAtDesc = 'DELETED_AT_DESC',
  DeletedByAsc = 'DELETED_BY_ASC',
  DeletedByDesc = 'DELETED_BY_DESC',
  DeletionAsc = 'DELETION_ASC',
  DeletionDesc = 'DELETION_DESC',
  ExportServiceOrderDetailsByExportServiceOrderKeyCountAsc = 'EXPORT_SERVICE_ORDER_DETAILS_BY_EXPORT_SERVICE_ORDER_KEY__COUNT_ASC',
  ExportServiceOrderDetailsByExportServiceOrderKeyCountDesc = 'EXPORT_SERVICE_ORDER_DETAILS_BY_EXPORT_SERVICE_ORDER_KEY__COUNT_DESC',
  KeyAsc = 'KEY_ASC',
  KeyDesc = 'KEY_DESC',
  Natural = 'NATURAL',
  OrderByOrderKeyChangeAmountAsc = 'ORDER_BY_ORDER_KEY__CHANGE_AMOUNT_ASC',
  OrderByOrderKeyChangeAmountDesc = 'ORDER_BY_ORDER_KEY__CHANGE_AMOUNT_DESC',
  OrderByOrderKeyClientKeyAsc = 'ORDER_BY_ORDER_KEY__CLIENT_KEY_ASC',
  OrderByOrderKeyClientKeyDesc = 'ORDER_BY_ORDER_KEY__CLIENT_KEY_DESC',
  OrderByOrderKeyCreatedAtAsc = 'ORDER_BY_ORDER_KEY__CREATED_AT_ASC',
  OrderByOrderKeyCreatedAtDesc = 'ORDER_BY_ORDER_KEY__CREATED_AT_DESC',
  OrderByOrderKeyCreatedByAsc = 'ORDER_BY_ORDER_KEY__CREATED_BY_ASC',
  OrderByOrderKeyCreatedByDesc = 'ORDER_BY_ORDER_KEY__CREATED_BY_DESC',
  OrderByOrderKeyDeletedAtAsc = 'ORDER_BY_ORDER_KEY__DELETED_AT_ASC',
  OrderByOrderKeyDeletedAtDesc = 'ORDER_BY_ORDER_KEY__DELETED_AT_DESC',
  OrderByOrderKeyDeletedByAsc = 'ORDER_BY_ORDER_KEY__DELETED_BY_ASC',
  OrderByOrderKeyDeletedByDesc = 'ORDER_BY_ORDER_KEY__DELETED_BY_DESC',
  OrderByOrderKeyDeletionAsc = 'ORDER_BY_ORDER_KEY__DELETION_ASC',
  OrderByOrderKeyDeletionDesc = 'ORDER_BY_ORDER_KEY__DELETION_DESC',
  OrderByOrderKeyDiscountAsc = 'ORDER_BY_ORDER_KEY__DISCOUNT_ASC',
  OrderByOrderKeyDiscountDesc = 'ORDER_BY_ORDER_KEY__DISCOUNT_DESC',
  OrderByOrderKeyEmailAsc = 'ORDER_BY_ORDER_KEY__EMAIL_ASC',
  OrderByOrderKeyEmailDesc = 'ORDER_BY_ORDER_KEY__EMAIL_DESC',
  OrderByOrderKeyKeyAsc = 'ORDER_BY_ORDER_KEY__KEY_ASC',
  OrderByOrderKeyKeyDesc = 'ORDER_BY_ORDER_KEY__KEY_DESC',
  OrderByOrderKeyPaymentAmountAsc = 'ORDER_BY_ORDER_KEY__PAYMENT_AMOUNT_ASC',
  OrderByOrderKeyPaymentAmountDesc = 'ORDER_BY_ORDER_KEY__PAYMENT_AMOUNT_DESC',
  OrderByOrderKeyPhoneNumberAsc = 'ORDER_BY_ORDER_KEY__PHONE_NUMBER_ASC',
  OrderByOrderKeyPhoneNumberDesc = 'ORDER_BY_ORDER_KEY__PHONE_NUMBER_DESC',
  OrderByOrderKeyPointOfSaleKeyAsc = 'ORDER_BY_ORDER_KEY__POINT_OF_SALE_KEY_ASC',
  OrderByOrderKeyPointOfSaleKeyDesc = 'ORDER_BY_ORDER_KEY__POINT_OF_SALE_KEY_DESC',
  OrderByOrderKeyQuantityAsc = 'ORDER_BY_ORDER_KEY__QUANTITY_ASC',
  OrderByOrderKeyQuantityDesc = 'ORDER_BY_ORDER_KEY__QUANTITY_DESC',
  OrderByOrderKeyStatusAsc = 'ORDER_BY_ORDER_KEY__STATUS_ASC',
  OrderByOrderKeyStatusDesc = 'ORDER_BY_ORDER_KEY__STATUS_DESC',
  OrderByOrderKeySubtotalAsc = 'ORDER_BY_ORDER_KEY__SUBTOTAL_ASC',
  OrderByOrderKeySubtotalDesc = 'ORDER_BY_ORDER_KEY__SUBTOTAL_DESC',
  OrderByOrderKeyTenantIdAsc = 'ORDER_BY_ORDER_KEY__TENANT_ID_ASC',
  OrderByOrderKeyTenantIdDesc = 'ORDER_BY_ORDER_KEY__TENANT_ID_DESC',
  OrderByOrderKeyTotalAsc = 'ORDER_BY_ORDER_KEY__TOTAL_ASC',
  OrderByOrderKeyTotalDesc = 'ORDER_BY_ORDER_KEY__TOTAL_DESC',
  OrderByOrderKeyUpdatedAtAsc = 'ORDER_BY_ORDER_KEY__UPDATED_AT_ASC',
  OrderByOrderKeyUpdatedAtDesc = 'ORDER_BY_ORDER_KEY__UPDATED_AT_DESC',
  OrderByOrderKeyUpdatedByAsc = 'ORDER_BY_ORDER_KEY__UPDATED_BY_ASC',
  OrderByOrderKeyUpdatedByDesc = 'ORDER_BY_ORDER_KEY__UPDATED_BY_DESC',
  OrderKeyAsc = 'ORDER_KEY_ASC',
  OrderKeyDesc = 'ORDER_KEY_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  SinCodigoPaisAsc = 'SIN_CODIGO_PAIS_ASC',
  SinCodigoPaisDesc = 'SIN_CODIGO_PAIS_DESC',
  SinDireccionCompradorAsc = 'SIN_DIRECCION_COMPRADOR_ASC',
  SinDireccionCompradorDesc = 'SIN_DIRECCION_COMPRADOR_DESC',
  SinInformacionAdicionalAsc = 'SIN_INFORMACION_ADICIONAL_ASC',
  SinInformacionAdicionalDesc = 'SIN_INFORMACION_ADICIONAL_DESC',
  SinLugarDestinoAsc = 'SIN_LUGAR_DESTINO_ASC',
  SinLugarDestinoDesc = 'SIN_LUGAR_DESTINO_DESC',
  TenantIdAsc = 'TENANT_ID_ASC',
  TenantIdDesc = 'TENANT_ID_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UpdatedByAsc = 'UPDATED_BY_ASC',
  UpdatedByDesc = 'UPDATED_BY_DESC'
}

export type File = {
  __typename?: 'File';
  base64?: Maybe<Scalars['String']>;
  encoding?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  filesize?: Maybe<Scalars['BigFloat']>;
  mimetype?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['String']>;
};

/** A filter to be used against `File` object types. All fields are combined with a logical ‘and.’ */
export type FileFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<FileFilter>>;
  /** Filter by the object’s `base64` field. */
  base64?: InputMaybe<StringFilter>;
  /** Filter by the object’s `encoding` field. */
  encoding?: InputMaybe<StringFilter>;
  /** Filter by the object’s `filename` field. */
  filename?: InputMaybe<StringFilter>;
  /** Filter by the object’s `filesize` field. */
  filesize?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `mimetype` field. */
  mimetype?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<FileFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<FileFilter>>;
  /** Filter by the object’s `type` field. */
  type?: InputMaybe<StringFilter>;
  /** Filter by the object’s `uuid` field. */
  uuid?: InputMaybe<StringFilter>;
};

/** A connection to a list of `File` values. */
export type FilesConnection = {
  __typename?: 'FilesConnection';
  /** A list of edges which contains the `File` and cursor to aid in pagination. */
  edges: Array<FilesEdge>;
  /** A list of `File` objects. */
  nodes: Array<Maybe<File>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `File` edge in the connection. */
export type FilesEdge = {
  __typename?: 'FilesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `File` at the end of the edge. */
  node?: Maybe<File>;
};

/** Input for the nested mutation of `person` in the `UserInput` mutation. */
export type FkAppUserToPersonInput = {
  /** The primary key(s) for `person` for the far side of the relationship. */
  connectByKey?: InputMaybe<PersonPkPersonConnect>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<PersonNodeIdConnect>;
  /** A `PersonInput` object that will be created and connected to this object. */
  create?: InputMaybe<FkAppUserToPersonPersonCreateInput>;
  /** The primary key(s) and patch data for `person` for the far side of the relationship. */
  updateByKey?: InputMaybe<PersonOnUserForFkAppUserToPersonUsingPkPersonUpdate>;
  /** The primary key(s) and patch data for `person` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<UserOnUserForFkAppUserToPersonNodeIdUpdate>;
};

/** Input for the nested mutation of `user` in the `PersonInput` mutation. */
export type FkAppUserToPersonInverseInput = {
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectByKey?: InputMaybe<Array<UserPkUserConnect>>;
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<UserNodeIdConnect>>;
  /** A `UserInput` object that will be created and connected to this object. */
  create?: InputMaybe<Array<FkAppUserToPersonUserCreateInput>>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateByKey?: InputMaybe<Array<UserOnUserForFkAppUserToPersonUsingPkUserUpdate>>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<Array<PersonOnUserForFkAppUserToPersonNodeIdUpdate>>;
};

/** The `person` to be created by this mutation. */
export type FkAppUserToPersonPersonCreateInput = {
  firstName: Scalars['String'];
  firstSurname: Scalars['String'];
  middleName?: InputMaybe<Scalars['String']>;
  secondSurname?: InputMaybe<Scalars['String']>;
  users?: InputMaybe<FkAppUserToPersonInverseInput>;
};

/** The `user` to be created by this mutation. */
export type FkAppUserToPersonUserCreateInput = {
  email: Scalars['String'];
  person?: InputMaybe<FkAppUserToPersonInput>;
  pointOfSaleUsers?: InputMaybe<FkPointOfSaleUserToUserInverseInput>;
};

/** The `client` to be created by this mutation. */
export type FkClientHomologationToClientClientCreateInput = {
  createdAt?: InputMaybe<Scalars['Datetime']>;
  editable?: InputMaybe<Scalars['Boolean']>;
  email?: InputMaybe<Scalars['String']>;
  homologations?: InputMaybe<FkClientHomologationToClientInverseInput>;
  name: Scalars['String'];
  observation?: InputMaybe<Scalars['String']>;
  orders?: InputMaybe<FkOrderToClientInverseInput>;
  phoneNumber?: InputMaybe<Scalars['String']>;
};

/** The `clientHomologation` to be created by this mutation. */
export type FkClientHomologationToClientClientHomologationCreateInput = {
  /** [SIN] codigoExcepcion */
  allowInvalidNit?: InputMaybe<Scalars['Boolean']>;
  client?: InputMaybe<FkClientHomologationToClientInput>;
  /** [SIN] complemento */
  documentComplement?: InputMaybe<Scalars['String']>;
  /** [SIN] numeroDocumento */
  documentNumber: Scalars['String'];
  /** [SIN] codigoTipoDocumentoIdentidad */
  documentTypeCode: Scalars['Int'];
};

/** Input for the nested mutation of `client` in the `ClientHomologationInput` mutation. */
export type FkClientHomologationToClientInput = {
  /** The primary key(s) for `client` for the far side of the relationship. */
  connectByKey?: InputMaybe<ClientPkClientConnect>;
  /** The primary key(s) for `client` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<ClientNodeIdConnect>;
  /** A `ClientInput` object that will be created and connected to this object. */
  create?: InputMaybe<FkClientHomologationToClientClientCreateInput>;
  /** The primary key(s) and patch data for `client` for the far side of the relationship. */
  updateByKey?: InputMaybe<ClientOnClientHomologationForFkClientHomologationToClientUsingPkClientUpdate>;
  /** The primary key(s) and patch data for `client` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<ClientHomologationOnClientHomologationForFkClientHomologationToClientNodeIdUpdate>;
};

/** Input for the nested mutation of `clientHomologation` in the `ClientInput` mutation. */
export type FkClientHomologationToClientInverseInput = {
  /** The primary key(s) for `clientHomologation` for the far side of the relationship. */
  connectByClientKey?: InputMaybe<ClientHomologationUkClientHomologationClientIdConnect>;
  /** The primary key(s) for `clientHomologation` for the far side of the relationship. */
  connectByKey?: InputMaybe<ClientHomologationPkClientHomologationConnect>;
  /** The primary key(s) for `clientHomologation` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<ClientHomologationNodeIdConnect>;
  /** A `ClientHomologationInput` object that will be created and connected to this object. */
  create?: InputMaybe<Array<FkClientHomologationToClientClientHomologationCreateInput>>;
  /** The primary key(s) and patch data for `clientHomologation` for the far side of the relationship. */
  updateByClientKey?: InputMaybe<ClientHomologationOnClientHomologationForFkClientHomologationToClientUsingUkClientHomologationClientIdUpdate>;
  /** The primary key(s) and patch data for `clientHomologation` for the far side of the relationship. */
  updateByKey?: InputMaybe<ClientHomologationOnClientHomologationForFkClientHomologationToClientUsingPkClientHomologationUpdate>;
  /** The primary key(s) and patch data for `clientHomologation` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<ClientOnClientHomologationForFkClientHomologationToClientNodeIdUpdate>;
};

/** The `exportServiceOrder` to be created by this mutation. */
export type FkExportServiceOrderDetailToExportServiceOrderExportServiceOrderCreateInput = {
  deletion?: InputMaybe<Scalars['Boolean']>;
  exportServiceOrderDetails?: InputMaybe<FkExportServiceOrderDetailToExportServiceOrderInverseInput>;
  order?: InputMaybe<FkExportServiceOrderToOrderInput>;
  orderKey?: InputMaybe<Scalars['String']>;
  sinCodigoPais: Scalars['Int'];
  sinDireccionComprador: Scalars['String'];
  sinInformacionAdicional?: InputMaybe<Scalars['String']>;
  sinLugarDestino: Scalars['String'];
};

/** The `exportServiceOrderDetail` to be created by this mutation. */
export type FkExportServiceOrderDetailToExportServiceOrderExportServiceOrderDetailCreateInput = {
  discount: Scalars['BigFloat'];
  exportServiceOrder?: InputMaybe<FkExportServiceOrderDetailToExportServiceOrderInput>;
  price: Scalars['BigFloat'];
  productKey?: InputMaybe<Scalars['String']>;
  productService?: InputMaybe<FkExportServiceOrderDetailToProductInput>;
  quantity: Scalars['Int'];
};

/** Input for the nested mutation of `exportServiceOrder` in the `ExportServiceOrderDetailInput` mutation. */
export type FkExportServiceOrderDetailToExportServiceOrderInput = {
  /** The primary key(s) for `exportServiceOrder` for the far side of the relationship. */
  connectByKey?: InputMaybe<ExportServiceOrderPkExportServiceOrderConnect>;
  /** The primary key(s) for `exportServiceOrder` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<ExportServiceOrderNodeIdConnect>;
  /** The primary key(s) for `exportServiceOrder` for the far side of the relationship. */
  connectByOrderKey?: InputMaybe<ExportServiceOrderUkExportServiceOrderOrderKeyConnect>;
  /** A `ExportServiceOrderInput` object that will be created and connected to this object. */
  create?: InputMaybe<FkExportServiceOrderDetailToExportServiceOrderExportServiceOrderCreateInput>;
  /** The primary key(s) for `exportServiceOrder` for the far side of the relationship. */
  deleteByKey?: InputMaybe<ExportServiceOrderPkExportServiceOrderDelete>;
  /** The primary key(s) for `exportServiceOrder` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<ExportServiceOrderNodeIdDelete>;
  /** The primary key(s) for `exportServiceOrder` for the far side of the relationship. */
  deleteByOrderKey?: InputMaybe<ExportServiceOrderUkExportServiceOrderOrderKeyDelete>;
  /** The primary key(s) and patch data for `exportServiceOrder` for the far side of the relationship. */
  updateByKey?: InputMaybe<ExportServiceOrderOnExportServiceOrderDetailForFkExportServiceOrderDetailToExportServiceOrderUsingPkExportServiceOrderUpdate>;
  /** The primary key(s) and patch data for `exportServiceOrder` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<ExportServiceOrderDetailOnExportServiceOrderDetailForFkExportServiceOrderDetailToExportServiceOrderNodeIdUpdate>;
  /** The primary key(s) and patch data for `exportServiceOrder` for the far side of the relationship. */
  updateByOrderKey?: InputMaybe<ExportServiceOrderOnExportServiceOrderDetailForFkExportServiceOrderDetailToExportServiceOrderUsingUkExportServiceOrderOrderKeyUpdate>;
};

/** Input for the nested mutation of `exportServiceOrderDetail` in the `ExportServiceOrderInput` mutation. */
export type FkExportServiceOrderDetailToExportServiceOrderInverseInput = {
  /** The primary key(s) for `exportServiceOrderDetail` for the far side of the relationship. */
  connectByKey?: InputMaybe<Array<ExportServiceOrderDetailPkExportServiceOrderDetailConnect>>;
  /** The primary key(s) for `exportServiceOrderDetail` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<ExportServiceOrderDetailNodeIdConnect>>;
  /** A `ExportServiceOrderDetailInput` object that will be created and connected to this object. */
  create?: InputMaybe<Array<FkExportServiceOrderDetailToExportServiceOrderExportServiceOrderDetailCreateInput>>;
  /** The primary key(s) for `exportServiceOrderDetail` for the far side of the relationship. */
  deleteByKey?: InputMaybe<Array<ExportServiceOrderDetailPkExportServiceOrderDetailDelete>>;
  /** The primary key(s) for `exportServiceOrderDetail` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<Array<ExportServiceOrderDetailNodeIdDelete>>;
  /** Flag indicating whether all other `exportServiceOrderDetail` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars['Boolean']>;
  /** The primary key(s) and patch data for `exportServiceOrderDetail` for the far side of the relationship. */
  updateByKey?: InputMaybe<Array<ExportServiceOrderDetailOnExportServiceOrderDetailForFkExportServiceOrderDetailToExportServiceOrderUsingPkExportServiceOrderDetailUpdate>>;
  /** The primary key(s) and patch data for `exportServiceOrderDetail` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<Array<ExportServiceOrderOnExportServiceOrderDetailForFkExportServiceOrderDetailToExportServiceOrderNodeIdUpdate>>;
};

/** The `exportServiceOrderDetail` to be created by this mutation. */
export type FkExportServiceOrderDetailToProductExportServiceOrderDetailCreateInput = {
  discount: Scalars['BigFloat'];
  exportServiceOrder?: InputMaybe<FkExportServiceOrderDetailToExportServiceOrderInput>;
  exportServiceOrderKey?: InputMaybe<Scalars['String']>;
  price: Scalars['BigFloat'];
  productService?: InputMaybe<FkExportServiceOrderDetailToProductInput>;
  quantity: Scalars['Int'];
};

/** Input for the nested mutation of `product` in the `ExportServiceOrderDetailInput` mutation. */
export type FkExportServiceOrderDetailToProductInput = {
  /** The primary key(s) for `product` for the far side of the relationship. */
  connectByKey?: InputMaybe<ProductPkProductConnect>;
  /** The primary key(s) for `product` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<ProductNodeIdConnect>;
  /** A `ProductInput` object that will be created and connected to this object. */
  create?: InputMaybe<FkExportServiceOrderDetailToProductProductCreateInput>;
  /** The primary key(s) and patch data for `product` for the far side of the relationship. */
  updateByKey?: InputMaybe<ProductOnExportServiceOrderDetailForFkExportServiceOrderDetailToProductUsingPkProductUpdate>;
  /** The primary key(s) and patch data for `product` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<ExportServiceOrderDetailOnExportServiceOrderDetailForFkExportServiceOrderDetailToProductNodeIdUpdate>;
};

/** Input for the nested mutation of `exportServiceOrderDetail` in the `ProductInput` mutation. */
export type FkExportServiceOrderDetailToProductInverseInput = {
  /** The primary key(s) for `exportServiceOrderDetail` for the far side of the relationship. */
  connectByKey?: InputMaybe<Array<ExportServiceOrderDetailPkExportServiceOrderDetailConnect>>;
  /** The primary key(s) for `exportServiceOrderDetail` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<ExportServiceOrderDetailNodeIdConnect>>;
  /** A `ExportServiceOrderDetailInput` object that will be created and connected to this object. */
  create?: InputMaybe<Array<FkExportServiceOrderDetailToProductExportServiceOrderDetailCreateInput>>;
  /** The primary key(s) for `exportServiceOrderDetail` for the far side of the relationship. */
  deleteByKey?: InputMaybe<Array<ExportServiceOrderDetailPkExportServiceOrderDetailDelete>>;
  /** The primary key(s) for `exportServiceOrderDetail` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<Array<ExportServiceOrderDetailNodeIdDelete>>;
  /** Flag indicating whether all other `exportServiceOrderDetail` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars['Boolean']>;
  /** The primary key(s) and patch data for `exportServiceOrderDetail` for the far side of the relationship. */
  updateByKey?: InputMaybe<Array<ExportServiceOrderDetailOnExportServiceOrderDetailForFkExportServiceOrderDetailToProductUsingPkExportServiceOrderDetailUpdate>>;
  /** The primary key(s) and patch data for `exportServiceOrderDetail` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<Array<ProductOnExportServiceOrderDetailForFkExportServiceOrderDetailToProductNodeIdUpdate>>;
};

/** The `product` to be created by this mutation. */
export type FkExportServiceOrderDetailToProductProductCreateInput = {
  code?: InputMaybe<Scalars['String']>;
  description: Scalars['String'];
  exportServiceOrderDetails?: InputMaybe<FkExportServiceOrderDetailToProductInverseInput>;
  homologations?: InputMaybe<FkProductHomologationToProductInverseInput>;
  insuranceOrderDetails?: InputMaybe<FkInsuranceOrderDetailToProductInverseInput>;
  name?: InputMaybe<Scalars['String']>;
  price: Scalars['BigFloat'];
  rentalOrderDetails?: InputMaybe<FkRentalOrderDetailToProductInverseInput>;
  saleOrderDetails?: InputMaybe<FkSaleOrderDetailToProductInverseInput>;
  touristServiceOrderDetails?: InputMaybe<FkTouristServiceOrderDetailToProductInverseInput>;
  type: ProductType;
};

/** The `exportServiceOrder` to be created by this mutation. */
export type FkExportServiceOrderToOrderExportServiceOrderCreateInput = {
  deletion?: InputMaybe<Scalars['Boolean']>;
  exportServiceOrderDetails?: InputMaybe<FkExportServiceOrderDetailToExportServiceOrderInverseInput>;
  order?: InputMaybe<FkExportServiceOrderToOrderInput>;
  sinCodigoPais: Scalars['Int'];
  sinDireccionComprador: Scalars['String'];
  sinInformacionAdicional?: InputMaybe<Scalars['String']>;
  sinLugarDestino: Scalars['String'];
};

/** Input for the nested mutation of `order` in the `ExportServiceOrderInput` mutation. */
export type FkExportServiceOrderToOrderInput = {
  /** The primary key(s) for `order` for the far side of the relationship. */
  connectByKey?: InputMaybe<OrderPkOrderConnect>;
  /** The primary key(s) for `order` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<OrderNodeIdConnect>;
  /** A `OrderInput` object that will be created and connected to this object. */
  create?: InputMaybe<FkExportServiceOrderToOrderOrderCreateInput>;
  /** The primary key(s) and patch data for `order` for the far side of the relationship. */
  updateByKey?: InputMaybe<OrderOnExportServiceOrderForFkExportServiceOrderToOrderUsingPkOrderUpdate>;
  /** The primary key(s) and patch data for `order` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<ExportServiceOrderOnExportServiceOrderForFkExportServiceOrderToOrderNodeIdUpdate>;
};

/** Input for the nested mutation of `exportServiceOrder` in the `OrderInput` mutation. */
export type FkExportServiceOrderToOrderInverseInput = {
  /** The primary key(s) for `exportServiceOrder` for the far side of the relationship. */
  connectByKey?: InputMaybe<ExportServiceOrderPkExportServiceOrderConnect>;
  /** The primary key(s) for `exportServiceOrder` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<ExportServiceOrderNodeIdConnect>;
  /** The primary key(s) for `exportServiceOrder` for the far side of the relationship. */
  connectByOrderKey?: InputMaybe<ExportServiceOrderUkExportServiceOrderOrderKeyConnect>;
  /** A `ExportServiceOrderInput` object that will be created and connected to this object. */
  create?: InputMaybe<Array<FkExportServiceOrderToOrderExportServiceOrderCreateInput>>;
  /** The primary key(s) for `exportServiceOrder` for the far side of the relationship. */
  deleteByKey?: InputMaybe<ExportServiceOrderPkExportServiceOrderDelete>;
  /** The primary key(s) for `exportServiceOrder` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<ExportServiceOrderNodeIdDelete>;
  /** The primary key(s) for `exportServiceOrder` for the far side of the relationship. */
  deleteByOrderKey?: InputMaybe<ExportServiceOrderUkExportServiceOrderOrderKeyDelete>;
  /** Flag indicating whether all other `exportServiceOrder` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars['Boolean']>;
  /** The primary key(s) and patch data for `exportServiceOrder` for the far side of the relationship. */
  updateByKey?: InputMaybe<ExportServiceOrderOnExportServiceOrderForFkExportServiceOrderToOrderUsingPkExportServiceOrderUpdate>;
  /** The primary key(s) and patch data for `exportServiceOrder` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<OrderOnExportServiceOrderForFkExportServiceOrderToOrderNodeIdUpdate>;
  /** The primary key(s) and patch data for `exportServiceOrder` for the far side of the relationship. */
  updateByOrderKey?: InputMaybe<ExportServiceOrderOnExportServiceOrderForFkExportServiceOrderToOrderUsingUkExportServiceOrderOrderKeyUpdate>;
};

/** The `order` to be created by this mutation. */
export type FkExportServiceOrderToOrderOrderCreateInput = {
  client?: InputMaybe<FkOrderToClientInput>;
  clientKey?: InputMaybe<Scalars['String']>;
  discount?: InputMaybe<Scalars['BigFloat']>;
  email?: InputMaybe<Scalars['String']>;
  exportServiceOrders?: InputMaybe<FkExportServiceOrderToOrderInverseInput>;
  homologations?: InputMaybe<FkOrderHomologationToOrderInverseInput>;
  insuranceOrders?: InputMaybe<FkInsuranceOrderToOrderInverseInput>;
  /** Importe que el cliente pagó. */
  paymentAmount?: InputMaybe<Scalars['BigFloat']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  pointOfSale?: InputMaybe<FkOrderToPointOfSaleInput>;
  pointOfSaleKey?: InputMaybe<Scalars['String']>;
  rentalOrders?: InputMaybe<FkRentalOrderToOrderInverseInput>;
  saleOrders?: InputMaybe<FkSaleOrderToOrderInverseInput>;
  status?: InputMaybe<OrderStatus>;
  touristServiceOrders?: InputMaybe<FkTouristServiceOrderToOrderInverseInput>;
};

/** Input for the nested mutation of `insuranceOrder` in the `InsuranceOrderDetailInput` mutation. */
export type FkInsuranceOrderDetailToInsuranceOrderInput = {
  /** The primary key(s) for `insuranceOrder` for the far side of the relationship. */
  connectByKey?: InputMaybe<InsuranceOrderPkInsuranceOrderConnect>;
  /** The primary key(s) for `insuranceOrder` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<InsuranceOrderNodeIdConnect>;
  /** The primary key(s) for `insuranceOrder` for the far side of the relationship. */
  connectByOrderKey?: InputMaybe<InsuranceOrderUkInsuranceOrderOrderKeyConnect>;
  /** A `InsuranceOrderInput` object that will be created and connected to this object. */
  create?: InputMaybe<FkInsuranceOrderDetailToInsuranceOrderInsuranceOrderCreateInput>;
  /** The primary key(s) for `insuranceOrder` for the far side of the relationship. */
  deleteByKey?: InputMaybe<InsuranceOrderPkInsuranceOrderDelete>;
  /** The primary key(s) for `insuranceOrder` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<InsuranceOrderNodeIdDelete>;
  /** The primary key(s) for `insuranceOrder` for the far side of the relationship. */
  deleteByOrderKey?: InputMaybe<InsuranceOrderUkInsuranceOrderOrderKeyDelete>;
  /** The primary key(s) and patch data for `insuranceOrder` for the far side of the relationship. */
  updateByKey?: InputMaybe<InsuranceOrderOnInsuranceOrderDetailForFkInsuranceOrderDetailToInsuranceOrderUsingPkInsuranceOrderUpdate>;
  /** The primary key(s) and patch data for `insuranceOrder` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<InsuranceOrderDetailOnInsuranceOrderDetailForFkInsuranceOrderDetailToInsuranceOrderNodeIdUpdate>;
  /** The primary key(s) and patch data for `insuranceOrder` for the far side of the relationship. */
  updateByOrderKey?: InputMaybe<InsuranceOrderOnInsuranceOrderDetailForFkInsuranceOrderDetailToInsuranceOrderUsingUkInsuranceOrderOrderKeyUpdate>;
};

/** The `insuranceOrder` to be created by this mutation. */
export type FkInsuranceOrderDetailToInsuranceOrderInsuranceOrderCreateInput = {
  deletion?: InputMaybe<Scalars['Boolean']>;
  insuranceOrderDetails?: InputMaybe<FkInsuranceOrderDetailToInsuranceOrderInverseInput>;
  order?: InputMaybe<FkInsuranceOrderToOrderInput>;
  orderKey?: InputMaybe<Scalars['String']>;
  sinAjusteAfectacionIva: Scalars['BigFloat'];
};

/** The `insuranceOrderDetail` to be created by this mutation. */
export type FkInsuranceOrderDetailToInsuranceOrderInsuranceOrderDetailCreateInput = {
  discount: Scalars['BigFloat'];
  insuranceOrder?: InputMaybe<FkInsuranceOrderDetailToInsuranceOrderInput>;
  price: Scalars['BigFloat'];
  productKey?: InputMaybe<Scalars['String']>;
  productService?: InputMaybe<FkInsuranceOrderDetailToProductInput>;
  quantity: Scalars['Int'];
};

/** Input for the nested mutation of `insuranceOrderDetail` in the `InsuranceOrderInput` mutation. */
export type FkInsuranceOrderDetailToInsuranceOrderInverseInput = {
  /** The primary key(s) for `insuranceOrderDetail` for the far side of the relationship. */
  connectByKey?: InputMaybe<Array<InsuranceOrderDetailPkInsuranceOrderDetailConnect>>;
  /** The primary key(s) for `insuranceOrderDetail` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<InsuranceOrderDetailNodeIdConnect>>;
  /** A `InsuranceOrderDetailInput` object that will be created and connected to this object. */
  create?: InputMaybe<Array<FkInsuranceOrderDetailToInsuranceOrderInsuranceOrderDetailCreateInput>>;
  /** The primary key(s) for `insuranceOrderDetail` for the far side of the relationship. */
  deleteByKey?: InputMaybe<Array<InsuranceOrderDetailPkInsuranceOrderDetailDelete>>;
  /** The primary key(s) for `insuranceOrderDetail` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<Array<InsuranceOrderDetailNodeIdDelete>>;
  /** Flag indicating whether all other `insuranceOrderDetail` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars['Boolean']>;
  /** The primary key(s) and patch data for `insuranceOrderDetail` for the far side of the relationship. */
  updateByKey?: InputMaybe<Array<InsuranceOrderDetailOnInsuranceOrderDetailForFkInsuranceOrderDetailToInsuranceOrderUsingPkInsuranceOrderDetailUpdate>>;
  /** The primary key(s) and patch data for `insuranceOrderDetail` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<Array<InsuranceOrderOnInsuranceOrderDetailForFkInsuranceOrderDetailToInsuranceOrderNodeIdUpdate>>;
};

/** Input for the nested mutation of `product` in the `InsuranceOrderDetailInput` mutation. */
export type FkInsuranceOrderDetailToProductInput = {
  /** The primary key(s) for `product` for the far side of the relationship. */
  connectByKey?: InputMaybe<ProductPkProductConnect>;
  /** The primary key(s) for `product` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<ProductNodeIdConnect>;
  /** A `ProductInput` object that will be created and connected to this object. */
  create?: InputMaybe<FkInsuranceOrderDetailToProductProductCreateInput>;
  /** The primary key(s) and patch data for `product` for the far side of the relationship. */
  updateByKey?: InputMaybe<ProductOnInsuranceOrderDetailForFkInsuranceOrderDetailToProductUsingPkProductUpdate>;
  /** The primary key(s) and patch data for `product` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<InsuranceOrderDetailOnInsuranceOrderDetailForFkInsuranceOrderDetailToProductNodeIdUpdate>;
};

/** The `insuranceOrderDetail` to be created by this mutation. */
export type FkInsuranceOrderDetailToProductInsuranceOrderDetailCreateInput = {
  discount: Scalars['BigFloat'];
  insuranceOrder?: InputMaybe<FkInsuranceOrderDetailToInsuranceOrderInput>;
  insuranceOrderKey?: InputMaybe<Scalars['String']>;
  price: Scalars['BigFloat'];
  productService?: InputMaybe<FkInsuranceOrderDetailToProductInput>;
  quantity: Scalars['Int'];
};

/** Input for the nested mutation of `insuranceOrderDetail` in the `ProductInput` mutation. */
export type FkInsuranceOrderDetailToProductInverseInput = {
  /** The primary key(s) for `insuranceOrderDetail` for the far side of the relationship. */
  connectByKey?: InputMaybe<Array<InsuranceOrderDetailPkInsuranceOrderDetailConnect>>;
  /** The primary key(s) for `insuranceOrderDetail` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<InsuranceOrderDetailNodeIdConnect>>;
  /** A `InsuranceOrderDetailInput` object that will be created and connected to this object. */
  create?: InputMaybe<Array<FkInsuranceOrderDetailToProductInsuranceOrderDetailCreateInput>>;
  /** The primary key(s) for `insuranceOrderDetail` for the far side of the relationship. */
  deleteByKey?: InputMaybe<Array<InsuranceOrderDetailPkInsuranceOrderDetailDelete>>;
  /** The primary key(s) for `insuranceOrderDetail` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<Array<InsuranceOrderDetailNodeIdDelete>>;
  /** Flag indicating whether all other `insuranceOrderDetail` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars['Boolean']>;
  /** The primary key(s) and patch data for `insuranceOrderDetail` for the far side of the relationship. */
  updateByKey?: InputMaybe<Array<InsuranceOrderDetailOnInsuranceOrderDetailForFkInsuranceOrderDetailToProductUsingPkInsuranceOrderDetailUpdate>>;
  /** The primary key(s) and patch data for `insuranceOrderDetail` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<Array<ProductOnInsuranceOrderDetailForFkInsuranceOrderDetailToProductNodeIdUpdate>>;
};

/** The `product` to be created by this mutation. */
export type FkInsuranceOrderDetailToProductProductCreateInput = {
  code?: InputMaybe<Scalars['String']>;
  description: Scalars['String'];
  exportServiceOrderDetails?: InputMaybe<FkExportServiceOrderDetailToProductInverseInput>;
  homologations?: InputMaybe<FkProductHomologationToProductInverseInput>;
  insuranceOrderDetails?: InputMaybe<FkInsuranceOrderDetailToProductInverseInput>;
  name?: InputMaybe<Scalars['String']>;
  price: Scalars['BigFloat'];
  rentalOrderDetails?: InputMaybe<FkRentalOrderDetailToProductInverseInput>;
  saleOrderDetails?: InputMaybe<FkSaleOrderDetailToProductInverseInput>;
  touristServiceOrderDetails?: InputMaybe<FkTouristServiceOrderDetailToProductInverseInput>;
  type: ProductType;
};

/** Input for the nested mutation of `order` in the `InsuranceOrderInput` mutation. */
export type FkInsuranceOrderToOrderInput = {
  /** The primary key(s) for `order` for the far side of the relationship. */
  connectByKey?: InputMaybe<OrderPkOrderConnect>;
  /** The primary key(s) for `order` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<OrderNodeIdConnect>;
  /** A `OrderInput` object that will be created and connected to this object. */
  create?: InputMaybe<FkInsuranceOrderToOrderOrderCreateInput>;
  /** The primary key(s) and patch data for `order` for the far side of the relationship. */
  updateByKey?: InputMaybe<OrderOnInsuranceOrderForFkInsuranceOrderToOrderUsingPkOrderUpdate>;
  /** The primary key(s) and patch data for `order` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<InsuranceOrderOnInsuranceOrderForFkInsuranceOrderToOrderNodeIdUpdate>;
};

/** The `insuranceOrder` to be created by this mutation. */
export type FkInsuranceOrderToOrderInsuranceOrderCreateInput = {
  deletion?: InputMaybe<Scalars['Boolean']>;
  insuranceOrderDetails?: InputMaybe<FkInsuranceOrderDetailToInsuranceOrderInverseInput>;
  order?: InputMaybe<FkInsuranceOrderToOrderInput>;
  sinAjusteAfectacionIva: Scalars['BigFloat'];
};

/** Input for the nested mutation of `insuranceOrder` in the `OrderInput` mutation. */
export type FkInsuranceOrderToOrderInverseInput = {
  /** The primary key(s) for `insuranceOrder` for the far side of the relationship. */
  connectByKey?: InputMaybe<InsuranceOrderPkInsuranceOrderConnect>;
  /** The primary key(s) for `insuranceOrder` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<InsuranceOrderNodeIdConnect>;
  /** The primary key(s) for `insuranceOrder` for the far side of the relationship. */
  connectByOrderKey?: InputMaybe<InsuranceOrderUkInsuranceOrderOrderKeyConnect>;
  /** A `InsuranceOrderInput` object that will be created and connected to this object. */
  create?: InputMaybe<Array<FkInsuranceOrderToOrderInsuranceOrderCreateInput>>;
  /** The primary key(s) for `insuranceOrder` for the far side of the relationship. */
  deleteByKey?: InputMaybe<InsuranceOrderPkInsuranceOrderDelete>;
  /** The primary key(s) for `insuranceOrder` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<InsuranceOrderNodeIdDelete>;
  /** The primary key(s) for `insuranceOrder` for the far side of the relationship. */
  deleteByOrderKey?: InputMaybe<InsuranceOrderUkInsuranceOrderOrderKeyDelete>;
  /** Flag indicating whether all other `insuranceOrder` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars['Boolean']>;
  /** The primary key(s) and patch data for `insuranceOrder` for the far side of the relationship. */
  updateByKey?: InputMaybe<InsuranceOrderOnInsuranceOrderForFkInsuranceOrderToOrderUsingPkInsuranceOrderUpdate>;
  /** The primary key(s) and patch data for `insuranceOrder` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<OrderOnInsuranceOrderForFkInsuranceOrderToOrderNodeIdUpdate>;
  /** The primary key(s) and patch data for `insuranceOrder` for the far side of the relationship. */
  updateByOrderKey?: InputMaybe<InsuranceOrderOnInsuranceOrderForFkInsuranceOrderToOrderUsingUkInsuranceOrderOrderKeyUpdate>;
};

/** The `order` to be created by this mutation. */
export type FkInsuranceOrderToOrderOrderCreateInput = {
  client?: InputMaybe<FkOrderToClientInput>;
  clientKey?: InputMaybe<Scalars['String']>;
  discount?: InputMaybe<Scalars['BigFloat']>;
  email?: InputMaybe<Scalars['String']>;
  exportServiceOrders?: InputMaybe<FkExportServiceOrderToOrderInverseInput>;
  homologations?: InputMaybe<FkOrderHomologationToOrderInverseInput>;
  insuranceOrders?: InputMaybe<FkInsuranceOrderToOrderInverseInput>;
  /** Importe que el cliente pagó. */
  paymentAmount?: InputMaybe<Scalars['BigFloat']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  pointOfSale?: InputMaybe<FkOrderToPointOfSaleInput>;
  pointOfSaleKey?: InputMaybe<Scalars['String']>;
  rentalOrders?: InputMaybe<FkRentalOrderToOrderInverseInput>;
  saleOrders?: InputMaybe<FkSaleOrderToOrderInverseInput>;
  status?: InputMaybe<OrderStatus>;
  touristServiceOrders?: InputMaybe<FkTouristServiceOrderToOrderInverseInput>;
};

/** Input for the nested mutation of `order` in the `OrderHomologationInput` mutation. */
export type FkOrderHomologationToOrderInput = {
  /** The primary key(s) for `order` for the far side of the relationship. */
  connectByKey?: InputMaybe<OrderPkOrderConnect>;
  /** The primary key(s) for `order` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<OrderNodeIdConnect>;
  /** A `OrderInput` object that will be created and connected to this object. */
  create?: InputMaybe<FkOrderHomologationToOrderOrderCreateInput>;
  /** The primary key(s) and patch data for `order` for the far side of the relationship. */
  updateByKey?: InputMaybe<OrderOnOrderHomologationForFkOrderHomologationToOrderUsingPkOrderUpdate>;
  /** The primary key(s) and patch data for `order` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<OrderHomologationOnOrderHomologationForFkOrderHomologationToOrderNodeIdUpdate>;
};

/** Input for the nested mutation of `orderHomologation` in the `OrderInput` mutation. */
export type FkOrderHomologationToOrderInverseInput = {
  /** The primary key(s) for `orderHomologation` for the far side of the relationship. */
  connectByKey?: InputMaybe<OrderHomologationPkOrderHomologationConnect>;
  /** The primary key(s) for `orderHomologation` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<OrderHomologationNodeIdConnect>;
  /** The primary key(s) for `orderHomologation` for the far side of the relationship. */
  connectByOrderKey?: InputMaybe<OrderHomologationUkOrderHomologationOrderIdConnect>;
  /** A `OrderHomologationInput` object that will be created and connected to this object. */
  create?: InputMaybe<Array<FkOrderHomologationToOrderOrderHomologationCreateInput>>;
  /** The primary key(s) and patch data for `orderHomologation` for the far side of the relationship. */
  updateByKey?: InputMaybe<OrderHomologationOnOrderHomologationForFkOrderHomologationToOrderUsingPkOrderHomologationUpdate>;
  /** The primary key(s) and patch data for `orderHomologation` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<OrderOnOrderHomologationForFkOrderHomologationToOrderNodeIdUpdate>;
  /** The primary key(s) and patch data for `orderHomologation` for the far side of the relationship. */
  updateByOrderKey?: InputMaybe<OrderHomologationOnOrderHomologationForFkOrderHomologationToOrderUsingUkOrderHomologationOrderIdUpdate>;
};

/** The `order` to be created by this mutation. */
export type FkOrderHomologationToOrderOrderCreateInput = {
  client?: InputMaybe<FkOrderToClientInput>;
  clientKey?: InputMaybe<Scalars['String']>;
  discount?: InputMaybe<Scalars['BigFloat']>;
  email?: InputMaybe<Scalars['String']>;
  exportServiceOrders?: InputMaybe<FkExportServiceOrderToOrderInverseInput>;
  homologations?: InputMaybe<FkOrderHomologationToOrderInverseInput>;
  insuranceOrders?: InputMaybe<FkInsuranceOrderToOrderInverseInput>;
  /** Importe que el cliente pagó. */
  paymentAmount?: InputMaybe<Scalars['BigFloat']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  pointOfSale?: InputMaybe<FkOrderToPointOfSaleInput>;
  pointOfSaleKey?: InputMaybe<Scalars['String']>;
  rentalOrders?: InputMaybe<FkRentalOrderToOrderInverseInput>;
  saleOrders?: InputMaybe<FkSaleOrderToOrderInverseInput>;
  status?: InputMaybe<OrderStatus>;
  touristServiceOrders?: InputMaybe<FkTouristServiceOrderToOrderInverseInput>;
};

/** The `orderHomologation` to be created by this mutation. */
export type FkOrderHomologationToOrderOrderHomologationCreateInput = {
  activityCode: Scalars['String'];
  cardNumber?: InputMaybe<Scalars['BigInt']>;
  currencyCode: Scalars['Int'];
  /**
   * [SIN] Utilizado para generar CUF
   * 1-4
   * 1=EN LINEA
   * 2=FUERA DE LINEA
   * 3=MASIVO
   * 4=CONTINGENCIA
   */
  emissionTypeCode: Scalars['Int'];
  exchangeRate: Scalars['BigFloat'];
  /**
   * [SIN] Utilizado para generar CUF
   * 1-4
   * 1=FACTURA CON DERECHO A CREDITO FISCAL
   * ...
   */
  invoiceTypeCode?: InputMaybe<Scalars['Int']>;
  /**
   * [SIN] Utilizado para generar CUF
   * 1-2
   * 1=ELECTRONICA
   * 2=COMPUTARIZADA
   */
  modalityTypeCode: Scalars['Int'];
  order?: InputMaybe<FkOrderHomologationToOrderInput>;
  paymentMethodCode: Scalars['Int'];
  /**
   * [SIN] codigoDocumentoSector
   * 1=FCV
   * ...
   * 24=NCD
   * ...
   * 35=FAC_CVB
   * ...
   */
  sectorDocumentCode?: InputMaybe<Scalars['Int']>;
};

/** The `client` to be created by this mutation. */
export type FkOrderToClientClientCreateInput = {
  createdAt?: InputMaybe<Scalars['Datetime']>;
  editable?: InputMaybe<Scalars['Boolean']>;
  email?: InputMaybe<Scalars['String']>;
  homologations?: InputMaybe<FkClientHomologationToClientInverseInput>;
  name: Scalars['String'];
  observation?: InputMaybe<Scalars['String']>;
  orders?: InputMaybe<FkOrderToClientInverseInput>;
  phoneNumber?: InputMaybe<Scalars['String']>;
};

/** Input for the nested mutation of `client` in the `OrderInput` mutation. */
export type FkOrderToClientInput = {
  /** The primary key(s) for `client` for the far side of the relationship. */
  connectByKey?: InputMaybe<ClientPkClientConnect>;
  /** The primary key(s) for `client` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<ClientNodeIdConnect>;
  /** A `ClientInput` object that will be created and connected to this object. */
  create?: InputMaybe<FkOrderToClientClientCreateInput>;
  /** The primary key(s) and patch data for `client` for the far side of the relationship. */
  updateByKey?: InputMaybe<ClientOnOrderForFkOrderToClientUsingPkClientUpdate>;
  /** The primary key(s) and patch data for `client` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<OrderOnOrderForFkOrderToClientNodeIdUpdate>;
};

/** Input for the nested mutation of `order` in the `ClientInput` mutation. */
export type FkOrderToClientInverseInput = {
  /** The primary key(s) for `order` for the far side of the relationship. */
  connectByKey?: InputMaybe<Array<OrderPkOrderConnect>>;
  /** The primary key(s) for `order` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<OrderNodeIdConnect>>;
  /** A `OrderInput` object that will be created and connected to this object. */
  create?: InputMaybe<Array<FkOrderToClientOrderCreateInput>>;
  /** The primary key(s) and patch data for `order` for the far side of the relationship. */
  updateByKey?: InputMaybe<Array<OrderOnOrderForFkOrderToClientUsingPkOrderUpdate>>;
  /** The primary key(s) and patch data for `order` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<Array<ClientOnOrderForFkOrderToClientNodeIdUpdate>>;
};

/** The `order` to be created by this mutation. */
export type FkOrderToClientOrderCreateInput = {
  client?: InputMaybe<FkOrderToClientInput>;
  discount?: InputMaybe<Scalars['BigFloat']>;
  email?: InputMaybe<Scalars['String']>;
  exportServiceOrders?: InputMaybe<FkExportServiceOrderToOrderInverseInput>;
  homologations?: InputMaybe<FkOrderHomologationToOrderInverseInput>;
  insuranceOrders?: InputMaybe<FkInsuranceOrderToOrderInverseInput>;
  /** Importe que el cliente pagó. */
  paymentAmount?: InputMaybe<Scalars['BigFloat']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  pointOfSale?: InputMaybe<FkOrderToPointOfSaleInput>;
  pointOfSaleKey?: InputMaybe<Scalars['String']>;
  rentalOrders?: InputMaybe<FkRentalOrderToOrderInverseInput>;
  saleOrders?: InputMaybe<FkSaleOrderToOrderInverseInput>;
  status?: InputMaybe<OrderStatus>;
  touristServiceOrders?: InputMaybe<FkTouristServiceOrderToOrderInverseInput>;
};

/** Input for the nested mutation of `pointOfSale` in the `OrderInput` mutation. */
export type FkOrderToPointOfSaleInput = {
  /** The primary key(s) for `pointOfSale` for the far side of the relationship. */
  connectByKey?: InputMaybe<PointOfSalePkPointOfSaleConnect>;
  /** The primary key(s) for `pointOfSale` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<PointOfSaleNodeIdConnect>;
  /** The primary key(s) and patch data for `pointOfSale` for the far side of the relationship. */
  updateByKey?: InputMaybe<PointOfSaleOnOrderForFkOrderToPointOfSaleUsingPkPointOfSaleUpdate>;
  /** The primary key(s) and patch data for `pointOfSale` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<OrderOnOrderForFkOrderToPointOfSaleNodeIdUpdate>;
};

/** Input for the nested mutation of `order` in the `PointOfSaleInput` mutation. */
export type FkOrderToPointOfSaleInverseInput = {
  /** The primary key(s) for `order` for the far side of the relationship. */
  connectByKey?: InputMaybe<Array<OrderPkOrderConnect>>;
  /** The primary key(s) for `order` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<OrderNodeIdConnect>>;
  /** A `OrderInput` object that will be created and connected to this object. */
  create?: InputMaybe<Array<FkOrderToPointOfSaleOrderCreateInput>>;
  /** The primary key(s) and patch data for `order` for the far side of the relationship. */
  updateByKey?: InputMaybe<Array<OrderOnOrderForFkOrderToPointOfSaleUsingPkOrderUpdate>>;
  /** The primary key(s) and patch data for `order` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<Array<PointOfSaleOnOrderForFkOrderToPointOfSaleNodeIdUpdate>>;
};

/** The `order` to be created by this mutation. */
export type FkOrderToPointOfSaleOrderCreateInput = {
  client?: InputMaybe<FkOrderToClientInput>;
  clientKey?: InputMaybe<Scalars['String']>;
  discount?: InputMaybe<Scalars['BigFloat']>;
  email?: InputMaybe<Scalars['String']>;
  exportServiceOrders?: InputMaybe<FkExportServiceOrderToOrderInverseInput>;
  homologations?: InputMaybe<FkOrderHomologationToOrderInverseInput>;
  insuranceOrders?: InputMaybe<FkInsuranceOrderToOrderInverseInput>;
  /** Importe que el cliente pagó. */
  paymentAmount?: InputMaybe<Scalars['BigFloat']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  pointOfSale?: InputMaybe<FkOrderToPointOfSaleInput>;
  rentalOrders?: InputMaybe<FkRentalOrderToOrderInverseInput>;
  saleOrders?: InputMaybe<FkSaleOrderToOrderInverseInput>;
  status?: InputMaybe<OrderStatus>;
  touristServiceOrders?: InputMaybe<FkTouristServiceOrderToOrderInverseInput>;
};

/** The `branchOffice` to be created by this mutation. */
export type FkPointOfSaleToBranchOfficeBranchOfficeCreateInput = {
  address: Scalars['String'];
  code: Scalars['Int'];
  municipality: Scalars['String'];
  name: Scalars['String'];
  phoneNumber?: InputMaybe<Scalars['String']>;
  pointOfSales?: InputMaybe<FkPointOfSaleToBranchOfficeInverseInput>;
};

/** Input for the nested mutation of `branchOffice` in the `PointOfSaleInput` mutation. */
export type FkPointOfSaleToBranchOfficeInput = {
  /** The primary key(s) for `branchOffice` for the far side of the relationship. */
  connectByKey?: InputMaybe<BranchOfficePkBranchOfficeConnect>;
  /** The primary key(s) for `branchOffice` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<BranchOfficeNodeIdConnect>;
  /** A `BranchOfficeInput` object that will be created and connected to this object. */
  create?: InputMaybe<FkPointOfSaleToBranchOfficeBranchOfficeCreateInput>;
  /** The primary key(s) and patch data for `branchOffice` for the far side of the relationship. */
  updateByKey?: InputMaybe<BranchOfficeOnPointOfSaleForFkPointOfSaleToBranchOfficeUsingPkBranchOfficeUpdate>;
  /** The primary key(s) and patch data for `branchOffice` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<PointOfSaleOnPointOfSaleForFkPointOfSaleToBranchOfficeNodeIdUpdate>;
};

/** Input for the nested mutation of `pointOfSale` in the `BranchOfficeInput` mutation. */
export type FkPointOfSaleToBranchOfficeInverseInput = {
  /** The primary key(s) for `pointOfSale` for the far side of the relationship. */
  connectByKey?: InputMaybe<Array<PointOfSalePkPointOfSaleConnect>>;
  /** The primary key(s) for `pointOfSale` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<PointOfSaleNodeIdConnect>>;
  /** The primary key(s) and patch data for `pointOfSale` for the far side of the relationship. */
  updateByKey?: InputMaybe<Array<PointOfSaleOnPointOfSaleForFkPointOfSaleToBranchOfficeUsingPkPointOfSaleUpdate>>;
  /** The primary key(s) and patch data for `pointOfSale` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<Array<BranchOfficeOnPointOfSaleForFkPointOfSaleToBranchOfficeNodeIdUpdate>>;
};

/** Input for the nested mutation of `pointOfSale` in the `PointOfSaleUserInput` mutation. */
export type FkPointOfSaleUserToPointOfSaleInput = {
  /** The primary key(s) for `pointOfSale` for the far side of the relationship. */
  connectByKey?: InputMaybe<PointOfSalePkPointOfSaleConnect>;
  /** The primary key(s) for `pointOfSale` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<PointOfSaleNodeIdConnect>;
  /** The primary key(s) and patch data for `pointOfSale` for the far side of the relationship. */
  updateByKey?: InputMaybe<PointOfSaleOnPointOfSaleUserForFkPointOfSaleUserToPointOfSaleUsingPkPointOfSaleUpdate>;
  /** The primary key(s) and patch data for `pointOfSale` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<PointOfSaleUserOnPointOfSaleUserForFkPointOfSaleUserToPointOfSaleNodeIdUpdate>;
};

/** Input for the nested mutation of `pointOfSaleUser` in the `PointOfSaleInput` mutation. */
export type FkPointOfSaleUserToPointOfSaleInverseInput = {
  /** The primary key(s) for `pointOfSaleUser` for the far side of the relationship. */
  connectByKey?: InputMaybe<Array<PointOfSaleUserPkPointOfSaleUserConnect>>;
  /** The primary key(s) for `pointOfSaleUser` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<PointOfSaleUserNodeIdConnect>>;
  /** A `PointOfSaleUserInput` object that will be created and connected to this object. */
  create?: InputMaybe<Array<FkPointOfSaleUserToPointOfSalePointOfSaleUserCreateInput>>;
  /** The primary key(s) for `pointOfSaleUser` for the far side of the relationship. */
  deleteByKey?: InputMaybe<Array<PointOfSaleUserPkPointOfSaleUserDelete>>;
  /** The primary key(s) for `pointOfSaleUser` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<Array<PointOfSaleUserNodeIdDelete>>;
  /** Flag indicating whether all other `pointOfSaleUser` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars['Boolean']>;
  /** The primary key(s) and patch data for `pointOfSaleUser` for the far side of the relationship. */
  updateByKey?: InputMaybe<Array<PointOfSaleUserOnPointOfSaleUserForFkPointOfSaleUserToPointOfSaleUsingPkPointOfSaleUserUpdate>>;
  /** The primary key(s) and patch data for `pointOfSaleUser` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<Array<PointOfSaleOnPointOfSaleUserForFkPointOfSaleUserToPointOfSaleNodeIdUpdate>>;
};

/** The `pointOfSaleUser` to be created by this mutation. */
export type FkPointOfSaleUserToPointOfSalePointOfSaleUserCreateInput = {
  enabled?: InputMaybe<Scalars['Boolean']>;
  pointOfSale?: InputMaybe<FkPointOfSaleUserToPointOfSaleInput>;
  user?: InputMaybe<FkPointOfSaleUserToUserInput>;
  userKey?: InputMaybe<Scalars['String']>;
};

/** Input for the nested mutation of `user` in the `PointOfSaleUserInput` mutation. */
export type FkPointOfSaleUserToUserInput = {
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectByKey?: InputMaybe<UserPkUserConnect>;
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<UserNodeIdConnect>;
  /** A `UserInput` object that will be created and connected to this object. */
  create?: InputMaybe<FkPointOfSaleUserToUserUserCreateInput>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateByKey?: InputMaybe<UserOnPointOfSaleUserForFkPointOfSaleUserToUserUsingPkUserUpdate>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<PointOfSaleUserOnPointOfSaleUserForFkPointOfSaleUserToUserNodeIdUpdate>;
};

/** Input for the nested mutation of `pointOfSaleUser` in the `UserInput` mutation. */
export type FkPointOfSaleUserToUserInverseInput = {
  /** The primary key(s) for `pointOfSaleUser` for the far side of the relationship. */
  connectByKey?: InputMaybe<Array<PointOfSaleUserPkPointOfSaleUserConnect>>;
  /** The primary key(s) for `pointOfSaleUser` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<PointOfSaleUserNodeIdConnect>>;
  /** A `PointOfSaleUserInput` object that will be created and connected to this object. */
  create?: InputMaybe<Array<FkPointOfSaleUserToUserPointOfSaleUserCreateInput>>;
  /** The primary key(s) for `pointOfSaleUser` for the far side of the relationship. */
  deleteByKey?: InputMaybe<Array<PointOfSaleUserPkPointOfSaleUserDelete>>;
  /** The primary key(s) for `pointOfSaleUser` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<Array<PointOfSaleUserNodeIdDelete>>;
  /** Flag indicating whether all other `pointOfSaleUser` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars['Boolean']>;
  /** The primary key(s) and patch data for `pointOfSaleUser` for the far side of the relationship. */
  updateByKey?: InputMaybe<Array<PointOfSaleUserOnPointOfSaleUserForFkPointOfSaleUserToUserUsingPkPointOfSaleUserUpdate>>;
  /** The primary key(s) and patch data for `pointOfSaleUser` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<Array<UserOnPointOfSaleUserForFkPointOfSaleUserToUserNodeIdUpdate>>;
};

/** The `pointOfSaleUser` to be created by this mutation. */
export type FkPointOfSaleUserToUserPointOfSaleUserCreateInput = {
  enabled?: InputMaybe<Scalars['Boolean']>;
  pointOfSale?: InputMaybe<FkPointOfSaleUserToPointOfSaleInput>;
  pointOfSaleKey?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<FkPointOfSaleUserToUserInput>;
};

/** The `user` to be created by this mutation. */
export type FkPointOfSaleUserToUserUserCreateInput = {
  email: Scalars['String'];
  person?: InputMaybe<FkAppUserToPersonInput>;
  personKey?: InputMaybe<Scalars['String']>;
  pointOfSaleUsers?: InputMaybe<FkPointOfSaleUserToUserInverseInput>;
};

/** Input for the nested mutation of `product` in the `ProductHomologationInput` mutation. */
export type FkProductHomologationToProductInput = {
  /** The primary key(s) for `product` for the far side of the relationship. */
  connectByKey?: InputMaybe<ProductPkProductConnect>;
  /** The primary key(s) for `product` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<ProductNodeIdConnect>;
  /** A `ProductInput` object that will be created and connected to this object. */
  create?: InputMaybe<FkProductHomologationToProductProductCreateInput>;
  /** The primary key(s) and patch data for `product` for the far side of the relationship. */
  updateByKey?: InputMaybe<ProductOnProductHomologationForFkProductHomologationToProductUsingPkProductUpdate>;
  /** The primary key(s) and patch data for `product` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<ProductHomologationOnProductHomologationForFkProductHomologationToProductNodeIdUpdate>;
};

/** Input for the nested mutation of `productHomologation` in the `ProductInput` mutation. */
export type FkProductHomologationToProductInverseInput = {
  /** The primary key(s) for `productHomologation` for the far side of the relationship. */
  connectByKey?: InputMaybe<ProductHomologationPkProductHomologationConnect>;
  /** The primary key(s) for `productHomologation` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<ProductHomologationNodeIdConnect>;
  /** The primary key(s) for `productHomologation` for the far side of the relationship. */
  connectByProductKey?: InputMaybe<ProductHomologationUkProductHomologationProductKeyConnect>;
  /** A `ProductHomologationInput` object that will be created and connected to this object. */
  create?: InputMaybe<Array<FkProductHomologationToProductProductHomologationCreateInput>>;
  /** The primary key(s) and patch data for `productHomologation` for the far side of the relationship. */
  updateByKey?: InputMaybe<ProductHomologationOnProductHomologationForFkProductHomologationToProductUsingPkProductHomologationUpdate>;
  /** The primary key(s) and patch data for `productHomologation` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<ProductOnProductHomologationForFkProductHomologationToProductNodeIdUpdate>;
  /** The primary key(s) and patch data for `productHomologation` for the far side of the relationship. */
  updateByProductKey?: InputMaybe<ProductHomologationOnProductHomologationForFkProductHomologationToProductUsingUkProductHomologationProductKeyUpdate>;
};

/** The `product` to be created by this mutation. */
export type FkProductHomologationToProductProductCreateInput = {
  code?: InputMaybe<Scalars['String']>;
  description: Scalars['String'];
  exportServiceOrderDetails?: InputMaybe<FkExportServiceOrderDetailToProductInverseInput>;
  homologations?: InputMaybe<FkProductHomologationToProductInverseInput>;
  insuranceOrderDetails?: InputMaybe<FkInsuranceOrderDetailToProductInverseInput>;
  name?: InputMaybe<Scalars['String']>;
  price: Scalars['BigFloat'];
  rentalOrderDetails?: InputMaybe<FkRentalOrderDetailToProductInverseInput>;
  saleOrderDetails?: InputMaybe<FkSaleOrderDetailToProductInverseInput>;
  touristServiceOrderDetails?: InputMaybe<FkTouristServiceOrderDetailToProductInverseInput>;
  type: ProductType;
};

/** The `productHomologation` to be created by this mutation. */
export type FkProductHomologationToProductProductHomologationCreateInput = {
  /** [SIN] Codigo de actividad */
  activityCode: Scalars['String'];
  /** [SIN] Codigo de unidad de medida */
  measureUnitCode: Scalars['Int'];
  /** [SIN] Codigo de producto */
  productCode: Scalars['Int'];
  productService?: InputMaybe<FkProductHomologationToProductInput>;
};

/** Input for the nested mutation of `product` in the `RentalOrderDetailInput` mutation. */
export type FkRentalOrderDetailToProductInput = {
  /** The primary key(s) for `product` for the far side of the relationship. */
  connectByKey?: InputMaybe<ProductPkProductConnect>;
  /** The primary key(s) for `product` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<ProductNodeIdConnect>;
  /** A `ProductInput` object that will be created and connected to this object. */
  create?: InputMaybe<FkRentalOrderDetailToProductProductCreateInput>;
  /** The primary key(s) and patch data for `product` for the far side of the relationship. */
  updateByKey?: InputMaybe<ProductOnRentalOrderDetailForFkRentalOrderDetailToProductUsingPkProductUpdate>;
  /** The primary key(s) and patch data for `product` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<RentalOrderDetailOnRentalOrderDetailForFkRentalOrderDetailToProductNodeIdUpdate>;
};

/** Input for the nested mutation of `rentalOrderDetail` in the `ProductInput` mutation. */
export type FkRentalOrderDetailToProductInverseInput = {
  /** The primary key(s) for `rentalOrderDetail` for the far side of the relationship. */
  connectByKey?: InputMaybe<Array<RentalOrderDetailPkRentalOrderDetailConnect>>;
  /** The primary key(s) for `rentalOrderDetail` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<RentalOrderDetailNodeIdConnect>>;
  /** A `RentalOrderDetailInput` object that will be created and connected to this object. */
  create?: InputMaybe<Array<FkRentalOrderDetailToProductRentalOrderDetailCreateInput>>;
  /** The primary key(s) for `rentalOrderDetail` for the far side of the relationship. */
  deleteByKey?: InputMaybe<Array<RentalOrderDetailPkRentalOrderDetailDelete>>;
  /** The primary key(s) for `rentalOrderDetail` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<Array<RentalOrderDetailNodeIdDelete>>;
  /** Flag indicating whether all other `rentalOrderDetail` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars['Boolean']>;
  /** The primary key(s) and patch data for `rentalOrderDetail` for the far side of the relationship. */
  updateByKey?: InputMaybe<Array<RentalOrderDetailOnRentalOrderDetailForFkRentalOrderDetailToProductUsingPkRentalOrderDetailUpdate>>;
  /** The primary key(s) and patch data for `rentalOrderDetail` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<Array<ProductOnRentalOrderDetailForFkRentalOrderDetailToProductNodeIdUpdate>>;
};

/** The `product` to be created by this mutation. */
export type FkRentalOrderDetailToProductProductCreateInput = {
  code?: InputMaybe<Scalars['String']>;
  description: Scalars['String'];
  exportServiceOrderDetails?: InputMaybe<FkExportServiceOrderDetailToProductInverseInput>;
  homologations?: InputMaybe<FkProductHomologationToProductInverseInput>;
  insuranceOrderDetails?: InputMaybe<FkInsuranceOrderDetailToProductInverseInput>;
  name?: InputMaybe<Scalars['String']>;
  price: Scalars['BigFloat'];
  rentalOrderDetails?: InputMaybe<FkRentalOrderDetailToProductInverseInput>;
  saleOrderDetails?: InputMaybe<FkSaleOrderDetailToProductInverseInput>;
  touristServiceOrderDetails?: InputMaybe<FkTouristServiceOrderDetailToProductInverseInput>;
  type: ProductType;
};

/** The `rentalOrderDetail` to be created by this mutation. */
export type FkRentalOrderDetailToProductRentalOrderDetailCreateInput = {
  discount: Scalars['BigFloat'];
  price: Scalars['BigFloat'];
  productService?: InputMaybe<FkRentalOrderDetailToProductInput>;
  quantity: Scalars['BigFloat'];
  rentalOrder?: InputMaybe<FkRentalOrderDetailToRentalOrderInput>;
  rentalOrderKey?: InputMaybe<Scalars['String']>;
};

/** Input for the nested mutation of `rentalOrder` in the `RentalOrderDetailInput` mutation. */
export type FkRentalOrderDetailToRentalOrderInput = {
  /** The primary key(s) for `rentalOrder` for the far side of the relationship. */
  connectByKey?: InputMaybe<RentalOrderPkRentalOrderConnect>;
  /** The primary key(s) for `rentalOrder` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<RentalOrderNodeIdConnect>;
  /** The primary key(s) for `rentalOrder` for the far side of the relationship. */
  connectByOrderKey?: InputMaybe<RentalOrderUkRentalOrderOrderIdConnect>;
  /** A `RentalOrderInput` object that will be created and connected to this object. */
  create?: InputMaybe<FkRentalOrderDetailToRentalOrderRentalOrderCreateInput>;
  /** The primary key(s) for `rentalOrder` for the far side of the relationship. */
  deleteByKey?: InputMaybe<RentalOrderPkRentalOrderDelete>;
  /** The primary key(s) for `rentalOrder` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<RentalOrderNodeIdDelete>;
  /** The primary key(s) for `rentalOrder` for the far side of the relationship. */
  deleteByOrderKey?: InputMaybe<RentalOrderUkRentalOrderOrderIdDelete>;
  /** The primary key(s) and patch data for `rentalOrder` for the far side of the relationship. */
  updateByKey?: InputMaybe<RentalOrderOnRentalOrderDetailForFkRentalOrderDetailToRentalOrderUsingPkRentalOrderUpdate>;
  /** The primary key(s) and patch data for `rentalOrder` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<RentalOrderDetailOnRentalOrderDetailForFkRentalOrderDetailToRentalOrderNodeIdUpdate>;
  /** The primary key(s) and patch data for `rentalOrder` for the far side of the relationship. */
  updateByOrderKey?: InputMaybe<RentalOrderOnRentalOrderDetailForFkRentalOrderDetailToRentalOrderUsingUkRentalOrderOrderIdUpdate>;
};

/** Input for the nested mutation of `rentalOrderDetail` in the `RentalOrderInput` mutation. */
export type FkRentalOrderDetailToRentalOrderInverseInput = {
  /** The primary key(s) for `rentalOrderDetail` for the far side of the relationship. */
  connectByKey?: InputMaybe<Array<RentalOrderDetailPkRentalOrderDetailConnect>>;
  /** The primary key(s) for `rentalOrderDetail` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<RentalOrderDetailNodeIdConnect>>;
  /** A `RentalOrderDetailInput` object that will be created and connected to this object. */
  create?: InputMaybe<Array<FkRentalOrderDetailToRentalOrderRentalOrderDetailCreateInput>>;
  /** The primary key(s) for `rentalOrderDetail` for the far side of the relationship. */
  deleteByKey?: InputMaybe<Array<RentalOrderDetailPkRentalOrderDetailDelete>>;
  /** The primary key(s) for `rentalOrderDetail` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<Array<RentalOrderDetailNodeIdDelete>>;
  /** Flag indicating whether all other `rentalOrderDetail` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars['Boolean']>;
  /** The primary key(s) and patch data for `rentalOrderDetail` for the far side of the relationship. */
  updateByKey?: InputMaybe<Array<RentalOrderDetailOnRentalOrderDetailForFkRentalOrderDetailToRentalOrderUsingPkRentalOrderDetailUpdate>>;
  /** The primary key(s) and patch data for `rentalOrderDetail` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<Array<RentalOrderOnRentalOrderDetailForFkRentalOrderDetailToRentalOrderNodeIdUpdate>>;
};

/** The `rentalOrder` to be created by this mutation. */
export type FkRentalOrderDetailToRentalOrderRentalOrderCreateInput = {
  deletion?: InputMaybe<Scalars['Boolean']>;
  order?: InputMaybe<FkRentalOrderToOrderInput>;
  orderKey?: InputMaybe<Scalars['String']>;
  period?: InputMaybe<Scalars['String']>;
  rentalOrderDetails?: InputMaybe<FkRentalOrderDetailToRentalOrderInverseInput>;
};

/** The `rentalOrderDetail` to be created by this mutation. */
export type FkRentalOrderDetailToRentalOrderRentalOrderDetailCreateInput = {
  discount: Scalars['BigFloat'];
  price: Scalars['BigFloat'];
  productKey?: InputMaybe<Scalars['String']>;
  productService?: InputMaybe<FkRentalOrderDetailToProductInput>;
  quantity: Scalars['BigFloat'];
  rentalOrder?: InputMaybe<FkRentalOrderDetailToRentalOrderInput>;
};

/** Input for the nested mutation of `order` in the `RentalOrderInput` mutation. */
export type FkRentalOrderToOrderInput = {
  /** The primary key(s) for `order` for the far side of the relationship. */
  connectByKey?: InputMaybe<OrderPkOrderConnect>;
  /** The primary key(s) for `order` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<OrderNodeIdConnect>;
  /** A `OrderInput` object that will be created and connected to this object. */
  create?: InputMaybe<FkRentalOrderToOrderOrderCreateInput>;
  /** The primary key(s) and patch data for `order` for the far side of the relationship. */
  updateByKey?: InputMaybe<OrderOnRentalOrderForFkRentalOrderToOrderUsingPkOrderUpdate>;
  /** The primary key(s) and patch data for `order` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<RentalOrderOnRentalOrderForFkRentalOrderToOrderNodeIdUpdate>;
};

/** Input for the nested mutation of `rentalOrder` in the `OrderInput` mutation. */
export type FkRentalOrderToOrderInverseInput = {
  /** The primary key(s) for `rentalOrder` for the far side of the relationship. */
  connectByKey?: InputMaybe<RentalOrderPkRentalOrderConnect>;
  /** The primary key(s) for `rentalOrder` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<RentalOrderNodeIdConnect>;
  /** The primary key(s) for `rentalOrder` for the far side of the relationship. */
  connectByOrderKey?: InputMaybe<RentalOrderUkRentalOrderOrderIdConnect>;
  /** A `RentalOrderInput` object that will be created and connected to this object. */
  create?: InputMaybe<Array<FkRentalOrderToOrderRentalOrderCreateInput>>;
  /** The primary key(s) for `rentalOrder` for the far side of the relationship. */
  deleteByKey?: InputMaybe<RentalOrderPkRentalOrderDelete>;
  /** The primary key(s) for `rentalOrder` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<RentalOrderNodeIdDelete>;
  /** The primary key(s) for `rentalOrder` for the far side of the relationship. */
  deleteByOrderKey?: InputMaybe<RentalOrderUkRentalOrderOrderIdDelete>;
  /** Flag indicating whether all other `rentalOrder` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars['Boolean']>;
  /** The primary key(s) and patch data for `rentalOrder` for the far side of the relationship. */
  updateByKey?: InputMaybe<RentalOrderOnRentalOrderForFkRentalOrderToOrderUsingPkRentalOrderUpdate>;
  /** The primary key(s) and patch data for `rentalOrder` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<OrderOnRentalOrderForFkRentalOrderToOrderNodeIdUpdate>;
  /** The primary key(s) and patch data for `rentalOrder` for the far side of the relationship. */
  updateByOrderKey?: InputMaybe<RentalOrderOnRentalOrderForFkRentalOrderToOrderUsingUkRentalOrderOrderIdUpdate>;
};

/** The `order` to be created by this mutation. */
export type FkRentalOrderToOrderOrderCreateInput = {
  client?: InputMaybe<FkOrderToClientInput>;
  clientKey?: InputMaybe<Scalars['String']>;
  discount?: InputMaybe<Scalars['BigFloat']>;
  email?: InputMaybe<Scalars['String']>;
  exportServiceOrders?: InputMaybe<FkExportServiceOrderToOrderInverseInput>;
  homologations?: InputMaybe<FkOrderHomologationToOrderInverseInput>;
  insuranceOrders?: InputMaybe<FkInsuranceOrderToOrderInverseInput>;
  /** Importe que el cliente pagó. */
  paymentAmount?: InputMaybe<Scalars['BigFloat']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  pointOfSale?: InputMaybe<FkOrderToPointOfSaleInput>;
  pointOfSaleKey?: InputMaybe<Scalars['String']>;
  rentalOrders?: InputMaybe<FkRentalOrderToOrderInverseInput>;
  saleOrders?: InputMaybe<FkSaleOrderToOrderInverseInput>;
  status?: InputMaybe<OrderStatus>;
  touristServiceOrders?: InputMaybe<FkTouristServiceOrderToOrderInverseInput>;
};

/** The `rentalOrder` to be created by this mutation. */
export type FkRentalOrderToOrderRentalOrderCreateInput = {
  deletion?: InputMaybe<Scalars['Boolean']>;
  order?: InputMaybe<FkRentalOrderToOrderInput>;
  period?: InputMaybe<Scalars['String']>;
  rentalOrderDetails?: InputMaybe<FkRentalOrderDetailToRentalOrderInverseInput>;
};

/** Input for the nested mutation of `product` in the `SaleOrderDetailInput` mutation. */
export type FkSaleOrderDetailToProductInput = {
  /** The primary key(s) for `product` for the far side of the relationship. */
  connectByKey?: InputMaybe<ProductPkProductConnect>;
  /** The primary key(s) for `product` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<ProductNodeIdConnect>;
  /** A `ProductInput` object that will be created and connected to this object. */
  create?: InputMaybe<FkSaleOrderDetailToProductProductCreateInput>;
  /** The primary key(s) and patch data for `product` for the far side of the relationship. */
  updateByKey?: InputMaybe<ProductOnSaleOrderDetailForFkSaleOrderDetailToProductUsingPkProductUpdate>;
  /** The primary key(s) and patch data for `product` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<SaleOrderDetailOnSaleOrderDetailForFkSaleOrderDetailToProductNodeIdUpdate>;
};

/** Input for the nested mutation of `saleOrderDetail` in the `ProductInput` mutation. */
export type FkSaleOrderDetailToProductInverseInput = {
  /** The primary key(s) for `saleOrderDetail` for the far side of the relationship. */
  connectByKey?: InputMaybe<Array<SaleOrderDetailPkSaleOrderDetailConnect>>;
  /** The primary key(s) for `saleOrderDetail` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<SaleOrderDetailNodeIdConnect>>;
  /** A `SaleOrderDetailInput` object that will be created and connected to this object. */
  create?: InputMaybe<Array<FkSaleOrderDetailToProductSaleOrderDetailCreateInput>>;
  /** The primary key(s) and patch data for `saleOrderDetail` for the far side of the relationship. */
  updateByKey?: InputMaybe<Array<SaleOrderDetailOnSaleOrderDetailForFkSaleOrderDetailToProductUsingPkSaleOrderDetailUpdate>>;
  /** The primary key(s) and patch data for `saleOrderDetail` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<Array<ProductOnSaleOrderDetailForFkSaleOrderDetailToProductNodeIdUpdate>>;
};

/** The `product` to be created by this mutation. */
export type FkSaleOrderDetailToProductProductCreateInput = {
  code?: InputMaybe<Scalars['String']>;
  description: Scalars['String'];
  exportServiceOrderDetails?: InputMaybe<FkExportServiceOrderDetailToProductInverseInput>;
  homologations?: InputMaybe<FkProductHomologationToProductInverseInput>;
  insuranceOrderDetails?: InputMaybe<FkInsuranceOrderDetailToProductInverseInput>;
  name?: InputMaybe<Scalars['String']>;
  price: Scalars['BigFloat'];
  rentalOrderDetails?: InputMaybe<FkRentalOrderDetailToProductInverseInput>;
  saleOrderDetails?: InputMaybe<FkSaleOrderDetailToProductInverseInput>;
  touristServiceOrderDetails?: InputMaybe<FkTouristServiceOrderDetailToProductInverseInput>;
  type: ProductType;
};

/** The `saleOrderDetail` to be created by this mutation. */
export type FkSaleOrderDetailToProductSaleOrderDetailCreateInput = {
  discount: Scalars['BigFloat'];
  price: Scalars['BigFloat'];
  productService?: InputMaybe<FkSaleOrderDetailToProductInput>;
  quantity: Scalars['BigFloat'];
  saleOrder?: InputMaybe<FkSaleOrderDetailToSaleOrderInput>;
  saleOrderKey?: InputMaybe<Scalars['String']>;
  sinNumeroImei?: InputMaybe<Scalars['String']>;
  sinNumeroSerie?: InputMaybe<Scalars['String']>;
};

/** Input for the nested mutation of `saleOrder` in the `SaleOrderDetailInput` mutation. */
export type FkSaleOrderDetailToSaleOrderInput = {
  /** The primary key(s) for `saleOrder` for the far side of the relationship. */
  connectByKey?: InputMaybe<SaleOrderPkSaleOrderConnect>;
  /** The primary key(s) for `saleOrder` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<SaleOrderNodeIdConnect>;
  /** The primary key(s) for `saleOrder` for the far side of the relationship. */
  connectByOrderKey?: InputMaybe<SaleOrderUkSaleOrderOrderKeyConnect>;
  /** A `SaleOrderInput` object that will be created and connected to this object. */
  create?: InputMaybe<FkSaleOrderDetailToSaleOrderSaleOrderCreateInput>;
  /** The primary key(s) and patch data for `saleOrder` for the far side of the relationship. */
  updateByKey?: InputMaybe<SaleOrderOnSaleOrderDetailForFkSaleOrderDetailToSaleOrderUsingPkSaleOrderUpdate>;
  /** The primary key(s) and patch data for `saleOrder` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<SaleOrderDetailOnSaleOrderDetailForFkSaleOrderDetailToSaleOrderNodeIdUpdate>;
  /** The primary key(s) and patch data for `saleOrder` for the far side of the relationship. */
  updateByOrderKey?: InputMaybe<SaleOrderOnSaleOrderDetailForFkSaleOrderDetailToSaleOrderUsingUkSaleOrderOrderKeyUpdate>;
};

/** Input for the nested mutation of `saleOrderDetail` in the `SaleOrderInput` mutation. */
export type FkSaleOrderDetailToSaleOrderInverseInput = {
  /** The primary key(s) for `saleOrderDetail` for the far side of the relationship. */
  connectByKey?: InputMaybe<Array<SaleOrderDetailPkSaleOrderDetailConnect>>;
  /** The primary key(s) for `saleOrderDetail` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<SaleOrderDetailNodeIdConnect>>;
  /** A `SaleOrderDetailInput` object that will be created and connected to this object. */
  create?: InputMaybe<Array<FkSaleOrderDetailToSaleOrderSaleOrderDetailCreateInput>>;
  /** The primary key(s) and patch data for `saleOrderDetail` for the far side of the relationship. */
  updateByKey?: InputMaybe<Array<SaleOrderDetailOnSaleOrderDetailForFkSaleOrderDetailToSaleOrderUsingPkSaleOrderDetailUpdate>>;
  /** The primary key(s) and patch data for `saleOrderDetail` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<Array<SaleOrderOnSaleOrderDetailForFkSaleOrderDetailToSaleOrderNodeIdUpdate>>;
};

/** The `saleOrder` to be created by this mutation. */
export type FkSaleOrderDetailToSaleOrderSaleOrderCreateInput = {
  giftCard?: InputMaybe<Scalars['BigFloat']>;
  order?: InputMaybe<FkSaleOrderToOrderInput>;
  orderKey?: InputMaybe<Scalars['String']>;
  saleOrderDetails?: InputMaybe<FkSaleOrderDetailToSaleOrderInverseInput>;
};

/** The `saleOrderDetail` to be created by this mutation. */
export type FkSaleOrderDetailToSaleOrderSaleOrderDetailCreateInput = {
  discount: Scalars['BigFloat'];
  price: Scalars['BigFloat'];
  productKey?: InputMaybe<Scalars['String']>;
  productService?: InputMaybe<FkSaleOrderDetailToProductInput>;
  quantity: Scalars['BigFloat'];
  saleOrder?: InputMaybe<FkSaleOrderDetailToSaleOrderInput>;
  sinNumeroImei?: InputMaybe<Scalars['String']>;
  sinNumeroSerie?: InputMaybe<Scalars['String']>;
};

/** Input for the nested mutation of `order` in the `SaleOrderInput` mutation. */
export type FkSaleOrderToOrderInput = {
  /** The primary key(s) for `order` for the far side of the relationship. */
  connectByKey?: InputMaybe<OrderPkOrderConnect>;
  /** The primary key(s) for `order` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<OrderNodeIdConnect>;
  /** A `OrderInput` object that will be created and connected to this object. */
  create?: InputMaybe<FkSaleOrderToOrderOrderCreateInput>;
  /** The primary key(s) and patch data for `order` for the far side of the relationship. */
  updateByKey?: InputMaybe<OrderOnSaleOrderForFkSaleOrderToOrderUsingPkOrderUpdate>;
  /** The primary key(s) and patch data for `order` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<SaleOrderOnSaleOrderForFkSaleOrderToOrderNodeIdUpdate>;
};

/** Input for the nested mutation of `saleOrder` in the `OrderInput` mutation. */
export type FkSaleOrderToOrderInverseInput = {
  /** The primary key(s) for `saleOrder` for the far side of the relationship. */
  connectByKey?: InputMaybe<SaleOrderPkSaleOrderConnect>;
  /** The primary key(s) for `saleOrder` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<SaleOrderNodeIdConnect>;
  /** The primary key(s) for `saleOrder` for the far side of the relationship. */
  connectByOrderKey?: InputMaybe<SaleOrderUkSaleOrderOrderKeyConnect>;
  /** A `SaleOrderInput` object that will be created and connected to this object. */
  create?: InputMaybe<Array<FkSaleOrderToOrderSaleOrderCreateInput>>;
  /** The primary key(s) and patch data for `saleOrder` for the far side of the relationship. */
  updateByKey?: InputMaybe<SaleOrderOnSaleOrderForFkSaleOrderToOrderUsingPkSaleOrderUpdate>;
  /** The primary key(s) and patch data for `saleOrder` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<OrderOnSaleOrderForFkSaleOrderToOrderNodeIdUpdate>;
  /** The primary key(s) and patch data for `saleOrder` for the far side of the relationship. */
  updateByOrderKey?: InputMaybe<SaleOrderOnSaleOrderForFkSaleOrderToOrderUsingUkSaleOrderOrderKeyUpdate>;
};

/** The `order` to be created by this mutation. */
export type FkSaleOrderToOrderOrderCreateInput = {
  client?: InputMaybe<FkOrderToClientInput>;
  clientKey?: InputMaybe<Scalars['String']>;
  discount?: InputMaybe<Scalars['BigFloat']>;
  email?: InputMaybe<Scalars['String']>;
  exportServiceOrders?: InputMaybe<FkExportServiceOrderToOrderInverseInput>;
  homologations?: InputMaybe<FkOrderHomologationToOrderInverseInput>;
  insuranceOrders?: InputMaybe<FkInsuranceOrderToOrderInverseInput>;
  /** Importe que el cliente pagó. */
  paymentAmount?: InputMaybe<Scalars['BigFloat']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  pointOfSale?: InputMaybe<FkOrderToPointOfSaleInput>;
  pointOfSaleKey?: InputMaybe<Scalars['String']>;
  rentalOrders?: InputMaybe<FkRentalOrderToOrderInverseInput>;
  saleOrders?: InputMaybe<FkSaleOrderToOrderInverseInput>;
  status?: InputMaybe<OrderStatus>;
  touristServiceOrders?: InputMaybe<FkTouristServiceOrderToOrderInverseInput>;
};

/** The `saleOrder` to be created by this mutation. */
export type FkSaleOrderToOrderSaleOrderCreateInput = {
  giftCard?: InputMaybe<Scalars['BigFloat']>;
  order?: InputMaybe<FkSaleOrderToOrderInput>;
  saleOrderDetails?: InputMaybe<FkSaleOrderDetailToSaleOrderInverseInput>;
};

/** Input for the nested mutation of `product` in the `TouristServiceOrderDetailInput` mutation. */
export type FkTouristServiceOrderDetailToProductInput = {
  /** The primary key(s) for `product` for the far side of the relationship. */
  connectByKey?: InputMaybe<ProductPkProductConnect>;
  /** The primary key(s) for `product` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<ProductNodeIdConnect>;
  /** A `ProductInput` object that will be created and connected to this object. */
  create?: InputMaybe<FkTouristServiceOrderDetailToProductProductCreateInput>;
  /** The primary key(s) and patch data for `product` for the far side of the relationship. */
  updateByKey?: InputMaybe<ProductOnTouristServiceOrderDetailForFkTouristServiceOrderDetailToProductUsingPkProductUpdate>;
  /** The primary key(s) and patch data for `product` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<TouristServiceOrderDetailOnTouristServiceOrderDetailForFkTouristServiceOrderDetailToProductNodeIdUpdate>;
};

/** Input for the nested mutation of `touristServiceOrderDetail` in the `ProductInput` mutation. */
export type FkTouristServiceOrderDetailToProductInverseInput = {
  /** The primary key(s) for `touristServiceOrderDetail` for the far side of the relationship. */
  connectByKey?: InputMaybe<Array<TouristServiceOrderDetailPkTouristServiceOrderDetailConnect>>;
  /** The primary key(s) for `touristServiceOrderDetail` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<TouristServiceOrderDetailNodeIdConnect>>;
  /** A `TouristServiceOrderDetailInput` object that will be created and connected to this object. */
  create?: InputMaybe<Array<FkTouristServiceOrderDetailToProductTouristServiceOrderDetailCreateInput>>;
  /** The primary key(s) for `touristServiceOrderDetail` for the far side of the relationship. */
  deleteByKey?: InputMaybe<Array<TouristServiceOrderDetailPkTouristServiceOrderDetailDelete>>;
  /** The primary key(s) for `touristServiceOrderDetail` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<Array<TouristServiceOrderDetailNodeIdDelete>>;
  /** Flag indicating whether all other `touristServiceOrderDetail` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars['Boolean']>;
  /** The primary key(s) and patch data for `touristServiceOrderDetail` for the far side of the relationship. */
  updateByKey?: InputMaybe<Array<TouristServiceOrderDetailOnTouristServiceOrderDetailForFkTouristServiceOrderDetailToProductUsingPkTouristServiceOrderDetailUpdate>>;
  /** The primary key(s) and patch data for `touristServiceOrderDetail` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<Array<ProductOnTouristServiceOrderDetailForFkTouristServiceOrderDetailToProductNodeIdUpdate>>;
};

/** The `product` to be created by this mutation. */
export type FkTouristServiceOrderDetailToProductProductCreateInput = {
  code?: InputMaybe<Scalars['String']>;
  description: Scalars['String'];
  exportServiceOrderDetails?: InputMaybe<FkExportServiceOrderDetailToProductInverseInput>;
  homologations?: InputMaybe<FkProductHomologationToProductInverseInput>;
  insuranceOrderDetails?: InputMaybe<FkInsuranceOrderDetailToProductInverseInput>;
  name?: InputMaybe<Scalars['String']>;
  price: Scalars['BigFloat'];
  rentalOrderDetails?: InputMaybe<FkRentalOrderDetailToProductInverseInput>;
  saleOrderDetails?: InputMaybe<FkSaleOrderDetailToProductInverseInput>;
  touristServiceOrderDetails?: InputMaybe<FkTouristServiceOrderDetailToProductInverseInput>;
  type: ProductType;
};

/** The `touristServiceOrderDetail` to be created by this mutation. */
export type FkTouristServiceOrderDetailToProductTouristServiceOrderDetailCreateInput = {
  discount: Scalars['BigFloat'];
  price: Scalars['BigFloat'];
  productService?: InputMaybe<FkTouristServiceOrderDetailToProductInput>;
  quantity: Scalars['BigFloat'];
  sinDetalleHuespedesJson?: InputMaybe<Scalars['String']>;
  sinTipoHabitacionCodigo?: InputMaybe<Scalars['Int']>;
  touristServiceOrder?: InputMaybe<FkTouristServiceOrderDetailToTouristServiceOrderInput>;
  touristServiceOrderKey?: InputMaybe<Scalars['String']>;
};

/** Input for the nested mutation of `touristServiceOrder` in the `TouristServiceOrderDetailInput` mutation. */
export type FkTouristServiceOrderDetailToTouristServiceOrderInput = {
  /** The primary key(s) for `touristServiceOrder` for the far side of the relationship. */
  connectByKey?: InputMaybe<TouristServiceOrderPkTouristServiceOrderConnect>;
  /** The primary key(s) for `touristServiceOrder` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<TouristServiceOrderNodeIdConnect>;
  /** The primary key(s) for `touristServiceOrder` for the far side of the relationship. */
  connectByOrderKey?: InputMaybe<TouristServiceOrderUkTouristServiceOrderOrderKeyConnect>;
  /** A `TouristServiceOrderInput` object that will be created and connected to this object. */
  create?: InputMaybe<FkTouristServiceOrderDetailToTouristServiceOrderTouristServiceOrderCreateInput>;
  /** The primary key(s) for `touristServiceOrder` for the far side of the relationship. */
  deleteByKey?: InputMaybe<TouristServiceOrderPkTouristServiceOrderDelete>;
  /** The primary key(s) for `touristServiceOrder` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<TouristServiceOrderNodeIdDelete>;
  /** The primary key(s) for `touristServiceOrder` for the far side of the relationship. */
  deleteByOrderKey?: InputMaybe<TouristServiceOrderUkTouristServiceOrderOrderKeyDelete>;
  /** The primary key(s) and patch data for `touristServiceOrder` for the far side of the relationship. */
  updateByKey?: InputMaybe<TouristServiceOrderOnTouristServiceOrderDetailForFkTouristServiceOrderDetailToTouristServiceOrderUsingPkTouristServiceOrderUpdate>;
  /** The primary key(s) and patch data for `touristServiceOrder` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<TouristServiceOrderDetailOnTouristServiceOrderDetailForFkTouristServiceOrderDetailToTouristServiceOrderNodeIdUpdate>;
  /** The primary key(s) and patch data for `touristServiceOrder` for the far side of the relationship. */
  updateByOrderKey?: InputMaybe<TouristServiceOrderOnTouristServiceOrderDetailForFkTouristServiceOrderDetailToTouristServiceOrderUsingUkTouristServiceOrderOrderKeyUpdate>;
};

/** Input for the nested mutation of `touristServiceOrderDetail` in the `TouristServiceOrderInput` mutation. */
export type FkTouristServiceOrderDetailToTouristServiceOrderInverseInput = {
  /** The primary key(s) for `touristServiceOrderDetail` for the far side of the relationship. */
  connectByKey?: InputMaybe<Array<TouristServiceOrderDetailPkTouristServiceOrderDetailConnect>>;
  /** The primary key(s) for `touristServiceOrderDetail` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<TouristServiceOrderDetailNodeIdConnect>>;
  /** A `TouristServiceOrderDetailInput` object that will be created and connected to this object. */
  create?: InputMaybe<Array<FkTouristServiceOrderDetailToTouristServiceOrderTouristServiceOrderDetailCreateInput>>;
  /** The primary key(s) for `touristServiceOrderDetail` for the far side of the relationship. */
  deleteByKey?: InputMaybe<Array<TouristServiceOrderDetailPkTouristServiceOrderDetailDelete>>;
  /** The primary key(s) for `touristServiceOrderDetail` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<Array<TouristServiceOrderDetailNodeIdDelete>>;
  /** Flag indicating whether all other `touristServiceOrderDetail` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars['Boolean']>;
  /** The primary key(s) and patch data for `touristServiceOrderDetail` for the far side of the relationship. */
  updateByKey?: InputMaybe<Array<TouristServiceOrderDetailOnTouristServiceOrderDetailForFkTouristServiceOrderDetailToTouristServiceOrderUsingPkTouristServiceOrderDetailUpdate>>;
  /** The primary key(s) and patch data for `touristServiceOrderDetail` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<Array<TouristServiceOrderOnTouristServiceOrderDetailForFkTouristServiceOrderDetailToTouristServiceOrderNodeIdUpdate>>;
};

/** The `touristServiceOrder` to be created by this mutation. */
export type FkTouristServiceOrderDetailToTouristServiceOrderTouristServiceOrderCreateInput = {
  cantidadHabitaciones?: InputMaybe<Scalars['Int']>;
  cantidadHuespedes?: InputMaybe<Scalars['Int']>;
  cantidadMayores?: InputMaybe<Scalars['Int']>;
  cantidadMenores?: InputMaybe<Scalars['Int']>;
  deletion?: InputMaybe<Scalars['Boolean']>;
  fechaIngresoHospedaje?: InputMaybe<Scalars['Datetime']>;
  giftCard?: InputMaybe<Scalars['BigFloat']>;
  operadorTurismoRazonSocial?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<FkTouristServiceOrderToOrderInput>;
  orderKey?: InputMaybe<Scalars['String']>;
  touristServiceOrderDetails?: InputMaybe<FkTouristServiceOrderDetailToTouristServiceOrderInverseInput>;
};

/** The `touristServiceOrderDetail` to be created by this mutation. */
export type FkTouristServiceOrderDetailToTouristServiceOrderTouristServiceOrderDetailCreateInput = {
  discount: Scalars['BigFloat'];
  price: Scalars['BigFloat'];
  productKey?: InputMaybe<Scalars['String']>;
  productService?: InputMaybe<FkTouristServiceOrderDetailToProductInput>;
  quantity: Scalars['BigFloat'];
  sinDetalleHuespedesJson?: InputMaybe<Scalars['String']>;
  sinTipoHabitacionCodigo?: InputMaybe<Scalars['Int']>;
  touristServiceOrder?: InputMaybe<FkTouristServiceOrderDetailToTouristServiceOrderInput>;
};

/** Input for the nested mutation of `order` in the `TouristServiceOrderInput` mutation. */
export type FkTouristServiceOrderToOrderInput = {
  /** The primary key(s) for `order` for the far side of the relationship. */
  connectByKey?: InputMaybe<OrderPkOrderConnect>;
  /** The primary key(s) for `order` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<OrderNodeIdConnect>;
  /** A `OrderInput` object that will be created and connected to this object. */
  create?: InputMaybe<FkTouristServiceOrderToOrderOrderCreateInput>;
  /** The primary key(s) and patch data for `order` for the far side of the relationship. */
  updateByKey?: InputMaybe<OrderOnTouristServiceOrderForFkTouristServiceOrderToOrderUsingPkOrderUpdate>;
  /** The primary key(s) and patch data for `order` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<TouristServiceOrderOnTouristServiceOrderForFkTouristServiceOrderToOrderNodeIdUpdate>;
};

/** Input for the nested mutation of `touristServiceOrder` in the `OrderInput` mutation. */
export type FkTouristServiceOrderToOrderInverseInput = {
  /** The primary key(s) for `touristServiceOrder` for the far side of the relationship. */
  connectByKey?: InputMaybe<TouristServiceOrderPkTouristServiceOrderConnect>;
  /** The primary key(s) for `touristServiceOrder` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<TouristServiceOrderNodeIdConnect>;
  /** The primary key(s) for `touristServiceOrder` for the far side of the relationship. */
  connectByOrderKey?: InputMaybe<TouristServiceOrderUkTouristServiceOrderOrderKeyConnect>;
  /** A `TouristServiceOrderInput` object that will be created and connected to this object. */
  create?: InputMaybe<Array<FkTouristServiceOrderToOrderTouristServiceOrderCreateInput>>;
  /** The primary key(s) for `touristServiceOrder` for the far side of the relationship. */
  deleteByKey?: InputMaybe<TouristServiceOrderPkTouristServiceOrderDelete>;
  /** The primary key(s) for `touristServiceOrder` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<TouristServiceOrderNodeIdDelete>;
  /** The primary key(s) for `touristServiceOrder` for the far side of the relationship. */
  deleteByOrderKey?: InputMaybe<TouristServiceOrderUkTouristServiceOrderOrderKeyDelete>;
  /** Flag indicating whether all other `touristServiceOrder` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars['Boolean']>;
  /** The primary key(s) and patch data for `touristServiceOrder` for the far side of the relationship. */
  updateByKey?: InputMaybe<TouristServiceOrderOnTouristServiceOrderForFkTouristServiceOrderToOrderUsingPkTouristServiceOrderUpdate>;
  /** The primary key(s) and patch data for `touristServiceOrder` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<OrderOnTouristServiceOrderForFkTouristServiceOrderToOrderNodeIdUpdate>;
  /** The primary key(s) and patch data for `touristServiceOrder` for the far side of the relationship. */
  updateByOrderKey?: InputMaybe<TouristServiceOrderOnTouristServiceOrderForFkTouristServiceOrderToOrderUsingUkTouristServiceOrderOrderKeyUpdate>;
};

/** The `order` to be created by this mutation. */
export type FkTouristServiceOrderToOrderOrderCreateInput = {
  client?: InputMaybe<FkOrderToClientInput>;
  clientKey?: InputMaybe<Scalars['String']>;
  discount?: InputMaybe<Scalars['BigFloat']>;
  email?: InputMaybe<Scalars['String']>;
  exportServiceOrders?: InputMaybe<FkExportServiceOrderToOrderInverseInput>;
  homologations?: InputMaybe<FkOrderHomologationToOrderInverseInput>;
  insuranceOrders?: InputMaybe<FkInsuranceOrderToOrderInverseInput>;
  /** Importe que el cliente pagó. */
  paymentAmount?: InputMaybe<Scalars['BigFloat']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  pointOfSale?: InputMaybe<FkOrderToPointOfSaleInput>;
  pointOfSaleKey?: InputMaybe<Scalars['String']>;
  rentalOrders?: InputMaybe<FkRentalOrderToOrderInverseInput>;
  saleOrders?: InputMaybe<FkSaleOrderToOrderInverseInput>;
  status?: InputMaybe<OrderStatus>;
  touristServiceOrders?: InputMaybe<FkTouristServiceOrderToOrderInverseInput>;
};

/** The `touristServiceOrder` to be created by this mutation. */
export type FkTouristServiceOrderToOrderTouristServiceOrderCreateInput = {
  cantidadHabitaciones?: InputMaybe<Scalars['Int']>;
  cantidadHuespedes?: InputMaybe<Scalars['Int']>;
  cantidadMayores?: InputMaybe<Scalars['Int']>;
  cantidadMenores?: InputMaybe<Scalars['Int']>;
  deletion?: InputMaybe<Scalars['Boolean']>;
  fechaIngresoHospedaje?: InputMaybe<Scalars['Datetime']>;
  giftCard?: InputMaybe<Scalars['BigFloat']>;
  operadorTurismoRazonSocial?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<FkTouristServiceOrderToOrderInput>;
  touristServiceOrderDetails?: InputMaybe<FkTouristServiceOrderDetailToTouristServiceOrderInverseInput>;
};

export type GenerateCufdInput = {
  pointOfSaleKey: Scalars['String'];
};

export type GenerateCufdPayload = {
  __typename?: 'GenerateCufdPayload';
  cufd?: Maybe<Cufd>;
};

export type GenerateCuisInput = {
  pointOfSaleKey: Scalars['String'];
};

export type GenerateCuisPayload = {
  __typename?: 'GenerateCuisPayload';
  cuis?: Maybe<Cuis>;
};

export type GenerateInvoiceInput = {
  orderKey: Scalars['String'];
};

export type GenerateInvoicePayload = {
  __typename?: 'GenerateInvoicePayload';
  boolean?: Maybe<Scalars['Boolean']>;
  invoice?: Maybe<Invoice>;
};

/** Orden de renta. */
export type InsuranceOrder = Node & {
  __typename?: 'InsuranceOrder';
  createdAt: Scalars['Datetime'];
  createdBy: Scalars['Int'];
  deletedAt?: Maybe<Scalars['Datetime']>;
  deletedBy?: Maybe<Scalars['Int']>;
  deletion: Scalars['Boolean'];
  /** Reads and enables pagination through a set of `InsuranceOrderDetail`. */
  insuranceOrderDetails: InsuranceOrderDetailsConnection;
  key: Scalars['String'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads a single `Order` that is related to this `InsuranceOrder`. */
  order?: Maybe<Order>;
  orderKey: Scalars['String'];
  sinAjusteAfectacionIva: Scalars['BigFloat'];
  tenantId: Scalars['Int'];
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['Int']>;
};


/** Orden de renta. */
export type InsuranceOrderInsuranceOrderDetailsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<InsuranceOrderDetailCondition>;
  filter?: InputMaybe<InsuranceOrderDetailFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InsuranceOrderDetailsOrderBy>>;
};

/**
 * A condition to be used against `InsuranceOrder` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type InsuranceOrderCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `deletedAt` field. */
  deletedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `deletedBy` field. */
  deletedBy?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `deletion` field. */
  deletion?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `key` field. */
  key?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `orderKey` field. */
  orderKey?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `sinAjusteAfectacionIva` field. */
  sinAjusteAfectacionIva?: InputMaybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `tenantId` field. */
  tenantId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedBy` field. */
  updatedBy?: InputMaybe<Scalars['Int']>;
};

/** Detalle de venta. */
export type InsuranceOrderDetail = Node & {
  __typename?: 'InsuranceOrderDetail';
  createdAt: Scalars['Datetime'];
  createdBy: Scalars['Int'];
  deletedAt?: Maybe<Scalars['Datetime']>;
  deletedBy?: Maybe<Scalars['Int']>;
  deletion: Scalars['Boolean'];
  discount: Scalars['BigFloat'];
  /** Reads a single `InsuranceOrder` that is related to this `InsuranceOrderDetail`. */
  insuranceOrder?: Maybe<InsuranceOrder>;
  insuranceOrderKey: Scalars['String'];
  key: Scalars['String'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  price: Scalars['BigFloat'];
  productKey: Scalars['String'];
  /** Reads a single `Product` that is related to this `InsuranceOrderDetail`. */
  productService?: Maybe<Product>;
  quantity: Scalars['Int'];
  subtotal: Scalars['BigFloat'];
  tenantId: Scalars['Int'];
  total: Scalars['BigFloat'];
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['Int']>;
};

/**
 * A condition to be used against `InsuranceOrderDetail` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type InsuranceOrderDetailCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `deletedAt` field. */
  deletedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `deletedBy` field. */
  deletedBy?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `deletion` field. */
  deletion?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `discount` field. */
  discount?: InputMaybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `insuranceOrderKey` field. */
  insuranceOrderKey?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `key` field. */
  key?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `price` field. */
  price?: InputMaybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `productKey` field. */
  productKey?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `quantity` field. */
  quantity?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `subtotal` field. */
  subtotal?: InputMaybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `tenantId` field. */
  tenantId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `total` field. */
  total?: InputMaybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedBy` field. */
  updatedBy?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against `InsuranceOrderDetail` object types. All fields are combined with a logical ‘and.’ */
export type InsuranceOrderDetailFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<InsuranceOrderDetailFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy?: InputMaybe<IntFilter>;
  /** Filter by the object’s `deletedAt` field. */
  deletedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `deletedBy` field. */
  deletedBy?: InputMaybe<IntFilter>;
  /** Filter by the object’s `deletion` field. */
  deletion?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `discount` field. */
  discount?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `insuranceOrderKey` field. */
  insuranceOrderKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `key` field. */
  key?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<InsuranceOrderDetailFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<InsuranceOrderDetailFilter>>;
  /** Filter by the object’s `price` field. */
  price?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `productKey` field. */
  productKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `quantity` field. */
  quantity?: InputMaybe<IntFilter>;
  /** Filter by the object’s `subtotal` field. */
  subtotal?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `tenantId` field. */
  tenantId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `total` field. */
  total?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `updatedBy` field. */
  updatedBy?: InputMaybe<IntFilter>;
};

/** An input for mutations affecting `InsuranceOrderDetail` */
export type InsuranceOrderDetailInput = {
  discount: Scalars['BigFloat'];
  insuranceOrder?: InputMaybe<FkInsuranceOrderDetailToInsuranceOrderInput>;
  insuranceOrderKey?: InputMaybe<Scalars['String']>;
  price: Scalars['BigFloat'];
  productKey?: InputMaybe<Scalars['String']>;
  productService?: InputMaybe<FkInsuranceOrderDetailToProductInput>;
  quantity: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to connect. */
export type InsuranceOrderDetailNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `insuranceOrderDetail` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type InsuranceOrderDetailNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `insuranceOrderDetail` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type InsuranceOrderDetailOnInsuranceOrderDetailForFkInsuranceOrderDetailToInsuranceOrderNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `insuranceOrder` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `insuranceOrder` being updated. */
  patch: InsuranceOrderPatch;
};

/** The fields on `insuranceOrderDetail` to look up the row to update. */
export type InsuranceOrderDetailOnInsuranceOrderDetailForFkInsuranceOrderDetailToInsuranceOrderUsingPkInsuranceOrderDetailUpdate = {
  key: Scalars['String'];
  /** An object where the defined keys will be set on the `insuranceOrderDetail` being updated. */
  patch: UpdateInsuranceOrderDetailOnInsuranceOrderDetailForFkInsuranceOrderDetailToInsuranceOrderPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type InsuranceOrderDetailOnInsuranceOrderDetailForFkInsuranceOrderDetailToProductNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `product` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `product` being updated. */
  patch: ProductPatch;
};

/** The fields on `insuranceOrderDetail` to look up the row to update. */
export type InsuranceOrderDetailOnInsuranceOrderDetailForFkInsuranceOrderDetailToProductUsingPkInsuranceOrderDetailUpdate = {
  key: Scalars['String'];
  /** An object where the defined keys will be set on the `insuranceOrderDetail` being updated. */
  patch: UpdateInsuranceOrderDetailOnInsuranceOrderDetailForFkInsuranceOrderDetailToProductPatch;
};

/** Represents an update to a `InsuranceOrderDetail`. Fields that are set will be updated. */
export type InsuranceOrderDetailPatch = {
  discount?: InputMaybe<Scalars['BigFloat']>;
  insuranceOrder?: InputMaybe<FkInsuranceOrderDetailToInsuranceOrderInput>;
  insuranceOrderKey?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['BigFloat']>;
  productKey?: InputMaybe<Scalars['String']>;
  productService?: InputMaybe<FkInsuranceOrderDetailToProductInput>;
  quantity?: InputMaybe<Scalars['Int']>;
};

/** The fields on `insuranceOrderDetail` to look up the row to connect. */
export type InsuranceOrderDetailPkInsuranceOrderDetailConnect = {
  key: Scalars['String'];
};

/** The fields on `insuranceOrderDetail` to look up the row to delete. */
export type InsuranceOrderDetailPkInsuranceOrderDetailDelete = {
  key: Scalars['String'];
};

/** A connection to a list of `InsuranceOrderDetail` values. */
export type InsuranceOrderDetailsConnection = {
  __typename?: 'InsuranceOrderDetailsConnection';
  /** A list of edges which contains the `InsuranceOrderDetail` and cursor to aid in pagination. */
  edges: Array<InsuranceOrderDetailsEdge>;
  /** A list of `InsuranceOrderDetail` objects. */
  nodes: Array<Maybe<InsuranceOrderDetail>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `InsuranceOrderDetail` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `InsuranceOrderDetail` edge in the connection. */
export type InsuranceOrderDetailsEdge = {
  __typename?: 'InsuranceOrderDetailsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `InsuranceOrderDetail` at the end of the edge. */
  node?: Maybe<InsuranceOrderDetail>;
};

/** Methods to use when ordering `InsuranceOrderDetail`. */
export enum InsuranceOrderDetailsOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  DeletedAtAsc = 'DELETED_AT_ASC',
  DeletedAtDesc = 'DELETED_AT_DESC',
  DeletedByAsc = 'DELETED_BY_ASC',
  DeletedByDesc = 'DELETED_BY_DESC',
  DeletionAsc = 'DELETION_ASC',
  DeletionDesc = 'DELETION_DESC',
  DiscountAsc = 'DISCOUNT_ASC',
  DiscountDesc = 'DISCOUNT_DESC',
  InsuranceOrderByInsuranceOrderKeyCreatedAtAsc = 'INSURANCE_ORDER_BY_INSURANCE_ORDER_KEY__CREATED_AT_ASC',
  InsuranceOrderByInsuranceOrderKeyCreatedAtDesc = 'INSURANCE_ORDER_BY_INSURANCE_ORDER_KEY__CREATED_AT_DESC',
  InsuranceOrderByInsuranceOrderKeyCreatedByAsc = 'INSURANCE_ORDER_BY_INSURANCE_ORDER_KEY__CREATED_BY_ASC',
  InsuranceOrderByInsuranceOrderKeyCreatedByDesc = 'INSURANCE_ORDER_BY_INSURANCE_ORDER_KEY__CREATED_BY_DESC',
  InsuranceOrderByInsuranceOrderKeyDeletedAtAsc = 'INSURANCE_ORDER_BY_INSURANCE_ORDER_KEY__DELETED_AT_ASC',
  InsuranceOrderByInsuranceOrderKeyDeletedAtDesc = 'INSURANCE_ORDER_BY_INSURANCE_ORDER_KEY__DELETED_AT_DESC',
  InsuranceOrderByInsuranceOrderKeyDeletedByAsc = 'INSURANCE_ORDER_BY_INSURANCE_ORDER_KEY__DELETED_BY_ASC',
  InsuranceOrderByInsuranceOrderKeyDeletedByDesc = 'INSURANCE_ORDER_BY_INSURANCE_ORDER_KEY__DELETED_BY_DESC',
  InsuranceOrderByInsuranceOrderKeyDeletionAsc = 'INSURANCE_ORDER_BY_INSURANCE_ORDER_KEY__DELETION_ASC',
  InsuranceOrderByInsuranceOrderKeyDeletionDesc = 'INSURANCE_ORDER_BY_INSURANCE_ORDER_KEY__DELETION_DESC',
  InsuranceOrderByInsuranceOrderKeyKeyAsc = 'INSURANCE_ORDER_BY_INSURANCE_ORDER_KEY__KEY_ASC',
  InsuranceOrderByInsuranceOrderKeyKeyDesc = 'INSURANCE_ORDER_BY_INSURANCE_ORDER_KEY__KEY_DESC',
  InsuranceOrderByInsuranceOrderKeyOrderKeyAsc = 'INSURANCE_ORDER_BY_INSURANCE_ORDER_KEY__ORDER_KEY_ASC',
  InsuranceOrderByInsuranceOrderKeyOrderKeyDesc = 'INSURANCE_ORDER_BY_INSURANCE_ORDER_KEY__ORDER_KEY_DESC',
  InsuranceOrderByInsuranceOrderKeySinAjusteAfectacionIvaAsc = 'INSURANCE_ORDER_BY_INSURANCE_ORDER_KEY__SIN_AJUSTE_AFECTACION_IVA_ASC',
  InsuranceOrderByInsuranceOrderKeySinAjusteAfectacionIvaDesc = 'INSURANCE_ORDER_BY_INSURANCE_ORDER_KEY__SIN_AJUSTE_AFECTACION_IVA_DESC',
  InsuranceOrderByInsuranceOrderKeyTenantIdAsc = 'INSURANCE_ORDER_BY_INSURANCE_ORDER_KEY__TENANT_ID_ASC',
  InsuranceOrderByInsuranceOrderKeyTenantIdDesc = 'INSURANCE_ORDER_BY_INSURANCE_ORDER_KEY__TENANT_ID_DESC',
  InsuranceOrderByInsuranceOrderKeyUpdatedAtAsc = 'INSURANCE_ORDER_BY_INSURANCE_ORDER_KEY__UPDATED_AT_ASC',
  InsuranceOrderByInsuranceOrderKeyUpdatedAtDesc = 'INSURANCE_ORDER_BY_INSURANCE_ORDER_KEY__UPDATED_AT_DESC',
  InsuranceOrderByInsuranceOrderKeyUpdatedByAsc = 'INSURANCE_ORDER_BY_INSURANCE_ORDER_KEY__UPDATED_BY_ASC',
  InsuranceOrderByInsuranceOrderKeyUpdatedByDesc = 'INSURANCE_ORDER_BY_INSURANCE_ORDER_KEY__UPDATED_BY_DESC',
  InsuranceOrderKeyAsc = 'INSURANCE_ORDER_KEY_ASC',
  InsuranceOrderKeyDesc = 'INSURANCE_ORDER_KEY_DESC',
  KeyAsc = 'KEY_ASC',
  KeyDesc = 'KEY_DESC',
  Natural = 'NATURAL',
  PriceAsc = 'PRICE_ASC',
  PriceDesc = 'PRICE_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ProductByProductKeyCodeAsc = 'PRODUCT_BY_PRODUCT_KEY__CODE_ASC',
  ProductByProductKeyCodeDesc = 'PRODUCT_BY_PRODUCT_KEY__CODE_DESC',
  ProductByProductKeyCreatedAtAsc = 'PRODUCT_BY_PRODUCT_KEY__CREATED_AT_ASC',
  ProductByProductKeyCreatedAtDesc = 'PRODUCT_BY_PRODUCT_KEY__CREATED_AT_DESC',
  ProductByProductKeyCreatedByAsc = 'PRODUCT_BY_PRODUCT_KEY__CREATED_BY_ASC',
  ProductByProductKeyCreatedByDesc = 'PRODUCT_BY_PRODUCT_KEY__CREATED_BY_DESC',
  ProductByProductKeyDeletedAtAsc = 'PRODUCT_BY_PRODUCT_KEY__DELETED_AT_ASC',
  ProductByProductKeyDeletedAtDesc = 'PRODUCT_BY_PRODUCT_KEY__DELETED_AT_DESC',
  ProductByProductKeyDeletedByAsc = 'PRODUCT_BY_PRODUCT_KEY__DELETED_BY_ASC',
  ProductByProductKeyDeletedByDesc = 'PRODUCT_BY_PRODUCT_KEY__DELETED_BY_DESC',
  ProductByProductKeyDeletionAsc = 'PRODUCT_BY_PRODUCT_KEY__DELETION_ASC',
  ProductByProductKeyDeletionDesc = 'PRODUCT_BY_PRODUCT_KEY__DELETION_DESC',
  ProductByProductKeyDescriptionAsc = 'PRODUCT_BY_PRODUCT_KEY__DESCRIPTION_ASC',
  ProductByProductKeyDescriptionDesc = 'PRODUCT_BY_PRODUCT_KEY__DESCRIPTION_DESC',
  ProductByProductKeyKeyAsc = 'PRODUCT_BY_PRODUCT_KEY__KEY_ASC',
  ProductByProductKeyKeyDesc = 'PRODUCT_BY_PRODUCT_KEY__KEY_DESC',
  ProductByProductKeyNameAsc = 'PRODUCT_BY_PRODUCT_KEY__NAME_ASC',
  ProductByProductKeyNameDesc = 'PRODUCT_BY_PRODUCT_KEY__NAME_DESC',
  ProductByProductKeyPriceAsc = 'PRODUCT_BY_PRODUCT_KEY__PRICE_ASC',
  ProductByProductKeyPriceDesc = 'PRODUCT_BY_PRODUCT_KEY__PRICE_DESC',
  ProductByProductKeyTenantIdAsc = 'PRODUCT_BY_PRODUCT_KEY__TENANT_ID_ASC',
  ProductByProductKeyTenantIdDesc = 'PRODUCT_BY_PRODUCT_KEY__TENANT_ID_DESC',
  ProductByProductKeyTypeAsc = 'PRODUCT_BY_PRODUCT_KEY__TYPE_ASC',
  ProductByProductKeyTypeDesc = 'PRODUCT_BY_PRODUCT_KEY__TYPE_DESC',
  ProductByProductKeyUpdatedAtAsc = 'PRODUCT_BY_PRODUCT_KEY__UPDATED_AT_ASC',
  ProductByProductKeyUpdatedAtDesc = 'PRODUCT_BY_PRODUCT_KEY__UPDATED_AT_DESC',
  ProductByProductKeyUpdatedByAsc = 'PRODUCT_BY_PRODUCT_KEY__UPDATED_BY_ASC',
  ProductByProductKeyUpdatedByDesc = 'PRODUCT_BY_PRODUCT_KEY__UPDATED_BY_DESC',
  ProductKeyAsc = 'PRODUCT_KEY_ASC',
  ProductKeyDesc = 'PRODUCT_KEY_DESC',
  QuantityAsc = 'QUANTITY_ASC',
  QuantityDesc = 'QUANTITY_DESC',
  SubtotalAsc = 'SUBTOTAL_ASC',
  SubtotalDesc = 'SUBTOTAL_DESC',
  TenantIdAsc = 'TENANT_ID_ASC',
  TenantIdDesc = 'TENANT_ID_DESC',
  TotalAsc = 'TOTAL_ASC',
  TotalDesc = 'TOTAL_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UpdatedByAsc = 'UPDATED_BY_ASC',
  UpdatedByDesc = 'UPDATED_BY_DESC'
}

/** A filter to be used against `InsuranceOrder` object types. All fields are combined with a logical ‘and.’ */
export type InsuranceOrderFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<InsuranceOrderFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy?: InputMaybe<IntFilter>;
  /** Filter by the object’s `deletedAt` field. */
  deletedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `deletedBy` field. */
  deletedBy?: InputMaybe<IntFilter>;
  /** Filter by the object’s `deletion` field. */
  deletion?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `key` field. */
  key?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<InsuranceOrderFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<InsuranceOrderFilter>>;
  /** Filter by the object’s `orderKey` field. */
  orderKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `sinAjusteAfectacionIva` field. */
  sinAjusteAfectacionIva?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `tenantId` field. */
  tenantId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `updatedBy` field. */
  updatedBy?: InputMaybe<IntFilter>;
};

/** An input for mutations affecting `InsuranceOrder` */
export type InsuranceOrderInput = {
  deletion?: InputMaybe<Scalars['Boolean']>;
  insuranceOrderDetails?: InputMaybe<FkInsuranceOrderDetailToInsuranceOrderInverseInput>;
  order?: InputMaybe<FkInsuranceOrderToOrderInput>;
  orderKey?: InputMaybe<Scalars['String']>;
  sinAjusteAfectacionIva: Scalars['BigFloat'];
};

/** The globally unique `ID` look up for the row to connect. */
export type InsuranceOrderNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `insuranceOrder` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type InsuranceOrderNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `insuranceOrder` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type InsuranceOrderOnInsuranceOrderDetailForFkInsuranceOrderDetailToInsuranceOrderNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `insuranceOrderDetail` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `insuranceOrderDetail` being updated. */
  patch: InsuranceOrderDetailPatch;
};

/** The fields on `insuranceOrder` to look up the row to update. */
export type InsuranceOrderOnInsuranceOrderDetailForFkInsuranceOrderDetailToInsuranceOrderUsingPkInsuranceOrderUpdate = {
  key: Scalars['String'];
  /** An object where the defined keys will be set on the `insuranceOrder` being updated. */
  patch: UpdateInsuranceOrderOnInsuranceOrderDetailForFkInsuranceOrderDetailToInsuranceOrderPatch;
};

/** The fields on `insuranceOrder` to look up the row to update. */
export type InsuranceOrderOnInsuranceOrderDetailForFkInsuranceOrderDetailToInsuranceOrderUsingUkInsuranceOrderOrderKeyUpdate = {
  orderKey: Scalars['String'];
  /** An object where the defined keys will be set on the `insuranceOrder` being updated. */
  patch: UpdateInsuranceOrderOnInsuranceOrderDetailForFkInsuranceOrderDetailToInsuranceOrderPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type InsuranceOrderOnInsuranceOrderForFkInsuranceOrderToOrderNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `order` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `order` being updated. */
  patch: OrderPatch;
};

/** The fields on `insuranceOrder` to look up the row to update. */
export type InsuranceOrderOnInsuranceOrderForFkInsuranceOrderToOrderUsingPkInsuranceOrderUpdate = {
  key: Scalars['String'];
  /** An object where the defined keys will be set on the `insuranceOrder` being updated. */
  patch: UpdateInsuranceOrderOnInsuranceOrderForFkInsuranceOrderToOrderPatch;
};

/** The fields on `insuranceOrder` to look up the row to update. */
export type InsuranceOrderOnInsuranceOrderForFkInsuranceOrderToOrderUsingUkInsuranceOrderOrderKeyUpdate = {
  orderKey: Scalars['String'];
  /** An object where the defined keys will be set on the `insuranceOrder` being updated. */
  patch: UpdateInsuranceOrderOnInsuranceOrderForFkInsuranceOrderToOrderPatch;
};

/** Represents an update to a `InsuranceOrder`. Fields that are set will be updated. */
export type InsuranceOrderPatch = {
  deletion?: InputMaybe<Scalars['Boolean']>;
  insuranceOrderDetails?: InputMaybe<FkInsuranceOrderDetailToInsuranceOrderInverseInput>;
  order?: InputMaybe<FkInsuranceOrderToOrderInput>;
  orderKey?: InputMaybe<Scalars['String']>;
  sinAjusteAfectacionIva?: InputMaybe<Scalars['BigFloat']>;
};

/** The fields on `insuranceOrder` to look up the row to connect. */
export type InsuranceOrderPkInsuranceOrderConnect = {
  key: Scalars['String'];
};

/** The fields on `insuranceOrder` to look up the row to delete. */
export type InsuranceOrderPkInsuranceOrderDelete = {
  key: Scalars['String'];
};

/** The fields on `insuranceOrder` to look up the row to connect. */
export type InsuranceOrderUkInsuranceOrderOrderKeyConnect = {
  orderKey: Scalars['String'];
};

/** The fields on `insuranceOrder` to look up the row to delete. */
export type InsuranceOrderUkInsuranceOrderOrderKeyDelete = {
  orderKey: Scalars['String'];
};

/** A connection to a list of `InsuranceOrder` values. */
export type InsuranceOrdersConnection = {
  __typename?: 'InsuranceOrdersConnection';
  /** A list of edges which contains the `InsuranceOrder` and cursor to aid in pagination. */
  edges: Array<InsuranceOrdersEdge>;
  /** A list of `InsuranceOrder` objects. */
  nodes: Array<Maybe<InsuranceOrder>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `InsuranceOrder` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `InsuranceOrder` edge in the connection. */
export type InsuranceOrdersEdge = {
  __typename?: 'InsuranceOrdersEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `InsuranceOrder` at the end of the edge. */
  node?: Maybe<InsuranceOrder>;
};

/** Methods to use when ordering `InsuranceOrder`. */
export enum InsuranceOrdersOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  DeletedAtAsc = 'DELETED_AT_ASC',
  DeletedAtDesc = 'DELETED_AT_DESC',
  DeletedByAsc = 'DELETED_BY_ASC',
  DeletedByDesc = 'DELETED_BY_DESC',
  DeletionAsc = 'DELETION_ASC',
  DeletionDesc = 'DELETION_DESC',
  InsuranceOrderDetailsByInsuranceOrderKeyCountAsc = 'INSURANCE_ORDER_DETAILS_BY_INSURANCE_ORDER_KEY__COUNT_ASC',
  InsuranceOrderDetailsByInsuranceOrderKeyCountDesc = 'INSURANCE_ORDER_DETAILS_BY_INSURANCE_ORDER_KEY__COUNT_DESC',
  KeyAsc = 'KEY_ASC',
  KeyDesc = 'KEY_DESC',
  Natural = 'NATURAL',
  OrderByOrderKeyChangeAmountAsc = 'ORDER_BY_ORDER_KEY__CHANGE_AMOUNT_ASC',
  OrderByOrderKeyChangeAmountDesc = 'ORDER_BY_ORDER_KEY__CHANGE_AMOUNT_DESC',
  OrderByOrderKeyClientKeyAsc = 'ORDER_BY_ORDER_KEY__CLIENT_KEY_ASC',
  OrderByOrderKeyClientKeyDesc = 'ORDER_BY_ORDER_KEY__CLIENT_KEY_DESC',
  OrderByOrderKeyCreatedAtAsc = 'ORDER_BY_ORDER_KEY__CREATED_AT_ASC',
  OrderByOrderKeyCreatedAtDesc = 'ORDER_BY_ORDER_KEY__CREATED_AT_DESC',
  OrderByOrderKeyCreatedByAsc = 'ORDER_BY_ORDER_KEY__CREATED_BY_ASC',
  OrderByOrderKeyCreatedByDesc = 'ORDER_BY_ORDER_KEY__CREATED_BY_DESC',
  OrderByOrderKeyDeletedAtAsc = 'ORDER_BY_ORDER_KEY__DELETED_AT_ASC',
  OrderByOrderKeyDeletedAtDesc = 'ORDER_BY_ORDER_KEY__DELETED_AT_DESC',
  OrderByOrderKeyDeletedByAsc = 'ORDER_BY_ORDER_KEY__DELETED_BY_ASC',
  OrderByOrderKeyDeletedByDesc = 'ORDER_BY_ORDER_KEY__DELETED_BY_DESC',
  OrderByOrderKeyDeletionAsc = 'ORDER_BY_ORDER_KEY__DELETION_ASC',
  OrderByOrderKeyDeletionDesc = 'ORDER_BY_ORDER_KEY__DELETION_DESC',
  OrderByOrderKeyDiscountAsc = 'ORDER_BY_ORDER_KEY__DISCOUNT_ASC',
  OrderByOrderKeyDiscountDesc = 'ORDER_BY_ORDER_KEY__DISCOUNT_DESC',
  OrderByOrderKeyEmailAsc = 'ORDER_BY_ORDER_KEY__EMAIL_ASC',
  OrderByOrderKeyEmailDesc = 'ORDER_BY_ORDER_KEY__EMAIL_DESC',
  OrderByOrderKeyKeyAsc = 'ORDER_BY_ORDER_KEY__KEY_ASC',
  OrderByOrderKeyKeyDesc = 'ORDER_BY_ORDER_KEY__KEY_DESC',
  OrderByOrderKeyPaymentAmountAsc = 'ORDER_BY_ORDER_KEY__PAYMENT_AMOUNT_ASC',
  OrderByOrderKeyPaymentAmountDesc = 'ORDER_BY_ORDER_KEY__PAYMENT_AMOUNT_DESC',
  OrderByOrderKeyPhoneNumberAsc = 'ORDER_BY_ORDER_KEY__PHONE_NUMBER_ASC',
  OrderByOrderKeyPhoneNumberDesc = 'ORDER_BY_ORDER_KEY__PHONE_NUMBER_DESC',
  OrderByOrderKeyPointOfSaleKeyAsc = 'ORDER_BY_ORDER_KEY__POINT_OF_SALE_KEY_ASC',
  OrderByOrderKeyPointOfSaleKeyDesc = 'ORDER_BY_ORDER_KEY__POINT_OF_SALE_KEY_DESC',
  OrderByOrderKeyQuantityAsc = 'ORDER_BY_ORDER_KEY__QUANTITY_ASC',
  OrderByOrderKeyQuantityDesc = 'ORDER_BY_ORDER_KEY__QUANTITY_DESC',
  OrderByOrderKeyStatusAsc = 'ORDER_BY_ORDER_KEY__STATUS_ASC',
  OrderByOrderKeyStatusDesc = 'ORDER_BY_ORDER_KEY__STATUS_DESC',
  OrderByOrderKeySubtotalAsc = 'ORDER_BY_ORDER_KEY__SUBTOTAL_ASC',
  OrderByOrderKeySubtotalDesc = 'ORDER_BY_ORDER_KEY__SUBTOTAL_DESC',
  OrderByOrderKeyTenantIdAsc = 'ORDER_BY_ORDER_KEY__TENANT_ID_ASC',
  OrderByOrderKeyTenantIdDesc = 'ORDER_BY_ORDER_KEY__TENANT_ID_DESC',
  OrderByOrderKeyTotalAsc = 'ORDER_BY_ORDER_KEY__TOTAL_ASC',
  OrderByOrderKeyTotalDesc = 'ORDER_BY_ORDER_KEY__TOTAL_DESC',
  OrderByOrderKeyUpdatedAtAsc = 'ORDER_BY_ORDER_KEY__UPDATED_AT_ASC',
  OrderByOrderKeyUpdatedAtDesc = 'ORDER_BY_ORDER_KEY__UPDATED_AT_DESC',
  OrderByOrderKeyUpdatedByAsc = 'ORDER_BY_ORDER_KEY__UPDATED_BY_ASC',
  OrderByOrderKeyUpdatedByDesc = 'ORDER_BY_ORDER_KEY__UPDATED_BY_DESC',
  OrderKeyAsc = 'ORDER_KEY_ASC',
  OrderKeyDesc = 'ORDER_KEY_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  SinAjusteAfectacionIvaAsc = 'SIN_AJUSTE_AFECTACION_IVA_ASC',
  SinAjusteAfectacionIvaDesc = 'SIN_AJUSTE_AFECTACION_IVA_DESC',
  TenantIdAsc = 'TENANT_ID_ASC',
  TenantIdDesc = 'TENANT_ID_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UpdatedByAsc = 'UPDATED_BY_ASC',
  UpdatedByDesc = 'UPDATED_BY_DESC'
}

/** A filter to be used against Int fields. All fields are combined with a logical ‘and.’ */
export type IntFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['Int']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['Int']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['Int']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['Int']>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['Int']>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['Int']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['Int']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['Int']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['Int']>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type Invoice = {
  __typename?: 'Invoice';
  additionalDiscount?: Maybe<Scalars['BigFloat']>;
  businessName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  cuf?: Maybe<Scalars['String']>;
  currencyCode?: Maybe<Scalars['Int']>;
  documentNumber?: Maybe<Scalars['String']>;
  documentTypeCode?: Maybe<Scalars['String']>;
  documentTypeDescription?: Maybe<Scalars['String']>;
  emissionTypeCode?: Maybe<Scalars['Int']>;
  exchangeRate?: Maybe<Scalars['BigFloat']>;
  invoiceTypeCode?: Maybe<Scalars['Int']>;
  issuedAt?: Maybe<Scalars['Datetime']>;
  key?: Maybe<Scalars['String']>;
  modalityTypeCode?: Maybe<Scalars['Int']>;
  number?: Maybe<Scalars['Int']>;
  orderKey?: Maybe<Scalars['String']>;
  sectorDocumentCode?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  totalAmount?: Maybe<Scalars['BigFloat']>;
  totalAmountCurrency?: Maybe<Scalars['BigFloat']>;
  totalAmountSubjectToIva?: Maybe<Scalars['BigFloat']>;
  totalAmountToPay?: Maybe<Scalars['BigFloat']>;
  url?: Maybe<Scalars['String']>;
  verificationStatus?: Maybe<Scalars['String']>;
};

export type InvoiceCancellation = {
  __typename?: 'InvoiceCancellation';
  branchOfficeCode?: Maybe<Scalars['Int']>;
  branchOfficeKey?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  cufd?: Maybe<Scalars['String']>;
  cuis?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  pointOfSaleCode?: Maybe<Scalars['Int']>;
  pointOfSaleKey?: Maybe<Scalars['String']>;
  reasonCode?: Maybe<Scalars['Int']>;
  reasonDescription?: Maybe<Scalars['String']>;
  receptionCode?: Maybe<Scalars['String']>;
  sendingStatus?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
};

/** A filter to be used against `Invoice` object types. All fields are combined with a logical ‘and.’ */
export type InvoiceFilter = {
  /** Filter by the object’s `additionalDiscount` field. */
  additionalDiscount?: InputMaybe<BigFloatFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<InvoiceFilter>>;
  /** Filter by the object’s `businessName` field. */
  businessName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `cuf` field. */
  cuf?: InputMaybe<StringFilter>;
  /** Filter by the object’s `currencyCode` field. */
  currencyCode?: InputMaybe<IntFilter>;
  /** Filter by the object’s `documentNumber` field. */
  documentNumber?: InputMaybe<StringFilter>;
  /** Filter by the object’s `documentTypeCode` field. */
  documentTypeCode?: InputMaybe<StringFilter>;
  /** Filter by the object’s `documentTypeDescription` field. */
  documentTypeDescription?: InputMaybe<StringFilter>;
  /** Filter by the object’s `emissionTypeCode` field. */
  emissionTypeCode?: InputMaybe<IntFilter>;
  /** Filter by the object’s `exchangeRate` field. */
  exchangeRate?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `invoiceTypeCode` field. */
  invoiceTypeCode?: InputMaybe<IntFilter>;
  /** Filter by the object’s `issuedAt` field. */
  issuedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `key` field. */
  key?: InputMaybe<StringFilter>;
  /** Filter by the object’s `modalityTypeCode` field. */
  modalityTypeCode?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<InvoiceFilter>;
  /** Filter by the object’s `number` field. */
  number?: InputMaybe<IntFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<InvoiceFilter>>;
  /** Filter by the object’s `orderKey` field. */
  orderKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `sectorDocumentCode` field. */
  sectorDocumentCode?: InputMaybe<IntFilter>;
  /** Filter by the object’s `status` field. */
  status?: InputMaybe<StringFilter>;
  /** Filter by the object’s `totalAmount` field. */
  totalAmount?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `totalAmountCurrency` field. */
  totalAmountCurrency?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `totalAmountSubjectToIva` field. */
  totalAmountSubjectToIva?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `totalAmountToPay` field. */
  totalAmountToPay?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `url` field. */
  url?: InputMaybe<StringFilter>;
  /** Filter by the object’s `verificationStatus` field. */
  verificationStatus?: InputMaybe<StringFilter>;
};

export enum InvoiceStatus {
  Cancelled = 'CANCELLED',
  Generated = 'GENERATED',
  Invoiced = 'INVOICED',
  Registered = 'REGISTERED',
  Rejected = 'REJECTED'
}

/** A filter to be used against InvoiceStatus fields. All fields are combined with a logical ‘and.’ */
export type InvoiceStatusFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<InvoiceStatus>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<InvoiceStatus>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<InvoiceStatus>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<InvoiceStatus>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<InvoiceStatus>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<InvoiceStatus>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<InvoiceStatus>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<InvoiceStatus>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<InvoiceStatus>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<InvoiceStatus>>;
};

/** A connection to a list of `Invoice` values. */
export type InvoicesConnection = {
  __typename?: 'InvoicesConnection';
  /** A list of edges which contains the `Invoice` and cursor to aid in pagination. */
  edges: Array<InvoicesEdge>;
  /** A list of `Invoice` objects. */
  nodes: Array<Maybe<Invoice>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Invoice` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Invoice` edge in the connection. */
export type InvoicesEdge = {
  __typename?: 'InvoicesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Invoice` at the end of the edge. */
  node?: Maybe<Invoice>;
};

/** The root mutation type which contains root level fields which mutate data. */
export type Mutation = {
  __typename?: 'Mutation';
  /** Activar cuenta mediante token. */
  activateAccount?: Maybe<ActivateAccountPayload>;
  authenticate?: Maybe<AuthenticatePayload>;
  /** Genera JWT para el usuario relacionado al Api Key */
  authenticateByApiKey?: Maybe<AuthenticateByApiKeyPayload>;
  cancelInvoice?: Maybe<CancelInvoicePayload>;
  /** Creates a single `BranchOffice`. */
  createBranchOffice?: Maybe<CreateBranchOfficePayload>;
  /** Creates a single `Client`. */
  createClient?: Maybe<CreateClientPayload>;
  /** Creates a single `ClientHomologation`. */
  createClientHomologation?: Maybe<CreateClientHomologationPayload>;
  /** Creates a single `ExportServiceOrder`. */
  createExportServiceOrder?: Maybe<CreateExportServiceOrderPayload>;
  /** Creates a single `ExportServiceOrderDetail`. */
  createExportServiceOrderDetail?: Maybe<CreateExportServiceOrderDetailPayload>;
  /** Creates a single `InsuranceOrder`. */
  createInsuranceOrder?: Maybe<CreateInsuranceOrderPayload>;
  /** Creates a single `InsuranceOrderDetail`. */
  createInsuranceOrderDetail?: Maybe<CreateInsuranceOrderDetailPayload>;
  /** Creates a single `Order`. */
  createOrder?: Maybe<CreateOrderPayload>;
  /** Creates a single `OrderHomologation`. */
  createOrderHomologation?: Maybe<CreateOrderHomologationPayload>;
  /** Creates a single `Person`. */
  createPerson?: Maybe<CreatePersonPayload>;
  createPointOfSale?: Maybe<CreatePointOfSalePayload>;
  /** Creates a single `PointOfSaleUser`. */
  createPointOfSaleUser?: Maybe<CreatePointOfSaleUserPayload>;
  /** Creates a single `Product`. */
  createProduct?: Maybe<CreateProductPayload>;
  /** Creates a single `ProductHomologation`. */
  createProductHomologation?: Maybe<CreateProductHomologationPayload>;
  /** Creates a single `RentalOrder`. */
  createRentalOrder?: Maybe<CreateRentalOrderPayload>;
  /** Creates a single `RentalOrderDetail`. */
  createRentalOrderDetail?: Maybe<CreateRentalOrderDetailPayload>;
  /** Creates a single `SaleOrder`. */
  createSaleOrder?: Maybe<CreateSaleOrderPayload>;
  /** Creates a single `SaleOrderDetail`. */
  createSaleOrderDetail?: Maybe<CreateSaleOrderDetailPayload>;
  createSignificantEvent?: Maybe<CreateSignificantEventPayload>;
  /** Creates a single `TouristServiceOrder`. */
  createTouristServiceOrder?: Maybe<CreateTouristServiceOrderPayload>;
  /** Creates a single `TouristServiceOrderDetail`. */
  createTouristServiceOrderDetail?: Maybe<CreateTouristServiceOrderDetailPayload>;
  /** Creates a single `User`. */
  createUser?: Maybe<CreateUserPayload>;
  /** Creates a single `ViewBranchOffice`. */
  createViewBranchOffice?: Maybe<CreateViewBranchOfficePayload>;
  /** Creates a single `ViewDelegatedToken`. */
  createViewDelegatedToken?: Maybe<CreateViewDelegatedTokenPayload>;
  /** Creates a single `ViewDigitalSignature`. */
  createViewDigitalSignature?: Maybe<CreateViewDigitalSignaturePayload>;
  /** Creates a single `ViewProduct`. */
  createViewProduct?: Maybe<CreateViewProductPayload>;
  /** Creates a single `ViewSyncParameter`. */
  createViewSyncParameter?: Maybe<CreateViewSyncParameterPayload>;
  /** Creates a single `ViewSyncRequest`. */
  createViewSyncRequest?: Maybe<CreateViewSyncRequestPayload>;
  deleteClient?: Maybe<DeleteClientPayload>;
  /** Deletes a single `ExportServiceOrder` using a unique key. */
  deleteExportServiceOrder?: Maybe<DeleteExportServiceOrderPayload>;
  /** Deletes a single `ExportServiceOrder` using its globally unique id. */
  deleteExportServiceOrderByNodeId?: Maybe<DeleteExportServiceOrderPayload>;
  /** Deletes a single `ExportServiceOrder` using a unique key. */
  deleteExportServiceOrderByOrderKey?: Maybe<DeleteExportServiceOrderPayload>;
  /** Deletes a single `ExportServiceOrderDetail` using a unique key. */
  deleteExportServiceOrderDetail?: Maybe<DeleteExportServiceOrderDetailPayload>;
  /** Deletes a single `ExportServiceOrderDetail` using its globally unique id. */
  deleteExportServiceOrderDetailByNodeId?: Maybe<DeleteExportServiceOrderDetailPayload>;
  /** Deletes a single `InsuranceOrder` using a unique key. */
  deleteInsuranceOrder?: Maybe<DeleteInsuranceOrderPayload>;
  /** Deletes a single `InsuranceOrder` using its globally unique id. */
  deleteInsuranceOrderByNodeId?: Maybe<DeleteInsuranceOrderPayload>;
  /** Deletes a single `InsuranceOrder` using a unique key. */
  deleteInsuranceOrderByOrderKey?: Maybe<DeleteInsuranceOrderPayload>;
  /** Deletes a single `InsuranceOrderDetail` using a unique key. */
  deleteInsuranceOrderDetail?: Maybe<DeleteInsuranceOrderDetailPayload>;
  /** Deletes a single `InsuranceOrderDetail` using its globally unique id. */
  deleteInsuranceOrderDetailByNodeId?: Maybe<DeleteInsuranceOrderDetailPayload>;
  /** Deletes a single `PointOfSaleUser` using a unique key. */
  deletePointOfSaleUser?: Maybe<DeletePointOfSaleUserPayload>;
  /** Deletes a single `PointOfSaleUser` using its globally unique id. */
  deletePointOfSaleUserByNodeId?: Maybe<DeletePointOfSaleUserPayload>;
  /** Deletes a single `RentalOrder` using a unique key. */
  deleteRentalOrder?: Maybe<DeleteRentalOrderPayload>;
  /** Deletes a single `RentalOrder` using its globally unique id. */
  deleteRentalOrderByNodeId?: Maybe<DeleteRentalOrderPayload>;
  /** Deletes a single `RentalOrder` using a unique key. */
  deleteRentalOrderByOrderKey?: Maybe<DeleteRentalOrderPayload>;
  /** Deletes a single `RentalOrderDetail` using a unique key. */
  deleteRentalOrderDetail?: Maybe<DeleteRentalOrderDetailPayload>;
  /** Deletes a single `RentalOrderDetail` using its globally unique id. */
  deleteRentalOrderDetailByNodeId?: Maybe<DeleteRentalOrderDetailPayload>;
  /** Deletes a single `TouristServiceOrder` using a unique key. */
  deleteTouristServiceOrder?: Maybe<DeleteTouristServiceOrderPayload>;
  /** Deletes a single `TouristServiceOrder` using its globally unique id. */
  deleteTouristServiceOrderByNodeId?: Maybe<DeleteTouristServiceOrderPayload>;
  /** Deletes a single `TouristServiceOrder` using a unique key. */
  deleteTouristServiceOrderByOrderKey?: Maybe<DeleteTouristServiceOrderPayload>;
  /** Deletes a single `TouristServiceOrderDetail` using a unique key. */
  deleteTouristServiceOrderDetail?: Maybe<DeleteTouristServiceOrderDetailPayload>;
  /** Deletes a single `TouristServiceOrderDetail` using its globally unique id. */
  deleteTouristServiceOrderDetailByNodeId?: Maybe<DeleteTouristServiceOrderDetailPayload>;
  generateCufd?: Maybe<GenerateCufdPayload>;
  generateCuis?: Maybe<GenerateCuisPayload>;
  generateInvoice?: Maybe<GenerateInvoicePayload>;
  registerUser: RegisterUserPayload;
  requestSync?: Maybe<RequestSyncPayload>;
  sendSignificantEvent?: Maybe<SendSignificantEventPayload>;
  sinGenerateMassiveCufds?: Maybe<SinGenerateMassiveCufdsPayload>;
  syncPointsOfSale?: Maybe<SyncPointsOfSalePayload>;
  /** Updates a single `BranchOffice` using a unique key and a patch. */
  updateBranchOffice?: Maybe<UpdateBranchOfficePayload>;
  /** Updates a single `BranchOffice` using its globally unique id and a patch. */
  updateBranchOfficeByNodeId?: Maybe<UpdateBranchOfficePayload>;
  /** Updates a single `Client` using a unique key and a patch. */
  updateClient?: Maybe<UpdateClientPayload>;
  /** Updates a single `Client` using its globally unique id and a patch. */
  updateClientByNodeId?: Maybe<UpdateClientPayload>;
  /** Updates a single `ClientHomologation` using a unique key and a patch. */
  updateClientHomologation?: Maybe<UpdateClientHomologationPayload>;
  /** Updates a single `ClientHomologation` using a unique key and a patch. */
  updateClientHomologationByClientKey?: Maybe<UpdateClientHomologationPayload>;
  /** Updates a single `ClientHomologation` using its globally unique id and a patch. */
  updateClientHomologationByNodeId?: Maybe<UpdateClientHomologationPayload>;
  /** Updates a single `ExportServiceOrder` using a unique key and a patch. */
  updateExportServiceOrder?: Maybe<UpdateExportServiceOrderPayload>;
  /** Updates a single `ExportServiceOrder` using its globally unique id and a patch. */
  updateExportServiceOrderByNodeId?: Maybe<UpdateExportServiceOrderPayload>;
  /** Updates a single `ExportServiceOrder` using a unique key and a patch. */
  updateExportServiceOrderByOrderKey?: Maybe<UpdateExportServiceOrderPayload>;
  /** Updates a single `ExportServiceOrderDetail` using a unique key and a patch. */
  updateExportServiceOrderDetail?: Maybe<UpdateExportServiceOrderDetailPayload>;
  /** Updates a single `ExportServiceOrderDetail` using its globally unique id and a patch. */
  updateExportServiceOrderDetailByNodeId?: Maybe<UpdateExportServiceOrderDetailPayload>;
  /** Updates a single `InsuranceOrder` using a unique key and a patch. */
  updateInsuranceOrder?: Maybe<UpdateInsuranceOrderPayload>;
  /** Updates a single `InsuranceOrder` using its globally unique id and a patch. */
  updateInsuranceOrderByNodeId?: Maybe<UpdateInsuranceOrderPayload>;
  /** Updates a single `InsuranceOrder` using a unique key and a patch. */
  updateInsuranceOrderByOrderKey?: Maybe<UpdateInsuranceOrderPayload>;
  /** Updates a single `InsuranceOrderDetail` using a unique key and a patch. */
  updateInsuranceOrderDetail?: Maybe<UpdateInsuranceOrderDetailPayload>;
  /** Updates a single `InsuranceOrderDetail` using its globally unique id and a patch. */
  updateInsuranceOrderDetailByNodeId?: Maybe<UpdateInsuranceOrderDetailPayload>;
  /** Updates a single `Order` using a unique key and a patch. */
  updateOrder?: Maybe<UpdateOrderPayload>;
  /** Updates a single `Order` using its globally unique id and a patch. */
  updateOrderByNodeId?: Maybe<UpdateOrderPayload>;
  /** Updates a single `OrderHomologation` using a unique key and a patch. */
  updateOrderHomologation?: Maybe<UpdateOrderHomologationPayload>;
  /** Updates a single `OrderHomologation` using its globally unique id and a patch. */
  updateOrderHomologationByNodeId?: Maybe<UpdateOrderHomologationPayload>;
  /** Updates a single `OrderHomologation` using a unique key and a patch. */
  updateOrderHomologationByOrderKey?: Maybe<UpdateOrderHomologationPayload>;
  /** Updates a single `Person` using a unique key and a patch. */
  updatePerson?: Maybe<UpdatePersonPayload>;
  /** Updates a single `Person` using its globally unique id and a patch. */
  updatePersonByNodeId?: Maybe<UpdatePersonPayload>;
  /** Updates a single `PointOfSaleUser` using a unique key and a patch. */
  updatePointOfSaleUser?: Maybe<UpdatePointOfSaleUserPayload>;
  /** Updates a single `PointOfSaleUser` using its globally unique id and a patch. */
  updatePointOfSaleUserByNodeId?: Maybe<UpdatePointOfSaleUserPayload>;
  /** Updates a single `Product` using a unique key and a patch. */
  updateProduct?: Maybe<UpdateProductPayload>;
  /** Updates a single `Product` using its globally unique id and a patch. */
  updateProductByNodeId?: Maybe<UpdateProductPayload>;
  /** Updates a single `ProductHomologation` using a unique key and a patch. */
  updateProductHomologation?: Maybe<UpdateProductHomologationPayload>;
  /** Updates a single `ProductHomologation` using its globally unique id and a patch. */
  updateProductHomologationByNodeId?: Maybe<UpdateProductHomologationPayload>;
  /** Updates a single `ProductHomologation` using a unique key and a patch. */
  updateProductHomologationByProductKey?: Maybe<UpdateProductHomologationPayload>;
  /** Updates a single `RentalOrder` using a unique key and a patch. */
  updateRentalOrder?: Maybe<UpdateRentalOrderPayload>;
  /** Updates a single `RentalOrder` using its globally unique id and a patch. */
  updateRentalOrderByNodeId?: Maybe<UpdateRentalOrderPayload>;
  /** Updates a single `RentalOrder` using a unique key and a patch. */
  updateRentalOrderByOrderKey?: Maybe<UpdateRentalOrderPayload>;
  /** Updates a single `RentalOrderDetail` using a unique key and a patch. */
  updateRentalOrderDetail?: Maybe<UpdateRentalOrderDetailPayload>;
  /** Updates a single `RentalOrderDetail` using its globally unique id and a patch. */
  updateRentalOrderDetailByNodeId?: Maybe<UpdateRentalOrderDetailPayload>;
  /** Updates a single `SaleOrder` using a unique key and a patch. */
  updateSaleOrder?: Maybe<UpdateSaleOrderPayload>;
  /** Updates a single `SaleOrder` using its globally unique id and a patch. */
  updateSaleOrderByNodeId?: Maybe<UpdateSaleOrderPayload>;
  /** Updates a single `SaleOrder` using a unique key and a patch. */
  updateSaleOrderByOrderKey?: Maybe<UpdateSaleOrderPayload>;
  /** Updates a single `SaleOrderDetail` using a unique key and a patch. */
  updateSaleOrderDetail?: Maybe<UpdateSaleOrderDetailPayload>;
  /** Updates a single `SaleOrderDetail` using its globally unique id and a patch. */
  updateSaleOrderDetailByNodeId?: Maybe<UpdateSaleOrderDetailPayload>;
  /** Updates a single `TouristServiceOrder` using a unique key and a patch. */
  updateTouristServiceOrder?: Maybe<UpdateTouristServiceOrderPayload>;
  /** Updates a single `TouristServiceOrder` using its globally unique id and a patch. */
  updateTouristServiceOrderByNodeId?: Maybe<UpdateTouristServiceOrderPayload>;
  /** Updates a single `TouristServiceOrder` using a unique key and a patch. */
  updateTouristServiceOrderByOrderKey?: Maybe<UpdateTouristServiceOrderPayload>;
  /** Updates a single `TouristServiceOrderDetail` using a unique key and a patch. */
  updateTouristServiceOrderDetail?: Maybe<UpdateTouristServiceOrderDetailPayload>;
  /** Updates a single `TouristServiceOrderDetail` using its globally unique id and a patch. */
  updateTouristServiceOrderDetailByNodeId?: Maybe<UpdateTouristServiceOrderDetailPayload>;
  /** Updates a single `User` using a unique key and a patch. */
  updateUser?: Maybe<UpdateUserPayload>;
  /** Updates a single `User` using its globally unique id and a patch. */
  updateUserByNodeId?: Maybe<UpdateUserPayload>;
  uploadDelegatedToken: UploadDelegatedTokenPayload;
  uploadDigitalSignature: UploadDigitalSignaturePayload;
  uploadRegisterDocuments: UploadRegisterDocumentsPayload;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationActivateAccountArgs = {
  input: ActivateAccountInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationAuthenticateArgs = {
  input: AuthenticateInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationAuthenticateByApiKeyArgs = {
  input: AuthenticateByApiKeyInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCancelInvoiceArgs = {
  input: CancelInvoiceInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateBranchOfficeArgs = {
  input: CreateBranchOfficeInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateClientArgs = {
  input: CreateClientInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateClientHomologationArgs = {
  input: CreateClientHomologationInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateExportServiceOrderArgs = {
  input: CreateExportServiceOrderInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateExportServiceOrderDetailArgs = {
  input: CreateExportServiceOrderDetailInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateInsuranceOrderArgs = {
  input: CreateInsuranceOrderInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateInsuranceOrderDetailArgs = {
  input: CreateInsuranceOrderDetailInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateOrderArgs = {
  input: CreateOrderInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateOrderHomologationArgs = {
  input: CreateOrderHomologationInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreatePersonArgs = {
  input: CreatePersonInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreatePointOfSaleArgs = {
  input: CreatePointOfSaleInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreatePointOfSaleUserArgs = {
  input: CreatePointOfSaleUserInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateProductArgs = {
  input: CreateProductInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateProductHomologationArgs = {
  input: CreateProductHomologationInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateRentalOrderArgs = {
  input: CreateRentalOrderInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateRentalOrderDetailArgs = {
  input: CreateRentalOrderDetailInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateSaleOrderArgs = {
  input: CreateSaleOrderInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateSaleOrderDetailArgs = {
  input: CreateSaleOrderDetailInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateSignificantEventArgs = {
  input: CreateSignificantEventInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateTouristServiceOrderArgs = {
  input: CreateTouristServiceOrderInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateTouristServiceOrderDetailArgs = {
  input: CreateTouristServiceOrderDetailInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateUserArgs = {
  input: CreateUserInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateViewBranchOfficeArgs = {
  input: CreateViewBranchOfficeInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateViewDelegatedTokenArgs = {
  input: CreateViewDelegatedTokenInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateViewDigitalSignatureArgs = {
  input: CreateViewDigitalSignatureInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateViewProductArgs = {
  input: CreateViewProductInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateViewSyncParameterArgs = {
  input: CreateViewSyncParameterInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateViewSyncRequestArgs = {
  input: CreateViewSyncRequestInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteClientArgs = {
  input: DeleteClientInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteExportServiceOrderArgs = {
  input: DeleteExportServiceOrderInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteExportServiceOrderByNodeIdArgs = {
  input: DeleteExportServiceOrderByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteExportServiceOrderByOrderKeyArgs = {
  input: DeleteExportServiceOrderByOrderKeyInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteExportServiceOrderDetailArgs = {
  input: DeleteExportServiceOrderDetailInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteExportServiceOrderDetailByNodeIdArgs = {
  input: DeleteExportServiceOrderDetailByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteInsuranceOrderArgs = {
  input: DeleteInsuranceOrderInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteInsuranceOrderByNodeIdArgs = {
  input: DeleteInsuranceOrderByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteInsuranceOrderByOrderKeyArgs = {
  input: DeleteInsuranceOrderByOrderKeyInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteInsuranceOrderDetailArgs = {
  input: DeleteInsuranceOrderDetailInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteInsuranceOrderDetailByNodeIdArgs = {
  input: DeleteInsuranceOrderDetailByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeletePointOfSaleUserArgs = {
  input: DeletePointOfSaleUserInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeletePointOfSaleUserByNodeIdArgs = {
  input: DeletePointOfSaleUserByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteRentalOrderArgs = {
  input: DeleteRentalOrderInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteRentalOrderByNodeIdArgs = {
  input: DeleteRentalOrderByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteRentalOrderByOrderKeyArgs = {
  input: DeleteRentalOrderByOrderKeyInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteRentalOrderDetailArgs = {
  input: DeleteRentalOrderDetailInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteRentalOrderDetailByNodeIdArgs = {
  input: DeleteRentalOrderDetailByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteTouristServiceOrderArgs = {
  input: DeleteTouristServiceOrderInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteTouristServiceOrderByNodeIdArgs = {
  input: DeleteTouristServiceOrderByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteTouristServiceOrderByOrderKeyArgs = {
  input: DeleteTouristServiceOrderByOrderKeyInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteTouristServiceOrderDetailArgs = {
  input: DeleteTouristServiceOrderDetailInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteTouristServiceOrderDetailByNodeIdArgs = {
  input: DeleteTouristServiceOrderDetailByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationGenerateCufdArgs = {
  input: GenerateCufdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationGenerateCuisArgs = {
  input: GenerateCuisInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationGenerateInvoiceArgs = {
  input: GenerateInvoiceInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationRegisterUserArgs = {
  input: RegisterUserInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationRequestSyncArgs = {
  input: RequestSyncInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationSendSignificantEventArgs = {
  input: SendSignificantEventInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationSinGenerateMassiveCufdsArgs = {
  input: SinGenerateMassiveCufdsInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationSyncPointsOfSaleArgs = {
  input: SyncPointsOfSaleInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateBranchOfficeArgs = {
  input: UpdateBranchOfficeInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateBranchOfficeByNodeIdArgs = {
  input: UpdateBranchOfficeByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateClientArgs = {
  input: UpdateClientInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateClientByNodeIdArgs = {
  input: UpdateClientByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateClientHomologationArgs = {
  input: UpdateClientHomologationInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateClientHomologationByClientKeyArgs = {
  input: UpdateClientHomologationByClientKeyInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateClientHomologationByNodeIdArgs = {
  input: UpdateClientHomologationByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateExportServiceOrderArgs = {
  input: UpdateExportServiceOrderInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateExportServiceOrderByNodeIdArgs = {
  input: UpdateExportServiceOrderByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateExportServiceOrderByOrderKeyArgs = {
  input: UpdateExportServiceOrderByOrderKeyInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateExportServiceOrderDetailArgs = {
  input: UpdateExportServiceOrderDetailInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateExportServiceOrderDetailByNodeIdArgs = {
  input: UpdateExportServiceOrderDetailByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateInsuranceOrderArgs = {
  input: UpdateInsuranceOrderInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateInsuranceOrderByNodeIdArgs = {
  input: UpdateInsuranceOrderByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateInsuranceOrderByOrderKeyArgs = {
  input: UpdateInsuranceOrderByOrderKeyInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateInsuranceOrderDetailArgs = {
  input: UpdateInsuranceOrderDetailInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateInsuranceOrderDetailByNodeIdArgs = {
  input: UpdateInsuranceOrderDetailByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateOrderArgs = {
  input: UpdateOrderInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateOrderByNodeIdArgs = {
  input: UpdateOrderByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateOrderHomologationArgs = {
  input: UpdateOrderHomologationInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateOrderHomologationByNodeIdArgs = {
  input: UpdateOrderHomologationByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateOrderHomologationByOrderKeyArgs = {
  input: UpdateOrderHomologationByOrderKeyInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdatePersonArgs = {
  input: UpdatePersonInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdatePersonByNodeIdArgs = {
  input: UpdatePersonByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdatePointOfSaleUserArgs = {
  input: UpdatePointOfSaleUserInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdatePointOfSaleUserByNodeIdArgs = {
  input: UpdatePointOfSaleUserByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateProductArgs = {
  input: UpdateProductInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateProductByNodeIdArgs = {
  input: UpdateProductByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateProductHomologationArgs = {
  input: UpdateProductHomologationInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateProductHomologationByNodeIdArgs = {
  input: UpdateProductHomologationByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateProductHomologationByProductKeyArgs = {
  input: UpdateProductHomologationByProductKeyInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateRentalOrderArgs = {
  input: UpdateRentalOrderInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateRentalOrderByNodeIdArgs = {
  input: UpdateRentalOrderByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateRentalOrderByOrderKeyArgs = {
  input: UpdateRentalOrderByOrderKeyInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateRentalOrderDetailArgs = {
  input: UpdateRentalOrderDetailInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateRentalOrderDetailByNodeIdArgs = {
  input: UpdateRentalOrderDetailByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateSaleOrderArgs = {
  input: UpdateSaleOrderInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateSaleOrderByNodeIdArgs = {
  input: UpdateSaleOrderByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateSaleOrderByOrderKeyArgs = {
  input: UpdateSaleOrderByOrderKeyInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateSaleOrderDetailArgs = {
  input: UpdateSaleOrderDetailInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateSaleOrderDetailByNodeIdArgs = {
  input: UpdateSaleOrderDetailByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateTouristServiceOrderArgs = {
  input: UpdateTouristServiceOrderInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateTouristServiceOrderByNodeIdArgs = {
  input: UpdateTouristServiceOrderByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateTouristServiceOrderByOrderKeyArgs = {
  input: UpdateTouristServiceOrderByOrderKeyInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateTouristServiceOrderDetailArgs = {
  input: UpdateTouristServiceOrderDetailInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateTouristServiceOrderDetailByNodeIdArgs = {
  input: UpdateTouristServiceOrderDetailByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUserByNodeIdArgs = {
  input: UpdateUserByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUploadDelegatedTokenArgs = {
  input: UploadDelegatedTokenInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUploadDigitalSignatureArgs = {
  input: UploadDigitalSignatureInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUploadRegisterDocumentsArgs = {
  backDocumentPhoto: Scalars['Upload'];
  certificateRequestPhoto: Scalars['Upload'];
  frontDocumentPhoto: Scalars['Upload'];
  frontDocumentSelfie: Scalars['Upload'];
  invoiceAddress: Scalars['String'];
  invoiceMunicipality: Scalars['String'];
  invoicePhoneNumber: Scalars['String'];
  nitDocumentPhoto: Scalars['Upload'];
  token: Scalars['String'];
};

/** An object with a globally unique `ID`. */
export type Node = {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
};

export type Notification = {
  __typename?: 'Notification';
  content?: Maybe<Scalars['String']>;
  event?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
};

/** Orden */
export type Order = Node & {
  __typename?: 'Order';
  /** Importe que se devuelve al cliente en concepto de cambio. Debe calcularse. */
  changeAmount: Scalars['BigFloat'];
  /** Reads a single `Client` that is related to this `Order`. */
  client?: Maybe<Client>;
  clientKey: Scalars['String'];
  createdAt: Scalars['Datetime'];
  discount: Scalars['BigFloat'];
  email?: Maybe<Scalars['String']>;
  /** Reads a single `ExportServiceOrder` that is related to this `Order`. */
  exportServiceOrder?: Maybe<ExportServiceOrder>;
  /**
   * Reads and enables pagination through a set of `ExportServiceOrder`.
   * @deprecated Please use exportServiceOrder instead
   */
  exportServiceOrders: ExportServiceOrdersConnection;
  /** Reads a single `OrderHomologation` that is related to this `Order`. */
  homologation?: Maybe<OrderHomologation>;
  /**
   * Reads and enables pagination through a set of `OrderHomologation`.
   * @deprecated Please use homologation instead
   */
  homologations: OrderHomologationsConnection;
  /** Reads a single `InsuranceOrder` that is related to this `Order`. */
  insuranceOrder?: Maybe<InsuranceOrder>;
  /**
   * Reads and enables pagination through a set of `InsuranceOrder`.
   * @deprecated Please use insuranceOrder instead
   */
  insuranceOrders: InsuranceOrdersConnection;
  invoice?: Maybe<Invoice>;
  /** Identificador */
  key: Scalars['String'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Importe que el cliente pagó. */
  paymentAmount: Scalars['BigFloat'];
  phoneNumber?: Maybe<Scalars['String']>;
  /** Reads a single `PointOfSale` that is related to this `Order`. */
  pointOfSale?: Maybe<PointOfSale>;
  pointOfSaleKey: Scalars['String'];
  quantity: Scalars['BigFloat'];
  /** Reads a single `RentalOrder` that is related to this `Order`. */
  rentalOrder?: Maybe<RentalOrder>;
  /**
   * Reads and enables pagination through a set of `RentalOrder`.
   * @deprecated Please use rentalOrder instead
   */
  rentalOrders: RentalOrdersConnection;
  /** Reads a single `SaleOrder` that is related to this `Order`. */
  saleOrder?: Maybe<SaleOrder>;
  /**
   * Reads and enables pagination through a set of `SaleOrder`.
   * @deprecated Please use saleOrder instead
   */
  saleOrders: SaleOrdersConnection;
  status: OrderStatus;
  subtotal: Scalars['BigFloat'];
  total: Scalars['BigFloat'];
  /** Reads a single `TouristServiceOrder` that is related to this `Order`. */
  touristServiceOrder?: Maybe<TouristServiceOrder>;
  /**
   * Reads and enables pagination through a set of `TouristServiceOrder`.
   * @deprecated Please use touristServiceOrder instead
   */
  touristServiceOrders: TouristServiceOrdersConnection;
  updatedAt?: Maybe<Scalars['Datetime']>;
};


/** Orden */
export type OrderExportServiceOrdersArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ExportServiceOrderCondition>;
  filter?: InputMaybe<ExportServiceOrderFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ExportServiceOrdersOrderBy>>;
};


/** Orden */
export type OrderHomologationsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


/** Orden */
export type OrderInsuranceOrdersArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<InsuranceOrderCondition>;
  filter?: InputMaybe<InsuranceOrderFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InsuranceOrdersOrderBy>>;
};


/** Orden */
export type OrderRentalOrdersArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<RentalOrderCondition>;
  filter?: InputMaybe<RentalOrderFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<RentalOrdersOrderBy>>;
};


/** Orden */
export type OrderSaleOrdersArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


/** Orden */
export type OrderTouristServiceOrdersArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TouristServiceOrderCondition>;
  filter?: InputMaybe<TouristServiceOrderFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TouristServiceOrdersOrderBy>>;
};

/** Homologación de venta para el SIN */
export type OrderHomologation = Node & {
  __typename?: 'OrderHomologation';
  activityCode: Scalars['String'];
  activityDescription: Scalars['String'];
  cardNumber?: Maybe<Scalars['BigInt']>;
  createdAt: Scalars['Datetime'];
  currencyCode: Scalars['Int'];
  /**
   * [SIN] Utilizado para generar CUF
   * 1-4
   * 1=EN LINEA
   * 2=FUERA DE LINEA
   * 3=MASIVO
   * 4=CONTINGENCIA
   */
  emissionTypeCode: Scalars['Int'];
  exchangeRate: Scalars['BigFloat'];
  /**
   * [SIN] Utilizado para generar CUF
   * 1-4
   * 1=FACTURA CON DERECHO A CREDITO FISCAL
   * ...
   */
  invoiceTypeCode: Scalars['Int'];
  /** Identificador */
  key: Scalars['String'];
  /**
   * [SIN] Utilizado para generar CUF
   * 1-2
   * 1=ELECTRONICA
   * 2=COMPUTARIZADA
   */
  modalityTypeCode: Scalars['Int'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads a single `Order` that is related to this `OrderHomologation`. */
  order?: Maybe<Order>;
  orderKey: Scalars['String'];
  paymentMethodCode: Scalars['Int'];
  paymentMethodDescription?: Maybe<Scalars['String']>;
  /**
   * [SIN] codigoDocumentoSector
   * 1=FCV
   * ...
   * 24=NCD
   * ...
   * 35=FAC_CVB
   * ...
   */
  sectorDocumentCode: Scalars['Int'];
  updatedAt?: Maybe<Scalars['Datetime']>;
};

/** An input for mutations affecting `OrderHomologation` */
export type OrderHomologationInput = {
  activityCode: Scalars['String'];
  cardNumber?: InputMaybe<Scalars['BigInt']>;
  currencyCode: Scalars['Int'];
  /**
   * [SIN] Utilizado para generar CUF
   * 1-4
   * 1=EN LINEA
   * 2=FUERA DE LINEA
   * 3=MASIVO
   * 4=CONTINGENCIA
   */
  emissionTypeCode: Scalars['Int'];
  exchangeRate: Scalars['BigFloat'];
  /**
   * [SIN] Utilizado para generar CUF
   * 1-4
   * 1=FACTURA CON DERECHO A CREDITO FISCAL
   * ...
   */
  invoiceTypeCode?: InputMaybe<Scalars['Int']>;
  /**
   * [SIN] Utilizado para generar CUF
   * 1-2
   * 1=ELECTRONICA
   * 2=COMPUTARIZADA
   */
  modalityTypeCode: Scalars['Int'];
  order?: InputMaybe<FkOrderHomologationToOrderInput>;
  orderKey?: InputMaybe<Scalars['String']>;
  paymentMethodCode: Scalars['Int'];
  /**
   * [SIN] codigoDocumentoSector
   * 1=FCV
   * ...
   * 24=NCD
   * ...
   * 35=FAC_CVB
   * ...
   */
  sectorDocumentCode?: InputMaybe<Scalars['Int']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type OrderHomologationNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `orderHomologation` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type OrderHomologationOnOrderHomologationForFkOrderHomologationToOrderNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `order` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `order` being updated. */
  patch: OrderPatch;
};

/** The fields on `orderHomologation` to look up the row to update. */
export type OrderHomologationOnOrderHomologationForFkOrderHomologationToOrderUsingPkOrderHomologationUpdate = {
  /** Identificador */
  key: Scalars['String'];
  /** An object where the defined keys will be set on the `orderHomologation` being updated. */
  patch: UpdateOrderHomologationOnOrderHomologationForFkOrderHomologationToOrderPatch;
};

/** The fields on `orderHomologation` to look up the row to update. */
export type OrderHomologationOnOrderHomologationForFkOrderHomologationToOrderUsingUkOrderHomologationOrderIdUpdate = {
  orderKey: Scalars['String'];
  /** An object where the defined keys will be set on the `orderHomologation` being updated. */
  patch: UpdateOrderHomologationOnOrderHomologationForFkOrderHomologationToOrderPatch;
};

/** Represents an update to a `OrderHomologation`. Fields that are set will be updated. */
export type OrderHomologationPatch = {
  activityCode?: InputMaybe<Scalars['String']>;
  cardNumber?: InputMaybe<Scalars['BigInt']>;
  currencyCode?: InputMaybe<Scalars['Int']>;
  /**
   * [SIN] Utilizado para generar CUF
   * 1-4
   * 1=EN LINEA
   * 2=FUERA DE LINEA
   * 3=MASIVO
   * 4=CONTINGENCIA
   */
  emissionTypeCode?: InputMaybe<Scalars['Int']>;
  exchangeRate?: InputMaybe<Scalars['BigFloat']>;
  /**
   * [SIN] Utilizado para generar CUF
   * 1-4
   * 1=FACTURA CON DERECHO A CREDITO FISCAL
   * ...
   */
  invoiceTypeCode?: InputMaybe<Scalars['Int']>;
  /**
   * [SIN] Utilizado para generar CUF
   * 1-2
   * 1=ELECTRONICA
   * 2=COMPUTARIZADA
   */
  modalityTypeCode?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<FkOrderHomologationToOrderInput>;
  orderKey?: InputMaybe<Scalars['String']>;
  paymentMethodCode?: InputMaybe<Scalars['Int']>;
  /**
   * [SIN] codigoDocumentoSector
   * 1=FCV
   * ...
   * 24=NCD
   * ...
   * 35=FAC_CVB
   * ...
   */
  sectorDocumentCode?: InputMaybe<Scalars['Int']>;
};

/** The fields on `orderHomologation` to look up the row to connect. */
export type OrderHomologationPkOrderHomologationConnect = {
  /** Identificador */
  key: Scalars['String'];
};

/** The fields on `orderHomologation` to look up the row to connect. */
export type OrderHomologationUkOrderHomologationOrderIdConnect = {
  orderKey: Scalars['String'];
};

/** A connection to a list of `OrderHomologation` values. */
export type OrderHomologationsConnection = {
  __typename?: 'OrderHomologationsConnection';
  /** A list of edges which contains the `OrderHomologation` and cursor to aid in pagination. */
  edges: Array<OrderHomologationsEdge>;
  /** A list of `OrderHomologation` objects. */
  nodes: Array<Maybe<OrderHomologation>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `OrderHomologation` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `OrderHomologation` edge in the connection. */
export type OrderHomologationsEdge = {
  __typename?: 'OrderHomologationsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `OrderHomologation` at the end of the edge. */
  node?: Maybe<OrderHomologation>;
};

/** An input for mutations affecting `Order` */
export type OrderInput = {
  client?: InputMaybe<FkOrderToClientInput>;
  clientKey?: InputMaybe<Scalars['String']>;
  discount?: InputMaybe<Scalars['BigFloat']>;
  email?: InputMaybe<Scalars['String']>;
  exportServiceOrders?: InputMaybe<FkExportServiceOrderToOrderInverseInput>;
  homologations?: InputMaybe<FkOrderHomologationToOrderInverseInput>;
  insuranceOrders?: InputMaybe<FkInsuranceOrderToOrderInverseInput>;
  /** Importe que el cliente pagó. */
  paymentAmount?: InputMaybe<Scalars['BigFloat']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  pointOfSale?: InputMaybe<FkOrderToPointOfSaleInput>;
  pointOfSaleKey?: InputMaybe<Scalars['String']>;
  rentalOrders?: InputMaybe<FkRentalOrderToOrderInverseInput>;
  saleOrders?: InputMaybe<FkSaleOrderToOrderInverseInput>;
  status?: InputMaybe<OrderStatus>;
  touristServiceOrders?: InputMaybe<FkTouristServiceOrderToOrderInverseInput>;
};

/** The globally unique `ID` look up for the row to connect. */
export type OrderNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `order` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type OrderOnExportServiceOrderForFkExportServiceOrderToOrderNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `exportServiceOrder` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `exportServiceOrder` being updated. */
  patch: ExportServiceOrderPatch;
};

/** The fields on `order` to look up the row to update. */
export type OrderOnExportServiceOrderForFkExportServiceOrderToOrderUsingPkOrderUpdate = {
  /** Identificador */
  key: Scalars['String'];
  /** An object where the defined keys will be set on the `order` being updated. */
  patch: UpdateOrderOnExportServiceOrderForFkExportServiceOrderToOrderPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type OrderOnInsuranceOrderForFkInsuranceOrderToOrderNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `insuranceOrder` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `insuranceOrder` being updated. */
  patch: InsuranceOrderPatch;
};

/** The fields on `order` to look up the row to update. */
export type OrderOnInsuranceOrderForFkInsuranceOrderToOrderUsingPkOrderUpdate = {
  /** Identificador */
  key: Scalars['String'];
  /** An object where the defined keys will be set on the `order` being updated. */
  patch: UpdateOrderOnInsuranceOrderForFkInsuranceOrderToOrderPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type OrderOnOrderForFkOrderToClientNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `client` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `client` being updated. */
  patch: ClientPatch;
};

/** The fields on `order` to look up the row to update. */
export type OrderOnOrderForFkOrderToClientUsingPkOrderUpdate = {
  /** Identificador */
  key: Scalars['String'];
  /** An object where the defined keys will be set on the `order` being updated. */
  patch: UpdateOrderOnOrderForFkOrderToClientPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type OrderOnOrderForFkOrderToPointOfSaleNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `pointOfSale` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `pointOfSale` being updated. */
  patch: PointOfSalePatch;
};

/** The fields on `order` to look up the row to update. */
export type OrderOnOrderForFkOrderToPointOfSaleUsingPkOrderUpdate = {
  /** Identificador */
  key: Scalars['String'];
  /** An object where the defined keys will be set on the `order` being updated. */
  patch: UpdateOrderOnOrderForFkOrderToPointOfSalePatch;
};

/** The globally unique `ID` look up for the row to update. */
export type OrderOnOrderHomologationForFkOrderHomologationToOrderNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `orderHomologation` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `orderHomologation` being updated. */
  patch: OrderHomologationPatch;
};

/** The fields on `order` to look up the row to update. */
export type OrderOnOrderHomologationForFkOrderHomologationToOrderUsingPkOrderUpdate = {
  /** Identificador */
  key: Scalars['String'];
  /** An object where the defined keys will be set on the `order` being updated. */
  patch: UpdateOrderOnOrderHomologationForFkOrderHomologationToOrderPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type OrderOnRentalOrderForFkRentalOrderToOrderNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `rentalOrder` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `rentalOrder` being updated. */
  patch: RentalOrderPatch;
};

/** The fields on `order` to look up the row to update. */
export type OrderOnRentalOrderForFkRentalOrderToOrderUsingPkOrderUpdate = {
  /** Identificador */
  key: Scalars['String'];
  /** An object where the defined keys will be set on the `order` being updated. */
  patch: UpdateOrderOnRentalOrderForFkRentalOrderToOrderPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type OrderOnSaleOrderForFkSaleOrderToOrderNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `saleOrder` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `saleOrder` being updated. */
  patch: SaleOrderPatch;
};

/** The fields on `order` to look up the row to update. */
export type OrderOnSaleOrderForFkSaleOrderToOrderUsingPkOrderUpdate = {
  /** Identificador */
  key: Scalars['String'];
  /** An object where the defined keys will be set on the `order` being updated. */
  patch: UpdateOrderOnSaleOrderForFkSaleOrderToOrderPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type OrderOnTouristServiceOrderForFkTouristServiceOrderToOrderNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `touristServiceOrder` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `touristServiceOrder` being updated. */
  patch: TouristServiceOrderPatch;
};

/** The fields on `order` to look up the row to update. */
export type OrderOnTouristServiceOrderForFkTouristServiceOrderToOrderUsingPkOrderUpdate = {
  /** Identificador */
  key: Scalars['String'];
  /** An object where the defined keys will be set on the `order` being updated. */
  patch: UpdateOrderOnTouristServiceOrderForFkTouristServiceOrderToOrderPatch;
};

/** Represents an update to a `Order`. Fields that are set will be updated. */
export type OrderPatch = {
  client?: InputMaybe<FkOrderToClientInput>;
  clientKey?: InputMaybe<Scalars['String']>;
  discount?: InputMaybe<Scalars['BigFloat']>;
  email?: InputMaybe<Scalars['String']>;
  exportServiceOrders?: InputMaybe<FkExportServiceOrderToOrderInverseInput>;
  homologations?: InputMaybe<FkOrderHomologationToOrderInverseInput>;
  insuranceOrders?: InputMaybe<FkInsuranceOrderToOrderInverseInput>;
  /** Importe que el cliente pagó. */
  paymentAmount?: InputMaybe<Scalars['BigFloat']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  pointOfSale?: InputMaybe<FkOrderToPointOfSaleInput>;
  pointOfSaleKey?: InputMaybe<Scalars['String']>;
  rentalOrders?: InputMaybe<FkRentalOrderToOrderInverseInput>;
  saleOrders?: InputMaybe<FkSaleOrderToOrderInverseInput>;
  status?: InputMaybe<OrderStatus>;
  touristServiceOrders?: InputMaybe<FkTouristServiceOrderToOrderInverseInput>;
};

/** The fields on `order` to look up the row to connect. */
export type OrderPkOrderConnect = {
  /** Identificador */
  key: Scalars['String'];
};

export enum OrderStatus {
  Cancelled = 'CANCELLED',
  Generated = 'GENERATED',
  Invoiced = 'INVOICED',
  Registered = 'REGISTERED',
  Rejected = 'REJECTED'
}

/** A connection to a list of `Order` values. */
export type OrdersConnection = {
  __typename?: 'OrdersConnection';
  /** A list of edges which contains the `Order` and cursor to aid in pagination. */
  edges: Array<OrdersEdge>;
  /** A list of `Order` objects. */
  nodes: Array<Maybe<Order>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Order` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Order` edge in the connection. */
export type OrdersEdge = {
  __typename?: 'OrdersEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Order` at the end of the edge. */
  node?: Maybe<Order>;
};

export type Package = {
  __typename?: 'Package';
  branchOfficeCode?: Maybe<Scalars['Int']>;
  branchOfficeName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  emissionTypeCode?: Maybe<Scalars['Int']>;
  invoiceTypeCode?: Maybe<Scalars['Int']>;
  key?: Maybe<Scalars['String']>;
  modalityTypeCode?: Maybe<Scalars['Int']>;
  pointOfSaleCode?: Maybe<Scalars['Int']>;
  pointOfSaleName?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  receptionCode?: Maybe<Scalars['String']>;
  sectorDocumentCode?: Maybe<Scalars['Int']>;
  sectorDocumentDescription?: Maybe<Scalars['String']>;
  sendingStatus?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  validationSendingStatus?: Maybe<Scalars['String']>;
  validationStatus?: Maybe<Scalars['String']>;
};

export enum PackageStatus {
  Accepted = 'ACCEPTED',
  Failed = 'FAILED',
  Finished = 'FINISHED',
  Registered = 'REGISTERED',
  Rejected = 'REJECTED'
}

/** A filter to be used against PackageStatus fields. All fields are combined with a logical ‘and.’ */
export type PackageStatusFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<PackageStatus>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<PackageStatus>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<PackageStatus>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<PackageStatus>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<PackageStatus>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<PackageStatus>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<PackageStatus>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<PackageStatus>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<PackageStatus>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<PackageStatus>>;
};

export enum PackageValidationStatus {
  Failed = 'FAILED',
  Observed = 'OBSERVED',
  Pending = 'PENDING',
  Registered = 'REGISTERED',
  Validated = 'VALIDATED'
}

/** A filter to be used against PackageValidationStatus fields. All fields are combined with a logical ‘and.’ */
export type PackageValidationStatusFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<PackageValidationStatus>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<PackageValidationStatus>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<PackageValidationStatus>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<PackageValidationStatus>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<PackageValidationStatus>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<PackageValidationStatus>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<PackageValidationStatus>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<PackageValidationStatus>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<PackageValidationStatus>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<PackageValidationStatus>>;
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['Cursor']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['Cursor']>;
};

export type Parameter = {
  __typename?: 'Parameter';
  classifier?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  value?: Maybe<Scalars['String']>;
};

export type PaymentMethodInfo = {
  __typename?: 'PaymentMethodInfo';
  showCardNumber?: Maybe<Scalars['Boolean']>;
  showGiftCard?: Maybe<Scalars['Boolean']>;
};

/** A `Person` edge in the connection. */
export type PeopleEdge = {
  __typename?: 'PeopleEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node?: Maybe<Person>;
};

export type Permission = {
  __typename?: 'Permission';
  code: Scalars['String'];
  /** Fecha de creación. */
  createdAt: Scalars['Datetime'];
  createdBy: Scalars['Int'];
  /** Fecha de eliminación. */
  deletedAt?: Maybe<Scalars['Datetime']>;
  deletedBy?: Maybe<Scalars['Int']>;
  deletion: Scalars['Boolean'];
  description: Scalars['String'];
  enabled: Scalars['Boolean'];
  name: Scalars['String'];
  /** Fecha de modificación */
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['Int']>;
};

/** A filter to be used against `Permission` object types. All fields are combined with a logical ‘and.’ */
export type PermissionFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<PermissionFilter>>;
  /** Filter by the object’s `code` field. */
  code?: InputMaybe<StringFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy?: InputMaybe<IntFilter>;
  /** Filter by the object’s `deletedAt` field. */
  deletedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `deletedBy` field. */
  deletedBy?: InputMaybe<IntFilter>;
  /** Filter by the object’s `deletion` field. */
  deletion?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `description` field. */
  description?: InputMaybe<StringFilter>;
  /** Filter by the object’s `enabled` field. */
  enabled?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<PermissionFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<PermissionFilter>>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `updatedBy` field. */
  updatedBy?: InputMaybe<IntFilter>;
};

/** A connection to a list of `Permission` values. */
export type PermissionsConnection = {
  __typename?: 'PermissionsConnection';
  /** A list of edges which contains the `Permission` and cursor to aid in pagination. */
  edges: Array<PermissionsEdge>;
  /** A list of `Permission` objects. */
  nodes: Array<Maybe<Permission>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Permission` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Permission` edge in the connection. */
export type PermissionsEdge = {
  __typename?: 'PermissionsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Permission` at the end of the edge. */
  node?: Maybe<Permission>;
};

/** Persona */
export type Person = Node & {
  __typename?: 'Person';
  createdAt: Scalars['Datetime'];
  firstName: Scalars['String'];
  firstSurname: Scalars['String'];
  fullName?: Maybe<Scalars['String']>;
  key: Scalars['String'];
  middleName?: Maybe<Scalars['String']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  secondSurname?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Reads and enables pagination through a set of `User`. */
  users: UsersConnection;
};


/** Persona */
export type PersonUsersArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

/** An input for mutations affecting `Person` */
export type PersonInput = {
  firstName: Scalars['String'];
  firstSurname: Scalars['String'];
  middleName?: InputMaybe<Scalars['String']>;
  secondSurname?: InputMaybe<Scalars['String']>;
  users?: InputMaybe<FkAppUserToPersonInverseInput>;
};

/** The globally unique `ID` look up for the row to connect. */
export type PersonNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `person` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type PersonOnUserForFkAppUserToPersonNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `user` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `user` being updated. */
  patch: UserPatch;
};

/** The fields on `person` to look up the row to update. */
export type PersonOnUserForFkAppUserToPersonUsingPkPersonUpdate = {
  key: Scalars['String'];
  /** An object where the defined keys will be set on the `person` being updated. */
  patch: UpdatePersonOnUserForFkAppUserToPersonPatch;
};

/** Represents an update to a `Person`. Fields that are set will be updated. */
export type PersonPatch = {
  firstName?: InputMaybe<Scalars['String']>;
  firstSurname?: InputMaybe<Scalars['String']>;
  middleName?: InputMaybe<Scalars['String']>;
  secondSurname?: InputMaybe<Scalars['String']>;
  users?: InputMaybe<FkAppUserToPersonInverseInput>;
};

/** The fields on `person` to look up the row to connect. */
export type PersonPkPersonConnect = {
  key: Scalars['String'];
};

/** Punto de venta */
export type PointOfSale = Node & {
  __typename?: 'PointOfSale';
  /** Reads a single `BranchOffice` that is related to this `PointOfSale`. */
  branchOffice?: Maybe<BranchOffice>;
  branchOfficeKey: Scalars['String'];
  code: Scalars['Int'];
  commissionAgentNit?: Maybe<Scalars['BigInt']>;
  contractNumber?: Maybe<Scalars['String']>;
  createdAt: Scalars['Datetime'];
  cufd?: Maybe<Cufd>;
  cuis?: Maybe<Cuis>;
  description?: Maybe<Scalars['String']>;
  finishDate?: Maybe<Scalars['Datetime']>;
  /** Identificador */
  key: Scalars['String'];
  modalityCode: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads and enables pagination through a set of `Order`. */
  orders: OrdersConnection;
  /** Reads and enables pagination through a set of `PointOfSaleUser`. */
  pointOfSaleUsers: PointOfSaleUsersConnection;
  startDate?: Maybe<Scalars['Datetime']>;
  status: PointOfSaleStatus;
  typeCode: Scalars['Int'];
  typeName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
};


/** Punto de venta */
export type PointOfSaleOrdersArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


/** Punto de venta */
export type PointOfSalePointOfSaleUsersArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type PointOfSaleNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `pointOfSale` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type PointOfSaleOnOrderForFkOrderToPointOfSaleNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `order` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `order` being updated. */
  patch: OrderPatch;
};

/** The fields on `pointOfSale` to look up the row to update. */
export type PointOfSaleOnOrderForFkOrderToPointOfSaleUsingPkPointOfSaleUpdate = {
  /** Identificador */
  key: Scalars['String'];
  /** An object where the defined keys will be set on the `pointOfSale` being updated. */
  patch: UpdatePointOfSaleOnOrderForFkOrderToPointOfSalePatch;
};

/** The globally unique `ID` look up for the row to update. */
export type PointOfSaleOnPointOfSaleForFkPointOfSaleToBranchOfficeNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `branchOffice` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `branchOffice` being updated. */
  patch: BranchOfficePatch;
};

/** The fields on `pointOfSale` to look up the row to update. */
export type PointOfSaleOnPointOfSaleForFkPointOfSaleToBranchOfficeUsingPkPointOfSaleUpdate = {
  /** Identificador */
  key: Scalars['String'];
  /** An object where the defined keys will be set on the `pointOfSale` being updated. */
  patch: UpdatePointOfSaleOnPointOfSaleForFkPointOfSaleToBranchOfficePatch;
};

/** The globally unique `ID` look up for the row to update. */
export type PointOfSaleOnPointOfSaleUserForFkPointOfSaleUserToPointOfSaleNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `pointOfSaleUser` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `pointOfSaleUser` being updated. */
  patch: PointOfSaleUserPatch;
};

/** The fields on `pointOfSale` to look up the row to update. */
export type PointOfSaleOnPointOfSaleUserForFkPointOfSaleUserToPointOfSaleUsingPkPointOfSaleUpdate = {
  /** Identificador */
  key: Scalars['String'];
  /** An object where the defined keys will be set on the `pointOfSale` being updated. */
  patch: UpdatePointOfSaleOnPointOfSaleUserForFkPointOfSaleUserToPointOfSalePatch;
};

/** Represents an update to a `PointOfSale`. Fields that are set will be updated. */
export type PointOfSalePatch = {
  branchOffice?: InputMaybe<FkPointOfSaleToBranchOfficeInput>;
  branchOfficeKey?: InputMaybe<Scalars['String']>;
  code?: InputMaybe<Scalars['Int']>;
  commissionAgentNit?: InputMaybe<Scalars['BigInt']>;
  contractNumber?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  finishDate?: InputMaybe<Scalars['Datetime']>;
  modalityCode?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  orders?: InputMaybe<FkOrderToPointOfSaleInverseInput>;
  pointOfSaleUsers?: InputMaybe<FkPointOfSaleUserToPointOfSaleInverseInput>;
  startDate?: InputMaybe<Scalars['Datetime']>;
  status?: InputMaybe<PointOfSaleStatus>;
  typeCode?: InputMaybe<Scalars['Int']>;
};

/** The fields on `pointOfSale` to look up the row to connect. */
export type PointOfSalePkPointOfSaleConnect = {
  /** Identificador */
  key: Scalars['String'];
};

export enum PointOfSaleStatus {
  Active = 'ACTIVE',
  Closed = 'CLOSED'
}

/** A filter to be used against PointOfSaleStatus fields. All fields are combined with a logical ‘and.’ */
export type PointOfSaleStatusFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<PointOfSaleStatus>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<PointOfSaleStatus>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<PointOfSaleStatus>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<PointOfSaleStatus>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<PointOfSaleStatus>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<PointOfSaleStatus>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<PointOfSaleStatus>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<PointOfSaleStatus>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<PointOfSaleStatus>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<PointOfSaleStatus>>;
};

/** Asignación de Usuario a Punto de Venta */
export type PointOfSaleUser = Node & {
  __typename?: 'PointOfSaleUser';
  createdAt: Scalars['Datetime'];
  enabled: Scalars['Boolean'];
  key: Scalars['String'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads a single `PointOfSale` that is related to this `PointOfSaleUser`. */
  pointOfSale?: Maybe<PointOfSale>;
  pointOfSaleKey: Scalars['String'];
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Reads a single `User` that is related to this `PointOfSaleUser`. */
  user?: Maybe<User>;
  userKey: Scalars['String'];
};

/** An input for mutations affecting `PointOfSaleUser` */
export type PointOfSaleUserInput = {
  enabled?: InputMaybe<Scalars['Boolean']>;
  pointOfSale?: InputMaybe<FkPointOfSaleUserToPointOfSaleInput>;
  pointOfSaleKey?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<FkPointOfSaleUserToUserInput>;
  userKey?: InputMaybe<Scalars['String']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type PointOfSaleUserNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `pointOfSaleUser` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type PointOfSaleUserNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `pointOfSaleUser` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type PointOfSaleUserOnPointOfSaleUserForFkPointOfSaleUserToPointOfSaleNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `pointOfSale` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `pointOfSale` being updated. */
  patch: PointOfSalePatch;
};

/** The fields on `pointOfSaleUser` to look up the row to update. */
export type PointOfSaleUserOnPointOfSaleUserForFkPointOfSaleUserToPointOfSaleUsingPkPointOfSaleUserUpdate = {
  key: Scalars['String'];
  /** An object where the defined keys will be set on the `pointOfSaleUser` being updated. */
  patch: UpdatePointOfSaleUserOnPointOfSaleUserForFkPointOfSaleUserToPointOfSalePatch;
};

/** The globally unique `ID` look up for the row to update. */
export type PointOfSaleUserOnPointOfSaleUserForFkPointOfSaleUserToUserNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `user` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `user` being updated. */
  patch: UserPatch;
};

/** The fields on `pointOfSaleUser` to look up the row to update. */
export type PointOfSaleUserOnPointOfSaleUserForFkPointOfSaleUserToUserUsingPkPointOfSaleUserUpdate = {
  key: Scalars['String'];
  /** An object where the defined keys will be set on the `pointOfSaleUser` being updated. */
  patch: UpdatePointOfSaleUserOnPointOfSaleUserForFkPointOfSaleUserToUserPatch;
};

/** Represents an update to a `PointOfSaleUser`. Fields that are set will be updated. */
export type PointOfSaleUserPatch = {
  enabled?: InputMaybe<Scalars['Boolean']>;
  pointOfSale?: InputMaybe<FkPointOfSaleUserToPointOfSaleInput>;
  pointOfSaleKey?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<FkPointOfSaleUserToUserInput>;
  userKey?: InputMaybe<Scalars['String']>;
};

/** The fields on `pointOfSaleUser` to look up the row to connect. */
export type PointOfSaleUserPkPointOfSaleUserConnect = {
  key: Scalars['String'];
};

/** The fields on `pointOfSaleUser` to look up the row to delete. */
export type PointOfSaleUserPkPointOfSaleUserDelete = {
  key: Scalars['String'];
};

/** A connection to a list of `PointOfSaleUser` values. */
export type PointOfSaleUsersConnection = {
  __typename?: 'PointOfSaleUsersConnection';
  /** A list of edges which contains the `PointOfSaleUser` and cursor to aid in pagination. */
  edges: Array<PointOfSaleUsersEdge>;
  /** A list of `PointOfSaleUser` objects. */
  nodes: Array<Maybe<PointOfSaleUser>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `PointOfSaleUser` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `PointOfSaleUser` edge in the connection. */
export type PointOfSaleUsersEdge = {
  __typename?: 'PointOfSaleUsersEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `PointOfSaleUser` at the end of the edge. */
  node?: Maybe<PointOfSaleUser>;
};

/** A connection to a list of `PointOfSale` values. */
export type PointOfSalesConnection = {
  __typename?: 'PointOfSalesConnection';
  /** A list of edges which contains the `PointOfSale` and cursor to aid in pagination. */
  edges: Array<PointOfSalesEdge>;
  /** A list of `PointOfSale` objects. */
  nodes: Array<Maybe<PointOfSale>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `PointOfSale` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `PointOfSale` edge in the connection. */
export type PointOfSalesEdge = {
  __typename?: 'PointOfSalesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `PointOfSale` at the end of the edge. */
  node?: Maybe<PointOfSale>;
};

/** Producto/Servicio */
export type Product = Node & {
  __typename?: 'Product';
  code?: Maybe<Scalars['String']>;
  createdAt: Scalars['Datetime'];
  description: Scalars['String'];
  /** Reads and enables pagination through a set of `ExportServiceOrderDetail`. */
  exportServiceOrderDetails: ExportServiceOrderDetailsConnection;
  /** Reads a single `ProductHomologation` that is related to this `Product`. */
  homologation?: Maybe<ProductHomologation>;
  /**
   * Reads and enables pagination through a set of `ProductHomologation`.
   * @deprecated Please use homologation instead
   */
  homologations: ProductHomologationsConnection;
  /** Reads and enables pagination through a set of `InsuranceOrderDetail`. */
  insuranceOrderDetails: InsuranceOrderDetailsConnection;
  /** Identificador */
  key: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  price: Scalars['BigFloat'];
  /** Reads and enables pagination through a set of `RentalOrderDetail`. */
  rentalOrderDetails: RentalOrderDetailsConnection;
  /** Reads and enables pagination through a set of `SaleOrderDetail`. */
  saleOrderDetails: SaleOrderDetailsConnection;
  /** Reads and enables pagination through a set of `TouristServiceOrderDetail`. */
  touristServiceOrderDetails: TouristServiceOrderDetailsConnection;
  type: ProductType;
  updatedAt?: Maybe<Scalars['Datetime']>;
};


/** Producto/Servicio */
export type ProductExportServiceOrderDetailsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ExportServiceOrderDetailCondition>;
  filter?: InputMaybe<ExportServiceOrderDetailFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ExportServiceOrderDetailsOrderBy>>;
};


/** Producto/Servicio */
export type ProductHomologationsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


/** Producto/Servicio */
export type ProductInsuranceOrderDetailsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<InsuranceOrderDetailCondition>;
  filter?: InputMaybe<InsuranceOrderDetailFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InsuranceOrderDetailsOrderBy>>;
};


/** Producto/Servicio */
export type ProductRentalOrderDetailsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<RentalOrderDetailCondition>;
  filter?: InputMaybe<RentalOrderDetailFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<RentalOrderDetailsOrderBy>>;
};


/** Producto/Servicio */
export type ProductSaleOrderDetailsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


/** Producto/Servicio */
export type ProductTouristServiceOrderDetailsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TouristServiceOrderDetailCondition>;
  filter?: InputMaybe<TouristServiceOrderDetailFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TouristServiceOrderDetailsOrderBy>>;
};

/** Homologación Producto/Servicio */
export type ProductHomologation = Node & {
  __typename?: 'ProductHomologation';
  /** [SIN] Codigo de actividad */
  activityCode: Scalars['String'];
  /** [SIN] Descripción de actividad */
  activityDescription: Scalars['String'];
  createdAt: Scalars['Datetime'];
  /** Identificador */
  key: Scalars['String'];
  /** [SIN] Codigo de unidad de medida */
  measureUnitCode: Scalars['Int'];
  /** [SIN] Descripción de unidad de medida */
  measureUnitDescription: Scalars['String'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** [SIN] Codigo de producto */
  productCode: Scalars['Int'];
  /** [SIN] Descripción de producto */
  productDescription: Scalars['String'];
  productKey: Scalars['String'];
  /** Reads a single `Product` that is related to this `ProductHomologation`. */
  productService?: Maybe<Product>;
  updatedAt?: Maybe<Scalars['Datetime']>;
};

/** An input for mutations affecting `ProductHomologation` */
export type ProductHomologationInput = {
  /** [SIN] Codigo de actividad */
  activityCode: Scalars['String'];
  /** [SIN] Codigo de unidad de medida */
  measureUnitCode: Scalars['Int'];
  /** [SIN] Codigo de producto */
  productCode: Scalars['Int'];
  productKey?: InputMaybe<Scalars['String']>;
  productService?: InputMaybe<FkProductHomologationToProductInput>;
};

/** The globally unique `ID` look up for the row to connect. */
export type ProductHomologationNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `productHomologation` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type ProductHomologationOnProductHomologationForFkProductHomologationToProductNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `product` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `product` being updated. */
  patch: ProductPatch;
};

/** The fields on `productHomologation` to look up the row to update. */
export type ProductHomologationOnProductHomologationForFkProductHomologationToProductUsingPkProductHomologationUpdate = {
  /** Identificador */
  key: Scalars['String'];
  /** An object where the defined keys will be set on the `productHomologation` being updated. */
  patch: UpdateProductHomologationOnProductHomologationForFkProductHomologationToProductPatch;
};

/** The fields on `productHomologation` to look up the row to update. */
export type ProductHomologationOnProductHomologationForFkProductHomologationToProductUsingUkProductHomologationProductKeyUpdate = {
  /** An object where the defined keys will be set on the `productHomologation` being updated. */
  patch: UpdateProductHomologationOnProductHomologationForFkProductHomologationToProductPatch;
  productKey: Scalars['String'];
};

/** Represents an update to a `ProductHomologation`. Fields that are set will be updated. */
export type ProductHomologationPatch = {
  /** [SIN] Codigo de actividad */
  activityCode?: InputMaybe<Scalars['String']>;
  /** [SIN] Codigo de unidad de medida */
  measureUnitCode?: InputMaybe<Scalars['Int']>;
  /** [SIN] Codigo de producto */
  productCode?: InputMaybe<Scalars['Int']>;
  productKey?: InputMaybe<Scalars['String']>;
  productService?: InputMaybe<FkProductHomologationToProductInput>;
};

/** The fields on `productHomologation` to look up the row to connect. */
export type ProductHomologationPkProductHomologationConnect = {
  /** Identificador */
  key: Scalars['String'];
};

/** The fields on `productHomologation` to look up the row to connect. */
export type ProductHomologationUkProductHomologationProductKeyConnect = {
  productKey: Scalars['String'];
};

/** A connection to a list of `ProductHomologation` values. */
export type ProductHomologationsConnection = {
  __typename?: 'ProductHomologationsConnection';
  /** A list of edges which contains the `ProductHomologation` and cursor to aid in pagination. */
  edges: Array<ProductHomologationsEdge>;
  /** A list of `ProductHomologation` objects. */
  nodes: Array<Maybe<ProductHomologation>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ProductHomologation` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ProductHomologation` edge in the connection. */
export type ProductHomologationsEdge = {
  __typename?: 'ProductHomologationsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `ProductHomologation` at the end of the edge. */
  node?: Maybe<ProductHomologation>;
};

/** An input for mutations affecting `Product` */
export type ProductInput = {
  code?: InputMaybe<Scalars['String']>;
  description: Scalars['String'];
  exportServiceOrderDetails?: InputMaybe<FkExportServiceOrderDetailToProductInverseInput>;
  homologations?: InputMaybe<FkProductHomologationToProductInverseInput>;
  insuranceOrderDetails?: InputMaybe<FkInsuranceOrderDetailToProductInverseInput>;
  name?: InputMaybe<Scalars['String']>;
  price: Scalars['BigFloat'];
  rentalOrderDetails?: InputMaybe<FkRentalOrderDetailToProductInverseInput>;
  saleOrderDetails?: InputMaybe<FkSaleOrderDetailToProductInverseInput>;
  touristServiceOrderDetails?: InputMaybe<FkTouristServiceOrderDetailToProductInverseInput>;
  type: ProductType;
};

/** The globally unique `ID` look up for the row to connect. */
export type ProductNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `product` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type ProductOnExportServiceOrderDetailForFkExportServiceOrderDetailToProductNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `exportServiceOrderDetail` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `exportServiceOrderDetail` being updated. */
  patch: ExportServiceOrderDetailPatch;
};

/** The fields on `product` to look up the row to update. */
export type ProductOnExportServiceOrderDetailForFkExportServiceOrderDetailToProductUsingPkProductUpdate = {
  /** Identificador */
  key: Scalars['String'];
  /** An object where the defined keys will be set on the `product` being updated. */
  patch: UpdateProductOnExportServiceOrderDetailForFkExportServiceOrderDetailToProductPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type ProductOnInsuranceOrderDetailForFkInsuranceOrderDetailToProductNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `insuranceOrderDetail` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `insuranceOrderDetail` being updated. */
  patch: InsuranceOrderDetailPatch;
};

/** The fields on `product` to look up the row to update. */
export type ProductOnInsuranceOrderDetailForFkInsuranceOrderDetailToProductUsingPkProductUpdate = {
  /** Identificador */
  key: Scalars['String'];
  /** An object where the defined keys will be set on the `product` being updated. */
  patch: UpdateProductOnInsuranceOrderDetailForFkInsuranceOrderDetailToProductPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type ProductOnProductHomologationForFkProductHomologationToProductNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `productHomologation` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `productHomologation` being updated. */
  patch: ProductHomologationPatch;
};

/** The fields on `product` to look up the row to update. */
export type ProductOnProductHomologationForFkProductHomologationToProductUsingPkProductUpdate = {
  /** Identificador */
  key: Scalars['String'];
  /** An object where the defined keys will be set on the `product` being updated. */
  patch: UpdateProductOnProductHomologationForFkProductHomologationToProductPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type ProductOnRentalOrderDetailForFkRentalOrderDetailToProductNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `rentalOrderDetail` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `rentalOrderDetail` being updated. */
  patch: RentalOrderDetailPatch;
};

/** The fields on `product` to look up the row to update. */
export type ProductOnRentalOrderDetailForFkRentalOrderDetailToProductUsingPkProductUpdate = {
  /** Identificador */
  key: Scalars['String'];
  /** An object where the defined keys will be set on the `product` being updated. */
  patch: UpdateProductOnRentalOrderDetailForFkRentalOrderDetailToProductPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type ProductOnSaleOrderDetailForFkSaleOrderDetailToProductNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `saleOrderDetail` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `saleOrderDetail` being updated. */
  patch: SaleOrderDetailPatch;
};

/** The fields on `product` to look up the row to update. */
export type ProductOnSaleOrderDetailForFkSaleOrderDetailToProductUsingPkProductUpdate = {
  /** Identificador */
  key: Scalars['String'];
  /** An object where the defined keys will be set on the `product` being updated. */
  patch: UpdateProductOnSaleOrderDetailForFkSaleOrderDetailToProductPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type ProductOnTouristServiceOrderDetailForFkTouristServiceOrderDetailToProductNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `touristServiceOrderDetail` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `touristServiceOrderDetail` being updated. */
  patch: TouristServiceOrderDetailPatch;
};

/** The fields on `product` to look up the row to update. */
export type ProductOnTouristServiceOrderDetailForFkTouristServiceOrderDetailToProductUsingPkProductUpdate = {
  /** Identificador */
  key: Scalars['String'];
  /** An object where the defined keys will be set on the `product` being updated. */
  patch: UpdateProductOnTouristServiceOrderDetailForFkTouristServiceOrderDetailToProductPatch;
};

/** Represents an update to a `Product`. Fields that are set will be updated. */
export type ProductPatch = {
  code?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  exportServiceOrderDetails?: InputMaybe<FkExportServiceOrderDetailToProductInverseInput>;
  homologations?: InputMaybe<FkProductHomologationToProductInverseInput>;
  insuranceOrderDetails?: InputMaybe<FkInsuranceOrderDetailToProductInverseInput>;
  name?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['BigFloat']>;
  rentalOrderDetails?: InputMaybe<FkRentalOrderDetailToProductInverseInput>;
  saleOrderDetails?: InputMaybe<FkSaleOrderDetailToProductInverseInput>;
  touristServiceOrderDetails?: InputMaybe<FkTouristServiceOrderDetailToProductInverseInput>;
  type?: InputMaybe<ProductType>;
};

/** The fields on `product` to look up the row to connect. */
export type ProductPkProductConnect = {
  /** Identificador */
  key: Scalars['String'];
};

export enum ProductType {
  Product = 'PRODUCT',
  Service = 'SERVICE'
}

/** A `Product` edge in the connection. */
export type ProductsEdge = {
  __typename?: 'ProductsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Product` at the end of the edge. */
  node?: Maybe<Product>;
};

/** The root query type which gives access points into the data universe. */
export type Query = Node & {
  __typename?: 'Query';
  accountByTenantKey?: Maybe<Account>;
  branchOffice?: Maybe<BranchOffice>;
  /** Reads a single `BranchOffice` using its globally unique `ID`. */
  branchOfficeByNodeId?: Maybe<BranchOffice>;
  client?: Maybe<Client>;
  /** Reads a single `Client` using its globally unique `ID`. */
  clientByNodeId?: Maybe<Client>;
  clientHomologation?: Maybe<ClientHomologation>;
  clientHomologationByClientKey?: Maybe<ClientHomologation>;
  /** Reads a single `ClientHomologation` using its globally unique `ID`. */
  clientHomologationByNodeId?: Maybe<ClientHomologation>;
  /** Obtiene el Usuario correspondiente al token JWT. */
  currentUser?: Maybe<User>;
  digitalSignature?: Maybe<DigitalSignature>;
  /** Reads a single `DigitalSignature` using its globally unique `ID`. */
  digitalSignatureByNodeId?: Maybe<DigitalSignature>;
  downloadInvoice: File;
  downloadOrders: File;
  downloadProducts: File;
  downloadRegisterFile: File;
  exportServiceOrder?: Maybe<ExportServiceOrder>;
  /** Reads a single `ExportServiceOrder` using its globally unique `ID`. */
  exportServiceOrderByNodeId?: Maybe<ExportServiceOrder>;
  exportServiceOrderByOrderKey?: Maybe<ExportServiceOrder>;
  exportServiceOrderDetail?: Maybe<ExportServiceOrderDetail>;
  /** Reads a single `ExportServiceOrderDetail` using its globally unique `ID`. */
  exportServiceOrderDetailByNodeId?: Maybe<ExportServiceOrderDetail>;
  /** Reads and enables pagination through a set of `ExportServiceOrderDetail`. */
  exportServiceOrderDetails?: Maybe<ExportServiceOrderDetailsConnection>;
  /** Reads and enables pagination through a set of `ExportServiceOrder`. */
  exportServiceOrders?: Maybe<ExportServiceOrdersConnection>;
  getParameter?: Maybe<Parameter>;
  /** Reads and enables pagination through a set of `File`. */
  getRegisterFilesByTenantKey?: Maybe<FilesConnection>;
  insuranceOrder?: Maybe<InsuranceOrder>;
  /** Reads a single `InsuranceOrder` using its globally unique `ID`. */
  insuranceOrderByNodeId?: Maybe<InsuranceOrder>;
  insuranceOrderByOrderKey?: Maybe<InsuranceOrder>;
  insuranceOrderDetail?: Maybe<InsuranceOrderDetail>;
  /** Reads a single `InsuranceOrderDetail` using its globally unique `ID`. */
  insuranceOrderDetailByNodeId?: Maybe<InsuranceOrderDetail>;
  /** Reads and enables pagination through a set of `InsuranceOrderDetail`. */
  insuranceOrderDetails?: Maybe<InsuranceOrderDetailsConnection>;
  /** Reads and enables pagination through a set of `InsuranceOrder`. */
  insuranceOrders?: Maybe<InsuranceOrdersConnection>;
  invoiceByKey?: Maybe<Invoice>;
  invoiceCancellationByKey?: Maybe<InvoiceCancellation>;
  /** Fetches an object given its globally unique `ID`. */
  node?: Maybe<Node>;
  /** The root query type must be a `Node` to work well with Relay 1 mutations. This just resolves to `query`. */
  nodeId: Scalars['ID'];
  order?: Maybe<Order>;
  /** Reads a single `Order` using its globally unique `ID`. */
  orderByNodeId?: Maybe<Order>;
  orderHomologation?: Maybe<OrderHomologation>;
  /** Reads a single `OrderHomologation` using its globally unique `ID`. */
  orderHomologationByNodeId?: Maybe<OrderHomologation>;
  orderHomologationByOrderKey?: Maybe<OrderHomologation>;
  packageByKey?: Maybe<Package>;
  /** Reads and enables pagination through a set of `Invoice`. */
  packageInvoices?: Maybe<InvoicesConnection>;
  paymentMethodInfoByCode?: Maybe<PaymentMethodInfo>;
  person?: Maybe<Person>;
  /** Reads a single `Person` using its globally unique `ID`. */
  personByNodeId?: Maybe<Person>;
  pointOfSale?: Maybe<PointOfSale>;
  /** Reads a single `PointOfSale` using its globally unique `ID`. */
  pointOfSaleByNodeId?: Maybe<PointOfSale>;
  pointOfSaleUser?: Maybe<PointOfSaleUser>;
  /** Reads a single `PointOfSaleUser` using its globally unique `ID`. */
  pointOfSaleUserByNodeId?: Maybe<PointOfSaleUser>;
  product?: Maybe<Product>;
  /** Reads a single `Product` using its globally unique `ID`. */
  productByNodeId?: Maybe<Product>;
  productHomologation?: Maybe<ProductHomologation>;
  /** Reads a single `ProductHomologation` using its globally unique `ID`. */
  productHomologationByNodeId?: Maybe<ProductHomologation>;
  productHomologationByProductKey?: Maybe<ProductHomologation>;
  /**
   * Exposes the root query type nested one level down. This is helpful for Relay 1
   * which can only query top level fields if they are in a particular form.
   */
  query: Query;
  rentalOrder?: Maybe<RentalOrder>;
  /** Reads a single `RentalOrder` using its globally unique `ID`. */
  rentalOrderByNodeId?: Maybe<RentalOrder>;
  rentalOrderByOrderKey?: Maybe<RentalOrder>;
  rentalOrderDetail?: Maybe<RentalOrderDetail>;
  /** Reads a single `RentalOrderDetail` using its globally unique `ID`. */
  rentalOrderDetailByNodeId?: Maybe<RentalOrderDetail>;
  /** Reads and enables pagination through a set of `RentalOrderDetail`. */
  rentalOrderDetails?: Maybe<RentalOrderDetailsConnection>;
  /** Reads and enables pagination through a set of `RentalOrder`. */
  rentalOrders?: Maybe<RentalOrdersConnection>;
  saleOrder?: Maybe<SaleOrder>;
  /** Reads a single `SaleOrder` using its globally unique `ID`. */
  saleOrderByNodeId?: Maybe<SaleOrder>;
  saleOrderByOrderKey?: Maybe<SaleOrder>;
  saleOrderDetail?: Maybe<SaleOrderDetail>;
  /** Reads a single `SaleOrderDetail` using its globally unique `ID`. */
  saleOrderDetailByNodeId?: Maybe<SaleOrderDetail>;
  /** Reads and enables pagination through a set of `SectorDocumentInvoiceType`. */
  sectorDocumentInvoiceTypes?: Maybe<SectorDocumentInvoiceTypesConnection>;
  /** Reads and enables pagination through a set of `SectorDocumentInvoiceType`. */
  sectorDocumentInvoiceTypesByActivityCode?: Maybe<SectorDocumentInvoiceTypesConnection>;
  /** Reads and enables pagination through a set of `SyncParameter`. */
  sectorDocumentsByActivityCode?: Maybe<SyncParametersConnection>;
  significantEventByKey?: Maybe<SignificantEvent>;
  sinVerifyNit: Scalars['Boolean'];
  sinVerifyNitOnRegister: SinVerifyNitPayload;
  /** Reads and enables pagination through a set of `SyncActivity`. */
  syncActivities?: Maybe<SyncActivitiesConnection>;
  /** Reads and enables pagination through a set of `SyncActivitySectorDocument`. */
  syncActivitySectorDocuments?: Maybe<SyncActivitySectorDocumentsConnection>;
  /** Reads and enables pagination through a set of `SyncDatetime`. */
  syncDatetimes?: Maybe<SyncDatetimesConnection>;
  /** Reads and enables pagination through a set of `SyncInvoiceLegend`. */
  syncInvoiceLegends?: Maybe<SyncInvoiceLegendsConnection>;
  /** Reads and enables pagination through a set of `SyncRequest`. */
  syncRequestsSummary?: Maybe<SyncRequestsConnection>;
  touristServiceOrder?: Maybe<TouristServiceOrder>;
  /** Reads a single `TouristServiceOrder` using its globally unique `ID`. */
  touristServiceOrderByNodeId?: Maybe<TouristServiceOrder>;
  touristServiceOrderByOrderKey?: Maybe<TouristServiceOrder>;
  touristServiceOrderDetail?: Maybe<TouristServiceOrderDetail>;
  /** Reads a single `TouristServiceOrderDetail` using its globally unique `ID`. */
  touristServiceOrderDetailByNodeId?: Maybe<TouristServiceOrderDetail>;
  /** Reads and enables pagination through a set of `TouristServiceOrderDetail`. */
  touristServiceOrderDetails?: Maybe<TouristServiceOrderDetailsConnection>;
  /** Reads and enables pagination through a set of `TouristServiceOrder`. */
  touristServiceOrders?: Maybe<TouristServiceOrdersConnection>;
  user?: Maybe<User>;
  /** Reads a single `User` using its globally unique `ID`. */
  userByNodeId?: Maybe<User>;
  /** Reads and enables pagination through a set of `ViewAccount`. */
  viewAccounts?: Maybe<ViewAccountsConnection>;
  /** Reads and enables pagination through a set of `ViewBranchOffice`. */
  viewBranchOffices?: Maybe<ViewBranchOfficesConnection>;
  /** Reads and enables pagination through a set of `ViewClient`. */
  viewClients?: Maybe<ViewClientsConnection>;
  /** Reads and enables pagination through a set of `ViewCufdCode`. */
  viewCufdCodes?: Maybe<ViewCufdCodesConnection>;
  /** Reads and enables pagination through a set of `ViewCuisCode`. */
  viewCuisCodes?: Maybe<ViewCuisCodesConnection>;
  /** Reads and enables pagination through a set of `ViewDelegatedToken`. */
  viewDelegatedTokens?: Maybe<ViewDelegatedTokensConnection>;
  /** Reads and enables pagination through a set of `ViewDigitalSignature`. */
  viewDigitalSignatures?: Maybe<ViewDigitalSignaturesConnection>;
  /** Reads and enables pagination through a set of `ViewInvoice`. */
  viewInvoices?: Maybe<ViewInvoicesConnection>;
  /** Reads and enables pagination through a set of `ViewOrder`. */
  viewOrders?: Maybe<ViewOrdersConnection>;
  /** Reads and enables pagination through a set of `ViewPackage`. */
  viewPackages?: Maybe<ViewPackagesConnection>;
  /** Reads and enables pagination through a set of `ViewPaymentMethod`. */
  viewPaymentMethods?: Maybe<ViewPaymentMethodsConnection>;
  /** Reads and enables pagination through a set of `ViewPointOfSale`. */
  viewPointOfSales?: Maybe<ViewPointOfSalesConnection>;
  /** Reads and enables pagination through a set of `ViewProduct`. */
  viewProducts?: Maybe<ViewProductsConnection>;
  /** Reads and enables pagination through a set of `ViewSignificantEvent`. */
  viewSignificantEvents?: Maybe<ViewSignificantEventsConnection>;
  /** Reads and enables pagination through a set of `ViewSyncParameter`. */
  viewSyncParameters?: Maybe<ViewSyncParametersConnection>;
  /** Reads and enables pagination through a set of `ViewSyncProduct`. */
  viewSyncProducts?: Maybe<ViewSyncProductsConnection>;
  /** Reads and enables pagination through a set of `ViewSyncRequest`. */
  viewSyncRequests?: Maybe<ViewSyncRequestsConnection>;
  /** Reads and enables pagination through a set of `ViewUser`. */
  viewUsers?: Maybe<ViewUsersConnection>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAccountByTenantKeyArgs = {
  key?: InputMaybe<Scalars['String']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryBranchOfficeArgs = {
  key: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryBranchOfficeByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryClientArgs = {
  key: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryClientByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryClientHomologationArgs = {
  key: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryClientHomologationByClientKeyArgs = {
  clientKey: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryClientHomologationByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryDigitalSignatureArgs = {
  key: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryDigitalSignatureByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryDownloadInvoiceArgs = {
  key: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryDownloadRegisterFileArgs = {
  uuid: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryExportServiceOrderArgs = {
  key: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryExportServiceOrderByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryExportServiceOrderByOrderKeyArgs = {
  orderKey: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryExportServiceOrderDetailArgs = {
  key: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryExportServiceOrderDetailByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryExportServiceOrderDetailsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ExportServiceOrderDetailCondition>;
  filter?: InputMaybe<ExportServiceOrderDetailFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ExportServiceOrderDetailsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryExportServiceOrdersArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ExportServiceOrderCondition>;
  filter?: InputMaybe<ExportServiceOrderFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ExportServiceOrdersOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGetParameterArgs = {
  classifier: Scalars['String'];
  code: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryGetRegisterFilesByTenantKeyArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  filter?: InputMaybe<FileFilter>;
  first?: InputMaybe<Scalars['Int']>;
  key?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryInsuranceOrderArgs = {
  key: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryInsuranceOrderByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryInsuranceOrderByOrderKeyArgs = {
  orderKey: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryInsuranceOrderDetailArgs = {
  key: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryInsuranceOrderDetailByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryInsuranceOrderDetailsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<InsuranceOrderDetailCondition>;
  filter?: InputMaybe<InsuranceOrderDetailFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InsuranceOrderDetailsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryInsuranceOrdersArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<InsuranceOrderCondition>;
  filter?: InputMaybe<InsuranceOrderFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InsuranceOrdersOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryInvoiceByKeyArgs = {
  key?: InputMaybe<Scalars['String']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryInvoiceCancellationByKeyArgs = {
  key?: InputMaybe<Scalars['String']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryNodeArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryOrderArgs = {
  key: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryOrderByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryOrderHomologationArgs = {
  key: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryOrderHomologationByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryOrderHomologationByOrderKeyArgs = {
  orderKey: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryPackageByKeyArgs = {
  key?: InputMaybe<Scalars['String']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryPackageInvoicesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  filter?: InputMaybe<InvoiceFilter>;
  first?: InputMaybe<Scalars['Int']>;
  key?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryPaymentMethodInfoByCodeArgs = {
  code: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryPersonArgs = {
  key: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryPersonByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryPointOfSaleArgs = {
  key: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryPointOfSaleByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryPointOfSaleUserArgs = {
  key: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryPointOfSaleUserByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryProductArgs = {
  key: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryProductByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryProductHomologationArgs = {
  key: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryProductHomologationByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryProductHomologationByProductKeyArgs = {
  productKey: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryRentalOrderArgs = {
  key: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryRentalOrderByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryRentalOrderByOrderKeyArgs = {
  orderKey: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryRentalOrderDetailArgs = {
  key: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryRentalOrderDetailByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryRentalOrderDetailsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<RentalOrderDetailCondition>;
  filter?: InputMaybe<RentalOrderDetailFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<RentalOrderDetailsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryRentalOrdersArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<RentalOrderCondition>;
  filter?: InputMaybe<RentalOrderFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<RentalOrdersOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QuerySaleOrderArgs = {
  key: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySaleOrderByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySaleOrderByOrderKeyArgs = {
  orderKey: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySaleOrderDetailArgs = {
  key: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySaleOrderDetailByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySectorDocumentInvoiceTypesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  filter?: InputMaybe<SectorDocumentInvoiceTypeFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


/** The root query type which gives access points into the data universe. */
export type QuerySectorDocumentInvoiceTypesByActivityCodeArgs = {
  activityCode: Scalars['String'];
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  filter?: InputMaybe<SectorDocumentInvoiceTypeFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


/** The root query type which gives access points into the data universe. */
export type QuerySectorDocumentsByActivityCodeArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  code: Scalars['String'];
  filter?: InputMaybe<SyncParameterFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


/** The root query type which gives access points into the data universe. */
export type QuerySignificantEventByKeyArgs = {
  key?: InputMaybe<Scalars['String']>;
};


/** The root query type which gives access points into the data universe. */
export type QuerySinVerifyNitArgs = {
  nit: Scalars['BigFloat'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySinVerifyNitOnRegisterArgs = {
  nit: Scalars['BigFloat'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySyncActivitiesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  filter?: InputMaybe<SyncActivityFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


/** The root query type which gives access points into the data universe. */
export type QuerySyncActivitySectorDocumentsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  filter?: InputMaybe<SyncActivitySectorDocumentFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


/** The root query type which gives access points into the data universe. */
export type QuerySyncDatetimesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  filter?: InputMaybe<SyncDatetimeFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


/** The root query type which gives access points into the data universe. */
export type QuerySyncInvoiceLegendsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  filter?: InputMaybe<SyncInvoiceLegendFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


/** The root query type which gives access points into the data universe. */
export type QuerySyncRequestsSummaryArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  filter?: InputMaybe<SyncRequestFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryTouristServiceOrderArgs = {
  key: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryTouristServiceOrderByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryTouristServiceOrderByOrderKeyArgs = {
  orderKey: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryTouristServiceOrderDetailArgs = {
  key: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryTouristServiceOrderDetailByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryTouristServiceOrderDetailsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TouristServiceOrderDetailCondition>;
  filter?: InputMaybe<TouristServiceOrderDetailFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TouristServiceOrderDetailsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryTouristServiceOrdersArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TouristServiceOrderCondition>;
  filter?: InputMaybe<TouristServiceOrderFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TouristServiceOrdersOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryUserArgs = {
  key: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryUserByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryViewAccountsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ViewAccountCondition>;
  filter?: InputMaybe<ViewAccountFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ViewAccountsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryViewBranchOfficesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ViewBranchOfficeCondition>;
  filter?: InputMaybe<ViewBranchOfficeFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ViewBranchOfficesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryViewClientsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ViewClientCondition>;
  filter?: InputMaybe<ViewClientFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ViewClientsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryViewCufdCodesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ViewCufdCodeCondition>;
  filter?: InputMaybe<ViewCufdCodeFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ViewCufdCodesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryViewCuisCodesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ViewCuisCodeCondition>;
  filter?: InputMaybe<ViewCuisCodeFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ViewCuisCodesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryViewDelegatedTokensArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ViewDelegatedTokenCondition>;
  filter?: InputMaybe<ViewDelegatedTokenFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ViewDelegatedTokensOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryViewDigitalSignaturesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ViewDigitalSignatureCondition>;
  filter?: InputMaybe<ViewDigitalSignatureFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ViewDigitalSignaturesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryViewInvoicesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ViewInvoiceCondition>;
  filter?: InputMaybe<ViewInvoiceFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ViewInvoicesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryViewOrdersArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ViewOrderCondition>;
  filter?: InputMaybe<ViewOrderFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ViewOrdersOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryViewPackagesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ViewPackageCondition>;
  filter?: InputMaybe<ViewPackageFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ViewPackagesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryViewPaymentMethodsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ViewPaymentMethodCondition>;
  filter?: InputMaybe<ViewPaymentMethodFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ViewPaymentMethodsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryViewPointOfSalesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ViewPointOfSaleCondition>;
  filter?: InputMaybe<ViewPointOfSaleFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ViewPointOfSalesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryViewProductsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ViewProductCondition>;
  filter?: InputMaybe<ViewProductFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ViewProductsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryViewSignificantEventsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ViewSignificantEventCondition>;
  filter?: InputMaybe<ViewSignificantEventFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ViewSignificantEventsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryViewSyncParametersArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ViewSyncParameterCondition>;
  filter?: InputMaybe<ViewSyncParameterFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ViewSyncParametersOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryViewSyncProductsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ViewSyncProductCondition>;
  filter?: InputMaybe<ViewSyncProductFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ViewSyncProductsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryViewSyncRequestsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ViewSyncRequestCondition>;
  filter?: InputMaybe<ViewSyncRequestFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ViewSyncRequestsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryViewUsersArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ViewUserCondition>;
  filter?: InputMaybe<ViewUserFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ViewUsersOrderBy>>;
};

export type RegisterUserInput = {
  email: Scalars['String'];
  password: Scalars['String'];
  personDocumentNumber: Scalars['String'];
  personFirstName: Scalars['String'];
  personFirstSurname: Scalars['String'];
  personMiddleName: Scalars['String'];
  personPhoneNumber: Scalars['String'];
  personSecondSurname: Scalars['String'];
  tenantNit: Scalars['BigInt'];
  tenantRazonSocial: Scalars['String'];
  tenantSiatUsername: Scalars['String'];
};

export type RegisterUserPayload = {
  __typename?: 'RegisterUserPayload';
  query?: Maybe<Query>;
  registration?: Maybe<Registration>;
};

export type Registration = {
  __typename?: 'Registration';
  email?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  nit?: Maybe<Scalars['String']>;
  personDocumentNumber?: Maybe<Scalars['String']>;
  personFullName?: Maybe<Scalars['String']>;
  personJobTitle?: Maybe<Scalars['String']>;
  personPhoneNumber?: Maybe<Scalars['String']>;
  razonSocial?: Maybe<Scalars['String']>;
  siatUsername?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
};

/** Orden de renta. */
export type RentalOrder = Node & {
  __typename?: 'RentalOrder';
  createdAt: Scalars['Datetime'];
  createdBy: Scalars['Int'];
  deletedAt?: Maybe<Scalars['Datetime']>;
  deletedBy?: Maybe<Scalars['Int']>;
  deletion: Scalars['Boolean'];
  key: Scalars['String'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads a single `Order` that is related to this `RentalOrder`. */
  order?: Maybe<Order>;
  orderKey: Scalars['String'];
  period?: Maybe<Scalars['String']>;
  /** Reads and enables pagination through a set of `RentalOrderDetail`. */
  rentalOrderDetails: RentalOrderDetailsConnection;
  tenantId: Scalars['Int'];
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['Int']>;
};


/** Orden de renta. */
export type RentalOrderRentalOrderDetailsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<RentalOrderDetailCondition>;
  filter?: InputMaybe<RentalOrderDetailFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<RentalOrderDetailsOrderBy>>;
};

/**
 * A condition to be used against `RentalOrder` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type RentalOrderCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `deletedAt` field. */
  deletedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `deletedBy` field. */
  deletedBy?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `deletion` field. */
  deletion?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `key` field. */
  key?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `orderKey` field. */
  orderKey?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `period` field. */
  period?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `tenantId` field. */
  tenantId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedBy` field. */
  updatedBy?: InputMaybe<Scalars['Int']>;
};

/** Detalle de venta. */
export type RentalOrderDetail = Node & {
  __typename?: 'RentalOrderDetail';
  createdAt: Scalars['Datetime'];
  createdBy: Scalars['Int'];
  deletedAt?: Maybe<Scalars['Datetime']>;
  deletedBy?: Maybe<Scalars['Int']>;
  deletion: Scalars['Boolean'];
  discount: Scalars['BigFloat'];
  key: Scalars['String'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  price: Scalars['BigFloat'];
  productKey: Scalars['String'];
  /** Reads a single `Product` that is related to this `RentalOrderDetail`. */
  productService?: Maybe<Product>;
  quantity: Scalars['BigFloat'];
  /** Reads a single `RentalOrder` that is related to this `RentalOrderDetail`. */
  rentalOrder?: Maybe<RentalOrder>;
  rentalOrderKey: Scalars['String'];
  subtotal: Scalars['BigFloat'];
  tenantId: Scalars['Int'];
  total: Scalars['BigFloat'];
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['Int']>;
};

/**
 * A condition to be used against `RentalOrderDetail` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type RentalOrderDetailCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `deletedAt` field. */
  deletedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `deletedBy` field. */
  deletedBy?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `deletion` field. */
  deletion?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `discount` field. */
  discount?: InputMaybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `key` field. */
  key?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `price` field. */
  price?: InputMaybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `productKey` field. */
  productKey?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `quantity` field. */
  quantity?: InputMaybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `rentalOrderKey` field. */
  rentalOrderKey?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `subtotal` field. */
  subtotal?: InputMaybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `tenantId` field. */
  tenantId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `total` field. */
  total?: InputMaybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedBy` field. */
  updatedBy?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against `RentalOrderDetail` object types. All fields are combined with a logical ‘and.’ */
export type RentalOrderDetailFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<RentalOrderDetailFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy?: InputMaybe<IntFilter>;
  /** Filter by the object’s `deletedAt` field. */
  deletedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `deletedBy` field. */
  deletedBy?: InputMaybe<IntFilter>;
  /** Filter by the object’s `deletion` field. */
  deletion?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `discount` field. */
  discount?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `key` field. */
  key?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<RentalOrderDetailFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<RentalOrderDetailFilter>>;
  /** Filter by the object’s `price` field. */
  price?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `productKey` field. */
  productKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `quantity` field. */
  quantity?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `rentalOrderKey` field. */
  rentalOrderKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `subtotal` field. */
  subtotal?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `tenantId` field. */
  tenantId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `total` field. */
  total?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `updatedBy` field. */
  updatedBy?: InputMaybe<IntFilter>;
};

/** An input for mutations affecting `RentalOrderDetail` */
export type RentalOrderDetailInput = {
  discount: Scalars['BigFloat'];
  price: Scalars['BigFloat'];
  productKey?: InputMaybe<Scalars['String']>;
  productService?: InputMaybe<FkRentalOrderDetailToProductInput>;
  quantity: Scalars['BigFloat'];
  rentalOrder?: InputMaybe<FkRentalOrderDetailToRentalOrderInput>;
  rentalOrderKey?: InputMaybe<Scalars['String']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type RentalOrderDetailNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `rentalOrderDetail` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type RentalOrderDetailNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `rentalOrderDetail` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type RentalOrderDetailOnRentalOrderDetailForFkRentalOrderDetailToProductNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `product` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `product` being updated. */
  patch: ProductPatch;
};

/** The fields on `rentalOrderDetail` to look up the row to update. */
export type RentalOrderDetailOnRentalOrderDetailForFkRentalOrderDetailToProductUsingPkRentalOrderDetailUpdate = {
  key: Scalars['String'];
  /** An object where the defined keys will be set on the `rentalOrderDetail` being updated. */
  patch: UpdateRentalOrderDetailOnRentalOrderDetailForFkRentalOrderDetailToProductPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type RentalOrderDetailOnRentalOrderDetailForFkRentalOrderDetailToRentalOrderNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `rentalOrder` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `rentalOrder` being updated. */
  patch: RentalOrderPatch;
};

/** The fields on `rentalOrderDetail` to look up the row to update. */
export type RentalOrderDetailOnRentalOrderDetailForFkRentalOrderDetailToRentalOrderUsingPkRentalOrderDetailUpdate = {
  key: Scalars['String'];
  /** An object where the defined keys will be set on the `rentalOrderDetail` being updated. */
  patch: UpdateRentalOrderDetailOnRentalOrderDetailForFkRentalOrderDetailToRentalOrderPatch;
};

/** Represents an update to a `RentalOrderDetail`. Fields that are set will be updated. */
export type RentalOrderDetailPatch = {
  discount?: InputMaybe<Scalars['BigFloat']>;
  price?: InputMaybe<Scalars['BigFloat']>;
  productKey?: InputMaybe<Scalars['String']>;
  productService?: InputMaybe<FkRentalOrderDetailToProductInput>;
  quantity?: InputMaybe<Scalars['BigFloat']>;
  rentalOrder?: InputMaybe<FkRentalOrderDetailToRentalOrderInput>;
  rentalOrderKey?: InputMaybe<Scalars['String']>;
};

/** The fields on `rentalOrderDetail` to look up the row to connect. */
export type RentalOrderDetailPkRentalOrderDetailConnect = {
  key: Scalars['String'];
};

/** The fields on `rentalOrderDetail` to look up the row to delete. */
export type RentalOrderDetailPkRentalOrderDetailDelete = {
  key: Scalars['String'];
};

/** A connection to a list of `RentalOrderDetail` values. */
export type RentalOrderDetailsConnection = {
  __typename?: 'RentalOrderDetailsConnection';
  /** A list of edges which contains the `RentalOrderDetail` and cursor to aid in pagination. */
  edges: Array<RentalOrderDetailsEdge>;
  /** A list of `RentalOrderDetail` objects. */
  nodes: Array<Maybe<RentalOrderDetail>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `RentalOrderDetail` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `RentalOrderDetail` edge in the connection. */
export type RentalOrderDetailsEdge = {
  __typename?: 'RentalOrderDetailsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `RentalOrderDetail` at the end of the edge. */
  node?: Maybe<RentalOrderDetail>;
};

/** Methods to use when ordering `RentalOrderDetail`. */
export enum RentalOrderDetailsOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  DeletedAtAsc = 'DELETED_AT_ASC',
  DeletedAtDesc = 'DELETED_AT_DESC',
  DeletedByAsc = 'DELETED_BY_ASC',
  DeletedByDesc = 'DELETED_BY_DESC',
  DeletionAsc = 'DELETION_ASC',
  DeletionDesc = 'DELETION_DESC',
  DiscountAsc = 'DISCOUNT_ASC',
  DiscountDesc = 'DISCOUNT_DESC',
  KeyAsc = 'KEY_ASC',
  KeyDesc = 'KEY_DESC',
  Natural = 'NATURAL',
  PriceAsc = 'PRICE_ASC',
  PriceDesc = 'PRICE_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ProductByProductKeyCodeAsc = 'PRODUCT_BY_PRODUCT_KEY__CODE_ASC',
  ProductByProductKeyCodeDesc = 'PRODUCT_BY_PRODUCT_KEY__CODE_DESC',
  ProductByProductKeyCreatedAtAsc = 'PRODUCT_BY_PRODUCT_KEY__CREATED_AT_ASC',
  ProductByProductKeyCreatedAtDesc = 'PRODUCT_BY_PRODUCT_KEY__CREATED_AT_DESC',
  ProductByProductKeyCreatedByAsc = 'PRODUCT_BY_PRODUCT_KEY__CREATED_BY_ASC',
  ProductByProductKeyCreatedByDesc = 'PRODUCT_BY_PRODUCT_KEY__CREATED_BY_DESC',
  ProductByProductKeyDeletedAtAsc = 'PRODUCT_BY_PRODUCT_KEY__DELETED_AT_ASC',
  ProductByProductKeyDeletedAtDesc = 'PRODUCT_BY_PRODUCT_KEY__DELETED_AT_DESC',
  ProductByProductKeyDeletedByAsc = 'PRODUCT_BY_PRODUCT_KEY__DELETED_BY_ASC',
  ProductByProductKeyDeletedByDesc = 'PRODUCT_BY_PRODUCT_KEY__DELETED_BY_DESC',
  ProductByProductKeyDeletionAsc = 'PRODUCT_BY_PRODUCT_KEY__DELETION_ASC',
  ProductByProductKeyDeletionDesc = 'PRODUCT_BY_PRODUCT_KEY__DELETION_DESC',
  ProductByProductKeyDescriptionAsc = 'PRODUCT_BY_PRODUCT_KEY__DESCRIPTION_ASC',
  ProductByProductKeyDescriptionDesc = 'PRODUCT_BY_PRODUCT_KEY__DESCRIPTION_DESC',
  ProductByProductKeyKeyAsc = 'PRODUCT_BY_PRODUCT_KEY__KEY_ASC',
  ProductByProductKeyKeyDesc = 'PRODUCT_BY_PRODUCT_KEY__KEY_DESC',
  ProductByProductKeyNameAsc = 'PRODUCT_BY_PRODUCT_KEY__NAME_ASC',
  ProductByProductKeyNameDesc = 'PRODUCT_BY_PRODUCT_KEY__NAME_DESC',
  ProductByProductKeyPriceAsc = 'PRODUCT_BY_PRODUCT_KEY__PRICE_ASC',
  ProductByProductKeyPriceDesc = 'PRODUCT_BY_PRODUCT_KEY__PRICE_DESC',
  ProductByProductKeyTenantIdAsc = 'PRODUCT_BY_PRODUCT_KEY__TENANT_ID_ASC',
  ProductByProductKeyTenantIdDesc = 'PRODUCT_BY_PRODUCT_KEY__TENANT_ID_DESC',
  ProductByProductKeyTypeAsc = 'PRODUCT_BY_PRODUCT_KEY__TYPE_ASC',
  ProductByProductKeyTypeDesc = 'PRODUCT_BY_PRODUCT_KEY__TYPE_DESC',
  ProductByProductKeyUpdatedAtAsc = 'PRODUCT_BY_PRODUCT_KEY__UPDATED_AT_ASC',
  ProductByProductKeyUpdatedAtDesc = 'PRODUCT_BY_PRODUCT_KEY__UPDATED_AT_DESC',
  ProductByProductKeyUpdatedByAsc = 'PRODUCT_BY_PRODUCT_KEY__UPDATED_BY_ASC',
  ProductByProductKeyUpdatedByDesc = 'PRODUCT_BY_PRODUCT_KEY__UPDATED_BY_DESC',
  ProductKeyAsc = 'PRODUCT_KEY_ASC',
  ProductKeyDesc = 'PRODUCT_KEY_DESC',
  QuantityAsc = 'QUANTITY_ASC',
  QuantityDesc = 'QUANTITY_DESC',
  RentalOrderByRentalOrderKeyCreatedAtAsc = 'RENTAL_ORDER_BY_RENTAL_ORDER_KEY__CREATED_AT_ASC',
  RentalOrderByRentalOrderKeyCreatedAtDesc = 'RENTAL_ORDER_BY_RENTAL_ORDER_KEY__CREATED_AT_DESC',
  RentalOrderByRentalOrderKeyCreatedByAsc = 'RENTAL_ORDER_BY_RENTAL_ORDER_KEY__CREATED_BY_ASC',
  RentalOrderByRentalOrderKeyCreatedByDesc = 'RENTAL_ORDER_BY_RENTAL_ORDER_KEY__CREATED_BY_DESC',
  RentalOrderByRentalOrderKeyDeletedAtAsc = 'RENTAL_ORDER_BY_RENTAL_ORDER_KEY__DELETED_AT_ASC',
  RentalOrderByRentalOrderKeyDeletedAtDesc = 'RENTAL_ORDER_BY_RENTAL_ORDER_KEY__DELETED_AT_DESC',
  RentalOrderByRentalOrderKeyDeletedByAsc = 'RENTAL_ORDER_BY_RENTAL_ORDER_KEY__DELETED_BY_ASC',
  RentalOrderByRentalOrderKeyDeletedByDesc = 'RENTAL_ORDER_BY_RENTAL_ORDER_KEY__DELETED_BY_DESC',
  RentalOrderByRentalOrderKeyDeletionAsc = 'RENTAL_ORDER_BY_RENTAL_ORDER_KEY__DELETION_ASC',
  RentalOrderByRentalOrderKeyDeletionDesc = 'RENTAL_ORDER_BY_RENTAL_ORDER_KEY__DELETION_DESC',
  RentalOrderByRentalOrderKeyKeyAsc = 'RENTAL_ORDER_BY_RENTAL_ORDER_KEY__KEY_ASC',
  RentalOrderByRentalOrderKeyKeyDesc = 'RENTAL_ORDER_BY_RENTAL_ORDER_KEY__KEY_DESC',
  RentalOrderByRentalOrderKeyOrderKeyAsc = 'RENTAL_ORDER_BY_RENTAL_ORDER_KEY__ORDER_KEY_ASC',
  RentalOrderByRentalOrderKeyOrderKeyDesc = 'RENTAL_ORDER_BY_RENTAL_ORDER_KEY__ORDER_KEY_DESC',
  RentalOrderByRentalOrderKeyPeriodAsc = 'RENTAL_ORDER_BY_RENTAL_ORDER_KEY__PERIOD_ASC',
  RentalOrderByRentalOrderKeyPeriodDesc = 'RENTAL_ORDER_BY_RENTAL_ORDER_KEY__PERIOD_DESC',
  RentalOrderByRentalOrderKeyTenantIdAsc = 'RENTAL_ORDER_BY_RENTAL_ORDER_KEY__TENANT_ID_ASC',
  RentalOrderByRentalOrderKeyTenantIdDesc = 'RENTAL_ORDER_BY_RENTAL_ORDER_KEY__TENANT_ID_DESC',
  RentalOrderByRentalOrderKeyUpdatedAtAsc = 'RENTAL_ORDER_BY_RENTAL_ORDER_KEY__UPDATED_AT_ASC',
  RentalOrderByRentalOrderKeyUpdatedAtDesc = 'RENTAL_ORDER_BY_RENTAL_ORDER_KEY__UPDATED_AT_DESC',
  RentalOrderByRentalOrderKeyUpdatedByAsc = 'RENTAL_ORDER_BY_RENTAL_ORDER_KEY__UPDATED_BY_ASC',
  RentalOrderByRentalOrderKeyUpdatedByDesc = 'RENTAL_ORDER_BY_RENTAL_ORDER_KEY__UPDATED_BY_DESC',
  RentalOrderKeyAsc = 'RENTAL_ORDER_KEY_ASC',
  RentalOrderKeyDesc = 'RENTAL_ORDER_KEY_DESC',
  SubtotalAsc = 'SUBTOTAL_ASC',
  SubtotalDesc = 'SUBTOTAL_DESC',
  TenantIdAsc = 'TENANT_ID_ASC',
  TenantIdDesc = 'TENANT_ID_DESC',
  TotalAsc = 'TOTAL_ASC',
  TotalDesc = 'TOTAL_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UpdatedByAsc = 'UPDATED_BY_ASC',
  UpdatedByDesc = 'UPDATED_BY_DESC'
}

/** A filter to be used against `RentalOrder` object types. All fields are combined with a logical ‘and.’ */
export type RentalOrderFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<RentalOrderFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy?: InputMaybe<IntFilter>;
  /** Filter by the object’s `deletedAt` field. */
  deletedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `deletedBy` field. */
  deletedBy?: InputMaybe<IntFilter>;
  /** Filter by the object’s `deletion` field. */
  deletion?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `key` field. */
  key?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<RentalOrderFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<RentalOrderFilter>>;
  /** Filter by the object’s `orderKey` field. */
  orderKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `period` field. */
  period?: InputMaybe<StringFilter>;
  /** Filter by the object’s `tenantId` field. */
  tenantId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `updatedBy` field. */
  updatedBy?: InputMaybe<IntFilter>;
};

/** An input for mutations affecting `RentalOrder` */
export type RentalOrderInput = {
  deletion?: InputMaybe<Scalars['Boolean']>;
  order?: InputMaybe<FkRentalOrderToOrderInput>;
  orderKey?: InputMaybe<Scalars['String']>;
  period?: InputMaybe<Scalars['String']>;
  rentalOrderDetails?: InputMaybe<FkRentalOrderDetailToRentalOrderInverseInput>;
};

/** The globally unique `ID` look up for the row to connect. */
export type RentalOrderNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `rentalOrder` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type RentalOrderNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `rentalOrder` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type RentalOrderOnRentalOrderDetailForFkRentalOrderDetailToRentalOrderNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `rentalOrderDetail` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `rentalOrderDetail` being updated. */
  patch: RentalOrderDetailPatch;
};

/** The fields on `rentalOrder` to look up the row to update. */
export type RentalOrderOnRentalOrderDetailForFkRentalOrderDetailToRentalOrderUsingPkRentalOrderUpdate = {
  key: Scalars['String'];
  /** An object where the defined keys will be set on the `rentalOrder` being updated. */
  patch: UpdateRentalOrderOnRentalOrderDetailForFkRentalOrderDetailToRentalOrderPatch;
};

/** The fields on `rentalOrder` to look up the row to update. */
export type RentalOrderOnRentalOrderDetailForFkRentalOrderDetailToRentalOrderUsingUkRentalOrderOrderIdUpdate = {
  orderKey: Scalars['String'];
  /** An object where the defined keys will be set on the `rentalOrder` being updated. */
  patch: UpdateRentalOrderOnRentalOrderDetailForFkRentalOrderDetailToRentalOrderPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type RentalOrderOnRentalOrderForFkRentalOrderToOrderNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `order` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `order` being updated. */
  patch: OrderPatch;
};

/** The fields on `rentalOrder` to look up the row to update. */
export type RentalOrderOnRentalOrderForFkRentalOrderToOrderUsingPkRentalOrderUpdate = {
  key: Scalars['String'];
  /** An object where the defined keys will be set on the `rentalOrder` being updated. */
  patch: UpdateRentalOrderOnRentalOrderForFkRentalOrderToOrderPatch;
};

/** The fields on `rentalOrder` to look up the row to update. */
export type RentalOrderOnRentalOrderForFkRentalOrderToOrderUsingUkRentalOrderOrderIdUpdate = {
  orderKey: Scalars['String'];
  /** An object where the defined keys will be set on the `rentalOrder` being updated. */
  patch: UpdateRentalOrderOnRentalOrderForFkRentalOrderToOrderPatch;
};

/** Represents an update to a `RentalOrder`. Fields that are set will be updated. */
export type RentalOrderPatch = {
  deletion?: InputMaybe<Scalars['Boolean']>;
  order?: InputMaybe<FkRentalOrderToOrderInput>;
  orderKey?: InputMaybe<Scalars['String']>;
  period?: InputMaybe<Scalars['String']>;
  rentalOrderDetails?: InputMaybe<FkRentalOrderDetailToRentalOrderInverseInput>;
};

/** The fields on `rentalOrder` to look up the row to connect. */
export type RentalOrderPkRentalOrderConnect = {
  key: Scalars['String'];
};

/** The fields on `rentalOrder` to look up the row to delete. */
export type RentalOrderPkRentalOrderDelete = {
  key: Scalars['String'];
};

/** The fields on `rentalOrder` to look up the row to connect. */
export type RentalOrderUkRentalOrderOrderIdConnect = {
  orderKey: Scalars['String'];
};

/** The fields on `rentalOrder` to look up the row to delete. */
export type RentalOrderUkRentalOrderOrderIdDelete = {
  orderKey: Scalars['String'];
};

/** A connection to a list of `RentalOrder` values. */
export type RentalOrdersConnection = {
  __typename?: 'RentalOrdersConnection';
  /** A list of edges which contains the `RentalOrder` and cursor to aid in pagination. */
  edges: Array<RentalOrdersEdge>;
  /** A list of `RentalOrder` objects. */
  nodes: Array<Maybe<RentalOrder>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `RentalOrder` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `RentalOrder` edge in the connection. */
export type RentalOrdersEdge = {
  __typename?: 'RentalOrdersEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `RentalOrder` at the end of the edge. */
  node?: Maybe<RentalOrder>;
};

/** Methods to use when ordering `RentalOrder`. */
export enum RentalOrdersOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  DeletedAtAsc = 'DELETED_AT_ASC',
  DeletedAtDesc = 'DELETED_AT_DESC',
  DeletedByAsc = 'DELETED_BY_ASC',
  DeletedByDesc = 'DELETED_BY_DESC',
  DeletionAsc = 'DELETION_ASC',
  DeletionDesc = 'DELETION_DESC',
  KeyAsc = 'KEY_ASC',
  KeyDesc = 'KEY_DESC',
  Natural = 'NATURAL',
  OrderByOrderKeyChangeAmountAsc = 'ORDER_BY_ORDER_KEY__CHANGE_AMOUNT_ASC',
  OrderByOrderKeyChangeAmountDesc = 'ORDER_BY_ORDER_KEY__CHANGE_AMOUNT_DESC',
  OrderByOrderKeyClientKeyAsc = 'ORDER_BY_ORDER_KEY__CLIENT_KEY_ASC',
  OrderByOrderKeyClientKeyDesc = 'ORDER_BY_ORDER_KEY__CLIENT_KEY_DESC',
  OrderByOrderKeyCreatedAtAsc = 'ORDER_BY_ORDER_KEY__CREATED_AT_ASC',
  OrderByOrderKeyCreatedAtDesc = 'ORDER_BY_ORDER_KEY__CREATED_AT_DESC',
  OrderByOrderKeyCreatedByAsc = 'ORDER_BY_ORDER_KEY__CREATED_BY_ASC',
  OrderByOrderKeyCreatedByDesc = 'ORDER_BY_ORDER_KEY__CREATED_BY_DESC',
  OrderByOrderKeyDeletedAtAsc = 'ORDER_BY_ORDER_KEY__DELETED_AT_ASC',
  OrderByOrderKeyDeletedAtDesc = 'ORDER_BY_ORDER_KEY__DELETED_AT_DESC',
  OrderByOrderKeyDeletedByAsc = 'ORDER_BY_ORDER_KEY__DELETED_BY_ASC',
  OrderByOrderKeyDeletedByDesc = 'ORDER_BY_ORDER_KEY__DELETED_BY_DESC',
  OrderByOrderKeyDeletionAsc = 'ORDER_BY_ORDER_KEY__DELETION_ASC',
  OrderByOrderKeyDeletionDesc = 'ORDER_BY_ORDER_KEY__DELETION_DESC',
  OrderByOrderKeyDiscountAsc = 'ORDER_BY_ORDER_KEY__DISCOUNT_ASC',
  OrderByOrderKeyDiscountDesc = 'ORDER_BY_ORDER_KEY__DISCOUNT_DESC',
  OrderByOrderKeyEmailAsc = 'ORDER_BY_ORDER_KEY__EMAIL_ASC',
  OrderByOrderKeyEmailDesc = 'ORDER_BY_ORDER_KEY__EMAIL_DESC',
  OrderByOrderKeyKeyAsc = 'ORDER_BY_ORDER_KEY__KEY_ASC',
  OrderByOrderKeyKeyDesc = 'ORDER_BY_ORDER_KEY__KEY_DESC',
  OrderByOrderKeyPaymentAmountAsc = 'ORDER_BY_ORDER_KEY__PAYMENT_AMOUNT_ASC',
  OrderByOrderKeyPaymentAmountDesc = 'ORDER_BY_ORDER_KEY__PAYMENT_AMOUNT_DESC',
  OrderByOrderKeyPhoneNumberAsc = 'ORDER_BY_ORDER_KEY__PHONE_NUMBER_ASC',
  OrderByOrderKeyPhoneNumberDesc = 'ORDER_BY_ORDER_KEY__PHONE_NUMBER_DESC',
  OrderByOrderKeyPointOfSaleKeyAsc = 'ORDER_BY_ORDER_KEY__POINT_OF_SALE_KEY_ASC',
  OrderByOrderKeyPointOfSaleKeyDesc = 'ORDER_BY_ORDER_KEY__POINT_OF_SALE_KEY_DESC',
  OrderByOrderKeyQuantityAsc = 'ORDER_BY_ORDER_KEY__QUANTITY_ASC',
  OrderByOrderKeyQuantityDesc = 'ORDER_BY_ORDER_KEY__QUANTITY_DESC',
  OrderByOrderKeyStatusAsc = 'ORDER_BY_ORDER_KEY__STATUS_ASC',
  OrderByOrderKeyStatusDesc = 'ORDER_BY_ORDER_KEY__STATUS_DESC',
  OrderByOrderKeySubtotalAsc = 'ORDER_BY_ORDER_KEY__SUBTOTAL_ASC',
  OrderByOrderKeySubtotalDesc = 'ORDER_BY_ORDER_KEY__SUBTOTAL_DESC',
  OrderByOrderKeyTenantIdAsc = 'ORDER_BY_ORDER_KEY__TENANT_ID_ASC',
  OrderByOrderKeyTenantIdDesc = 'ORDER_BY_ORDER_KEY__TENANT_ID_DESC',
  OrderByOrderKeyTotalAsc = 'ORDER_BY_ORDER_KEY__TOTAL_ASC',
  OrderByOrderKeyTotalDesc = 'ORDER_BY_ORDER_KEY__TOTAL_DESC',
  OrderByOrderKeyUpdatedAtAsc = 'ORDER_BY_ORDER_KEY__UPDATED_AT_ASC',
  OrderByOrderKeyUpdatedAtDesc = 'ORDER_BY_ORDER_KEY__UPDATED_AT_DESC',
  OrderByOrderKeyUpdatedByAsc = 'ORDER_BY_ORDER_KEY__UPDATED_BY_ASC',
  OrderByOrderKeyUpdatedByDesc = 'ORDER_BY_ORDER_KEY__UPDATED_BY_DESC',
  OrderKeyAsc = 'ORDER_KEY_ASC',
  OrderKeyDesc = 'ORDER_KEY_DESC',
  PeriodAsc = 'PERIOD_ASC',
  PeriodDesc = 'PERIOD_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  RentalOrderDetailsByRentalOrderKeyCountAsc = 'RENTAL_ORDER_DETAILS_BY_RENTAL_ORDER_KEY__COUNT_ASC',
  RentalOrderDetailsByRentalOrderKeyCountDesc = 'RENTAL_ORDER_DETAILS_BY_RENTAL_ORDER_KEY__COUNT_DESC',
  TenantIdAsc = 'TENANT_ID_ASC',
  TenantIdDesc = 'TENANT_ID_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UpdatedByAsc = 'UPDATED_BY_ASC',
  UpdatedByDesc = 'UPDATED_BY_DESC'
}

export enum RequestStatus {
  Failed = 'FAILED',
  Registered = 'REGISTERED',
  Success = 'SUCCESS'
}

/** A filter to be used against RequestStatus fields. All fields are combined with a logical ‘and.’ */
export type RequestStatusFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<RequestStatus>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<RequestStatus>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<RequestStatus>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<RequestStatus>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<RequestStatus>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<RequestStatus>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<RequestStatus>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<RequestStatus>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<RequestStatus>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<RequestStatus>>;
};

/** All input for the `requestSync` mutation. */
export type RequestSyncInput = {
  branchOfficeCode: Scalars['Int'];
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  pointOfSaleCode: Scalars['Int'];
  type: SyncRequestType;
};

/** The output of our `requestSync` mutation. */
export type RequestSyncPayload = {
  __typename?: 'RequestSyncPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  syncRequest?: Maybe<SyncRequest>;
};

/** Orden de venta */
export type SaleOrder = Node & {
  __typename?: 'SaleOrder';
  createdAt: Scalars['Datetime'];
  giftCard: Scalars['BigFloat'];
  /** Identificador */
  key: Scalars['String'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads a single `Order` that is related to this `SaleOrder`. */
  order?: Maybe<Order>;
  orderKey: Scalars['String'];
  /** Reads and enables pagination through a set of `SaleOrderDetail`. */
  saleOrderDetails: SaleOrderDetailsConnection;
  updatedAt?: Maybe<Scalars['Datetime']>;
};


/** Orden de venta */
export type SaleOrderSaleOrderDetailsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

/** Detalle de Orden de venta */
export type SaleOrderDetail = Node & {
  __typename?: 'SaleOrderDetail';
  createdAt: Scalars['Datetime'];
  discount: Scalars['BigFloat'];
  /** Identificador */
  key: Scalars['String'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  price: Scalars['BigFloat'];
  productKey: Scalars['String'];
  /** Reads a single `Product` that is related to this `SaleOrderDetail`. */
  productService?: Maybe<Product>;
  quantity: Scalars['BigFloat'];
  /** Reads a single `SaleOrder` that is related to this `SaleOrderDetail`. */
  saleOrder?: Maybe<SaleOrder>;
  saleOrderKey: Scalars['String'];
  sinNumeroImei?: Maybe<Scalars['String']>;
  sinNumeroSerie?: Maybe<Scalars['String']>;
  subtotal: Scalars['BigFloat'];
  total: Scalars['BigFloat'];
  updatedAt?: Maybe<Scalars['Datetime']>;
};

/** An input for mutations affecting `SaleOrderDetail` */
export type SaleOrderDetailInput = {
  discount: Scalars['BigFloat'];
  price: Scalars['BigFloat'];
  productKey?: InputMaybe<Scalars['String']>;
  productService?: InputMaybe<FkSaleOrderDetailToProductInput>;
  quantity: Scalars['BigFloat'];
  saleOrder?: InputMaybe<FkSaleOrderDetailToSaleOrderInput>;
  saleOrderKey?: InputMaybe<Scalars['String']>;
  sinNumeroImei?: InputMaybe<Scalars['String']>;
  sinNumeroSerie?: InputMaybe<Scalars['String']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type SaleOrderDetailNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `saleOrderDetail` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type SaleOrderDetailOnSaleOrderDetailForFkSaleOrderDetailToProductNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `product` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `product` being updated. */
  patch: ProductPatch;
};

/** The fields on `saleOrderDetail` to look up the row to update. */
export type SaleOrderDetailOnSaleOrderDetailForFkSaleOrderDetailToProductUsingPkSaleOrderDetailUpdate = {
  /** Identificador */
  key: Scalars['String'];
  /** An object where the defined keys will be set on the `saleOrderDetail` being updated. */
  patch: UpdateSaleOrderDetailOnSaleOrderDetailForFkSaleOrderDetailToProductPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type SaleOrderDetailOnSaleOrderDetailForFkSaleOrderDetailToSaleOrderNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `saleOrder` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `saleOrder` being updated. */
  patch: SaleOrderPatch;
};

/** The fields on `saleOrderDetail` to look up the row to update. */
export type SaleOrderDetailOnSaleOrderDetailForFkSaleOrderDetailToSaleOrderUsingPkSaleOrderDetailUpdate = {
  /** Identificador */
  key: Scalars['String'];
  /** An object where the defined keys will be set on the `saleOrderDetail` being updated. */
  patch: UpdateSaleOrderDetailOnSaleOrderDetailForFkSaleOrderDetailToSaleOrderPatch;
};

/** Represents an update to a `SaleOrderDetail`. Fields that are set will be updated. */
export type SaleOrderDetailPatch = {
  discount?: InputMaybe<Scalars['BigFloat']>;
  price?: InputMaybe<Scalars['BigFloat']>;
  productKey?: InputMaybe<Scalars['String']>;
  productService?: InputMaybe<FkSaleOrderDetailToProductInput>;
  quantity?: InputMaybe<Scalars['BigFloat']>;
  saleOrder?: InputMaybe<FkSaleOrderDetailToSaleOrderInput>;
  saleOrderKey?: InputMaybe<Scalars['String']>;
  sinNumeroImei?: InputMaybe<Scalars['String']>;
  sinNumeroSerie?: InputMaybe<Scalars['String']>;
};

/** The fields on `saleOrderDetail` to look up the row to connect. */
export type SaleOrderDetailPkSaleOrderDetailConnect = {
  /** Identificador */
  key: Scalars['String'];
};

/** A connection to a list of `SaleOrderDetail` values. */
export type SaleOrderDetailsConnection = {
  __typename?: 'SaleOrderDetailsConnection';
  /** A list of edges which contains the `SaleOrderDetail` and cursor to aid in pagination. */
  edges: Array<SaleOrderDetailsEdge>;
  /** A list of `SaleOrderDetail` objects. */
  nodes: Array<Maybe<SaleOrderDetail>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `SaleOrderDetail` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `SaleOrderDetail` edge in the connection. */
export type SaleOrderDetailsEdge = {
  __typename?: 'SaleOrderDetailsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `SaleOrderDetail` at the end of the edge. */
  node?: Maybe<SaleOrderDetail>;
};

/** An input for mutations affecting `SaleOrder` */
export type SaleOrderInput = {
  giftCard?: InputMaybe<Scalars['BigFloat']>;
  order?: InputMaybe<FkSaleOrderToOrderInput>;
  orderKey?: InputMaybe<Scalars['String']>;
  saleOrderDetails?: InputMaybe<FkSaleOrderDetailToSaleOrderInverseInput>;
};

/** The globally unique `ID` look up for the row to connect. */
export type SaleOrderNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `saleOrder` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type SaleOrderOnSaleOrderDetailForFkSaleOrderDetailToSaleOrderNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `saleOrderDetail` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `saleOrderDetail` being updated. */
  patch: SaleOrderDetailPatch;
};

/** The fields on `saleOrder` to look up the row to update. */
export type SaleOrderOnSaleOrderDetailForFkSaleOrderDetailToSaleOrderUsingPkSaleOrderUpdate = {
  /** Identificador */
  key: Scalars['String'];
  /** An object where the defined keys will be set on the `saleOrder` being updated. */
  patch: UpdateSaleOrderOnSaleOrderDetailForFkSaleOrderDetailToSaleOrderPatch;
};

/** The fields on `saleOrder` to look up the row to update. */
export type SaleOrderOnSaleOrderDetailForFkSaleOrderDetailToSaleOrderUsingUkSaleOrderOrderKeyUpdate = {
  orderKey: Scalars['String'];
  /** An object where the defined keys will be set on the `saleOrder` being updated. */
  patch: UpdateSaleOrderOnSaleOrderDetailForFkSaleOrderDetailToSaleOrderPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type SaleOrderOnSaleOrderForFkSaleOrderToOrderNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `order` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `order` being updated. */
  patch: OrderPatch;
};

/** The fields on `saleOrder` to look up the row to update. */
export type SaleOrderOnSaleOrderForFkSaleOrderToOrderUsingPkSaleOrderUpdate = {
  /** Identificador */
  key: Scalars['String'];
  /** An object where the defined keys will be set on the `saleOrder` being updated. */
  patch: UpdateSaleOrderOnSaleOrderForFkSaleOrderToOrderPatch;
};

/** The fields on `saleOrder` to look up the row to update. */
export type SaleOrderOnSaleOrderForFkSaleOrderToOrderUsingUkSaleOrderOrderKeyUpdate = {
  orderKey: Scalars['String'];
  /** An object where the defined keys will be set on the `saleOrder` being updated. */
  patch: UpdateSaleOrderOnSaleOrderForFkSaleOrderToOrderPatch;
};

/** Represents an update to a `SaleOrder`. Fields that are set will be updated. */
export type SaleOrderPatch = {
  giftCard?: InputMaybe<Scalars['BigFloat']>;
  order?: InputMaybe<FkSaleOrderToOrderInput>;
  orderKey?: InputMaybe<Scalars['String']>;
  saleOrderDetails?: InputMaybe<FkSaleOrderDetailToSaleOrderInverseInput>;
};

/** The fields on `saleOrder` to look up the row to connect. */
export type SaleOrderPkSaleOrderConnect = {
  /** Identificador */
  key: Scalars['String'];
};

/** The fields on `saleOrder` to look up the row to connect. */
export type SaleOrderUkSaleOrderOrderKeyConnect = {
  orderKey: Scalars['String'];
};

/** A connection to a list of `SaleOrder` values. */
export type SaleOrdersConnection = {
  __typename?: 'SaleOrdersConnection';
  /** A list of edges which contains the `SaleOrder` and cursor to aid in pagination. */
  edges: Array<SaleOrdersEdge>;
  /** A list of `SaleOrder` objects. */
  nodes: Array<Maybe<SaleOrder>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `SaleOrder` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `SaleOrder` edge in the connection. */
export type SaleOrdersEdge = {
  __typename?: 'SaleOrdersEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `SaleOrder` at the end of the edge. */
  node?: Maybe<SaleOrder>;
};

export type SectorDocumentInvoiceType = {
  __typename?: 'SectorDocumentInvoiceType';
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  features?: Maybe<Scalars['String']>;
  invoiceTypeCode?: Maybe<Scalars['Int']>;
  invoiceTypeDescription?: Maybe<Scalars['String']>;
  sectorDocumentCode?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['Int']>;
};

/** A filter to be used against `SectorDocumentInvoiceType` object types. All fields are combined with a logical ‘and.’ */
export type SectorDocumentInvoiceTypeFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<SectorDocumentInvoiceTypeFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy?: InputMaybe<IntFilter>;
  /** Filter by the object’s `description` field. */
  description?: InputMaybe<StringFilter>;
  /** Filter by the object’s `features` field. */
  features?: InputMaybe<StringFilter>;
  /** Filter by the object’s `invoiceTypeCode` field. */
  invoiceTypeCode?: InputMaybe<IntFilter>;
  /** Filter by the object’s `invoiceTypeDescription` field. */
  invoiceTypeDescription?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<SectorDocumentInvoiceTypeFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<SectorDocumentInvoiceTypeFilter>>;
  /** Filter by the object’s `sectorDocumentCode` field. */
  sectorDocumentCode?: InputMaybe<IntFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `updatedBy` field. */
  updatedBy?: InputMaybe<IntFilter>;
};

/** A connection to a list of `SectorDocumentInvoiceType` values. */
export type SectorDocumentInvoiceTypesConnection = {
  __typename?: 'SectorDocumentInvoiceTypesConnection';
  /** A list of edges which contains the `SectorDocumentInvoiceType` and cursor to aid in pagination. */
  edges: Array<SectorDocumentInvoiceTypesEdge>;
  /** A list of `SectorDocumentInvoiceType` objects. */
  nodes: Array<Maybe<SectorDocumentInvoiceType>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `SectorDocumentInvoiceType` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `SectorDocumentInvoiceType` edge in the connection. */
export type SectorDocumentInvoiceTypesEdge = {
  __typename?: 'SectorDocumentInvoiceTypesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `SectorDocumentInvoiceType` at the end of the edge. */
  node?: Maybe<SectorDocumentInvoiceType>;
};

/** All input for the `sendSignificantEvent` mutation. */
export type SendSignificantEventInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  key: Scalars['String'];
};

/** The output of our `sendSignificantEvent` mutation. */
export type SendSignificantEventPayload = {
  __typename?: 'SendSignificantEventPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  significantEvent?: Maybe<SignificantEvent>;
};

export enum SendingStatus {
  NotSent = 'NOT_SENT',
  Sending = 'SENDING',
  Sent = 'SENT'
}

/** A filter to be used against SendingStatus fields. All fields are combined with a logical ‘and.’ */
export type SendingStatusFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<SendingStatus>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<SendingStatus>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<SendingStatus>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<SendingStatus>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<SendingStatus>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<SendingStatus>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<SendingStatus>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<SendingStatus>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<SendingStatus>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<SendingStatus>>;
};

export type SignificantEvent = {
  __typename?: 'SignificantEvent';
  branchOfficeCode?: Maybe<Scalars['Int']>;
  branchOfficeName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['Int']>;
  cufd?: Maybe<Scalars['String']>;
  cufdEvent?: Maybe<Scalars['String']>;
  cuis?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  finishDate?: Maybe<Scalars['Datetime']>;
  id?: Maybe<Scalars['Int']>;
  key?: Maybe<Scalars['String']>;
  pointOfSaleCode?: Maybe<Scalars['Int']>;
  pointOfSaleName?: Maybe<Scalars['String']>;
  reasonCode?: Maybe<Scalars['Int']>;
  reasonDescription?: Maybe<Scalars['String']>;
  receptionCode?: Maybe<Scalars['BigInt']>;
  sendingStatus?: Maybe<Scalars['String']>;
  sentAt?: Maybe<Scalars['Datetime']>;
  sentBy?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['Datetime']>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['Int']>;
};

export enum SignificantEventStatus {
  Failed = 'FAILED',
  Finished = 'FINISHED',
  Registered = 'REGISTERED',
  Rejected = 'REJECTED',
  Success = 'SUCCESS'
}

/** A filter to be used against SignificantEventStatus fields. All fields are combined with a logical ‘and.’ */
export type SignificantEventStatusFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<SignificantEventStatus>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<SignificantEventStatus>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<SignificantEventStatus>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<SignificantEventStatus>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<SignificantEventStatus>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<SignificantEventStatus>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<SignificantEventStatus>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<SignificantEventStatus>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<SignificantEventStatus>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<SignificantEventStatus>>;
};

export type SinGenerateMassiveCufdsInput = {
  pointOfSaleCodes: Array<InputMaybe<Scalars['Int']>>;
};

export type SinGenerateMassiveCufdsPayload = {
  __typename?: 'SinGenerateMassiveCufdsPayload';
  cufds: Array<Maybe<Cufd>>;
};

export type SinVerifyNitPayload = {
  __typename?: 'SinVerifyNitPayload';
  isValid: Scalars['Boolean'];
};

/** A filter to be used against String fields. All fields are combined with a logical ‘and.’ */
export type StringFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['String']>;
  /** Not equal to the specified value, treating null like an ordinary value (case-insensitive). */
  distinctFromInsensitive?: InputMaybe<Scalars['String']>;
  /** Ends with the specified string (case-sensitive). */
  endsWith?: InputMaybe<Scalars['String']>;
  /** Ends with the specified string (case-insensitive). */
  endsWithInsensitive?: InputMaybe<Scalars['String']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['String']>;
  /** Equal to the specified value (case-insensitive). */
  equalToInsensitive?: InputMaybe<Scalars['String']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['String']>;
  /** Greater than the specified value (case-insensitive). */
  greaterThanInsensitive?: InputMaybe<Scalars['String']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['String']>;
  /** Greater than or equal to the specified value (case-insensitive). */
  greaterThanOrEqualToInsensitive?: InputMaybe<Scalars['String']>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['String']>>;
  /** Included in the specified list (case-insensitive). */
  inInsensitive?: InputMaybe<Array<Scalars['String']>>;
  /** Contains the specified string (case-sensitive). */
  includes?: InputMaybe<Scalars['String']>;
  /** Contains the specified string (case-insensitive). */
  includesInsensitive?: InputMaybe<Scalars['String']>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['String']>;
  /** Less than the specified value (case-insensitive). */
  lessThanInsensitive?: InputMaybe<Scalars['String']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['String']>;
  /** Less than or equal to the specified value (case-insensitive). */
  lessThanOrEqualToInsensitive?: InputMaybe<Scalars['String']>;
  /** Matches the specified pattern (case-sensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  like?: InputMaybe<Scalars['String']>;
  /** Matches the specified pattern (case-insensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  likeInsensitive?: InputMaybe<Scalars['String']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['String']>;
  /** Equal to the specified value, treating null like an ordinary value (case-insensitive). */
  notDistinctFromInsensitive?: InputMaybe<Scalars['String']>;
  /** Does not end with the specified string (case-sensitive). */
  notEndsWith?: InputMaybe<Scalars['String']>;
  /** Does not end with the specified string (case-insensitive). */
  notEndsWithInsensitive?: InputMaybe<Scalars['String']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['String']>;
  /** Not equal to the specified value (case-insensitive). */
  notEqualToInsensitive?: InputMaybe<Scalars['String']>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars['String']>>;
  /** Not included in the specified list (case-insensitive). */
  notInInsensitive?: InputMaybe<Array<Scalars['String']>>;
  /** Does not contain the specified string (case-sensitive). */
  notIncludes?: InputMaybe<Scalars['String']>;
  /** Does not contain the specified string (case-insensitive). */
  notIncludesInsensitive?: InputMaybe<Scalars['String']>;
  /** Does not match the specified pattern (case-sensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  notLike?: InputMaybe<Scalars['String']>;
  /** Does not match the specified pattern (case-insensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  notLikeInsensitive?: InputMaybe<Scalars['String']>;
  /** Does not start with the specified string (case-sensitive). */
  notStartsWith?: InputMaybe<Scalars['String']>;
  /** Does not start with the specified string (case-insensitive). */
  notStartsWithInsensitive?: InputMaybe<Scalars['String']>;
  /** Starts with the specified string (case-sensitive). */
  startsWith?: InputMaybe<Scalars['String']>;
  /** Starts with the specified string (case-insensitive). */
  startsWithInsensitive?: InputMaybe<Scalars['String']>;
};

/** The root subscription type: contains realtime events you can subscribe to with the `subscription` operation. */
export type Subscription = {
  __typename?: 'Subscription';
  /**
   * Triggered when the current user's data changes:
   * - direct modifications to the user
   * - when their organization membership changes
   */
  userNotification?: Maybe<UserNotificationPayload>;
};

/** A connection to a list of `SyncActivity` values. */
export type SyncActivitiesConnection = {
  __typename?: 'SyncActivitiesConnection';
  /** A list of edges which contains the `SyncActivity` and cursor to aid in pagination. */
  edges: Array<SyncActivitiesEdge>;
  /** A list of `SyncActivity` objects. */
  nodes: Array<Maybe<SyncActivity>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `SyncActivity` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `SyncActivity` edge in the connection. */
export type SyncActivitiesEdge = {
  __typename?: 'SyncActivitiesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `SyncActivity` at the end of the edge. */
  node?: Maybe<SyncActivity>;
};

export type SyncActivity = {
  __typename?: 'SyncActivity';
  caebCode?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  description?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
};

/** A filter to be used against `SyncActivity` object types. All fields are combined with a logical ‘and.’ */
export type SyncActivityFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<SyncActivityFilter>>;
  /** Filter by the object’s `caebCode` field. */
  caebCode?: InputMaybe<StringFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `description` field. */
  description?: InputMaybe<StringFilter>;
  /** Filter by the object’s `enabled` field. */
  enabled?: InputMaybe<BooleanFilter>;
  /** Negates the expression. */
  not?: InputMaybe<SyncActivityFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<SyncActivityFilter>>;
  /** Filter by the object’s `type` field. */
  type?: InputMaybe<StringFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
};

export type SyncActivitySectorDocument = {
  __typename?: 'SyncActivitySectorDocument';
  activityCode?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  sectorDocumentCode?: Maybe<Scalars['String']>;
  sectorDocumentType?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
};

/** A filter to be used against `SyncActivitySectorDocument` object types. All fields are combined with a logical ‘and.’ */
export type SyncActivitySectorDocumentFilter = {
  /** Filter by the object’s `activityCode` field. */
  activityCode?: InputMaybe<StringFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<SyncActivitySectorDocumentFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Negates the expression. */
  not?: InputMaybe<SyncActivitySectorDocumentFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<SyncActivitySectorDocumentFilter>>;
  /** Filter by the object’s `sectorDocumentCode` field. */
  sectorDocumentCode?: InputMaybe<StringFilter>;
  /** Filter by the object’s `sectorDocumentType` field. */
  sectorDocumentType?: InputMaybe<StringFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
};

/** A connection to a list of `SyncActivitySectorDocument` values. */
export type SyncActivitySectorDocumentsConnection = {
  __typename?: 'SyncActivitySectorDocumentsConnection';
  /** A list of edges which contains the `SyncActivitySectorDocument` and cursor to aid in pagination. */
  edges: Array<SyncActivitySectorDocumentsEdge>;
  /** A list of `SyncActivitySectorDocument` objects. */
  nodes: Array<Maybe<SyncActivitySectorDocument>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `SyncActivitySectorDocument` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `SyncActivitySectorDocument` edge in the connection. */
export type SyncActivitySectorDocumentsEdge = {
  __typename?: 'SyncActivitySectorDocumentsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `SyncActivitySectorDocument` at the end of the edge. */
  node?: Maybe<SyncActivitySectorDocument>;
};

export type SyncDatetime = {
  __typename?: 'SyncDatetime';
  createdAt?: Maybe<Scalars['Datetime']>;
  value?: Maybe<Scalars['String']>;
};

/** A filter to be used against `SyncDatetime` object types. All fields are combined with a logical ‘and.’ */
export type SyncDatetimeFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<SyncDatetimeFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Negates the expression. */
  not?: InputMaybe<SyncDatetimeFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<SyncDatetimeFilter>>;
  /** Filter by the object’s `value` field. */
  value?: InputMaybe<StringFilter>;
};

/** A connection to a list of `SyncDatetime` values. */
export type SyncDatetimesConnection = {
  __typename?: 'SyncDatetimesConnection';
  /** A list of edges which contains the `SyncDatetime` and cursor to aid in pagination. */
  edges: Array<SyncDatetimesEdge>;
  /** A list of `SyncDatetime` objects. */
  nodes: Array<Maybe<SyncDatetime>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `SyncDatetime` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `SyncDatetime` edge in the connection. */
export type SyncDatetimesEdge = {
  __typename?: 'SyncDatetimesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `SyncDatetime` at the end of the edge. */
  node?: Maybe<SyncDatetime>;
};

export type SyncInvoiceLegend = {
  __typename?: 'SyncInvoiceLegend';
  activityCode?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  description?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
};

/** A filter to be used against `SyncInvoiceLegend` object types. All fields are combined with a logical ‘and.’ */
export type SyncInvoiceLegendFilter = {
  /** Filter by the object’s `activityCode` field. */
  activityCode?: InputMaybe<StringFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<SyncInvoiceLegendFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `description` field. */
  description?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<SyncInvoiceLegendFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<SyncInvoiceLegendFilter>>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
};

/** A connection to a list of `SyncInvoiceLegend` values. */
export type SyncInvoiceLegendsConnection = {
  __typename?: 'SyncInvoiceLegendsConnection';
  /** A list of edges which contains the `SyncInvoiceLegend` and cursor to aid in pagination. */
  edges: Array<SyncInvoiceLegendsEdge>;
  /** A list of `SyncInvoiceLegend` objects. */
  nodes: Array<Maybe<SyncInvoiceLegend>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `SyncInvoiceLegend` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `SyncInvoiceLegend` edge in the connection. */
export type SyncInvoiceLegendsEdge = {
  __typename?: 'SyncInvoiceLegendsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `SyncInvoiceLegend` at the end of the edge. */
  node?: Maybe<SyncInvoiceLegend>;
};

export type SyncParameter = {
  __typename?: 'SyncParameter';
  classifierCode?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  description?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
};

/** A filter to be used against `SyncParameter` object types. All fields are combined with a logical ‘and.’ */
export type SyncParameterFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<SyncParameterFilter>>;
  /** Filter by the object’s `classifierCode` field. */
  classifierCode?: InputMaybe<StringFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `description` field. */
  description?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<SyncParameterFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<SyncParameterFilter>>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
};

export enum SyncParameterType {
  CancellationReason = 'CANCELLATION_REASON',
  Currency = 'CURRENCY',
  EmissionType = 'EMISSION_TYPE',
  IdentityDocumentType = 'IDENTITY_DOCUMENT_TYPE',
  InvoiceType = 'INVOICE_TYPE',
  MeasureUnit = 'MEASURE_UNIT',
  Message = 'MESSAGE',
  OriginCountry = 'ORIGIN_COUNTRY',
  PaymentMethod = 'PAYMENT_METHOD',
  PointOfSaleType = 'POINT_OF_SALE_TYPE',
  RoomType = 'ROOM_TYPE',
  SectorDocumentType = 'SECTOR_DOCUMENT_TYPE',
  SignificantEvent = 'SIGNIFICANT_EVENT'
}

/** A filter to be used against SyncParameterType fields. All fields are combined with a logical ‘and.’ */
export type SyncParameterTypeFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<SyncParameterType>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<SyncParameterType>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<SyncParameterType>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<SyncParameterType>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<SyncParameterType>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<SyncParameterType>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<SyncParameterType>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<SyncParameterType>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<SyncParameterType>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<SyncParameterType>>;
};

/** A connection to a list of `SyncParameter` values. */
export type SyncParametersConnection = {
  __typename?: 'SyncParametersConnection';
  /** A list of edges which contains the `SyncParameter` and cursor to aid in pagination. */
  edges: Array<SyncParametersEdge>;
  /** A list of `SyncParameter` objects. */
  nodes: Array<Maybe<SyncParameter>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `SyncParameter` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `SyncParameter` edge in the connection. */
export type SyncParametersEdge = {
  __typename?: 'SyncParametersEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `SyncParameter` at the end of the edge. */
  node?: Maybe<SyncParameter>;
};

export type SyncPointsOfSaleInput = {
  branchOfficeCode: Scalars['Int'];
};

export type SyncPointsOfSalePayload = {
  __typename?: 'SyncPointsOfSalePayload';
  boolean?: Maybe<Scalars['Boolean']>;
  query?: Maybe<Query>;
};

export type SyncRequest = {
  __typename?: 'SyncRequest';
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['Int']>;
  key?: Maybe<Scalars['String']>;
  sendingStatus?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['Int']>;
};

/** A filter to be used against `SyncRequest` object types. All fields are combined with a logical ‘and.’ */
export type SyncRequestFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<SyncRequestFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy?: InputMaybe<IntFilter>;
  /** Filter by the object’s `key` field. */
  key?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<SyncRequestFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<SyncRequestFilter>>;
  /** Filter by the object’s `sendingStatus` field. */
  sendingStatus?: InputMaybe<StringFilter>;
  /** Filter by the object’s `status` field. */
  status?: InputMaybe<StringFilter>;
  /** Filter by the object’s `type` field. */
  type?: InputMaybe<StringFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `updatedBy` field. */
  updatedBy?: InputMaybe<IntFilter>;
};

export enum SyncRequestType {
  Activity = 'ACTIVITY',
  ActivitySectorDocument = 'ACTIVITY_SECTOR_DOCUMENT',
  Datetime = 'DATETIME',
  InvoiceLegend = 'INVOICE_LEGEND',
  ParamCancellationReason = 'PARAM_CANCELLATION_REASON',
  ParamCurrency = 'PARAM_CURRENCY',
  ParamEmissionType = 'PARAM_EMISSION_TYPE',
  ParamIdentityDocumentType = 'PARAM_IDENTITY_DOCUMENT_TYPE',
  ParamInvoiceType = 'PARAM_INVOICE_TYPE',
  ParamMeasureUnit = 'PARAM_MEASURE_UNIT',
  ParamMessage = 'PARAM_MESSAGE',
  ParamOriginCountry = 'PARAM_ORIGIN_COUNTRY',
  ParamPaymentMethod = 'PARAM_PAYMENT_METHOD',
  ParamPointOfSaleType = 'PARAM_POINT_OF_SALE_TYPE',
  ParamRoomType = 'PARAM_ROOM_TYPE',
  ParamSectorDocumentType = 'PARAM_SECTOR_DOCUMENT_TYPE',
  ParamSignificantEvent = 'PARAM_SIGNIFICANT_EVENT',
  ProductService = 'PRODUCT_SERVICE'
}

/** A filter to be used against SyncRequestType fields. All fields are combined with a logical ‘and.’ */
export type SyncRequestTypeFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<SyncRequestType>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<SyncRequestType>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<SyncRequestType>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<SyncRequestType>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<SyncRequestType>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<SyncRequestType>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<SyncRequestType>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<SyncRequestType>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<SyncRequestType>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<SyncRequestType>>;
};

/** A connection to a list of `SyncRequest` values. */
export type SyncRequestsConnection = {
  __typename?: 'SyncRequestsConnection';
  /** A list of edges which contains the `SyncRequest` and cursor to aid in pagination. */
  edges: Array<SyncRequestsEdge>;
  /** A list of `SyncRequest` objects. */
  nodes: Array<Maybe<SyncRequest>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `SyncRequest` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `SyncRequest` edge in the connection. */
export type SyncRequestsEdge = {
  __typename?: 'SyncRequestsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `SyncRequest` at the end of the edge. */
  node?: Maybe<SyncRequest>;
};

export type Tenant = {
  __typename?: 'Tenant';
  businessName?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  nit?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `Tenant` object types. All fields are combined with a logical ‘and.’ */
export type TenantFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<TenantFilter>>;
  /** Filter by the object’s `businessName` field. */
  businessName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `key` field. */
  key?: InputMaybe<StringFilter>;
  /** Filter by the object’s `nit` field. */
  nit?: InputMaybe<BigIntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<TenantFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<TenantFilter>>;
};

/** A connection to a list of `Tenant` values. */
export type TenantsConnection = {
  __typename?: 'TenantsConnection';
  /** A list of edges which contains the `Tenant` and cursor to aid in pagination. */
  edges: Array<TenantsEdge>;
  /** A list of `Tenant` objects. */
  nodes: Array<Maybe<Tenant>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Tenant` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Tenant` edge in the connection. */
export type TenantsEdge = {
  __typename?: 'TenantsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Tenant` at the end of the edge. */
  node?: Maybe<Tenant>;
};

/** Orden de renta. */
export type TouristServiceOrder = Node & {
  __typename?: 'TouristServiceOrder';
  cantidadHabitaciones?: Maybe<Scalars['Int']>;
  cantidadHuespedes?: Maybe<Scalars['Int']>;
  cantidadMayores?: Maybe<Scalars['Int']>;
  cantidadMenores?: Maybe<Scalars['Int']>;
  createdAt: Scalars['Datetime'];
  createdBy: Scalars['Int'];
  deletedAt?: Maybe<Scalars['Datetime']>;
  deletedBy?: Maybe<Scalars['Int']>;
  deletion: Scalars['Boolean'];
  fechaIngresoHospedaje?: Maybe<Scalars['Datetime']>;
  giftCard: Scalars['BigFloat'];
  key: Scalars['String'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  operadorTurismoRazonSocial?: Maybe<Scalars['String']>;
  /** Reads a single `Order` that is related to this `TouristServiceOrder`. */
  order?: Maybe<Order>;
  orderKey: Scalars['String'];
  tenantId: Scalars['Int'];
  /** Reads and enables pagination through a set of `TouristServiceOrderDetail`. */
  touristServiceOrderDetails: TouristServiceOrderDetailsConnection;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['Int']>;
};


/** Orden de renta. */
export type TouristServiceOrderTouristServiceOrderDetailsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TouristServiceOrderDetailCondition>;
  filter?: InputMaybe<TouristServiceOrderDetailFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TouristServiceOrderDetailsOrderBy>>;
};

/**
 * A condition to be used against `TouristServiceOrder` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type TouristServiceOrderCondition = {
  /** Checks for equality with the object’s `cantidadHabitaciones` field. */
  cantidadHabitaciones?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `cantidadHuespedes` field. */
  cantidadHuespedes?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `cantidadMayores` field. */
  cantidadMayores?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `cantidadMenores` field. */
  cantidadMenores?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `deletedAt` field. */
  deletedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `deletedBy` field. */
  deletedBy?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `deletion` field. */
  deletion?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `fechaIngresoHospedaje` field. */
  fechaIngresoHospedaje?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `giftCard` field. */
  giftCard?: InputMaybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `key` field. */
  key?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `operadorTurismoRazonSocial` field. */
  operadorTurismoRazonSocial?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `orderKey` field. */
  orderKey?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `tenantId` field. */
  tenantId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedBy` field. */
  updatedBy?: InputMaybe<Scalars['Int']>;
};

/** Detalle de venta. */
export type TouristServiceOrderDetail = Node & {
  __typename?: 'TouristServiceOrderDetail';
  createdAt: Scalars['Datetime'];
  createdBy: Scalars['Int'];
  deletedAt?: Maybe<Scalars['Datetime']>;
  deletedBy?: Maybe<Scalars['Int']>;
  deletion: Scalars['Boolean'];
  discount: Scalars['BigFloat'];
  key: Scalars['String'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  price: Scalars['BigFloat'];
  productKey: Scalars['String'];
  /** Reads a single `Product` that is related to this `TouristServiceOrderDetail`. */
  productService?: Maybe<Product>;
  quantity: Scalars['BigFloat'];
  sinDetalleHuespedesJson?: Maybe<Scalars['String']>;
  sinTipoHabitacionCodigo?: Maybe<Scalars['Int']>;
  subtotal: Scalars['BigFloat'];
  tenantId: Scalars['Int'];
  total: Scalars['BigFloat'];
  /** Reads a single `TouristServiceOrder` that is related to this `TouristServiceOrderDetail`. */
  touristServiceOrder?: Maybe<TouristServiceOrder>;
  touristServiceOrderKey: Scalars['String'];
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['Int']>;
};

/**
 * A condition to be used against `TouristServiceOrderDetail` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type TouristServiceOrderDetailCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `deletedAt` field. */
  deletedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `deletedBy` field. */
  deletedBy?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `deletion` field. */
  deletion?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `discount` field. */
  discount?: InputMaybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `key` field. */
  key?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `price` field. */
  price?: InputMaybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `productKey` field. */
  productKey?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `quantity` field. */
  quantity?: InputMaybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `sinDetalleHuespedesJson` field. */
  sinDetalleHuespedesJson?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `sinTipoHabitacionCodigo` field. */
  sinTipoHabitacionCodigo?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `subtotal` field. */
  subtotal?: InputMaybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `tenantId` field. */
  tenantId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `total` field. */
  total?: InputMaybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `touristServiceOrderKey` field. */
  touristServiceOrderKey?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedBy` field. */
  updatedBy?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against `TouristServiceOrderDetail` object types. All fields are combined with a logical ‘and.’ */
export type TouristServiceOrderDetailFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<TouristServiceOrderDetailFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy?: InputMaybe<IntFilter>;
  /** Filter by the object’s `deletedAt` field. */
  deletedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `deletedBy` field. */
  deletedBy?: InputMaybe<IntFilter>;
  /** Filter by the object’s `deletion` field. */
  deletion?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `discount` field. */
  discount?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `key` field. */
  key?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<TouristServiceOrderDetailFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<TouristServiceOrderDetailFilter>>;
  /** Filter by the object’s `price` field. */
  price?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `productKey` field. */
  productKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `quantity` field. */
  quantity?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `sinDetalleHuespedesJson` field. */
  sinDetalleHuespedesJson?: InputMaybe<StringFilter>;
  /** Filter by the object’s `sinTipoHabitacionCodigo` field. */
  sinTipoHabitacionCodigo?: InputMaybe<IntFilter>;
  /** Filter by the object’s `subtotal` field. */
  subtotal?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `tenantId` field. */
  tenantId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `total` field. */
  total?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `touristServiceOrderKey` field. */
  touristServiceOrderKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `updatedBy` field. */
  updatedBy?: InputMaybe<IntFilter>;
};

/** An input for mutations affecting `TouristServiceOrderDetail` */
export type TouristServiceOrderDetailInput = {
  discount: Scalars['BigFloat'];
  price: Scalars['BigFloat'];
  productKey?: InputMaybe<Scalars['String']>;
  productService?: InputMaybe<FkTouristServiceOrderDetailToProductInput>;
  quantity: Scalars['BigFloat'];
  sinDetalleHuespedesJson?: InputMaybe<Scalars['String']>;
  sinTipoHabitacionCodigo?: InputMaybe<Scalars['Int']>;
  touristServiceOrder?: InputMaybe<FkTouristServiceOrderDetailToTouristServiceOrderInput>;
  touristServiceOrderKey?: InputMaybe<Scalars['String']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type TouristServiceOrderDetailNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `touristServiceOrderDetail` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type TouristServiceOrderDetailNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `touristServiceOrderDetail` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type TouristServiceOrderDetailOnTouristServiceOrderDetailForFkTouristServiceOrderDetailToProductNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `product` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `product` being updated. */
  patch: ProductPatch;
};

/** The fields on `touristServiceOrderDetail` to look up the row to update. */
export type TouristServiceOrderDetailOnTouristServiceOrderDetailForFkTouristServiceOrderDetailToProductUsingPkTouristServiceOrderDetailUpdate = {
  key: Scalars['String'];
  /** An object where the defined keys will be set on the `touristServiceOrderDetail` being updated. */
  patch: UpdateTouristServiceOrderDetailOnTouristServiceOrderDetailForFkTouristServiceOrderDetailToProductPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type TouristServiceOrderDetailOnTouristServiceOrderDetailForFkTouristServiceOrderDetailToTouristServiceOrderNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `touristServiceOrder` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `touristServiceOrder` being updated. */
  patch: TouristServiceOrderPatch;
};

/** The fields on `touristServiceOrderDetail` to look up the row to update. */
export type TouristServiceOrderDetailOnTouristServiceOrderDetailForFkTouristServiceOrderDetailToTouristServiceOrderUsingPkTouristServiceOrderDetailUpdate = {
  key: Scalars['String'];
  /** An object where the defined keys will be set on the `touristServiceOrderDetail` being updated. */
  patch: UpdateTouristServiceOrderDetailOnTouristServiceOrderDetailForFkTouristServiceOrderDetailToTouristServiceOrderPatch;
};

/** Represents an update to a `TouristServiceOrderDetail`. Fields that are set will be updated. */
export type TouristServiceOrderDetailPatch = {
  discount?: InputMaybe<Scalars['BigFloat']>;
  price?: InputMaybe<Scalars['BigFloat']>;
  productKey?: InputMaybe<Scalars['String']>;
  productService?: InputMaybe<FkTouristServiceOrderDetailToProductInput>;
  quantity?: InputMaybe<Scalars['BigFloat']>;
  sinDetalleHuespedesJson?: InputMaybe<Scalars['String']>;
  sinTipoHabitacionCodigo?: InputMaybe<Scalars['Int']>;
  touristServiceOrder?: InputMaybe<FkTouristServiceOrderDetailToTouristServiceOrderInput>;
  touristServiceOrderKey?: InputMaybe<Scalars['String']>;
};

/** The fields on `touristServiceOrderDetail` to look up the row to connect. */
export type TouristServiceOrderDetailPkTouristServiceOrderDetailConnect = {
  key: Scalars['String'];
};

/** The fields on `touristServiceOrderDetail` to look up the row to delete. */
export type TouristServiceOrderDetailPkTouristServiceOrderDetailDelete = {
  key: Scalars['String'];
};

/** A connection to a list of `TouristServiceOrderDetail` values. */
export type TouristServiceOrderDetailsConnection = {
  __typename?: 'TouristServiceOrderDetailsConnection';
  /** A list of edges which contains the `TouristServiceOrderDetail` and cursor to aid in pagination. */
  edges: Array<TouristServiceOrderDetailsEdge>;
  /** A list of `TouristServiceOrderDetail` objects. */
  nodes: Array<Maybe<TouristServiceOrderDetail>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `TouristServiceOrderDetail` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `TouristServiceOrderDetail` edge in the connection. */
export type TouristServiceOrderDetailsEdge = {
  __typename?: 'TouristServiceOrderDetailsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `TouristServiceOrderDetail` at the end of the edge. */
  node?: Maybe<TouristServiceOrderDetail>;
};

/** Methods to use when ordering `TouristServiceOrderDetail`. */
export enum TouristServiceOrderDetailsOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  DeletedAtAsc = 'DELETED_AT_ASC',
  DeletedAtDesc = 'DELETED_AT_DESC',
  DeletedByAsc = 'DELETED_BY_ASC',
  DeletedByDesc = 'DELETED_BY_DESC',
  DeletionAsc = 'DELETION_ASC',
  DeletionDesc = 'DELETION_DESC',
  DiscountAsc = 'DISCOUNT_ASC',
  DiscountDesc = 'DISCOUNT_DESC',
  KeyAsc = 'KEY_ASC',
  KeyDesc = 'KEY_DESC',
  Natural = 'NATURAL',
  PriceAsc = 'PRICE_ASC',
  PriceDesc = 'PRICE_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ProductByProductKeyCodeAsc = 'PRODUCT_BY_PRODUCT_KEY__CODE_ASC',
  ProductByProductKeyCodeDesc = 'PRODUCT_BY_PRODUCT_KEY__CODE_DESC',
  ProductByProductKeyCreatedAtAsc = 'PRODUCT_BY_PRODUCT_KEY__CREATED_AT_ASC',
  ProductByProductKeyCreatedAtDesc = 'PRODUCT_BY_PRODUCT_KEY__CREATED_AT_DESC',
  ProductByProductKeyCreatedByAsc = 'PRODUCT_BY_PRODUCT_KEY__CREATED_BY_ASC',
  ProductByProductKeyCreatedByDesc = 'PRODUCT_BY_PRODUCT_KEY__CREATED_BY_DESC',
  ProductByProductKeyDeletedAtAsc = 'PRODUCT_BY_PRODUCT_KEY__DELETED_AT_ASC',
  ProductByProductKeyDeletedAtDesc = 'PRODUCT_BY_PRODUCT_KEY__DELETED_AT_DESC',
  ProductByProductKeyDeletedByAsc = 'PRODUCT_BY_PRODUCT_KEY__DELETED_BY_ASC',
  ProductByProductKeyDeletedByDesc = 'PRODUCT_BY_PRODUCT_KEY__DELETED_BY_DESC',
  ProductByProductKeyDeletionAsc = 'PRODUCT_BY_PRODUCT_KEY__DELETION_ASC',
  ProductByProductKeyDeletionDesc = 'PRODUCT_BY_PRODUCT_KEY__DELETION_DESC',
  ProductByProductKeyDescriptionAsc = 'PRODUCT_BY_PRODUCT_KEY__DESCRIPTION_ASC',
  ProductByProductKeyDescriptionDesc = 'PRODUCT_BY_PRODUCT_KEY__DESCRIPTION_DESC',
  ProductByProductKeyKeyAsc = 'PRODUCT_BY_PRODUCT_KEY__KEY_ASC',
  ProductByProductKeyKeyDesc = 'PRODUCT_BY_PRODUCT_KEY__KEY_DESC',
  ProductByProductKeyNameAsc = 'PRODUCT_BY_PRODUCT_KEY__NAME_ASC',
  ProductByProductKeyNameDesc = 'PRODUCT_BY_PRODUCT_KEY__NAME_DESC',
  ProductByProductKeyPriceAsc = 'PRODUCT_BY_PRODUCT_KEY__PRICE_ASC',
  ProductByProductKeyPriceDesc = 'PRODUCT_BY_PRODUCT_KEY__PRICE_DESC',
  ProductByProductKeyTenantIdAsc = 'PRODUCT_BY_PRODUCT_KEY__TENANT_ID_ASC',
  ProductByProductKeyTenantIdDesc = 'PRODUCT_BY_PRODUCT_KEY__TENANT_ID_DESC',
  ProductByProductKeyTypeAsc = 'PRODUCT_BY_PRODUCT_KEY__TYPE_ASC',
  ProductByProductKeyTypeDesc = 'PRODUCT_BY_PRODUCT_KEY__TYPE_DESC',
  ProductByProductKeyUpdatedAtAsc = 'PRODUCT_BY_PRODUCT_KEY__UPDATED_AT_ASC',
  ProductByProductKeyUpdatedAtDesc = 'PRODUCT_BY_PRODUCT_KEY__UPDATED_AT_DESC',
  ProductByProductKeyUpdatedByAsc = 'PRODUCT_BY_PRODUCT_KEY__UPDATED_BY_ASC',
  ProductByProductKeyUpdatedByDesc = 'PRODUCT_BY_PRODUCT_KEY__UPDATED_BY_DESC',
  ProductKeyAsc = 'PRODUCT_KEY_ASC',
  ProductKeyDesc = 'PRODUCT_KEY_DESC',
  QuantityAsc = 'QUANTITY_ASC',
  QuantityDesc = 'QUANTITY_DESC',
  SinDetalleHuespedesJsonAsc = 'SIN_DETALLE_HUESPEDES_JSON_ASC',
  SinDetalleHuespedesJsonDesc = 'SIN_DETALLE_HUESPEDES_JSON_DESC',
  SinTipoHabitacionCodigoAsc = 'SIN_TIPO_HABITACION_CODIGO_ASC',
  SinTipoHabitacionCodigoDesc = 'SIN_TIPO_HABITACION_CODIGO_DESC',
  SubtotalAsc = 'SUBTOTAL_ASC',
  SubtotalDesc = 'SUBTOTAL_DESC',
  TenantIdAsc = 'TENANT_ID_ASC',
  TenantIdDesc = 'TENANT_ID_DESC',
  TotalAsc = 'TOTAL_ASC',
  TotalDesc = 'TOTAL_DESC',
  TouristServiceOrderByTouristServiceOrderKeyCantidadHabitacionesAsc = 'TOURIST_SERVICE_ORDER_BY_TOURIST_SERVICE_ORDER_KEY__CANTIDAD_HABITACIONES_ASC',
  TouristServiceOrderByTouristServiceOrderKeyCantidadHabitacionesDesc = 'TOURIST_SERVICE_ORDER_BY_TOURIST_SERVICE_ORDER_KEY__CANTIDAD_HABITACIONES_DESC',
  TouristServiceOrderByTouristServiceOrderKeyCantidadHuespedesAsc = 'TOURIST_SERVICE_ORDER_BY_TOURIST_SERVICE_ORDER_KEY__CANTIDAD_HUESPEDES_ASC',
  TouristServiceOrderByTouristServiceOrderKeyCantidadHuespedesDesc = 'TOURIST_SERVICE_ORDER_BY_TOURIST_SERVICE_ORDER_KEY__CANTIDAD_HUESPEDES_DESC',
  TouristServiceOrderByTouristServiceOrderKeyCantidadMayoresAsc = 'TOURIST_SERVICE_ORDER_BY_TOURIST_SERVICE_ORDER_KEY__CANTIDAD_MAYORES_ASC',
  TouristServiceOrderByTouristServiceOrderKeyCantidadMayoresDesc = 'TOURIST_SERVICE_ORDER_BY_TOURIST_SERVICE_ORDER_KEY__CANTIDAD_MAYORES_DESC',
  TouristServiceOrderByTouristServiceOrderKeyCantidadMenoresAsc = 'TOURIST_SERVICE_ORDER_BY_TOURIST_SERVICE_ORDER_KEY__CANTIDAD_MENORES_ASC',
  TouristServiceOrderByTouristServiceOrderKeyCantidadMenoresDesc = 'TOURIST_SERVICE_ORDER_BY_TOURIST_SERVICE_ORDER_KEY__CANTIDAD_MENORES_DESC',
  TouristServiceOrderByTouristServiceOrderKeyCreatedAtAsc = 'TOURIST_SERVICE_ORDER_BY_TOURIST_SERVICE_ORDER_KEY__CREATED_AT_ASC',
  TouristServiceOrderByTouristServiceOrderKeyCreatedAtDesc = 'TOURIST_SERVICE_ORDER_BY_TOURIST_SERVICE_ORDER_KEY__CREATED_AT_DESC',
  TouristServiceOrderByTouristServiceOrderKeyCreatedByAsc = 'TOURIST_SERVICE_ORDER_BY_TOURIST_SERVICE_ORDER_KEY__CREATED_BY_ASC',
  TouristServiceOrderByTouristServiceOrderKeyCreatedByDesc = 'TOURIST_SERVICE_ORDER_BY_TOURIST_SERVICE_ORDER_KEY__CREATED_BY_DESC',
  TouristServiceOrderByTouristServiceOrderKeyDeletedAtAsc = 'TOURIST_SERVICE_ORDER_BY_TOURIST_SERVICE_ORDER_KEY__DELETED_AT_ASC',
  TouristServiceOrderByTouristServiceOrderKeyDeletedAtDesc = 'TOURIST_SERVICE_ORDER_BY_TOURIST_SERVICE_ORDER_KEY__DELETED_AT_DESC',
  TouristServiceOrderByTouristServiceOrderKeyDeletedByAsc = 'TOURIST_SERVICE_ORDER_BY_TOURIST_SERVICE_ORDER_KEY__DELETED_BY_ASC',
  TouristServiceOrderByTouristServiceOrderKeyDeletedByDesc = 'TOURIST_SERVICE_ORDER_BY_TOURIST_SERVICE_ORDER_KEY__DELETED_BY_DESC',
  TouristServiceOrderByTouristServiceOrderKeyDeletionAsc = 'TOURIST_SERVICE_ORDER_BY_TOURIST_SERVICE_ORDER_KEY__DELETION_ASC',
  TouristServiceOrderByTouristServiceOrderKeyDeletionDesc = 'TOURIST_SERVICE_ORDER_BY_TOURIST_SERVICE_ORDER_KEY__DELETION_DESC',
  TouristServiceOrderByTouristServiceOrderKeyFechaIngresoHospedajeAsc = 'TOURIST_SERVICE_ORDER_BY_TOURIST_SERVICE_ORDER_KEY__FECHA_INGRESO_HOSPEDAJE_ASC',
  TouristServiceOrderByTouristServiceOrderKeyFechaIngresoHospedajeDesc = 'TOURIST_SERVICE_ORDER_BY_TOURIST_SERVICE_ORDER_KEY__FECHA_INGRESO_HOSPEDAJE_DESC',
  TouristServiceOrderByTouristServiceOrderKeyGiftCardAsc = 'TOURIST_SERVICE_ORDER_BY_TOURIST_SERVICE_ORDER_KEY__GIFT_CARD_ASC',
  TouristServiceOrderByTouristServiceOrderKeyGiftCardDesc = 'TOURIST_SERVICE_ORDER_BY_TOURIST_SERVICE_ORDER_KEY__GIFT_CARD_DESC',
  TouristServiceOrderByTouristServiceOrderKeyKeyAsc = 'TOURIST_SERVICE_ORDER_BY_TOURIST_SERVICE_ORDER_KEY__KEY_ASC',
  TouristServiceOrderByTouristServiceOrderKeyKeyDesc = 'TOURIST_SERVICE_ORDER_BY_TOURIST_SERVICE_ORDER_KEY__KEY_DESC',
  TouristServiceOrderByTouristServiceOrderKeyOperadorTurismoRazonSocialAsc = 'TOURIST_SERVICE_ORDER_BY_TOURIST_SERVICE_ORDER_KEY__OPERADOR_TURISMO_RAZON_SOCIAL_ASC',
  TouristServiceOrderByTouristServiceOrderKeyOperadorTurismoRazonSocialDesc = 'TOURIST_SERVICE_ORDER_BY_TOURIST_SERVICE_ORDER_KEY__OPERADOR_TURISMO_RAZON_SOCIAL_DESC',
  TouristServiceOrderByTouristServiceOrderKeyOrderKeyAsc = 'TOURIST_SERVICE_ORDER_BY_TOURIST_SERVICE_ORDER_KEY__ORDER_KEY_ASC',
  TouristServiceOrderByTouristServiceOrderKeyOrderKeyDesc = 'TOURIST_SERVICE_ORDER_BY_TOURIST_SERVICE_ORDER_KEY__ORDER_KEY_DESC',
  TouristServiceOrderByTouristServiceOrderKeyTenantIdAsc = 'TOURIST_SERVICE_ORDER_BY_TOURIST_SERVICE_ORDER_KEY__TENANT_ID_ASC',
  TouristServiceOrderByTouristServiceOrderKeyTenantIdDesc = 'TOURIST_SERVICE_ORDER_BY_TOURIST_SERVICE_ORDER_KEY__TENANT_ID_DESC',
  TouristServiceOrderByTouristServiceOrderKeyUpdatedAtAsc = 'TOURIST_SERVICE_ORDER_BY_TOURIST_SERVICE_ORDER_KEY__UPDATED_AT_ASC',
  TouristServiceOrderByTouristServiceOrderKeyUpdatedAtDesc = 'TOURIST_SERVICE_ORDER_BY_TOURIST_SERVICE_ORDER_KEY__UPDATED_AT_DESC',
  TouristServiceOrderByTouristServiceOrderKeyUpdatedByAsc = 'TOURIST_SERVICE_ORDER_BY_TOURIST_SERVICE_ORDER_KEY__UPDATED_BY_ASC',
  TouristServiceOrderByTouristServiceOrderKeyUpdatedByDesc = 'TOURIST_SERVICE_ORDER_BY_TOURIST_SERVICE_ORDER_KEY__UPDATED_BY_DESC',
  TouristServiceOrderKeyAsc = 'TOURIST_SERVICE_ORDER_KEY_ASC',
  TouristServiceOrderKeyDesc = 'TOURIST_SERVICE_ORDER_KEY_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UpdatedByAsc = 'UPDATED_BY_ASC',
  UpdatedByDesc = 'UPDATED_BY_DESC'
}

/** A filter to be used against `TouristServiceOrder` object types. All fields are combined with a logical ‘and.’ */
export type TouristServiceOrderFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<TouristServiceOrderFilter>>;
  /** Filter by the object’s `cantidadHabitaciones` field. */
  cantidadHabitaciones?: InputMaybe<IntFilter>;
  /** Filter by the object’s `cantidadHuespedes` field. */
  cantidadHuespedes?: InputMaybe<IntFilter>;
  /** Filter by the object’s `cantidadMayores` field. */
  cantidadMayores?: InputMaybe<IntFilter>;
  /** Filter by the object’s `cantidadMenores` field. */
  cantidadMenores?: InputMaybe<IntFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy?: InputMaybe<IntFilter>;
  /** Filter by the object’s `deletedAt` field. */
  deletedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `deletedBy` field. */
  deletedBy?: InputMaybe<IntFilter>;
  /** Filter by the object’s `deletion` field. */
  deletion?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `fechaIngresoHospedaje` field. */
  fechaIngresoHospedaje?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `giftCard` field. */
  giftCard?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `key` field. */
  key?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<TouristServiceOrderFilter>;
  /** Filter by the object’s `operadorTurismoRazonSocial` field. */
  operadorTurismoRazonSocial?: InputMaybe<StringFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<TouristServiceOrderFilter>>;
  /** Filter by the object’s `orderKey` field. */
  orderKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `tenantId` field. */
  tenantId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `updatedBy` field. */
  updatedBy?: InputMaybe<IntFilter>;
};

/** An input for mutations affecting `TouristServiceOrder` */
export type TouristServiceOrderInput = {
  cantidadHabitaciones?: InputMaybe<Scalars['Int']>;
  cantidadHuespedes?: InputMaybe<Scalars['Int']>;
  cantidadMayores?: InputMaybe<Scalars['Int']>;
  cantidadMenores?: InputMaybe<Scalars['Int']>;
  deletion?: InputMaybe<Scalars['Boolean']>;
  fechaIngresoHospedaje?: InputMaybe<Scalars['Datetime']>;
  giftCard?: InputMaybe<Scalars['BigFloat']>;
  operadorTurismoRazonSocial?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<FkTouristServiceOrderToOrderInput>;
  orderKey?: InputMaybe<Scalars['String']>;
  touristServiceOrderDetails?: InputMaybe<FkTouristServiceOrderDetailToTouristServiceOrderInverseInput>;
};

/** The globally unique `ID` look up for the row to connect. */
export type TouristServiceOrderNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `touristServiceOrder` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type TouristServiceOrderNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `touristServiceOrder` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type TouristServiceOrderOnTouristServiceOrderDetailForFkTouristServiceOrderDetailToTouristServiceOrderNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `touristServiceOrderDetail` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `touristServiceOrderDetail` being updated. */
  patch: TouristServiceOrderDetailPatch;
};

/** The fields on `touristServiceOrder` to look up the row to update. */
export type TouristServiceOrderOnTouristServiceOrderDetailForFkTouristServiceOrderDetailToTouristServiceOrderUsingPkTouristServiceOrderUpdate = {
  key: Scalars['String'];
  /** An object where the defined keys will be set on the `touristServiceOrder` being updated. */
  patch: UpdateTouristServiceOrderOnTouristServiceOrderDetailForFkTouristServiceOrderDetailToTouristServiceOrderPatch;
};

/** The fields on `touristServiceOrder` to look up the row to update. */
export type TouristServiceOrderOnTouristServiceOrderDetailForFkTouristServiceOrderDetailToTouristServiceOrderUsingUkTouristServiceOrderOrderKeyUpdate = {
  orderKey: Scalars['String'];
  /** An object where the defined keys will be set on the `touristServiceOrder` being updated. */
  patch: UpdateTouristServiceOrderOnTouristServiceOrderDetailForFkTouristServiceOrderDetailToTouristServiceOrderPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type TouristServiceOrderOnTouristServiceOrderForFkTouristServiceOrderToOrderNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `order` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `order` being updated. */
  patch: OrderPatch;
};

/** The fields on `touristServiceOrder` to look up the row to update. */
export type TouristServiceOrderOnTouristServiceOrderForFkTouristServiceOrderToOrderUsingPkTouristServiceOrderUpdate = {
  key: Scalars['String'];
  /** An object where the defined keys will be set on the `touristServiceOrder` being updated. */
  patch: UpdateTouristServiceOrderOnTouristServiceOrderForFkTouristServiceOrderToOrderPatch;
};

/** The fields on `touristServiceOrder` to look up the row to update. */
export type TouristServiceOrderOnTouristServiceOrderForFkTouristServiceOrderToOrderUsingUkTouristServiceOrderOrderKeyUpdate = {
  orderKey: Scalars['String'];
  /** An object where the defined keys will be set on the `touristServiceOrder` being updated. */
  patch: UpdateTouristServiceOrderOnTouristServiceOrderForFkTouristServiceOrderToOrderPatch;
};

/** Represents an update to a `TouristServiceOrder`. Fields that are set will be updated. */
export type TouristServiceOrderPatch = {
  cantidadHabitaciones?: InputMaybe<Scalars['Int']>;
  cantidadHuespedes?: InputMaybe<Scalars['Int']>;
  cantidadMayores?: InputMaybe<Scalars['Int']>;
  cantidadMenores?: InputMaybe<Scalars['Int']>;
  deletion?: InputMaybe<Scalars['Boolean']>;
  fechaIngresoHospedaje?: InputMaybe<Scalars['Datetime']>;
  giftCard?: InputMaybe<Scalars['BigFloat']>;
  operadorTurismoRazonSocial?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<FkTouristServiceOrderToOrderInput>;
  orderKey?: InputMaybe<Scalars['String']>;
  touristServiceOrderDetails?: InputMaybe<FkTouristServiceOrderDetailToTouristServiceOrderInverseInput>;
};

/** The fields on `touristServiceOrder` to look up the row to connect. */
export type TouristServiceOrderPkTouristServiceOrderConnect = {
  key: Scalars['String'];
};

/** The fields on `touristServiceOrder` to look up the row to delete. */
export type TouristServiceOrderPkTouristServiceOrderDelete = {
  key: Scalars['String'];
};

/** The fields on `touristServiceOrder` to look up the row to connect. */
export type TouristServiceOrderUkTouristServiceOrderOrderKeyConnect = {
  orderKey: Scalars['String'];
};

/** The fields on `touristServiceOrder` to look up the row to delete. */
export type TouristServiceOrderUkTouristServiceOrderOrderKeyDelete = {
  orderKey: Scalars['String'];
};

/** A connection to a list of `TouristServiceOrder` values. */
export type TouristServiceOrdersConnection = {
  __typename?: 'TouristServiceOrdersConnection';
  /** A list of edges which contains the `TouristServiceOrder` and cursor to aid in pagination. */
  edges: Array<TouristServiceOrdersEdge>;
  /** A list of `TouristServiceOrder` objects. */
  nodes: Array<Maybe<TouristServiceOrder>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `TouristServiceOrder` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `TouristServiceOrder` edge in the connection. */
export type TouristServiceOrdersEdge = {
  __typename?: 'TouristServiceOrdersEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `TouristServiceOrder` at the end of the edge. */
  node?: Maybe<TouristServiceOrder>;
};

/** Methods to use when ordering `TouristServiceOrder`. */
export enum TouristServiceOrdersOrderBy {
  CantidadHabitacionesAsc = 'CANTIDAD_HABITACIONES_ASC',
  CantidadHabitacionesDesc = 'CANTIDAD_HABITACIONES_DESC',
  CantidadHuespedesAsc = 'CANTIDAD_HUESPEDES_ASC',
  CantidadHuespedesDesc = 'CANTIDAD_HUESPEDES_DESC',
  CantidadMayoresAsc = 'CANTIDAD_MAYORES_ASC',
  CantidadMayoresDesc = 'CANTIDAD_MAYORES_DESC',
  CantidadMenoresAsc = 'CANTIDAD_MENORES_ASC',
  CantidadMenoresDesc = 'CANTIDAD_MENORES_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  DeletedAtAsc = 'DELETED_AT_ASC',
  DeletedAtDesc = 'DELETED_AT_DESC',
  DeletedByAsc = 'DELETED_BY_ASC',
  DeletedByDesc = 'DELETED_BY_DESC',
  DeletionAsc = 'DELETION_ASC',
  DeletionDesc = 'DELETION_DESC',
  FechaIngresoHospedajeAsc = 'FECHA_INGRESO_HOSPEDAJE_ASC',
  FechaIngresoHospedajeDesc = 'FECHA_INGRESO_HOSPEDAJE_DESC',
  GiftCardAsc = 'GIFT_CARD_ASC',
  GiftCardDesc = 'GIFT_CARD_DESC',
  KeyAsc = 'KEY_ASC',
  KeyDesc = 'KEY_DESC',
  Natural = 'NATURAL',
  OperadorTurismoRazonSocialAsc = 'OPERADOR_TURISMO_RAZON_SOCIAL_ASC',
  OperadorTurismoRazonSocialDesc = 'OPERADOR_TURISMO_RAZON_SOCIAL_DESC',
  OrderByOrderKeyChangeAmountAsc = 'ORDER_BY_ORDER_KEY__CHANGE_AMOUNT_ASC',
  OrderByOrderKeyChangeAmountDesc = 'ORDER_BY_ORDER_KEY__CHANGE_AMOUNT_DESC',
  OrderByOrderKeyClientKeyAsc = 'ORDER_BY_ORDER_KEY__CLIENT_KEY_ASC',
  OrderByOrderKeyClientKeyDesc = 'ORDER_BY_ORDER_KEY__CLIENT_KEY_DESC',
  OrderByOrderKeyCreatedAtAsc = 'ORDER_BY_ORDER_KEY__CREATED_AT_ASC',
  OrderByOrderKeyCreatedAtDesc = 'ORDER_BY_ORDER_KEY__CREATED_AT_DESC',
  OrderByOrderKeyCreatedByAsc = 'ORDER_BY_ORDER_KEY__CREATED_BY_ASC',
  OrderByOrderKeyCreatedByDesc = 'ORDER_BY_ORDER_KEY__CREATED_BY_DESC',
  OrderByOrderKeyDeletedAtAsc = 'ORDER_BY_ORDER_KEY__DELETED_AT_ASC',
  OrderByOrderKeyDeletedAtDesc = 'ORDER_BY_ORDER_KEY__DELETED_AT_DESC',
  OrderByOrderKeyDeletedByAsc = 'ORDER_BY_ORDER_KEY__DELETED_BY_ASC',
  OrderByOrderKeyDeletedByDesc = 'ORDER_BY_ORDER_KEY__DELETED_BY_DESC',
  OrderByOrderKeyDeletionAsc = 'ORDER_BY_ORDER_KEY__DELETION_ASC',
  OrderByOrderKeyDeletionDesc = 'ORDER_BY_ORDER_KEY__DELETION_DESC',
  OrderByOrderKeyDiscountAsc = 'ORDER_BY_ORDER_KEY__DISCOUNT_ASC',
  OrderByOrderKeyDiscountDesc = 'ORDER_BY_ORDER_KEY__DISCOUNT_DESC',
  OrderByOrderKeyEmailAsc = 'ORDER_BY_ORDER_KEY__EMAIL_ASC',
  OrderByOrderKeyEmailDesc = 'ORDER_BY_ORDER_KEY__EMAIL_DESC',
  OrderByOrderKeyKeyAsc = 'ORDER_BY_ORDER_KEY__KEY_ASC',
  OrderByOrderKeyKeyDesc = 'ORDER_BY_ORDER_KEY__KEY_DESC',
  OrderByOrderKeyPaymentAmountAsc = 'ORDER_BY_ORDER_KEY__PAYMENT_AMOUNT_ASC',
  OrderByOrderKeyPaymentAmountDesc = 'ORDER_BY_ORDER_KEY__PAYMENT_AMOUNT_DESC',
  OrderByOrderKeyPhoneNumberAsc = 'ORDER_BY_ORDER_KEY__PHONE_NUMBER_ASC',
  OrderByOrderKeyPhoneNumberDesc = 'ORDER_BY_ORDER_KEY__PHONE_NUMBER_DESC',
  OrderByOrderKeyPointOfSaleKeyAsc = 'ORDER_BY_ORDER_KEY__POINT_OF_SALE_KEY_ASC',
  OrderByOrderKeyPointOfSaleKeyDesc = 'ORDER_BY_ORDER_KEY__POINT_OF_SALE_KEY_DESC',
  OrderByOrderKeyQuantityAsc = 'ORDER_BY_ORDER_KEY__QUANTITY_ASC',
  OrderByOrderKeyQuantityDesc = 'ORDER_BY_ORDER_KEY__QUANTITY_DESC',
  OrderByOrderKeyStatusAsc = 'ORDER_BY_ORDER_KEY__STATUS_ASC',
  OrderByOrderKeyStatusDesc = 'ORDER_BY_ORDER_KEY__STATUS_DESC',
  OrderByOrderKeySubtotalAsc = 'ORDER_BY_ORDER_KEY__SUBTOTAL_ASC',
  OrderByOrderKeySubtotalDesc = 'ORDER_BY_ORDER_KEY__SUBTOTAL_DESC',
  OrderByOrderKeyTenantIdAsc = 'ORDER_BY_ORDER_KEY__TENANT_ID_ASC',
  OrderByOrderKeyTenantIdDesc = 'ORDER_BY_ORDER_KEY__TENANT_ID_DESC',
  OrderByOrderKeyTotalAsc = 'ORDER_BY_ORDER_KEY__TOTAL_ASC',
  OrderByOrderKeyTotalDesc = 'ORDER_BY_ORDER_KEY__TOTAL_DESC',
  OrderByOrderKeyUpdatedAtAsc = 'ORDER_BY_ORDER_KEY__UPDATED_AT_ASC',
  OrderByOrderKeyUpdatedAtDesc = 'ORDER_BY_ORDER_KEY__UPDATED_AT_DESC',
  OrderByOrderKeyUpdatedByAsc = 'ORDER_BY_ORDER_KEY__UPDATED_BY_ASC',
  OrderByOrderKeyUpdatedByDesc = 'ORDER_BY_ORDER_KEY__UPDATED_BY_DESC',
  OrderKeyAsc = 'ORDER_KEY_ASC',
  OrderKeyDesc = 'ORDER_KEY_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  TenantIdAsc = 'TENANT_ID_ASC',
  TenantIdDesc = 'TENANT_ID_DESC',
  TouristServiceOrderDetailsByTouristServiceOrderKeyCountAsc = 'TOURIST_SERVICE_ORDER_DETAILS_BY_TOURIST_SERVICE_ORDER_KEY__COUNT_ASC',
  TouristServiceOrderDetailsByTouristServiceOrderKeyCountDesc = 'TOURIST_SERVICE_ORDER_DETAILS_BY_TOURIST_SERVICE_ORDER_KEY__COUNT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UpdatedByAsc = 'UPDATED_BY_ASC',
  UpdatedByDesc = 'UPDATED_BY_DESC'
}

/** All input for the `updateBranchOfficeByNodeId` mutation. */
export type UpdateBranchOfficeByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `BranchOffice` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `BranchOffice` being updated. */
  patch: BranchOfficePatch;
};

/** All input for the `updateBranchOffice` mutation. */
export type UpdateBranchOfficeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Identificador */
  key: Scalars['String'];
  /** An object where the defined keys will be set on the `BranchOffice` being updated. */
  patch: BranchOfficePatch;
};

/** The output of our update `BranchOffice` mutation. */
export type UpdateBranchOfficePayload = {
  __typename?: 'UpdateBranchOfficePayload';
  /** The `BranchOffice` that was updated by this mutation. */
  branchOffice?: Maybe<BranchOffice>;
  /** An edge for our `BranchOffice`. May be used by Relay 1. */
  branchOfficeEdge?: Maybe<BranchOfficesEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `updateClientByNodeId` mutation. */
export type UpdateClientByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Client` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Client` being updated. */
  patch: ClientPatch;
};

/** All input for the `updateClientHomologationByClientKey` mutation. */
export type UpdateClientHomologationByClientKeyInput = {
  clientKey: Scalars['String'];
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `ClientHomologation` being updated. */
  patch: ClientHomologationPatch;
};

/** All input for the `updateClientHomologationByNodeId` mutation. */
export type UpdateClientHomologationByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `ClientHomologation` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `ClientHomologation` being updated. */
  patch: ClientHomologationPatch;
};

/** All input for the `updateClientHomologation` mutation. */
export type UpdateClientHomologationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Identificador */
  key: Scalars['String'];
  /** An object where the defined keys will be set on the `ClientHomologation` being updated. */
  patch: ClientHomologationPatch;
};

/** The output of our update `ClientHomologation` mutation. */
export type UpdateClientHomologationPayload = {
  __typename?: 'UpdateClientHomologationPayload';
  /** Reads a single `Client` that is related to this `ClientHomologation`. */
  client?: Maybe<Client>;
  /** The `ClientHomologation` that was updated by this mutation. */
  clientHomologation?: Maybe<ClientHomologation>;
  /** An edge for our `ClientHomologation`. May be used by Relay 1. */
  clientHomologationEdge?: Maybe<ClientHomologationsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `updateClient` mutation. */
export type UpdateClientInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  key: Scalars['String'];
  /** An object where the defined keys will be set on the `Client` being updated. */
  patch: ClientPatch;
};

/** The output of our update `Client` mutation. */
export type UpdateClientPayload = {
  __typename?: 'UpdateClientPayload';
  /** The `Client` that was updated by this mutation. */
  client?: Maybe<Client>;
  /** An edge for our `Client`. May be used by Relay 1. */
  clientEdge?: Maybe<ClientsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `updateExportServiceOrderByNodeId` mutation. */
export type UpdateExportServiceOrderByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `ExportServiceOrder` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `ExportServiceOrder` being updated. */
  patch: ExportServiceOrderPatch;
};

/** All input for the `updateExportServiceOrderByOrderKey` mutation. */
export type UpdateExportServiceOrderByOrderKeyInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  orderKey: Scalars['String'];
  /** An object where the defined keys will be set on the `ExportServiceOrder` being updated. */
  patch: ExportServiceOrderPatch;
};

/** All input for the `updateExportServiceOrderDetailByNodeId` mutation. */
export type UpdateExportServiceOrderDetailByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `ExportServiceOrderDetail` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `ExportServiceOrderDetail` being updated. */
  patch: ExportServiceOrderDetailPatch;
};

/** All input for the `updateExportServiceOrderDetail` mutation. */
export type UpdateExportServiceOrderDetailInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  key: Scalars['String'];
  /** An object where the defined keys will be set on the `ExportServiceOrderDetail` being updated. */
  patch: ExportServiceOrderDetailPatch;
};

/** The output of our update `ExportServiceOrderDetail` mutation. */
export type UpdateExportServiceOrderDetailPayload = {
  __typename?: 'UpdateExportServiceOrderDetailPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `ExportServiceOrder` that is related to this `ExportServiceOrderDetail`. */
  exportServiceOrder?: Maybe<ExportServiceOrder>;
  /** The `ExportServiceOrderDetail` that was updated by this mutation. */
  exportServiceOrderDetail?: Maybe<ExportServiceOrderDetail>;
  /** An edge for our `ExportServiceOrderDetail`. May be used by Relay 1. */
  exportServiceOrderDetailEdge?: Maybe<ExportServiceOrderDetailsEdge>;
  /** Reads a single `Product` that is related to this `ExportServiceOrderDetail`. */
  productService?: Maybe<Product>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our update `ExportServiceOrderDetail` mutation. */
export type UpdateExportServiceOrderDetailPayloadExportServiceOrderDetailEdgeArgs = {
  orderBy?: InputMaybe<Array<ExportServiceOrderDetailsOrderBy>>;
};

/** All input for the `updateExportServiceOrder` mutation. */
export type UpdateExportServiceOrderInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  key: Scalars['String'];
  /** An object where the defined keys will be set on the `ExportServiceOrder` being updated. */
  patch: ExportServiceOrderPatch;
};

/** The output of our update `ExportServiceOrder` mutation. */
export type UpdateExportServiceOrderPayload = {
  __typename?: 'UpdateExportServiceOrderPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `ExportServiceOrder` that was updated by this mutation. */
  exportServiceOrder?: Maybe<ExportServiceOrder>;
  /** An edge for our `ExportServiceOrder`. May be used by Relay 1. */
  exportServiceOrderEdge?: Maybe<ExportServiceOrdersEdge>;
  /** Reads a single `Order` that is related to this `ExportServiceOrder`. */
  order?: Maybe<Order>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our update `ExportServiceOrder` mutation. */
export type UpdateExportServiceOrderPayloadExportServiceOrderEdgeArgs = {
  orderBy?: InputMaybe<Array<ExportServiceOrdersOrderBy>>;
};

/** All input for the `updateInsuranceOrderByNodeId` mutation. */
export type UpdateInsuranceOrderByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `InsuranceOrder` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `InsuranceOrder` being updated. */
  patch: InsuranceOrderPatch;
};

/** All input for the `updateInsuranceOrderByOrderKey` mutation. */
export type UpdateInsuranceOrderByOrderKeyInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  orderKey: Scalars['String'];
  /** An object where the defined keys will be set on the `InsuranceOrder` being updated. */
  patch: InsuranceOrderPatch;
};

/** All input for the `updateInsuranceOrderDetailByNodeId` mutation. */
export type UpdateInsuranceOrderDetailByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `InsuranceOrderDetail` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `InsuranceOrderDetail` being updated. */
  patch: InsuranceOrderDetailPatch;
};

/** All input for the `updateInsuranceOrderDetail` mutation. */
export type UpdateInsuranceOrderDetailInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  key: Scalars['String'];
  /** An object where the defined keys will be set on the `InsuranceOrderDetail` being updated. */
  patch: InsuranceOrderDetailPatch;
};

/** The output of our update `InsuranceOrderDetail` mutation. */
export type UpdateInsuranceOrderDetailPayload = {
  __typename?: 'UpdateInsuranceOrderDetailPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `InsuranceOrder` that is related to this `InsuranceOrderDetail`. */
  insuranceOrder?: Maybe<InsuranceOrder>;
  /** The `InsuranceOrderDetail` that was updated by this mutation. */
  insuranceOrderDetail?: Maybe<InsuranceOrderDetail>;
  /** An edge for our `InsuranceOrderDetail`. May be used by Relay 1. */
  insuranceOrderDetailEdge?: Maybe<InsuranceOrderDetailsEdge>;
  /** Reads a single `Product` that is related to this `InsuranceOrderDetail`. */
  productService?: Maybe<Product>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our update `InsuranceOrderDetail` mutation. */
export type UpdateInsuranceOrderDetailPayloadInsuranceOrderDetailEdgeArgs = {
  orderBy?: InputMaybe<Array<InsuranceOrderDetailsOrderBy>>;
};

/** All input for the `updateInsuranceOrder` mutation. */
export type UpdateInsuranceOrderInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  key: Scalars['String'];
  /** An object where the defined keys will be set on the `InsuranceOrder` being updated. */
  patch: InsuranceOrderPatch;
};

/** The output of our update `InsuranceOrder` mutation. */
export type UpdateInsuranceOrderPayload = {
  __typename?: 'UpdateInsuranceOrderPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `InsuranceOrder` that was updated by this mutation. */
  insuranceOrder?: Maybe<InsuranceOrder>;
  /** An edge for our `InsuranceOrder`. May be used by Relay 1. */
  insuranceOrderEdge?: Maybe<InsuranceOrdersEdge>;
  /** Reads a single `Order` that is related to this `InsuranceOrder`. */
  order?: Maybe<Order>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our update `InsuranceOrder` mutation. */
export type UpdateInsuranceOrderPayloadInsuranceOrderEdgeArgs = {
  orderBy?: InputMaybe<Array<InsuranceOrdersOrderBy>>;
};

/** All input for the `updateOrderByNodeId` mutation. */
export type UpdateOrderByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Order` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Order` being updated. */
  patch: OrderPatch;
};

/** All input for the `updateOrderHomologationByNodeId` mutation. */
export type UpdateOrderHomologationByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `OrderHomologation` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `OrderHomologation` being updated. */
  patch: OrderHomologationPatch;
};

/** All input for the `updateOrderHomologationByOrderKey` mutation. */
export type UpdateOrderHomologationByOrderKeyInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  orderKey: Scalars['String'];
  /** An object where the defined keys will be set on the `OrderHomologation` being updated. */
  patch: OrderHomologationPatch;
};

/** All input for the `updateOrderHomologation` mutation. */
export type UpdateOrderHomologationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Identificador */
  key: Scalars['String'];
  /** An object where the defined keys will be set on the `OrderHomologation` being updated. */
  patch: OrderHomologationPatch;
};

/** The output of our update `OrderHomologation` mutation. */
export type UpdateOrderHomologationPayload = {
  __typename?: 'UpdateOrderHomologationPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Order` that is related to this `OrderHomologation`. */
  order?: Maybe<Order>;
  /** The `OrderHomologation` that was updated by this mutation. */
  orderHomologation?: Maybe<OrderHomologation>;
  /** An edge for our `OrderHomologation`. May be used by Relay 1. */
  orderHomologationEdge?: Maybe<OrderHomologationsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `updateOrder` mutation. */
export type UpdateOrderInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Identificador */
  key: Scalars['String'];
  /** An object where the defined keys will be set on the `Order` being updated. */
  patch: OrderPatch;
};

/** The output of our update `Order` mutation. */
export type UpdateOrderPayload = {
  __typename?: 'UpdateOrderPayload';
  /** Reads a single `Client` that is related to this `Order`. */
  client?: Maybe<Client>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Order` that was updated by this mutation. */
  order?: Maybe<Order>;
  /** An edge for our `Order`. May be used by Relay 1. */
  orderEdge?: Maybe<OrdersEdge>;
  /** Reads a single `PointOfSale` that is related to this `Order`. */
  pointOfSale?: Maybe<PointOfSale>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `updatePersonByNodeId` mutation. */
export type UpdatePersonByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Person` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Person` being updated. */
  patch: PersonPatch;
};

/** All input for the `updatePerson` mutation. */
export type UpdatePersonInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  key: Scalars['String'];
  /** An object where the defined keys will be set on the `Person` being updated. */
  patch: PersonPatch;
};

/** The output of our update `Person` mutation. */
export type UpdatePersonPayload = {
  __typename?: 'UpdatePersonPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Person` that was updated by this mutation. */
  person?: Maybe<Person>;
  /** An edge for our `Person`. May be used by Relay 1. */
  personEdge?: Maybe<PeopleEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `updatePointOfSaleUserByNodeId` mutation. */
export type UpdatePointOfSaleUserByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `PointOfSaleUser` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `PointOfSaleUser` being updated. */
  patch: PointOfSaleUserPatch;
};

/** All input for the `updatePointOfSaleUser` mutation. */
export type UpdatePointOfSaleUserInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  key: Scalars['String'];
  /** An object where the defined keys will be set on the `PointOfSaleUser` being updated. */
  patch: PointOfSaleUserPatch;
};

/** The output of our update `PointOfSaleUser` mutation. */
export type UpdatePointOfSaleUserPayload = {
  __typename?: 'UpdatePointOfSaleUserPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `PointOfSale` that is related to this `PointOfSaleUser`. */
  pointOfSale?: Maybe<PointOfSale>;
  /** The `PointOfSaleUser` that was updated by this mutation. */
  pointOfSaleUser?: Maybe<PointOfSaleUser>;
  /** An edge for our `PointOfSaleUser`. May be used by Relay 1. */
  pointOfSaleUserEdge?: Maybe<PointOfSaleUsersEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `PointOfSaleUser`. */
  user?: Maybe<User>;
};

/** All input for the `updateProductByNodeId` mutation. */
export type UpdateProductByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Product` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Product` being updated. */
  patch: ProductPatch;
};

/** All input for the `updateProductHomologationByNodeId` mutation. */
export type UpdateProductHomologationByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `ProductHomologation` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `ProductHomologation` being updated. */
  patch: ProductHomologationPatch;
};

/** All input for the `updateProductHomologationByProductKey` mutation. */
export type UpdateProductHomologationByProductKeyInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `ProductHomologation` being updated. */
  patch: ProductHomologationPatch;
  productKey: Scalars['String'];
};

/** All input for the `updateProductHomologation` mutation. */
export type UpdateProductHomologationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Identificador */
  key: Scalars['String'];
  /** An object where the defined keys will be set on the `ProductHomologation` being updated. */
  patch: ProductHomologationPatch;
};

/** The output of our update `ProductHomologation` mutation. */
export type UpdateProductHomologationPayload = {
  __typename?: 'UpdateProductHomologationPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `ProductHomologation` that was updated by this mutation. */
  productHomologation?: Maybe<ProductHomologation>;
  /** An edge for our `ProductHomologation`. May be used by Relay 1. */
  productHomologationEdge?: Maybe<ProductHomologationsEdge>;
  /** Reads a single `Product` that is related to this `ProductHomologation`. */
  productService?: Maybe<Product>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `updateProduct` mutation. */
export type UpdateProductInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Identificador */
  key: Scalars['String'];
  /** An object where the defined keys will be set on the `Product` being updated. */
  patch: ProductPatch;
};

/** The output of our update `Product` mutation. */
export type UpdateProductPayload = {
  __typename?: 'UpdateProductPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Product` that was updated by this mutation. */
  product?: Maybe<Product>;
  /** An edge for our `Product`. May be used by Relay 1. */
  productEdge?: Maybe<ProductsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `updateRentalOrderByNodeId` mutation. */
export type UpdateRentalOrderByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `RentalOrder` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `RentalOrder` being updated. */
  patch: RentalOrderPatch;
};

/** All input for the `updateRentalOrderByOrderKey` mutation. */
export type UpdateRentalOrderByOrderKeyInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  orderKey: Scalars['String'];
  /** An object where the defined keys will be set on the `RentalOrder` being updated. */
  patch: RentalOrderPatch;
};

/** All input for the `updateRentalOrderDetailByNodeId` mutation. */
export type UpdateRentalOrderDetailByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `RentalOrderDetail` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `RentalOrderDetail` being updated. */
  patch: RentalOrderDetailPatch;
};

/** All input for the `updateRentalOrderDetail` mutation. */
export type UpdateRentalOrderDetailInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  key: Scalars['String'];
  /** An object where the defined keys will be set on the `RentalOrderDetail` being updated. */
  patch: RentalOrderDetailPatch;
};

/** The output of our update `RentalOrderDetail` mutation. */
export type UpdateRentalOrderDetailPayload = {
  __typename?: 'UpdateRentalOrderDetailPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Product` that is related to this `RentalOrderDetail`. */
  productService?: Maybe<Product>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `RentalOrder` that is related to this `RentalOrderDetail`. */
  rentalOrder?: Maybe<RentalOrder>;
  /** The `RentalOrderDetail` that was updated by this mutation. */
  rentalOrderDetail?: Maybe<RentalOrderDetail>;
  /** An edge for our `RentalOrderDetail`. May be used by Relay 1. */
  rentalOrderDetailEdge?: Maybe<RentalOrderDetailsEdge>;
};


/** The output of our update `RentalOrderDetail` mutation. */
export type UpdateRentalOrderDetailPayloadRentalOrderDetailEdgeArgs = {
  orderBy?: InputMaybe<Array<RentalOrderDetailsOrderBy>>;
};

/** All input for the `updateRentalOrder` mutation. */
export type UpdateRentalOrderInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  key: Scalars['String'];
  /** An object where the defined keys will be set on the `RentalOrder` being updated. */
  patch: RentalOrderPatch;
};

/** The output of our update `RentalOrder` mutation. */
export type UpdateRentalOrderPayload = {
  __typename?: 'UpdateRentalOrderPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Order` that is related to this `RentalOrder`. */
  order?: Maybe<Order>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `RentalOrder` that was updated by this mutation. */
  rentalOrder?: Maybe<RentalOrder>;
  /** An edge for our `RentalOrder`. May be used by Relay 1. */
  rentalOrderEdge?: Maybe<RentalOrdersEdge>;
};


/** The output of our update `RentalOrder` mutation. */
export type UpdateRentalOrderPayloadRentalOrderEdgeArgs = {
  orderBy?: InputMaybe<Array<RentalOrdersOrderBy>>;
};

/** All input for the `updateSaleOrderByNodeId` mutation. */
export type UpdateSaleOrderByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `SaleOrder` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `SaleOrder` being updated. */
  patch: SaleOrderPatch;
};

/** All input for the `updateSaleOrderByOrderKey` mutation. */
export type UpdateSaleOrderByOrderKeyInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  orderKey: Scalars['String'];
  /** An object where the defined keys will be set on the `SaleOrder` being updated. */
  patch: SaleOrderPatch;
};

/** All input for the `updateSaleOrderDetailByNodeId` mutation. */
export type UpdateSaleOrderDetailByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `SaleOrderDetail` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `SaleOrderDetail` being updated. */
  patch: SaleOrderDetailPatch;
};

/** All input for the `updateSaleOrderDetail` mutation. */
export type UpdateSaleOrderDetailInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Identificador */
  key: Scalars['String'];
  /** An object where the defined keys will be set on the `SaleOrderDetail` being updated. */
  patch: SaleOrderDetailPatch;
};

/** The output of our update `SaleOrderDetail` mutation. */
export type UpdateSaleOrderDetailPayload = {
  __typename?: 'UpdateSaleOrderDetailPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Product` that is related to this `SaleOrderDetail`. */
  productService?: Maybe<Product>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `SaleOrder` that is related to this `SaleOrderDetail`. */
  saleOrder?: Maybe<SaleOrder>;
  /** The `SaleOrderDetail` that was updated by this mutation. */
  saleOrderDetail?: Maybe<SaleOrderDetail>;
  /** An edge for our `SaleOrderDetail`. May be used by Relay 1. */
  saleOrderDetailEdge?: Maybe<SaleOrderDetailsEdge>;
};

/** All input for the `updateSaleOrder` mutation. */
export type UpdateSaleOrderInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Identificador */
  key: Scalars['String'];
  /** An object where the defined keys will be set on the `SaleOrder` being updated. */
  patch: SaleOrderPatch;
};

/** The output of our update `SaleOrder` mutation. */
export type UpdateSaleOrderPayload = {
  __typename?: 'UpdateSaleOrderPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Order` that is related to this `SaleOrder`. */
  order?: Maybe<Order>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `SaleOrder` that was updated by this mutation. */
  saleOrder?: Maybe<SaleOrder>;
  /** An edge for our `SaleOrder`. May be used by Relay 1. */
  saleOrderEdge?: Maybe<SaleOrdersEdge>;
};

/** All input for the `updateTouristServiceOrderByNodeId` mutation. */
export type UpdateTouristServiceOrderByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `TouristServiceOrder` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `TouristServiceOrder` being updated. */
  patch: TouristServiceOrderPatch;
};

/** All input for the `updateTouristServiceOrderByOrderKey` mutation. */
export type UpdateTouristServiceOrderByOrderKeyInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  orderKey: Scalars['String'];
  /** An object where the defined keys will be set on the `TouristServiceOrder` being updated. */
  patch: TouristServiceOrderPatch;
};

/** All input for the `updateTouristServiceOrderDetailByNodeId` mutation. */
export type UpdateTouristServiceOrderDetailByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `TouristServiceOrderDetail` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `TouristServiceOrderDetail` being updated. */
  patch: TouristServiceOrderDetailPatch;
};

/** All input for the `updateTouristServiceOrderDetail` mutation. */
export type UpdateTouristServiceOrderDetailInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  key: Scalars['String'];
  /** An object where the defined keys will be set on the `TouristServiceOrderDetail` being updated. */
  patch: TouristServiceOrderDetailPatch;
};

/** The output of our update `TouristServiceOrderDetail` mutation. */
export type UpdateTouristServiceOrderDetailPayload = {
  __typename?: 'UpdateTouristServiceOrderDetailPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Product` that is related to this `TouristServiceOrderDetail`. */
  productService?: Maybe<Product>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `TouristServiceOrder` that is related to this `TouristServiceOrderDetail`. */
  touristServiceOrder?: Maybe<TouristServiceOrder>;
  /** The `TouristServiceOrderDetail` that was updated by this mutation. */
  touristServiceOrderDetail?: Maybe<TouristServiceOrderDetail>;
  /** An edge for our `TouristServiceOrderDetail`. May be used by Relay 1. */
  touristServiceOrderDetailEdge?: Maybe<TouristServiceOrderDetailsEdge>;
};


/** The output of our update `TouristServiceOrderDetail` mutation. */
export type UpdateTouristServiceOrderDetailPayloadTouristServiceOrderDetailEdgeArgs = {
  orderBy?: InputMaybe<Array<TouristServiceOrderDetailsOrderBy>>;
};

/** All input for the `updateTouristServiceOrder` mutation. */
export type UpdateTouristServiceOrderInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  key: Scalars['String'];
  /** An object where the defined keys will be set on the `TouristServiceOrder` being updated. */
  patch: TouristServiceOrderPatch;
};

/** The output of our update `TouristServiceOrder` mutation. */
export type UpdateTouristServiceOrderPayload = {
  __typename?: 'UpdateTouristServiceOrderPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Order` that is related to this `TouristServiceOrder`. */
  order?: Maybe<Order>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `TouristServiceOrder` that was updated by this mutation. */
  touristServiceOrder?: Maybe<TouristServiceOrder>;
  /** An edge for our `TouristServiceOrder`. May be used by Relay 1. */
  touristServiceOrderEdge?: Maybe<TouristServiceOrdersEdge>;
};


/** The output of our update `TouristServiceOrder` mutation. */
export type UpdateTouristServiceOrderPayloadTouristServiceOrderEdgeArgs = {
  orderBy?: InputMaybe<Array<TouristServiceOrdersOrderBy>>;
};

/** All input for the `updateUserByNodeId` mutation. */
export type UpdateUserByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `User` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `User` being updated. */
  patch: UserPatch;
};

/** All input for the `updateUser` mutation. */
export type UpdateUserInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  key: Scalars['String'];
  /** An object where the defined keys will be set on the `User` being updated. */
  patch: UserPatch;
};

/** The output of our update `User` mutation. */
export type UpdateUserPayload = {
  __typename?: 'UpdateUserPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Person` that is related to this `User`. */
  person?: Maybe<Person>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `User` that was updated by this mutation. */
  user?: Maybe<User>;
  /** An edge for our `User`. May be used by Relay 1. */
  userEdge?: Maybe<UsersEdge>;
};

export type UploadDelegatedTokenInput = {
  tenantKey: Scalars['String'];
  token: Scalars['String'];
};

export type UploadDelegatedTokenPayload = {
  __typename?: 'UploadDelegatedTokenPayload';
  delegatedToken?: Maybe<DelegatedToken>;
  message?: Maybe<Scalars['String']>;
};

export type UploadDigitalSignatureInput = {
  file: Scalars['Upload'];
  password: Scalars['String'];
  tenantKey: Scalars['String'];
};

export type UploadDigitalSignaturePayload = {
  __typename?: 'UploadDigitalSignaturePayload';
  message: Scalars['String'];
};

export type UploadRegisterDocumentsPayload = {
  __typename?: 'UploadRegisterDocumentsPayload';
  backDocumentPhotoValid: Scalars['Boolean'];
  certificateRequestPhotoValid: Scalars['Boolean'];
  frontDocumentPhotoValid: Scalars['Boolean'];
  frontDocumentSelfieValid: Scalars['Boolean'];
  jwtToken?: Maybe<Scalars['JwtToken']>;
  nitDocumentPhotoValid: Scalars['Boolean'];
};

/** Usuario */
export type User = Node & {
  __typename?: 'User';
  createdAt: Scalars['Datetime'];
  email: Scalars['String'];
  key: Scalars['String'];
  lastTenantKey?: Maybe<Scalars['String']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Obtiene todos los permisos asociados al usuario actual. */
  permissions: PermissionsConnection;
  /** Reads a single `Person` that is related to this `User`. */
  person?: Maybe<Person>;
  personKey: Scalars['String'];
  /** Reads and enables pagination through a set of `PointOfSaleUser`. */
  pointOfSaleUsers: PointOfSaleUsersConnection;
  tenant?: Maybe<Tenant>;
  /** Reads and enables pagination through a set of `Tenant`. */
  tenants: TenantsConnection;
  updatedAt?: Maybe<Scalars['Datetime']>;
};


/** Usuario */
export type UserPermissionsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  filter?: InputMaybe<PermissionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


/** Usuario */
export type UserPointOfSaleUsersArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


/** Usuario */
export type UserTenantsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  filter?: InputMaybe<TenantFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

/** An input for mutations affecting `User` */
export type UserInput = {
  email: Scalars['String'];
  person?: InputMaybe<FkAppUserToPersonInput>;
  personKey?: InputMaybe<Scalars['String']>;
  pointOfSaleUsers?: InputMaybe<FkPointOfSaleUserToUserInverseInput>;
};

/** The globally unique `ID` look up for the row to connect. */
export type UserNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `user` to be connected. */
  nodeId: Scalars['ID'];
};

export type UserNotificationPayload = {
  __typename?: 'UserNotificationPayload';
  event?: Maybe<Scalars['String']>;
  notification?: Maybe<Notification>;
};

/** The globally unique `ID` look up for the row to update. */
export type UserOnPointOfSaleUserForFkPointOfSaleUserToUserNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `pointOfSaleUser` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `pointOfSaleUser` being updated. */
  patch: PointOfSaleUserPatch;
};

/** The fields on `user` to look up the row to update. */
export type UserOnPointOfSaleUserForFkPointOfSaleUserToUserUsingPkUserUpdate = {
  key: Scalars['String'];
  /** An object where the defined keys will be set on the `user` being updated. */
  patch: UpdateUserOnPointOfSaleUserForFkPointOfSaleUserToUserPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type UserOnUserForFkAppUserToPersonNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `person` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `person` being updated. */
  patch: PersonPatch;
};

/** The fields on `user` to look up the row to update. */
export type UserOnUserForFkAppUserToPersonUsingPkUserUpdate = {
  key: Scalars['String'];
  /** An object where the defined keys will be set on the `user` being updated. */
  patch: UpdateUserOnUserForFkAppUserToPersonPatch;
};

/** Represents an update to a `User`. Fields that are set will be updated. */
export type UserPatch = {
  email?: InputMaybe<Scalars['String']>;
  person?: InputMaybe<FkAppUserToPersonInput>;
  personKey?: InputMaybe<Scalars['String']>;
  pointOfSaleUsers?: InputMaybe<FkPointOfSaleUserToUserInverseInput>;
};

/** The fields on `user` to look up the row to connect. */
export type UserPkUserConnect = {
  key: Scalars['String'];
};

/** A connection to a list of `User` values. */
export type UsersConnection = {
  __typename?: 'UsersConnection';
  /** A list of edges which contains the `User` and cursor to aid in pagination. */
  edges: Array<UsersEdge>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection. */
export type UsersEdge = {
  __typename?: 'UsersEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node?: Maybe<User>;
};

export type ViewAccount = {
  __typename?: 'ViewAccount';
  delegateEnabled?: Maybe<Scalars['Boolean']>;
  delegateExpireAt?: Maybe<Scalars['Datetime']>;
  delegateIssuedAt?: Maybe<Scalars['Datetime']>;
  delegateToken?: Maybe<Scalars['String']>;
  systemCode?: Maybe<Scalars['String']>;
  tenantBusinessName?: Maybe<Scalars['String']>;
  tenantCreatedAt?: Maybe<Scalars['Datetime']>;
  tenantEmail?: Maybe<Scalars['String']>;
  tenantKey?: Maybe<Scalars['String']>;
  tenantNit?: Maybe<Scalars['BigInt']>;
  tenantPhoneNumber?: Maybe<Scalars['String']>;
};

/**
 * A condition to be used against `ViewAccount` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type ViewAccountCondition = {
  /** Checks for equality with the object’s `delegateEnabled` field. */
  delegateEnabled?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `delegateExpireAt` field. */
  delegateExpireAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `delegateIssuedAt` field. */
  delegateIssuedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `delegateToken` field. */
  delegateToken?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `systemCode` field. */
  systemCode?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `tenantBusinessName` field. */
  tenantBusinessName?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `tenantCreatedAt` field. */
  tenantCreatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `tenantEmail` field. */
  tenantEmail?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `tenantKey` field. */
  tenantKey?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `tenantNit` field. */
  tenantNit?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `tenantPhoneNumber` field. */
  tenantPhoneNumber?: InputMaybe<Scalars['String']>;
};

/** A filter to be used against `ViewAccount` object types. All fields are combined with a logical ‘and.’ */
export type ViewAccountFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ViewAccountFilter>>;
  /** Filter by the object’s `delegateEnabled` field. */
  delegateEnabled?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `delegateExpireAt` field. */
  delegateExpireAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `delegateIssuedAt` field. */
  delegateIssuedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `delegateToken` field. */
  delegateToken?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ViewAccountFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ViewAccountFilter>>;
  /** Filter by the object’s `systemCode` field. */
  systemCode?: InputMaybe<StringFilter>;
  /** Filter by the object’s `tenantBusinessName` field. */
  tenantBusinessName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `tenantCreatedAt` field. */
  tenantCreatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `tenantEmail` field. */
  tenantEmail?: InputMaybe<StringFilter>;
  /** Filter by the object’s `tenantKey` field. */
  tenantKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `tenantNit` field. */
  tenantNit?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `tenantPhoneNumber` field. */
  tenantPhoneNumber?: InputMaybe<StringFilter>;
};

/** A connection to a list of `ViewAccount` values. */
export type ViewAccountsConnection = {
  __typename?: 'ViewAccountsConnection';
  /** A list of edges which contains the `ViewAccount` and cursor to aid in pagination. */
  edges: Array<ViewAccountsEdge>;
  /** A list of `ViewAccount` objects. */
  nodes: Array<Maybe<ViewAccount>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ViewAccount` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ViewAccount` edge in the connection. */
export type ViewAccountsEdge = {
  __typename?: 'ViewAccountsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `ViewAccount` at the end of the edge. */
  node?: Maybe<ViewAccount>;
};

/** Methods to use when ordering `ViewAccount`. */
export enum ViewAccountsOrderBy {
  DelegateEnabledAsc = 'DELEGATE_ENABLED_ASC',
  DelegateEnabledDesc = 'DELEGATE_ENABLED_DESC',
  DelegateExpireAtAsc = 'DELEGATE_EXPIRE_AT_ASC',
  DelegateExpireAtDesc = 'DELEGATE_EXPIRE_AT_DESC',
  DelegateIssuedAtAsc = 'DELEGATE_ISSUED_AT_ASC',
  DelegateIssuedAtDesc = 'DELEGATE_ISSUED_AT_DESC',
  DelegateTokenAsc = 'DELEGATE_TOKEN_ASC',
  DelegateTokenDesc = 'DELEGATE_TOKEN_DESC',
  Natural = 'NATURAL',
  SystemCodeAsc = 'SYSTEM_CODE_ASC',
  SystemCodeDesc = 'SYSTEM_CODE_DESC',
  TenantBusinessNameAsc = 'TENANT_BUSINESS_NAME_ASC',
  TenantBusinessNameDesc = 'TENANT_BUSINESS_NAME_DESC',
  TenantCreatedAtAsc = 'TENANT_CREATED_AT_ASC',
  TenantCreatedAtDesc = 'TENANT_CREATED_AT_DESC',
  TenantEmailAsc = 'TENANT_EMAIL_ASC',
  TenantEmailDesc = 'TENANT_EMAIL_DESC',
  TenantKeyAsc = 'TENANT_KEY_ASC',
  TenantKeyDesc = 'TENANT_KEY_DESC',
  TenantNitAsc = 'TENANT_NIT_ASC',
  TenantNitDesc = 'TENANT_NIT_DESC',
  TenantPhoneNumberAsc = 'TENANT_PHONE_NUMBER_ASC',
  TenantPhoneNumberDesc = 'TENANT_PHONE_NUMBER_DESC'
}

export type ViewBranchOffice = {
  __typename?: 'ViewBranchOffice';
  address?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  key?: Maybe<Scalars['String']>;
  municipality?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
};

/**
 * A condition to be used against `ViewBranchOffice` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type ViewBranchOfficeCondition = {
  /** Checks for equality with the object’s `address` field. */
  address?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `code` field. */
  code?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `key` field. */
  key?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `municipality` field. */
  municipality?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `phoneNumber` field. */
  phoneNumber?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** A filter to be used against `ViewBranchOffice` object types. All fields are combined with a logical ‘and.’ */
export type ViewBranchOfficeFilter = {
  /** Filter by the object’s `address` field. */
  address?: InputMaybe<StringFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ViewBranchOfficeFilter>>;
  /** Filter by the object’s `code` field. */
  code?: InputMaybe<IntFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `key` field. */
  key?: InputMaybe<StringFilter>;
  /** Filter by the object’s `municipality` field. */
  municipality?: InputMaybe<StringFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ViewBranchOfficeFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ViewBranchOfficeFilter>>;
  /** Filter by the object’s `phoneNumber` field. */
  phoneNumber?: InputMaybe<StringFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
};

/** An input for mutations affecting `ViewBranchOffice` */
export type ViewBranchOfficeInput = {
  address?: InputMaybe<Scalars['String']>;
  code?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  key?: InputMaybe<Scalars['String']>;
  municipality?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** A connection to a list of `ViewBranchOffice` values. */
export type ViewBranchOfficesConnection = {
  __typename?: 'ViewBranchOfficesConnection';
  /** A list of edges which contains the `ViewBranchOffice` and cursor to aid in pagination. */
  edges: Array<ViewBranchOfficesEdge>;
  /** A list of `ViewBranchOffice` objects. */
  nodes: Array<Maybe<ViewBranchOffice>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ViewBranchOffice` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ViewBranchOffice` edge in the connection. */
export type ViewBranchOfficesEdge = {
  __typename?: 'ViewBranchOfficesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `ViewBranchOffice` at the end of the edge. */
  node?: Maybe<ViewBranchOffice>;
};

/** Methods to use when ordering `ViewBranchOffice`. */
export enum ViewBranchOfficesOrderBy {
  AddressAsc = 'ADDRESS_ASC',
  AddressDesc = 'ADDRESS_DESC',
  CodeAsc = 'CODE_ASC',
  CodeDesc = 'CODE_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  KeyAsc = 'KEY_ASC',
  KeyDesc = 'KEY_DESC',
  MunicipalityAsc = 'MUNICIPALITY_ASC',
  MunicipalityDesc = 'MUNICIPALITY_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  PhoneNumberAsc = 'PHONE_NUMBER_ASC',
  PhoneNumberDesc = 'PHONE_NUMBER_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC'
}

export type ViewClient = {
  __typename?: 'ViewClient';
  createdAt?: Maybe<Scalars['Datetime']>;
  documentComplement?: Maybe<Scalars['String']>;
  documentNumber?: Maybe<Scalars['String']>;
  documentTypeCode?: Maybe<Scalars['Int']>;
  documentTypeDescription?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
};

/**
 * A condition to be used against `ViewClient` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type ViewClientCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `documentComplement` field. */
  documentComplement?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `documentNumber` field. */
  documentNumber?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `documentTypeCode` field. */
  documentTypeCode?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `documentTypeDescription` field. */
  documentTypeDescription?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `email` field. */
  email?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `key` field. */
  key?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `phoneNumber` field. */
  phoneNumber?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** A filter to be used against `ViewClient` object types. All fields are combined with a logical ‘and.’ */
export type ViewClientFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ViewClientFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `documentComplement` field. */
  documentComplement?: InputMaybe<StringFilter>;
  /** Filter by the object’s `documentNumber` field. */
  documentNumber?: InputMaybe<StringFilter>;
  /** Filter by the object’s `documentTypeCode` field. */
  documentTypeCode?: InputMaybe<IntFilter>;
  /** Filter by the object’s `documentTypeDescription` field. */
  documentTypeDescription?: InputMaybe<StringFilter>;
  /** Filter by the object’s `email` field. */
  email?: InputMaybe<StringFilter>;
  /** Filter by the object’s `key` field. */
  key?: InputMaybe<StringFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ViewClientFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ViewClientFilter>>;
  /** Filter by the object’s `phoneNumber` field. */
  phoneNumber?: InputMaybe<StringFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
};

/** A connection to a list of `ViewClient` values. */
export type ViewClientsConnection = {
  __typename?: 'ViewClientsConnection';
  /** A list of edges which contains the `ViewClient` and cursor to aid in pagination. */
  edges: Array<ViewClientsEdge>;
  /** A list of `ViewClient` objects. */
  nodes: Array<Maybe<ViewClient>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ViewClient` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ViewClient` edge in the connection. */
export type ViewClientsEdge = {
  __typename?: 'ViewClientsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `ViewClient` at the end of the edge. */
  node?: Maybe<ViewClient>;
};

/** Methods to use when ordering `ViewClient`. */
export enum ViewClientsOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  DocumentComplementAsc = 'DOCUMENT_COMPLEMENT_ASC',
  DocumentComplementDesc = 'DOCUMENT_COMPLEMENT_DESC',
  DocumentNumberAsc = 'DOCUMENT_NUMBER_ASC',
  DocumentNumberDesc = 'DOCUMENT_NUMBER_DESC',
  DocumentTypeCodeAsc = 'DOCUMENT_TYPE_CODE_ASC',
  DocumentTypeCodeDesc = 'DOCUMENT_TYPE_CODE_DESC',
  DocumentTypeDescriptionAsc = 'DOCUMENT_TYPE_DESCRIPTION_ASC',
  DocumentTypeDescriptionDesc = 'DOCUMENT_TYPE_DESCRIPTION_DESC',
  EmailAsc = 'EMAIL_ASC',
  EmailDesc = 'EMAIL_DESC',
  KeyAsc = 'KEY_ASC',
  KeyDesc = 'KEY_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  PhoneNumberAsc = 'PHONE_NUMBER_ASC',
  PhoneNumberDesc = 'PHONE_NUMBER_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC'
}

export type ViewCufdCode = {
  __typename?: 'ViewCufdCode';
  address?: Maybe<Scalars['String']>;
  branchOfficeCode?: Maybe<Scalars['Int']>;
  branchOfficeName?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  controlCode?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['Int']>;
  enabled?: Maybe<Scalars['Boolean']>;
  expireAt?: Maybe<Scalars['Datetime']>;
  generatedAt?: Maybe<Scalars['Datetime']>;
  id?: Maybe<Scalars['Int']>;
  key?: Maybe<Scalars['String']>;
  pointOfSaleCode?: Maybe<Scalars['Int']>;
  pointOfSaleName?: Maybe<Scalars['String']>;
  status?: Maybe<RequestStatus>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['Int']>;
};

/**
 * A condition to be used against `ViewCufdCode` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type ViewCufdCodeCondition = {
  /** Checks for equality with the object’s `address` field. */
  address?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `branchOfficeCode` field. */
  branchOfficeCode?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `branchOfficeName` field. */
  branchOfficeName?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `code` field. */
  code?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `controlCode` field. */
  controlCode?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `enabled` field. */
  enabled?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `expireAt` field. */
  expireAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `generatedAt` field. */
  generatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `key` field. */
  key?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `pointOfSaleCode` field. */
  pointOfSaleCode?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `pointOfSaleName` field. */
  pointOfSaleName?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `status` field. */
  status?: InputMaybe<RequestStatus>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedBy` field. */
  updatedBy?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against `ViewCufdCode` object types. All fields are combined with a logical ‘and.’ */
export type ViewCufdCodeFilter = {
  /** Filter by the object’s `address` field. */
  address?: InputMaybe<StringFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ViewCufdCodeFilter>>;
  /** Filter by the object’s `branchOfficeCode` field. */
  branchOfficeCode?: InputMaybe<IntFilter>;
  /** Filter by the object’s `branchOfficeName` field. */
  branchOfficeName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `code` field. */
  code?: InputMaybe<StringFilter>;
  /** Filter by the object’s `controlCode` field. */
  controlCode?: InputMaybe<StringFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy?: InputMaybe<IntFilter>;
  /** Filter by the object’s `enabled` field. */
  enabled?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `expireAt` field. */
  expireAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `generatedAt` field. */
  generatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `key` field. */
  key?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ViewCufdCodeFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ViewCufdCodeFilter>>;
  /** Filter by the object’s `pointOfSaleCode` field. */
  pointOfSaleCode?: InputMaybe<IntFilter>;
  /** Filter by the object’s `pointOfSaleName` field. */
  pointOfSaleName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `status` field. */
  status?: InputMaybe<RequestStatusFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `updatedBy` field. */
  updatedBy?: InputMaybe<IntFilter>;
};

/** A connection to a list of `ViewCufdCode` values. */
export type ViewCufdCodesConnection = {
  __typename?: 'ViewCufdCodesConnection';
  /** A list of edges which contains the `ViewCufdCode` and cursor to aid in pagination. */
  edges: Array<ViewCufdCodesEdge>;
  /** A list of `ViewCufdCode` objects. */
  nodes: Array<Maybe<ViewCufdCode>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ViewCufdCode` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ViewCufdCode` edge in the connection. */
export type ViewCufdCodesEdge = {
  __typename?: 'ViewCufdCodesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `ViewCufdCode` at the end of the edge. */
  node?: Maybe<ViewCufdCode>;
};

/** Methods to use when ordering `ViewCufdCode`. */
export enum ViewCufdCodesOrderBy {
  AddressAsc = 'ADDRESS_ASC',
  AddressDesc = 'ADDRESS_DESC',
  BranchOfficeCodeAsc = 'BRANCH_OFFICE_CODE_ASC',
  BranchOfficeCodeDesc = 'BRANCH_OFFICE_CODE_DESC',
  BranchOfficeNameAsc = 'BRANCH_OFFICE_NAME_ASC',
  BranchOfficeNameDesc = 'BRANCH_OFFICE_NAME_DESC',
  CodeAsc = 'CODE_ASC',
  CodeDesc = 'CODE_DESC',
  ControlCodeAsc = 'CONTROL_CODE_ASC',
  ControlCodeDesc = 'CONTROL_CODE_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  EnabledAsc = 'ENABLED_ASC',
  EnabledDesc = 'ENABLED_DESC',
  ExpireAtAsc = 'EXPIRE_AT_ASC',
  ExpireAtDesc = 'EXPIRE_AT_DESC',
  GeneratedAtAsc = 'GENERATED_AT_ASC',
  GeneratedAtDesc = 'GENERATED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  KeyAsc = 'KEY_ASC',
  KeyDesc = 'KEY_DESC',
  Natural = 'NATURAL',
  PointOfSaleCodeAsc = 'POINT_OF_SALE_CODE_ASC',
  PointOfSaleCodeDesc = 'POINT_OF_SALE_CODE_DESC',
  PointOfSaleNameAsc = 'POINT_OF_SALE_NAME_ASC',
  PointOfSaleNameDesc = 'POINT_OF_SALE_NAME_DESC',
  StatusAsc = 'STATUS_ASC',
  StatusDesc = 'STATUS_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UpdatedByAsc = 'UPDATED_BY_ASC',
  UpdatedByDesc = 'UPDATED_BY_DESC'
}

export type ViewCuisCode = {
  __typename?: 'ViewCuisCode';
  branchOfficeCode?: Maybe<Scalars['Int']>;
  branchOfficeName?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['Int']>;
  enabled?: Maybe<Scalars['Boolean']>;
  expireAt?: Maybe<Scalars['Datetime']>;
  id?: Maybe<Scalars['Int']>;
  key?: Maybe<Scalars['String']>;
  pointOfSaleCode?: Maybe<Scalars['Int']>;
  pointOfSaleName?: Maybe<Scalars['String']>;
  status?: Maybe<RequestStatus>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['Int']>;
};

/**
 * A condition to be used against `ViewCuisCode` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type ViewCuisCodeCondition = {
  /** Checks for equality with the object’s `branchOfficeCode` field. */
  branchOfficeCode?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `branchOfficeName` field. */
  branchOfficeName?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `code` field. */
  code?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `enabled` field. */
  enabled?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `expireAt` field. */
  expireAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `key` field. */
  key?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `pointOfSaleCode` field. */
  pointOfSaleCode?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `pointOfSaleName` field. */
  pointOfSaleName?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `status` field. */
  status?: InputMaybe<RequestStatus>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedBy` field. */
  updatedBy?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against `ViewCuisCode` object types. All fields are combined with a logical ‘and.’ */
export type ViewCuisCodeFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ViewCuisCodeFilter>>;
  /** Filter by the object’s `branchOfficeCode` field. */
  branchOfficeCode?: InputMaybe<IntFilter>;
  /** Filter by the object’s `branchOfficeName` field. */
  branchOfficeName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `code` field. */
  code?: InputMaybe<StringFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy?: InputMaybe<IntFilter>;
  /** Filter by the object’s `enabled` field. */
  enabled?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `expireAt` field. */
  expireAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `key` field. */
  key?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ViewCuisCodeFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ViewCuisCodeFilter>>;
  /** Filter by the object’s `pointOfSaleCode` field. */
  pointOfSaleCode?: InputMaybe<IntFilter>;
  /** Filter by the object’s `pointOfSaleName` field. */
  pointOfSaleName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `status` field. */
  status?: InputMaybe<RequestStatusFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `updatedBy` field. */
  updatedBy?: InputMaybe<IntFilter>;
};

/** A connection to a list of `ViewCuisCode` values. */
export type ViewCuisCodesConnection = {
  __typename?: 'ViewCuisCodesConnection';
  /** A list of edges which contains the `ViewCuisCode` and cursor to aid in pagination. */
  edges: Array<ViewCuisCodesEdge>;
  /** A list of `ViewCuisCode` objects. */
  nodes: Array<Maybe<ViewCuisCode>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ViewCuisCode` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ViewCuisCode` edge in the connection. */
export type ViewCuisCodesEdge = {
  __typename?: 'ViewCuisCodesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `ViewCuisCode` at the end of the edge. */
  node?: Maybe<ViewCuisCode>;
};

/** Methods to use when ordering `ViewCuisCode`. */
export enum ViewCuisCodesOrderBy {
  BranchOfficeCodeAsc = 'BRANCH_OFFICE_CODE_ASC',
  BranchOfficeCodeDesc = 'BRANCH_OFFICE_CODE_DESC',
  BranchOfficeNameAsc = 'BRANCH_OFFICE_NAME_ASC',
  BranchOfficeNameDesc = 'BRANCH_OFFICE_NAME_DESC',
  CodeAsc = 'CODE_ASC',
  CodeDesc = 'CODE_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  EnabledAsc = 'ENABLED_ASC',
  EnabledDesc = 'ENABLED_DESC',
  ExpireAtAsc = 'EXPIRE_AT_ASC',
  ExpireAtDesc = 'EXPIRE_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  KeyAsc = 'KEY_ASC',
  KeyDesc = 'KEY_DESC',
  Natural = 'NATURAL',
  PointOfSaleCodeAsc = 'POINT_OF_SALE_CODE_ASC',
  PointOfSaleCodeDesc = 'POINT_OF_SALE_CODE_DESC',
  PointOfSaleNameAsc = 'POINT_OF_SALE_NAME_ASC',
  PointOfSaleNameDesc = 'POINT_OF_SALE_NAME_DESC',
  StatusAsc = 'STATUS_ASC',
  StatusDesc = 'STATUS_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UpdatedByAsc = 'UPDATED_BY_ASC',
  UpdatedByDesc = 'UPDATED_BY_DESC'
}

export type ViewDelegatedToken = {
  __typename?: 'ViewDelegatedToken';
  createdAt?: Maybe<Scalars['Datetime']>;
  enabled?: Maybe<Scalars['Boolean']>;
  expireAt?: Maybe<Scalars['Datetime']>;
  id?: Maybe<Scalars['Int']>;
  issuedAt?: Maybe<Scalars['Datetime']>;
  key?: Maybe<Scalars['String']>;
  nit?: Maybe<Scalars['String']>;
  revoked?: Maybe<Scalars['Boolean']>;
  sub?: Maybe<Scalars['String']>;
  systemCode?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
};

/**
 * A condition to be used against `ViewDelegatedToken` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type ViewDelegatedTokenCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `enabled` field. */
  enabled?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `expireAt` field. */
  expireAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `issuedAt` field. */
  issuedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `key` field. */
  key?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `nit` field. */
  nit?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `revoked` field. */
  revoked?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `sub` field. */
  sub?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `systemCode` field. */
  systemCode?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** A filter to be used against `ViewDelegatedToken` object types. All fields are combined with a logical ‘and.’ */
export type ViewDelegatedTokenFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ViewDelegatedTokenFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `enabled` field. */
  enabled?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `expireAt` field. */
  expireAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `issuedAt` field. */
  issuedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `key` field. */
  key?: InputMaybe<StringFilter>;
  /** Filter by the object’s `nit` field. */
  nit?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ViewDelegatedTokenFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ViewDelegatedTokenFilter>>;
  /** Filter by the object’s `revoked` field. */
  revoked?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `sub` field. */
  sub?: InputMaybe<StringFilter>;
  /** Filter by the object’s `systemCode` field. */
  systemCode?: InputMaybe<StringFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
};

/** An input for mutations affecting `ViewDelegatedToken` */
export type ViewDelegatedTokenInput = {
  createdAt?: InputMaybe<Scalars['Datetime']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  expireAt?: InputMaybe<Scalars['Datetime']>;
  id?: InputMaybe<Scalars['Int']>;
  issuedAt?: InputMaybe<Scalars['Datetime']>;
  key?: InputMaybe<Scalars['String']>;
  nit?: InputMaybe<Scalars['String']>;
  revoked?: InputMaybe<Scalars['Boolean']>;
  sub?: InputMaybe<Scalars['String']>;
  systemCode?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** A connection to a list of `ViewDelegatedToken` values. */
export type ViewDelegatedTokensConnection = {
  __typename?: 'ViewDelegatedTokensConnection';
  /** A list of edges which contains the `ViewDelegatedToken` and cursor to aid in pagination. */
  edges: Array<ViewDelegatedTokensEdge>;
  /** A list of `ViewDelegatedToken` objects. */
  nodes: Array<Maybe<ViewDelegatedToken>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ViewDelegatedToken` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ViewDelegatedToken` edge in the connection. */
export type ViewDelegatedTokensEdge = {
  __typename?: 'ViewDelegatedTokensEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `ViewDelegatedToken` at the end of the edge. */
  node?: Maybe<ViewDelegatedToken>;
};

/** Methods to use when ordering `ViewDelegatedToken`. */
export enum ViewDelegatedTokensOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  EnabledAsc = 'ENABLED_ASC',
  EnabledDesc = 'ENABLED_DESC',
  ExpireAtAsc = 'EXPIRE_AT_ASC',
  ExpireAtDesc = 'EXPIRE_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  IssuedAtAsc = 'ISSUED_AT_ASC',
  IssuedAtDesc = 'ISSUED_AT_DESC',
  KeyAsc = 'KEY_ASC',
  KeyDesc = 'KEY_DESC',
  Natural = 'NATURAL',
  NitAsc = 'NIT_ASC',
  NitDesc = 'NIT_DESC',
  RevokedAsc = 'REVOKED_ASC',
  RevokedDesc = 'REVOKED_DESC',
  SubAsc = 'SUB_ASC',
  SubDesc = 'SUB_DESC',
  SystemCodeAsc = 'SYSTEM_CODE_ASC',
  SystemCodeDesc = 'SYSTEM_CODE_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC'
}

export type ViewDigitalSignature = {
  __typename?: 'ViewDigitalSignature';
  createdAt?: Maybe<Scalars['Datetime']>;
  enabled?: Maybe<Scalars['Boolean']>;
  expireAt?: Maybe<Scalars['Datetime']>;
  issuedAt?: Maybe<Scalars['Datetime']>;
  issuerCommonName?: Maybe<Scalars['String']>;
  issuerCountry?: Maybe<Scalars['String']>;
  issuerOrganization?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  revoked?: Maybe<Scalars['Boolean']>;
  subjectBusinessName?: Maybe<Scalars['String']>;
  subjectCountry?: Maybe<Scalars['String']>;
  subjectDocumentComplement?: Maybe<Scalars['String']>;
  subjectDocumentNumber?: Maybe<Scalars['String']>;
  subjectDocumentType?: Maybe<Scalars['String']>;
  subjectJobTitle?: Maybe<Scalars['String']>;
  subjectLegalRepresentative?: Maybe<Scalars['String']>;
  subjectNit?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
};

/**
 * A condition to be used against `ViewDigitalSignature` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type ViewDigitalSignatureCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `enabled` field. */
  enabled?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `expireAt` field. */
  expireAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `issuedAt` field. */
  issuedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `issuerCommonName` field. */
  issuerCommonName?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `issuerCountry` field. */
  issuerCountry?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `issuerOrganization` field. */
  issuerOrganization?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `key` field. */
  key?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `revoked` field. */
  revoked?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `subjectBusinessName` field. */
  subjectBusinessName?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `subjectCountry` field. */
  subjectCountry?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `subjectDocumentComplement` field. */
  subjectDocumentComplement?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `subjectDocumentNumber` field. */
  subjectDocumentNumber?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `subjectDocumentType` field. */
  subjectDocumentType?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `subjectJobTitle` field. */
  subjectJobTitle?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `subjectLegalRepresentative` field. */
  subjectLegalRepresentative?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `subjectNit` field. */
  subjectNit?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** A filter to be used against `ViewDigitalSignature` object types. All fields are combined with a logical ‘and.’ */
export type ViewDigitalSignatureFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ViewDigitalSignatureFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `enabled` field. */
  enabled?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `expireAt` field. */
  expireAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `issuedAt` field. */
  issuedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `issuerCommonName` field. */
  issuerCommonName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `issuerCountry` field. */
  issuerCountry?: InputMaybe<StringFilter>;
  /** Filter by the object’s `issuerOrganization` field. */
  issuerOrganization?: InputMaybe<StringFilter>;
  /** Filter by the object’s `key` field. */
  key?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ViewDigitalSignatureFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ViewDigitalSignatureFilter>>;
  /** Filter by the object’s `revoked` field. */
  revoked?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `subjectBusinessName` field. */
  subjectBusinessName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `subjectCountry` field. */
  subjectCountry?: InputMaybe<StringFilter>;
  /** Filter by the object’s `subjectDocumentComplement` field. */
  subjectDocumentComplement?: InputMaybe<StringFilter>;
  /** Filter by the object’s `subjectDocumentNumber` field. */
  subjectDocumentNumber?: InputMaybe<StringFilter>;
  /** Filter by the object’s `subjectDocumentType` field. */
  subjectDocumentType?: InputMaybe<StringFilter>;
  /** Filter by the object’s `subjectJobTitle` field. */
  subjectJobTitle?: InputMaybe<StringFilter>;
  /** Filter by the object’s `subjectLegalRepresentative` field. */
  subjectLegalRepresentative?: InputMaybe<StringFilter>;
  /** Filter by the object’s `subjectNit` field. */
  subjectNit?: InputMaybe<StringFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
};

/** An input for mutations affecting `ViewDigitalSignature` */
export type ViewDigitalSignatureInput = {
  createdAt?: InputMaybe<Scalars['Datetime']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  expireAt?: InputMaybe<Scalars['Datetime']>;
  issuedAt?: InputMaybe<Scalars['Datetime']>;
  issuerCommonName?: InputMaybe<Scalars['String']>;
  issuerCountry?: InputMaybe<Scalars['String']>;
  issuerOrganization?: InputMaybe<Scalars['String']>;
  key?: InputMaybe<Scalars['String']>;
  revoked?: InputMaybe<Scalars['Boolean']>;
  subjectBusinessName?: InputMaybe<Scalars['String']>;
  subjectCountry?: InputMaybe<Scalars['String']>;
  subjectDocumentComplement?: InputMaybe<Scalars['String']>;
  subjectDocumentNumber?: InputMaybe<Scalars['String']>;
  subjectDocumentType?: InputMaybe<Scalars['String']>;
  subjectJobTitle?: InputMaybe<Scalars['String']>;
  subjectLegalRepresentative?: InputMaybe<Scalars['String']>;
  subjectNit?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** A connection to a list of `ViewDigitalSignature` values. */
export type ViewDigitalSignaturesConnection = {
  __typename?: 'ViewDigitalSignaturesConnection';
  /** A list of edges which contains the `ViewDigitalSignature` and cursor to aid in pagination. */
  edges: Array<ViewDigitalSignaturesEdge>;
  /** A list of `ViewDigitalSignature` objects. */
  nodes: Array<Maybe<ViewDigitalSignature>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ViewDigitalSignature` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ViewDigitalSignature` edge in the connection. */
export type ViewDigitalSignaturesEdge = {
  __typename?: 'ViewDigitalSignaturesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `ViewDigitalSignature` at the end of the edge. */
  node?: Maybe<ViewDigitalSignature>;
};

/** Methods to use when ordering `ViewDigitalSignature`. */
export enum ViewDigitalSignaturesOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  EnabledAsc = 'ENABLED_ASC',
  EnabledDesc = 'ENABLED_DESC',
  ExpireAtAsc = 'EXPIRE_AT_ASC',
  ExpireAtDesc = 'EXPIRE_AT_DESC',
  IssuedAtAsc = 'ISSUED_AT_ASC',
  IssuedAtDesc = 'ISSUED_AT_DESC',
  IssuerCommonNameAsc = 'ISSUER_COMMON_NAME_ASC',
  IssuerCommonNameDesc = 'ISSUER_COMMON_NAME_DESC',
  IssuerCountryAsc = 'ISSUER_COUNTRY_ASC',
  IssuerCountryDesc = 'ISSUER_COUNTRY_DESC',
  IssuerOrganizationAsc = 'ISSUER_ORGANIZATION_ASC',
  IssuerOrganizationDesc = 'ISSUER_ORGANIZATION_DESC',
  KeyAsc = 'KEY_ASC',
  KeyDesc = 'KEY_DESC',
  Natural = 'NATURAL',
  RevokedAsc = 'REVOKED_ASC',
  RevokedDesc = 'REVOKED_DESC',
  SubjectBusinessNameAsc = 'SUBJECT_BUSINESS_NAME_ASC',
  SubjectBusinessNameDesc = 'SUBJECT_BUSINESS_NAME_DESC',
  SubjectCountryAsc = 'SUBJECT_COUNTRY_ASC',
  SubjectCountryDesc = 'SUBJECT_COUNTRY_DESC',
  SubjectDocumentComplementAsc = 'SUBJECT_DOCUMENT_COMPLEMENT_ASC',
  SubjectDocumentComplementDesc = 'SUBJECT_DOCUMENT_COMPLEMENT_DESC',
  SubjectDocumentNumberAsc = 'SUBJECT_DOCUMENT_NUMBER_ASC',
  SubjectDocumentNumberDesc = 'SUBJECT_DOCUMENT_NUMBER_DESC',
  SubjectDocumentTypeAsc = 'SUBJECT_DOCUMENT_TYPE_ASC',
  SubjectDocumentTypeDesc = 'SUBJECT_DOCUMENT_TYPE_DESC',
  SubjectJobTitleAsc = 'SUBJECT_JOB_TITLE_ASC',
  SubjectJobTitleDesc = 'SUBJECT_JOB_TITLE_DESC',
  SubjectLegalRepresentativeAsc = 'SUBJECT_LEGAL_REPRESENTATIVE_ASC',
  SubjectLegalRepresentativeDesc = 'SUBJECT_LEGAL_REPRESENTATIVE_DESC',
  SubjectNitAsc = 'SUBJECT_NIT_ASC',
  SubjectNitDesc = 'SUBJECT_NIT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC'
}

export type ViewInvoice = {
  __typename?: 'ViewInvoice';
  additionalDiscount?: Maybe<Scalars['BigFloat']>;
  businessName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  date?: Maybe<Scalars['Date']>;
  documentNumber?: Maybe<Scalars['String']>;
  documentTypeCode?: Maybe<Scalars['Int']>;
  documentTypeDescription?: Maybe<Scalars['String']>;
  issuedAt?: Maybe<Scalars['Datetime']>;
  key?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['BigInt']>;
  status?: Maybe<InvoiceStatus>;
  subtotal?: Maybe<Scalars['BigFloat']>;
  totalAmount?: Maybe<Scalars['BigFloat']>;
  totalAmountCurrency?: Maybe<Scalars['BigFloat']>;
  totalAmountSubjectToIva?: Maybe<Scalars['BigFloat']>;
  totalAmountToPay?: Maybe<Scalars['BigFloat']>;
  verificationStatus?: Maybe<InvoiceStatus>;
};

/**
 * A condition to be used against `ViewInvoice` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type ViewInvoiceCondition = {
  /** Checks for equality with the object’s `additionalDiscount` field. */
  additionalDiscount?: InputMaybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `businessName` field. */
  businessName?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `date` field. */
  date?: InputMaybe<Scalars['Date']>;
  /** Checks for equality with the object’s `documentNumber` field. */
  documentNumber?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `documentTypeCode` field. */
  documentTypeCode?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `documentTypeDescription` field. */
  documentTypeDescription?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `issuedAt` field. */
  issuedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `key` field. */
  key?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `number` field. */
  number?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `status` field. */
  status?: InputMaybe<InvoiceStatus>;
  /** Checks for equality with the object’s `subtotal` field. */
  subtotal?: InputMaybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `totalAmount` field. */
  totalAmount?: InputMaybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `totalAmountCurrency` field. */
  totalAmountCurrency?: InputMaybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `totalAmountSubjectToIva` field. */
  totalAmountSubjectToIva?: InputMaybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `totalAmountToPay` field. */
  totalAmountToPay?: InputMaybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `verificationStatus` field. */
  verificationStatus?: InputMaybe<InvoiceStatus>;
};

/** A filter to be used against `ViewInvoice` object types. All fields are combined with a logical ‘and.’ */
export type ViewInvoiceFilter = {
  /** Filter by the object’s `additionalDiscount` field. */
  additionalDiscount?: InputMaybe<BigFloatFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ViewInvoiceFilter>>;
  /** Filter by the object’s `businessName` field. */
  businessName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `date` field. */
  date?: InputMaybe<DateFilter>;
  /** Filter by the object’s `documentNumber` field. */
  documentNumber?: InputMaybe<StringFilter>;
  /** Filter by the object’s `documentTypeCode` field. */
  documentTypeCode?: InputMaybe<IntFilter>;
  /** Filter by the object’s `documentTypeDescription` field. */
  documentTypeDescription?: InputMaybe<StringFilter>;
  /** Filter by the object’s `issuedAt` field. */
  issuedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `key` field. */
  key?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ViewInvoiceFilter>;
  /** Filter by the object’s `number` field. */
  number?: InputMaybe<BigIntFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ViewInvoiceFilter>>;
  /** Filter by the object’s `status` field. */
  status?: InputMaybe<InvoiceStatusFilter>;
  /** Filter by the object’s `subtotal` field. */
  subtotal?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `totalAmount` field. */
  totalAmount?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `totalAmountCurrency` field. */
  totalAmountCurrency?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `totalAmountSubjectToIva` field. */
  totalAmountSubjectToIva?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `totalAmountToPay` field. */
  totalAmountToPay?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `verificationStatus` field. */
  verificationStatus?: InputMaybe<InvoiceStatusFilter>;
};

/** A connection to a list of `ViewInvoice` values. */
export type ViewInvoicesConnection = {
  __typename?: 'ViewInvoicesConnection';
  /** A list of edges which contains the `ViewInvoice` and cursor to aid in pagination. */
  edges: Array<ViewInvoicesEdge>;
  /** A list of `ViewInvoice` objects. */
  nodes: Array<Maybe<ViewInvoice>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ViewInvoice` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ViewInvoice` edge in the connection. */
export type ViewInvoicesEdge = {
  __typename?: 'ViewInvoicesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `ViewInvoice` at the end of the edge. */
  node?: Maybe<ViewInvoice>;
};

/** Methods to use when ordering `ViewInvoice`. */
export enum ViewInvoicesOrderBy {
  AdditionalDiscountAsc = 'ADDITIONAL_DISCOUNT_ASC',
  AdditionalDiscountDesc = 'ADDITIONAL_DISCOUNT_DESC',
  BusinessNameAsc = 'BUSINESS_NAME_ASC',
  BusinessNameDesc = 'BUSINESS_NAME_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  DateAsc = 'DATE_ASC',
  DateDesc = 'DATE_DESC',
  DocumentNumberAsc = 'DOCUMENT_NUMBER_ASC',
  DocumentNumberDesc = 'DOCUMENT_NUMBER_DESC',
  DocumentTypeCodeAsc = 'DOCUMENT_TYPE_CODE_ASC',
  DocumentTypeCodeDesc = 'DOCUMENT_TYPE_CODE_DESC',
  DocumentTypeDescriptionAsc = 'DOCUMENT_TYPE_DESCRIPTION_ASC',
  DocumentTypeDescriptionDesc = 'DOCUMENT_TYPE_DESCRIPTION_DESC',
  IssuedAtAsc = 'ISSUED_AT_ASC',
  IssuedAtDesc = 'ISSUED_AT_DESC',
  KeyAsc = 'KEY_ASC',
  KeyDesc = 'KEY_DESC',
  Natural = 'NATURAL',
  NumberAsc = 'NUMBER_ASC',
  NumberDesc = 'NUMBER_DESC',
  StatusAsc = 'STATUS_ASC',
  StatusDesc = 'STATUS_DESC',
  SubtotalAsc = 'SUBTOTAL_ASC',
  SubtotalDesc = 'SUBTOTAL_DESC',
  TotalAmountAsc = 'TOTAL_AMOUNT_ASC',
  TotalAmountCurrencyAsc = 'TOTAL_AMOUNT_CURRENCY_ASC',
  TotalAmountCurrencyDesc = 'TOTAL_AMOUNT_CURRENCY_DESC',
  TotalAmountDesc = 'TOTAL_AMOUNT_DESC',
  TotalAmountSubjectToIvaAsc = 'TOTAL_AMOUNT_SUBJECT_TO_IVA_ASC',
  TotalAmountSubjectToIvaDesc = 'TOTAL_AMOUNT_SUBJECT_TO_IVA_DESC',
  TotalAmountToPayAsc = 'TOTAL_AMOUNT_TO_PAY_ASC',
  TotalAmountToPayDesc = 'TOTAL_AMOUNT_TO_PAY_DESC',
  VerificationStatusAsc = 'VERIFICATION_STATUS_ASC',
  VerificationStatusDesc = 'VERIFICATION_STATUS_DESC'
}

export type ViewOrder = {
  __typename?: 'ViewOrder';
  businessName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  date?: Maybe<Scalars['Date']>;
  discount?: Maybe<Scalars['BigFloat']>;
  documentNumber?: Maybe<Scalars['String']>;
  documentTypeCode?: Maybe<Scalars['Int']>;
  documentTypeDescription?: Maybe<Scalars['String']>;
  invoiceIssuedAt?: Maybe<Scalars['Datetime']>;
  invoiceStatus?: Maybe<InvoiceStatus>;
  invoiceVerificationStatus?: Maybe<InvoiceStatus>;
  key?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  subtotal?: Maybe<Scalars['BigFloat']>;
  total?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `ViewOrder` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type ViewOrderCondition = {
  /** Checks for equality with the object’s `businessName` field. */
  businessName?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `date` field. */
  date?: InputMaybe<Scalars['Date']>;
  /** Checks for equality with the object’s `discount` field. */
  discount?: InputMaybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `documentNumber` field. */
  documentNumber?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `documentTypeCode` field. */
  documentTypeCode?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `documentTypeDescription` field. */
  documentTypeDescription?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `invoiceIssuedAt` field. */
  invoiceIssuedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `invoiceStatus` field. */
  invoiceStatus?: InputMaybe<InvoiceStatus>;
  /** Checks for equality with the object’s `invoiceVerificationStatus` field. */
  invoiceVerificationStatus?: InputMaybe<InvoiceStatus>;
  /** Checks for equality with the object’s `key` field. */
  key?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `number` field. */
  number?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `subtotal` field. */
  subtotal?: InputMaybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `total` field. */
  total?: InputMaybe<Scalars['BigFloat']>;
};

/** A filter to be used against `ViewOrder` object types. All fields are combined with a logical ‘and.’ */
export type ViewOrderFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ViewOrderFilter>>;
  /** Filter by the object’s `businessName` field. */
  businessName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `date` field. */
  date?: InputMaybe<DateFilter>;
  /** Filter by the object’s `discount` field. */
  discount?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `documentNumber` field. */
  documentNumber?: InputMaybe<StringFilter>;
  /** Filter by the object’s `documentTypeCode` field. */
  documentTypeCode?: InputMaybe<IntFilter>;
  /** Filter by the object’s `documentTypeDescription` field. */
  documentTypeDescription?: InputMaybe<StringFilter>;
  /** Filter by the object’s `invoiceIssuedAt` field. */
  invoiceIssuedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `invoiceStatus` field. */
  invoiceStatus?: InputMaybe<InvoiceStatusFilter>;
  /** Filter by the object’s `invoiceVerificationStatus` field. */
  invoiceVerificationStatus?: InputMaybe<InvoiceStatusFilter>;
  /** Filter by the object’s `key` field. */
  key?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ViewOrderFilter>;
  /** Filter by the object’s `number` field. */
  number?: InputMaybe<StringFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ViewOrderFilter>>;
  /** Filter by the object’s `subtotal` field. */
  subtotal?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `total` field. */
  total?: InputMaybe<BigFloatFilter>;
};

/** A connection to a list of `ViewOrder` values. */
export type ViewOrdersConnection = {
  __typename?: 'ViewOrdersConnection';
  /** A list of edges which contains the `ViewOrder` and cursor to aid in pagination. */
  edges: Array<ViewOrdersEdge>;
  /** A list of `ViewOrder` objects. */
  nodes: Array<Maybe<ViewOrder>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ViewOrder` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ViewOrder` edge in the connection. */
export type ViewOrdersEdge = {
  __typename?: 'ViewOrdersEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `ViewOrder` at the end of the edge. */
  node?: Maybe<ViewOrder>;
};

/** Methods to use when ordering `ViewOrder`. */
export enum ViewOrdersOrderBy {
  BusinessNameAsc = 'BUSINESS_NAME_ASC',
  BusinessNameDesc = 'BUSINESS_NAME_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  DateAsc = 'DATE_ASC',
  DateDesc = 'DATE_DESC',
  DiscountAsc = 'DISCOUNT_ASC',
  DiscountDesc = 'DISCOUNT_DESC',
  DocumentNumberAsc = 'DOCUMENT_NUMBER_ASC',
  DocumentNumberDesc = 'DOCUMENT_NUMBER_DESC',
  DocumentTypeCodeAsc = 'DOCUMENT_TYPE_CODE_ASC',
  DocumentTypeCodeDesc = 'DOCUMENT_TYPE_CODE_DESC',
  DocumentTypeDescriptionAsc = 'DOCUMENT_TYPE_DESCRIPTION_ASC',
  DocumentTypeDescriptionDesc = 'DOCUMENT_TYPE_DESCRIPTION_DESC',
  InvoiceIssuedAtAsc = 'INVOICE_ISSUED_AT_ASC',
  InvoiceIssuedAtDesc = 'INVOICE_ISSUED_AT_DESC',
  InvoiceStatusAsc = 'INVOICE_STATUS_ASC',
  InvoiceStatusDesc = 'INVOICE_STATUS_DESC',
  InvoiceVerificationStatusAsc = 'INVOICE_VERIFICATION_STATUS_ASC',
  InvoiceVerificationStatusDesc = 'INVOICE_VERIFICATION_STATUS_DESC',
  KeyAsc = 'KEY_ASC',
  KeyDesc = 'KEY_DESC',
  Natural = 'NATURAL',
  NumberAsc = 'NUMBER_ASC',
  NumberDesc = 'NUMBER_DESC',
  SubtotalAsc = 'SUBTOTAL_ASC',
  SubtotalDesc = 'SUBTOTAL_DESC',
  TotalAsc = 'TOTAL_ASC',
  TotalDesc = 'TOTAL_DESC'
}

export type ViewPackage = {
  __typename?: 'ViewPackage';
  createdAt?: Maybe<Scalars['Datetime']>;
  invoiceTypeCode?: Maybe<Scalars['Int']>;
  key?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  sectorDocumentCode?: Maybe<Scalars['Int']>;
  sectorDocumentDescription?: Maybe<Scalars['String']>;
  sendingStatus?: Maybe<SendingStatus>;
  status?: Maybe<PackageStatus>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  validationSendingStatus?: Maybe<SendingStatus>;
  validationStatus?: Maybe<PackageValidationStatus>;
};

/**
 * A condition to be used against `ViewPackage` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type ViewPackageCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `invoiceTypeCode` field. */
  invoiceTypeCode?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `key` field. */
  key?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `quantity` field. */
  quantity?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `sectorDocumentCode` field. */
  sectorDocumentCode?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `sectorDocumentDescription` field. */
  sectorDocumentDescription?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `sendingStatus` field. */
  sendingStatus?: InputMaybe<SendingStatus>;
  /** Checks for equality with the object’s `status` field. */
  status?: InputMaybe<PackageStatus>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `validationSendingStatus` field. */
  validationSendingStatus?: InputMaybe<SendingStatus>;
  /** Checks for equality with the object’s `validationStatus` field. */
  validationStatus?: InputMaybe<PackageValidationStatus>;
};

/** A filter to be used against `ViewPackage` object types. All fields are combined with a logical ‘and.’ */
export type ViewPackageFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ViewPackageFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `invoiceTypeCode` field. */
  invoiceTypeCode?: InputMaybe<IntFilter>;
  /** Filter by the object’s `key` field. */
  key?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ViewPackageFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ViewPackageFilter>>;
  /** Filter by the object’s `quantity` field. */
  quantity?: InputMaybe<IntFilter>;
  /** Filter by the object’s `sectorDocumentCode` field. */
  sectorDocumentCode?: InputMaybe<IntFilter>;
  /** Filter by the object’s `sectorDocumentDescription` field. */
  sectorDocumentDescription?: InputMaybe<StringFilter>;
  /** Filter by the object’s `sendingStatus` field. */
  sendingStatus?: InputMaybe<SendingStatusFilter>;
  /** Filter by the object’s `status` field. */
  status?: InputMaybe<PackageStatusFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `validationSendingStatus` field. */
  validationSendingStatus?: InputMaybe<SendingStatusFilter>;
  /** Filter by the object’s `validationStatus` field. */
  validationStatus?: InputMaybe<PackageValidationStatusFilter>;
};

/** A connection to a list of `ViewPackage` values. */
export type ViewPackagesConnection = {
  __typename?: 'ViewPackagesConnection';
  /** A list of edges which contains the `ViewPackage` and cursor to aid in pagination. */
  edges: Array<ViewPackagesEdge>;
  /** A list of `ViewPackage` objects. */
  nodes: Array<Maybe<ViewPackage>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ViewPackage` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ViewPackage` edge in the connection. */
export type ViewPackagesEdge = {
  __typename?: 'ViewPackagesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `ViewPackage` at the end of the edge. */
  node?: Maybe<ViewPackage>;
};

/** Methods to use when ordering `ViewPackage`. */
export enum ViewPackagesOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  InvoiceTypeCodeAsc = 'INVOICE_TYPE_CODE_ASC',
  InvoiceTypeCodeDesc = 'INVOICE_TYPE_CODE_DESC',
  KeyAsc = 'KEY_ASC',
  KeyDesc = 'KEY_DESC',
  Natural = 'NATURAL',
  QuantityAsc = 'QUANTITY_ASC',
  QuantityDesc = 'QUANTITY_DESC',
  SectorDocumentCodeAsc = 'SECTOR_DOCUMENT_CODE_ASC',
  SectorDocumentCodeDesc = 'SECTOR_DOCUMENT_CODE_DESC',
  SectorDocumentDescriptionAsc = 'SECTOR_DOCUMENT_DESCRIPTION_ASC',
  SectorDocumentDescriptionDesc = 'SECTOR_DOCUMENT_DESCRIPTION_DESC',
  SendingStatusAsc = 'SENDING_STATUS_ASC',
  SendingStatusDesc = 'SENDING_STATUS_DESC',
  StatusAsc = 'STATUS_ASC',
  StatusDesc = 'STATUS_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  ValidationSendingStatusAsc = 'VALIDATION_SENDING_STATUS_ASC',
  ValidationSendingStatusDesc = 'VALIDATION_SENDING_STATUS_DESC',
  ValidationStatusAsc = 'VALIDATION_STATUS_ASC',
  ValidationStatusDesc = 'VALIDATION_STATUS_DESC'
}

export type ViewPaymentMethod = {
  __typename?: 'ViewPaymentMethod';
  classifierCode?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<SyncParameterType>;
};

/**
 * A condition to be used against `ViewPaymentMethod` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type ViewPaymentMethodCondition = {
  /** Checks for equality with the object’s `classifierCode` field. */
  classifierCode?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `description` field. */
  description?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `type` field. */
  type?: InputMaybe<SyncParameterType>;
};

/** A filter to be used against `ViewPaymentMethod` object types. All fields are combined with a logical ‘and.’ */
export type ViewPaymentMethodFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ViewPaymentMethodFilter>>;
  /** Filter by the object’s `classifierCode` field. */
  classifierCode?: InputMaybe<IntFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<StringFilter>;
  /** Filter by the object’s `description` field. */
  description?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ViewPaymentMethodFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ViewPaymentMethodFilter>>;
  /** Filter by the object’s `type` field. */
  type?: InputMaybe<SyncParameterTypeFilter>;
};

/** A connection to a list of `ViewPaymentMethod` values. */
export type ViewPaymentMethodsConnection = {
  __typename?: 'ViewPaymentMethodsConnection';
  /** A list of edges which contains the `ViewPaymentMethod` and cursor to aid in pagination. */
  edges: Array<ViewPaymentMethodsEdge>;
  /** A list of `ViewPaymentMethod` objects. */
  nodes: Array<Maybe<ViewPaymentMethod>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ViewPaymentMethod` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ViewPaymentMethod` edge in the connection. */
export type ViewPaymentMethodsEdge = {
  __typename?: 'ViewPaymentMethodsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `ViewPaymentMethod` at the end of the edge. */
  node?: Maybe<ViewPaymentMethod>;
};

/** Methods to use when ordering `ViewPaymentMethod`. */
export enum ViewPaymentMethodsOrderBy {
  ClassifierCodeAsc = 'CLASSIFIER_CODE_ASC',
  ClassifierCodeDesc = 'CLASSIFIER_CODE_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  DescriptionAsc = 'DESCRIPTION_ASC',
  DescriptionDesc = 'DESCRIPTION_DESC',
  Natural = 'NATURAL',
  TypeAsc = 'TYPE_ASC',
  TypeDesc = 'TYPE_DESC'
}

export type ViewPointOfSale = {
  __typename?: 'ViewPointOfSale';
  branchOfficeCode?: Maybe<Scalars['Int']>;
  branchOfficeName?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['Int']>;
  commissionAgentNit?: Maybe<Scalars['BigInt']>;
  contractNumber?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  cufd?: Maybe<Scalars['String']>;
  cuis?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  finishDate?: Maybe<Scalars['Datetime']>;
  key?: Maybe<Scalars['String']>;
  modalityCode?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['Datetime']>;
  status?: Maybe<PointOfSaleStatus>;
  typeCode?: Maybe<Scalars['Int']>;
  typeDescription?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
};

/**
 * A condition to be used against `ViewPointOfSale` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type ViewPointOfSaleCondition = {
  /** Checks for equality with the object’s `branchOfficeCode` field. */
  branchOfficeCode?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `branchOfficeName` field. */
  branchOfficeName?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `code` field. */
  code?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `commissionAgentNit` field. */
  commissionAgentNit?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `contractNumber` field. */
  contractNumber?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `cufd` field. */
  cufd?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `cuis` field. */
  cuis?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `description` field. */
  description?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `finishDate` field. */
  finishDate?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `key` field. */
  key?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `modalityCode` field. */
  modalityCode?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `startDate` field. */
  startDate?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `status` field. */
  status?: InputMaybe<PointOfSaleStatus>;
  /** Checks for equality with the object’s `typeCode` field. */
  typeCode?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `typeDescription` field. */
  typeDescription?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** A filter to be used against `ViewPointOfSale` object types. All fields are combined with a logical ‘and.’ */
export type ViewPointOfSaleFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ViewPointOfSaleFilter>>;
  /** Filter by the object’s `branchOfficeCode` field. */
  branchOfficeCode?: InputMaybe<IntFilter>;
  /** Filter by the object’s `branchOfficeName` field. */
  branchOfficeName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `code` field. */
  code?: InputMaybe<IntFilter>;
  /** Filter by the object’s `commissionAgentNit` field. */
  commissionAgentNit?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `contractNumber` field. */
  contractNumber?: InputMaybe<StringFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `cufd` field. */
  cufd?: InputMaybe<StringFilter>;
  /** Filter by the object’s `cuis` field. */
  cuis?: InputMaybe<StringFilter>;
  /** Filter by the object’s `description` field. */
  description?: InputMaybe<StringFilter>;
  /** Filter by the object’s `finishDate` field. */
  finishDate?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `key` field. */
  key?: InputMaybe<StringFilter>;
  /** Filter by the object’s `modalityCode` field. */
  modalityCode?: InputMaybe<IntFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ViewPointOfSaleFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ViewPointOfSaleFilter>>;
  /** Filter by the object’s `startDate` field. */
  startDate?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `status` field. */
  status?: InputMaybe<PointOfSaleStatusFilter>;
  /** Filter by the object’s `typeCode` field. */
  typeCode?: InputMaybe<IntFilter>;
  /** Filter by the object’s `typeDescription` field. */
  typeDescription?: InputMaybe<StringFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
};

/** A connection to a list of `ViewPointOfSale` values. */
export type ViewPointOfSalesConnection = {
  __typename?: 'ViewPointOfSalesConnection';
  /** A list of edges which contains the `ViewPointOfSale` and cursor to aid in pagination. */
  edges: Array<ViewPointOfSalesEdge>;
  /** A list of `ViewPointOfSale` objects. */
  nodes: Array<Maybe<ViewPointOfSale>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ViewPointOfSale` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ViewPointOfSale` edge in the connection. */
export type ViewPointOfSalesEdge = {
  __typename?: 'ViewPointOfSalesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `ViewPointOfSale` at the end of the edge. */
  node?: Maybe<ViewPointOfSale>;
};

/** Methods to use when ordering `ViewPointOfSale`. */
export enum ViewPointOfSalesOrderBy {
  BranchOfficeCodeAsc = 'BRANCH_OFFICE_CODE_ASC',
  BranchOfficeCodeDesc = 'BRANCH_OFFICE_CODE_DESC',
  BranchOfficeNameAsc = 'BRANCH_OFFICE_NAME_ASC',
  BranchOfficeNameDesc = 'BRANCH_OFFICE_NAME_DESC',
  CodeAsc = 'CODE_ASC',
  CodeDesc = 'CODE_DESC',
  CommissionAgentNitAsc = 'COMMISSION_AGENT_NIT_ASC',
  CommissionAgentNitDesc = 'COMMISSION_AGENT_NIT_DESC',
  ContractNumberAsc = 'CONTRACT_NUMBER_ASC',
  ContractNumberDesc = 'CONTRACT_NUMBER_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CufdAsc = 'CUFD_ASC',
  CufdDesc = 'CUFD_DESC',
  CuisAsc = 'CUIS_ASC',
  CuisDesc = 'CUIS_DESC',
  DescriptionAsc = 'DESCRIPTION_ASC',
  DescriptionDesc = 'DESCRIPTION_DESC',
  FinishDateAsc = 'FINISH_DATE_ASC',
  FinishDateDesc = 'FINISH_DATE_DESC',
  KeyAsc = 'KEY_ASC',
  KeyDesc = 'KEY_DESC',
  ModalityCodeAsc = 'MODALITY_CODE_ASC',
  ModalityCodeDesc = 'MODALITY_CODE_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  StartDateAsc = 'START_DATE_ASC',
  StartDateDesc = 'START_DATE_DESC',
  StatusAsc = 'STATUS_ASC',
  StatusDesc = 'STATUS_DESC',
  TypeCodeAsc = 'TYPE_CODE_ASC',
  TypeCodeDesc = 'TYPE_CODE_DESC',
  TypeDescriptionAsc = 'TYPE_DESCRIPTION_ASC',
  TypeDescriptionDesc = 'TYPE_DESCRIPTION_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC'
}

export type ViewProduct = {
  __typename?: 'ViewProduct';
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  description?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['BigFloat']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
};

/**
 * A condition to be used against `ViewProduct` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type ViewProductCondition = {
  /** Checks for equality with the object’s `code` field. */
  code?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `description` field. */
  description?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `key` field. */
  key?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `price` field. */
  price?: InputMaybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** A filter to be used against `ViewProduct` object types. All fields are combined with a logical ‘and.’ */
export type ViewProductFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ViewProductFilter>>;
  /** Filter by the object’s `code` field. */
  code?: InputMaybe<StringFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `description` field. */
  description?: InputMaybe<StringFilter>;
  /** Filter by the object’s `key` field. */
  key?: InputMaybe<StringFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ViewProductFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ViewProductFilter>>;
  /** Filter by the object’s `price` field. */
  price?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
};

/** An input for mutations affecting `ViewProduct` */
export type ViewProductInput = {
  code?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  description?: InputMaybe<Scalars['String']>;
  key?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['BigFloat']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** A connection to a list of `ViewProduct` values. */
export type ViewProductsConnection = {
  __typename?: 'ViewProductsConnection';
  /** A list of edges which contains the `ViewProduct` and cursor to aid in pagination. */
  edges: Array<ViewProductsEdge>;
  /** A list of `ViewProduct` objects. */
  nodes: Array<Maybe<ViewProduct>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ViewProduct` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ViewProduct` edge in the connection. */
export type ViewProductsEdge = {
  __typename?: 'ViewProductsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `ViewProduct` at the end of the edge. */
  node?: Maybe<ViewProduct>;
};

/** Methods to use when ordering `ViewProduct`. */
export enum ViewProductsOrderBy {
  CodeAsc = 'CODE_ASC',
  CodeDesc = 'CODE_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  DescriptionAsc = 'DESCRIPTION_ASC',
  DescriptionDesc = 'DESCRIPTION_DESC',
  KeyAsc = 'KEY_ASC',
  KeyDesc = 'KEY_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  PriceAsc = 'PRICE_ASC',
  PriceDesc = 'PRICE_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC'
}

export type ViewSignificantEvent = {
  __typename?: 'ViewSignificantEvent';
  branchOfficeCode?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['Int']>;
  cufd?: Maybe<Scalars['String']>;
  cufdEvent?: Maybe<Scalars['String']>;
  cuis?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  finishDate?: Maybe<Scalars['Datetime']>;
  id?: Maybe<Scalars['Int']>;
  key?: Maybe<Scalars['String']>;
  pointOfSaleCode?: Maybe<Scalars['Int']>;
  reasonCode?: Maybe<Scalars['Int']>;
  reasonDescription?: Maybe<Scalars['String']>;
  receptionCode?: Maybe<Scalars['BigInt']>;
  sendingStatus?: Maybe<SendingStatus>;
  sentAt?: Maybe<Scalars['Datetime']>;
  sentBy?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['Datetime']>;
  status?: Maybe<SignificantEventStatus>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['Int']>;
};

/**
 * A condition to be used against `ViewSignificantEvent` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type ViewSignificantEventCondition = {
  /** Checks for equality with the object’s `branchOfficeCode` field. */
  branchOfficeCode?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `cufd` field. */
  cufd?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `cufdEvent` field. */
  cufdEvent?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `cuis` field. */
  cuis?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `description` field. */
  description?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `finishDate` field. */
  finishDate?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `key` field. */
  key?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `pointOfSaleCode` field. */
  pointOfSaleCode?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `reasonCode` field. */
  reasonCode?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `reasonDescription` field. */
  reasonDescription?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `receptionCode` field. */
  receptionCode?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `sendingStatus` field. */
  sendingStatus?: InputMaybe<SendingStatus>;
  /** Checks for equality with the object’s `sentAt` field. */
  sentAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `sentBy` field. */
  sentBy?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `startDate` field. */
  startDate?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `status` field. */
  status?: InputMaybe<SignificantEventStatus>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedBy` field. */
  updatedBy?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against `ViewSignificantEvent` object types. All fields are combined with a logical ‘and.’ */
export type ViewSignificantEventFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ViewSignificantEventFilter>>;
  /** Filter by the object’s `branchOfficeCode` field. */
  branchOfficeCode?: InputMaybe<IntFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy?: InputMaybe<IntFilter>;
  /** Filter by the object’s `cufd` field. */
  cufd?: InputMaybe<StringFilter>;
  /** Filter by the object’s `cufdEvent` field. */
  cufdEvent?: InputMaybe<StringFilter>;
  /** Filter by the object’s `cuis` field. */
  cuis?: InputMaybe<StringFilter>;
  /** Filter by the object’s `description` field. */
  description?: InputMaybe<StringFilter>;
  /** Filter by the object’s `finishDate` field. */
  finishDate?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `key` field. */
  key?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ViewSignificantEventFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ViewSignificantEventFilter>>;
  /** Filter by the object’s `pointOfSaleCode` field. */
  pointOfSaleCode?: InputMaybe<IntFilter>;
  /** Filter by the object’s `reasonCode` field. */
  reasonCode?: InputMaybe<IntFilter>;
  /** Filter by the object’s `reasonDescription` field. */
  reasonDescription?: InputMaybe<StringFilter>;
  /** Filter by the object’s `receptionCode` field. */
  receptionCode?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `sendingStatus` field. */
  sendingStatus?: InputMaybe<SendingStatusFilter>;
  /** Filter by the object’s `sentAt` field. */
  sentAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `sentBy` field. */
  sentBy?: InputMaybe<IntFilter>;
  /** Filter by the object’s `startDate` field. */
  startDate?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `status` field. */
  status?: InputMaybe<SignificantEventStatusFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `updatedBy` field. */
  updatedBy?: InputMaybe<IntFilter>;
};

/** A connection to a list of `ViewSignificantEvent` values. */
export type ViewSignificantEventsConnection = {
  __typename?: 'ViewSignificantEventsConnection';
  /** A list of edges which contains the `ViewSignificantEvent` and cursor to aid in pagination. */
  edges: Array<ViewSignificantEventsEdge>;
  /** A list of `ViewSignificantEvent` objects. */
  nodes: Array<Maybe<ViewSignificantEvent>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ViewSignificantEvent` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ViewSignificantEvent` edge in the connection. */
export type ViewSignificantEventsEdge = {
  __typename?: 'ViewSignificantEventsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `ViewSignificantEvent` at the end of the edge. */
  node?: Maybe<ViewSignificantEvent>;
};

/** Methods to use when ordering `ViewSignificantEvent`. */
export enum ViewSignificantEventsOrderBy {
  BranchOfficeCodeAsc = 'BRANCH_OFFICE_CODE_ASC',
  BranchOfficeCodeDesc = 'BRANCH_OFFICE_CODE_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  CufdAsc = 'CUFD_ASC',
  CufdDesc = 'CUFD_DESC',
  CufdEventAsc = 'CUFD_EVENT_ASC',
  CufdEventDesc = 'CUFD_EVENT_DESC',
  CuisAsc = 'CUIS_ASC',
  CuisDesc = 'CUIS_DESC',
  DescriptionAsc = 'DESCRIPTION_ASC',
  DescriptionDesc = 'DESCRIPTION_DESC',
  FinishDateAsc = 'FINISH_DATE_ASC',
  FinishDateDesc = 'FINISH_DATE_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  KeyAsc = 'KEY_ASC',
  KeyDesc = 'KEY_DESC',
  Natural = 'NATURAL',
  PointOfSaleCodeAsc = 'POINT_OF_SALE_CODE_ASC',
  PointOfSaleCodeDesc = 'POINT_OF_SALE_CODE_DESC',
  ReasonCodeAsc = 'REASON_CODE_ASC',
  ReasonCodeDesc = 'REASON_CODE_DESC',
  ReasonDescriptionAsc = 'REASON_DESCRIPTION_ASC',
  ReasonDescriptionDesc = 'REASON_DESCRIPTION_DESC',
  ReceptionCodeAsc = 'RECEPTION_CODE_ASC',
  ReceptionCodeDesc = 'RECEPTION_CODE_DESC',
  SendingStatusAsc = 'SENDING_STATUS_ASC',
  SendingStatusDesc = 'SENDING_STATUS_DESC',
  SentAtAsc = 'SENT_AT_ASC',
  SentAtDesc = 'SENT_AT_DESC',
  SentByAsc = 'SENT_BY_ASC',
  SentByDesc = 'SENT_BY_DESC',
  StartDateAsc = 'START_DATE_ASC',
  StartDateDesc = 'START_DATE_DESC',
  StatusAsc = 'STATUS_ASC',
  StatusDesc = 'STATUS_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UpdatedByAsc = 'UPDATED_BY_ASC',
  UpdatedByDesc = 'UPDATED_BY_DESC'
}

export type ViewSyncParameter = {
  __typename?: 'ViewSyncParameter';
  classifierCode?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<SyncParameterType>;
};

/**
 * A condition to be used against `ViewSyncParameter` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type ViewSyncParameterCondition = {
  /** Checks for equality with the object’s `classifierCode` field. */
  classifierCode?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `description` field. */
  description?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `type` field. */
  type?: InputMaybe<SyncParameterType>;
};

/** A filter to be used against `ViewSyncParameter` object types. All fields are combined with a logical ‘and.’ */
export type ViewSyncParameterFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ViewSyncParameterFilter>>;
  /** Filter by the object’s `classifierCode` field. */
  classifierCode?: InputMaybe<IntFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `description` field. */
  description?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ViewSyncParameterFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ViewSyncParameterFilter>>;
  /** Filter by the object’s `type` field. */
  type?: InputMaybe<SyncParameterTypeFilter>;
};

/** An input for mutations affecting `ViewSyncParameter` */
export type ViewSyncParameterInput = {
  classifierCode?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  description?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<SyncParameterType>;
};

/** A connection to a list of `ViewSyncParameter` values. */
export type ViewSyncParametersConnection = {
  __typename?: 'ViewSyncParametersConnection';
  /** A list of edges which contains the `ViewSyncParameter` and cursor to aid in pagination. */
  edges: Array<ViewSyncParametersEdge>;
  /** A list of `ViewSyncParameter` objects. */
  nodes: Array<Maybe<ViewSyncParameter>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ViewSyncParameter` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ViewSyncParameter` edge in the connection. */
export type ViewSyncParametersEdge = {
  __typename?: 'ViewSyncParametersEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `ViewSyncParameter` at the end of the edge. */
  node?: Maybe<ViewSyncParameter>;
};

/** Methods to use when ordering `ViewSyncParameter`. */
export enum ViewSyncParametersOrderBy {
  ClassifierCodeAsc = 'CLASSIFIER_CODE_ASC',
  ClassifierCodeDesc = 'CLASSIFIER_CODE_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  DescriptionAsc = 'DESCRIPTION_ASC',
  DescriptionDesc = 'DESCRIPTION_DESC',
  Natural = 'NATURAL',
  TypeAsc = 'TYPE_ASC',
  TypeDesc = 'TYPE_DESC'
}

export type ViewSyncProduct = {
  __typename?: 'ViewSyncProduct';
  activityCode?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  description?: Maybe<Scalars['String']>;
};

/**
 * A condition to be used against `ViewSyncProduct` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type ViewSyncProductCondition = {
  /** Checks for equality with the object’s `activityCode` field. */
  activityCode?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `code` field. */
  code?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `description` field. */
  description?: InputMaybe<Scalars['String']>;
};

/** A filter to be used against `ViewSyncProduct` object types. All fields are combined with a logical ‘and.’ */
export type ViewSyncProductFilter = {
  /** Filter by the object’s `activityCode` field. */
  activityCode?: InputMaybe<StringFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ViewSyncProductFilter>>;
  /** Filter by the object’s `code` field. */
  code?: InputMaybe<IntFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `description` field. */
  description?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ViewSyncProductFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ViewSyncProductFilter>>;
};

/** A connection to a list of `ViewSyncProduct` values. */
export type ViewSyncProductsConnection = {
  __typename?: 'ViewSyncProductsConnection';
  /** A list of edges which contains the `ViewSyncProduct` and cursor to aid in pagination. */
  edges: Array<ViewSyncProductsEdge>;
  /** A list of `ViewSyncProduct` objects. */
  nodes: Array<Maybe<ViewSyncProduct>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ViewSyncProduct` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ViewSyncProduct` edge in the connection. */
export type ViewSyncProductsEdge = {
  __typename?: 'ViewSyncProductsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `ViewSyncProduct` at the end of the edge. */
  node?: Maybe<ViewSyncProduct>;
};

/** Methods to use when ordering `ViewSyncProduct`. */
export enum ViewSyncProductsOrderBy {
  ActivityCodeAsc = 'ACTIVITY_CODE_ASC',
  ActivityCodeDesc = 'ACTIVITY_CODE_DESC',
  CodeAsc = 'CODE_ASC',
  CodeDesc = 'CODE_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  DescriptionAsc = 'DESCRIPTION_ASC',
  DescriptionDesc = 'DESCRIPTION_DESC',
  Natural = 'NATURAL'
}

export type ViewSyncRequest = {
  __typename?: 'ViewSyncRequest';
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['Int']>;
  key?: Maybe<Scalars['String']>;
  status?: Maybe<RequestStatus>;
  type?: Maybe<SyncRequestType>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['Int']>;
};

/**
 * A condition to be used against `ViewSyncRequest` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type ViewSyncRequestCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `key` field. */
  key?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `status` field. */
  status?: InputMaybe<RequestStatus>;
  /** Checks for equality with the object’s `type` field. */
  type?: InputMaybe<SyncRequestType>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedBy` field. */
  updatedBy?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against `ViewSyncRequest` object types. All fields are combined with a logical ‘and.’ */
export type ViewSyncRequestFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ViewSyncRequestFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy?: InputMaybe<IntFilter>;
  /** Filter by the object’s `key` field. */
  key?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ViewSyncRequestFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ViewSyncRequestFilter>>;
  /** Filter by the object’s `status` field. */
  status?: InputMaybe<RequestStatusFilter>;
  /** Filter by the object’s `type` field. */
  type?: InputMaybe<SyncRequestTypeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `updatedBy` field. */
  updatedBy?: InputMaybe<IntFilter>;
};

/** An input for mutations affecting `ViewSyncRequest` */
export type ViewSyncRequestInput = {
  createdAt?: InputMaybe<Scalars['Datetime']>;
  createdBy?: InputMaybe<Scalars['Int']>;
  key?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<RequestStatus>;
  type?: InputMaybe<SyncRequestType>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  updatedBy?: InputMaybe<Scalars['Int']>;
};

/** A connection to a list of `ViewSyncRequest` values. */
export type ViewSyncRequestsConnection = {
  __typename?: 'ViewSyncRequestsConnection';
  /** A list of edges which contains the `ViewSyncRequest` and cursor to aid in pagination. */
  edges: Array<ViewSyncRequestsEdge>;
  /** A list of `ViewSyncRequest` objects. */
  nodes: Array<Maybe<ViewSyncRequest>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ViewSyncRequest` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ViewSyncRequest` edge in the connection. */
export type ViewSyncRequestsEdge = {
  __typename?: 'ViewSyncRequestsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `ViewSyncRequest` at the end of the edge. */
  node?: Maybe<ViewSyncRequest>;
};

/** Methods to use when ordering `ViewSyncRequest`. */
export enum ViewSyncRequestsOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  KeyAsc = 'KEY_ASC',
  KeyDesc = 'KEY_DESC',
  Natural = 'NATURAL',
  StatusAsc = 'STATUS_ASC',
  StatusDesc = 'STATUS_DESC',
  TypeAsc = 'TYPE_ASC',
  TypeDesc = 'TYPE_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UpdatedByAsc = 'UPDATED_BY_ASC',
  UpdatedByDesc = 'UPDATED_BY_DESC'
}

export type ViewUser = {
  __typename?: 'ViewUser';
  createdAt?: Maybe<Scalars['Datetime']>;
  email?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  username?: Maybe<Scalars['String']>;
};

/**
 * A condition to be used against `ViewUser` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type ViewUserCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `email` field. */
  email?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `fullName` field. */
  fullName?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `key` field. */
  key?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `username` field. */
  username?: InputMaybe<Scalars['String']>;
};

/** A filter to be used against `ViewUser` object types. All fields are combined with a logical ‘and.’ */
export type ViewUserFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ViewUserFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `email` field. */
  email?: InputMaybe<StringFilter>;
  /** Filter by the object’s `fullName` field. */
  fullName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `key` field. */
  key?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ViewUserFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ViewUserFilter>>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `username` field. */
  username?: InputMaybe<StringFilter>;
};

/** A connection to a list of `ViewUser` values. */
export type ViewUsersConnection = {
  __typename?: 'ViewUsersConnection';
  /** A list of edges which contains the `ViewUser` and cursor to aid in pagination. */
  edges: Array<ViewUsersEdge>;
  /** A list of `ViewUser` objects. */
  nodes: Array<Maybe<ViewUser>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ViewUser` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ViewUser` edge in the connection. */
export type ViewUsersEdge = {
  __typename?: 'ViewUsersEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `ViewUser` at the end of the edge. */
  node?: Maybe<ViewUser>;
};

/** Methods to use when ordering `ViewUser`. */
export enum ViewUsersOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  EmailAsc = 'EMAIL_ASC',
  EmailDesc = 'EMAIL_DESC',
  FullNameAsc = 'FULL_NAME_ASC',
  FullNameDesc = 'FULL_NAME_DESC',
  KeyAsc = 'KEY_ASC',
  KeyDesc = 'KEY_DESC',
  Natural = 'NATURAL',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UsernameAsc = 'USERNAME_ASC',
  UsernameDesc = 'USERNAME_DESC'
}

/** An object where the defined keys will be set on the `branchOffice` being updated. */
export type UpdateBranchOfficeOnPointOfSaleForFkPointOfSaleToBranchOfficePatch = {
  address?: InputMaybe<Scalars['String']>;
  code?: InputMaybe<Scalars['Int']>;
  municipality?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  pointOfSales?: InputMaybe<FkPointOfSaleToBranchOfficeInverseInput>;
};

/** An object where the defined keys will be set on the `clientHomologation` being updated. */
export type UpdateClientHomologationOnClientHomologationForFkClientHomologationToClientPatch = {
  /** [SIN] codigoExcepcion */
  allowInvalidNit?: InputMaybe<Scalars['Boolean']>;
  client?: InputMaybe<FkClientHomologationToClientInput>;
  /** [SIN] complemento */
  documentComplement?: InputMaybe<Scalars['String']>;
  /** [SIN] numeroDocumento */
  documentNumber?: InputMaybe<Scalars['String']>;
  /** [SIN] codigoTipoDocumentoIdentidad */
  documentTypeCode?: InputMaybe<Scalars['Int']>;
};

/** An object where the defined keys will be set on the `client` being updated. */
export type UpdateClientOnClientHomologationForFkClientHomologationToClientPatch = {
  createdAt?: InputMaybe<Scalars['Datetime']>;
  editable?: InputMaybe<Scalars['Boolean']>;
  email?: InputMaybe<Scalars['String']>;
  homologations?: InputMaybe<FkClientHomologationToClientInverseInput>;
  name?: InputMaybe<Scalars['String']>;
  observation?: InputMaybe<Scalars['String']>;
  orders?: InputMaybe<FkOrderToClientInverseInput>;
  phoneNumber?: InputMaybe<Scalars['String']>;
};

/** An object where the defined keys will be set on the `client` being updated. */
export type UpdateClientOnOrderForFkOrderToClientPatch = {
  createdAt?: InputMaybe<Scalars['Datetime']>;
  editable?: InputMaybe<Scalars['Boolean']>;
  email?: InputMaybe<Scalars['String']>;
  homologations?: InputMaybe<FkClientHomologationToClientInverseInput>;
  name?: InputMaybe<Scalars['String']>;
  observation?: InputMaybe<Scalars['String']>;
  orders?: InputMaybe<FkOrderToClientInverseInput>;
  phoneNumber?: InputMaybe<Scalars['String']>;
};

/** An object where the defined keys will be set on the `exportServiceOrderDetail` being updated. */
export type UpdateExportServiceOrderDetailOnExportServiceOrderDetailForFkExportServiceOrderDetailToExportServiceOrderPatch = {
  discount?: InputMaybe<Scalars['BigFloat']>;
  exportServiceOrder?: InputMaybe<FkExportServiceOrderDetailToExportServiceOrderInput>;
  price?: InputMaybe<Scalars['BigFloat']>;
  productKey?: InputMaybe<Scalars['String']>;
  productService?: InputMaybe<FkExportServiceOrderDetailToProductInput>;
  quantity?: InputMaybe<Scalars['Int']>;
};

/** An object where the defined keys will be set on the `exportServiceOrderDetail` being updated. */
export type UpdateExportServiceOrderDetailOnExportServiceOrderDetailForFkExportServiceOrderDetailToProductPatch = {
  discount?: InputMaybe<Scalars['BigFloat']>;
  exportServiceOrder?: InputMaybe<FkExportServiceOrderDetailToExportServiceOrderInput>;
  exportServiceOrderKey?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['BigFloat']>;
  productService?: InputMaybe<FkExportServiceOrderDetailToProductInput>;
  quantity?: InputMaybe<Scalars['Int']>;
};

/** An object where the defined keys will be set on the `exportServiceOrder` being updated. */
export type UpdateExportServiceOrderOnExportServiceOrderDetailForFkExportServiceOrderDetailToExportServiceOrderPatch = {
  deletion?: InputMaybe<Scalars['Boolean']>;
  exportServiceOrderDetails?: InputMaybe<FkExportServiceOrderDetailToExportServiceOrderInverseInput>;
  order?: InputMaybe<FkExportServiceOrderToOrderInput>;
  orderKey?: InputMaybe<Scalars['String']>;
  sinCodigoPais?: InputMaybe<Scalars['Int']>;
  sinDireccionComprador?: InputMaybe<Scalars['String']>;
  sinInformacionAdicional?: InputMaybe<Scalars['String']>;
  sinLugarDestino?: InputMaybe<Scalars['String']>;
};

/** An object where the defined keys will be set on the `exportServiceOrder` being updated. */
export type UpdateExportServiceOrderOnExportServiceOrderForFkExportServiceOrderToOrderPatch = {
  deletion?: InputMaybe<Scalars['Boolean']>;
  exportServiceOrderDetails?: InputMaybe<FkExportServiceOrderDetailToExportServiceOrderInverseInput>;
  order?: InputMaybe<FkExportServiceOrderToOrderInput>;
  sinCodigoPais?: InputMaybe<Scalars['Int']>;
  sinDireccionComprador?: InputMaybe<Scalars['String']>;
  sinInformacionAdicional?: InputMaybe<Scalars['String']>;
  sinLugarDestino?: InputMaybe<Scalars['String']>;
};

/** An object where the defined keys will be set on the `insuranceOrderDetail` being updated. */
export type UpdateInsuranceOrderDetailOnInsuranceOrderDetailForFkInsuranceOrderDetailToInsuranceOrderPatch = {
  discount?: InputMaybe<Scalars['BigFloat']>;
  insuranceOrder?: InputMaybe<FkInsuranceOrderDetailToInsuranceOrderInput>;
  price?: InputMaybe<Scalars['BigFloat']>;
  productKey?: InputMaybe<Scalars['String']>;
  productService?: InputMaybe<FkInsuranceOrderDetailToProductInput>;
  quantity?: InputMaybe<Scalars['Int']>;
};

/** An object where the defined keys will be set on the `insuranceOrderDetail` being updated. */
export type UpdateInsuranceOrderDetailOnInsuranceOrderDetailForFkInsuranceOrderDetailToProductPatch = {
  discount?: InputMaybe<Scalars['BigFloat']>;
  insuranceOrder?: InputMaybe<FkInsuranceOrderDetailToInsuranceOrderInput>;
  insuranceOrderKey?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['BigFloat']>;
  productService?: InputMaybe<FkInsuranceOrderDetailToProductInput>;
  quantity?: InputMaybe<Scalars['Int']>;
};

/** An object where the defined keys will be set on the `insuranceOrder` being updated. */
export type UpdateInsuranceOrderOnInsuranceOrderDetailForFkInsuranceOrderDetailToInsuranceOrderPatch = {
  deletion?: InputMaybe<Scalars['Boolean']>;
  insuranceOrderDetails?: InputMaybe<FkInsuranceOrderDetailToInsuranceOrderInverseInput>;
  order?: InputMaybe<FkInsuranceOrderToOrderInput>;
  orderKey?: InputMaybe<Scalars['String']>;
  sinAjusteAfectacionIva?: InputMaybe<Scalars['BigFloat']>;
};

/** An object where the defined keys will be set on the `insuranceOrder` being updated. */
export type UpdateInsuranceOrderOnInsuranceOrderForFkInsuranceOrderToOrderPatch = {
  deletion?: InputMaybe<Scalars['Boolean']>;
  insuranceOrderDetails?: InputMaybe<FkInsuranceOrderDetailToInsuranceOrderInverseInput>;
  order?: InputMaybe<FkInsuranceOrderToOrderInput>;
  sinAjusteAfectacionIva?: InputMaybe<Scalars['BigFloat']>;
};

/** An object where the defined keys will be set on the `orderHomologation` being updated. */
export type UpdateOrderHomologationOnOrderHomologationForFkOrderHomologationToOrderPatch = {
  activityCode?: InputMaybe<Scalars['String']>;
  cardNumber?: InputMaybe<Scalars['BigInt']>;
  currencyCode?: InputMaybe<Scalars['Int']>;
  /**
   * [SIN] Utilizado para generar CUF
   * 1-4
   * 1=EN LINEA
   * 2=FUERA DE LINEA
   * 3=MASIVO
   * 4=CONTINGENCIA
   */
  emissionTypeCode?: InputMaybe<Scalars['Int']>;
  exchangeRate?: InputMaybe<Scalars['BigFloat']>;
  /**
   * [SIN] Utilizado para generar CUF
   * 1-4
   * 1=FACTURA CON DERECHO A CREDITO FISCAL
   * ...
   */
  invoiceTypeCode?: InputMaybe<Scalars['Int']>;
  /**
   * [SIN] Utilizado para generar CUF
   * 1-2
   * 1=ELECTRONICA
   * 2=COMPUTARIZADA
   */
  modalityTypeCode?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<FkOrderHomologationToOrderInput>;
  paymentMethodCode?: InputMaybe<Scalars['Int']>;
  /**
   * [SIN] codigoDocumentoSector
   * 1=FCV
   * ...
   * 24=NCD
   * ...
   * 35=FAC_CVB
   * ...
   */
  sectorDocumentCode?: InputMaybe<Scalars['Int']>;
};

/** An object where the defined keys will be set on the `order` being updated. */
export type UpdateOrderOnExportServiceOrderForFkExportServiceOrderToOrderPatch = {
  client?: InputMaybe<FkOrderToClientInput>;
  clientKey?: InputMaybe<Scalars['String']>;
  discount?: InputMaybe<Scalars['BigFloat']>;
  email?: InputMaybe<Scalars['String']>;
  exportServiceOrders?: InputMaybe<FkExportServiceOrderToOrderInverseInput>;
  homologations?: InputMaybe<FkOrderHomologationToOrderInverseInput>;
  insuranceOrders?: InputMaybe<FkInsuranceOrderToOrderInverseInput>;
  /** Importe que el cliente pagó. */
  paymentAmount?: InputMaybe<Scalars['BigFloat']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  pointOfSale?: InputMaybe<FkOrderToPointOfSaleInput>;
  pointOfSaleKey?: InputMaybe<Scalars['String']>;
  rentalOrders?: InputMaybe<FkRentalOrderToOrderInverseInput>;
  saleOrders?: InputMaybe<FkSaleOrderToOrderInverseInput>;
  status?: InputMaybe<OrderStatus>;
  touristServiceOrders?: InputMaybe<FkTouristServiceOrderToOrderInverseInput>;
};

/** An object where the defined keys will be set on the `order` being updated. */
export type UpdateOrderOnInsuranceOrderForFkInsuranceOrderToOrderPatch = {
  client?: InputMaybe<FkOrderToClientInput>;
  clientKey?: InputMaybe<Scalars['String']>;
  discount?: InputMaybe<Scalars['BigFloat']>;
  email?: InputMaybe<Scalars['String']>;
  exportServiceOrders?: InputMaybe<FkExportServiceOrderToOrderInverseInput>;
  homologations?: InputMaybe<FkOrderHomologationToOrderInverseInput>;
  insuranceOrders?: InputMaybe<FkInsuranceOrderToOrderInverseInput>;
  /** Importe que el cliente pagó. */
  paymentAmount?: InputMaybe<Scalars['BigFloat']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  pointOfSale?: InputMaybe<FkOrderToPointOfSaleInput>;
  pointOfSaleKey?: InputMaybe<Scalars['String']>;
  rentalOrders?: InputMaybe<FkRentalOrderToOrderInverseInput>;
  saleOrders?: InputMaybe<FkSaleOrderToOrderInverseInput>;
  status?: InputMaybe<OrderStatus>;
  touristServiceOrders?: InputMaybe<FkTouristServiceOrderToOrderInverseInput>;
};

/** An object where the defined keys will be set on the `order` being updated. */
export type UpdateOrderOnOrderForFkOrderToClientPatch = {
  client?: InputMaybe<FkOrderToClientInput>;
  discount?: InputMaybe<Scalars['BigFloat']>;
  email?: InputMaybe<Scalars['String']>;
  exportServiceOrders?: InputMaybe<FkExportServiceOrderToOrderInverseInput>;
  homologations?: InputMaybe<FkOrderHomologationToOrderInverseInput>;
  insuranceOrders?: InputMaybe<FkInsuranceOrderToOrderInverseInput>;
  /** Importe que el cliente pagó. */
  paymentAmount?: InputMaybe<Scalars['BigFloat']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  pointOfSale?: InputMaybe<FkOrderToPointOfSaleInput>;
  pointOfSaleKey?: InputMaybe<Scalars['String']>;
  rentalOrders?: InputMaybe<FkRentalOrderToOrderInverseInput>;
  saleOrders?: InputMaybe<FkSaleOrderToOrderInverseInput>;
  status?: InputMaybe<OrderStatus>;
  touristServiceOrders?: InputMaybe<FkTouristServiceOrderToOrderInverseInput>;
};

/** An object where the defined keys will be set on the `order` being updated. */
export type UpdateOrderOnOrderForFkOrderToPointOfSalePatch = {
  client?: InputMaybe<FkOrderToClientInput>;
  clientKey?: InputMaybe<Scalars['String']>;
  discount?: InputMaybe<Scalars['BigFloat']>;
  email?: InputMaybe<Scalars['String']>;
  exportServiceOrders?: InputMaybe<FkExportServiceOrderToOrderInverseInput>;
  homologations?: InputMaybe<FkOrderHomologationToOrderInverseInput>;
  insuranceOrders?: InputMaybe<FkInsuranceOrderToOrderInverseInput>;
  /** Importe que el cliente pagó. */
  paymentAmount?: InputMaybe<Scalars['BigFloat']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  pointOfSale?: InputMaybe<FkOrderToPointOfSaleInput>;
  rentalOrders?: InputMaybe<FkRentalOrderToOrderInverseInput>;
  saleOrders?: InputMaybe<FkSaleOrderToOrderInverseInput>;
  status?: InputMaybe<OrderStatus>;
  touristServiceOrders?: InputMaybe<FkTouristServiceOrderToOrderInverseInput>;
};

/** An object where the defined keys will be set on the `order` being updated. */
export type UpdateOrderOnOrderHomologationForFkOrderHomologationToOrderPatch = {
  client?: InputMaybe<FkOrderToClientInput>;
  clientKey?: InputMaybe<Scalars['String']>;
  discount?: InputMaybe<Scalars['BigFloat']>;
  email?: InputMaybe<Scalars['String']>;
  exportServiceOrders?: InputMaybe<FkExportServiceOrderToOrderInverseInput>;
  homologations?: InputMaybe<FkOrderHomologationToOrderInverseInput>;
  insuranceOrders?: InputMaybe<FkInsuranceOrderToOrderInverseInput>;
  /** Importe que el cliente pagó. */
  paymentAmount?: InputMaybe<Scalars['BigFloat']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  pointOfSale?: InputMaybe<FkOrderToPointOfSaleInput>;
  pointOfSaleKey?: InputMaybe<Scalars['String']>;
  rentalOrders?: InputMaybe<FkRentalOrderToOrderInverseInput>;
  saleOrders?: InputMaybe<FkSaleOrderToOrderInverseInput>;
  status?: InputMaybe<OrderStatus>;
  touristServiceOrders?: InputMaybe<FkTouristServiceOrderToOrderInverseInput>;
};

/** An object where the defined keys will be set on the `order` being updated. */
export type UpdateOrderOnRentalOrderForFkRentalOrderToOrderPatch = {
  client?: InputMaybe<FkOrderToClientInput>;
  clientKey?: InputMaybe<Scalars['String']>;
  discount?: InputMaybe<Scalars['BigFloat']>;
  email?: InputMaybe<Scalars['String']>;
  exportServiceOrders?: InputMaybe<FkExportServiceOrderToOrderInverseInput>;
  homologations?: InputMaybe<FkOrderHomologationToOrderInverseInput>;
  insuranceOrders?: InputMaybe<FkInsuranceOrderToOrderInverseInput>;
  /** Importe que el cliente pagó. */
  paymentAmount?: InputMaybe<Scalars['BigFloat']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  pointOfSale?: InputMaybe<FkOrderToPointOfSaleInput>;
  pointOfSaleKey?: InputMaybe<Scalars['String']>;
  rentalOrders?: InputMaybe<FkRentalOrderToOrderInverseInput>;
  saleOrders?: InputMaybe<FkSaleOrderToOrderInverseInput>;
  status?: InputMaybe<OrderStatus>;
  touristServiceOrders?: InputMaybe<FkTouristServiceOrderToOrderInverseInput>;
};

/** An object where the defined keys will be set on the `order` being updated. */
export type UpdateOrderOnSaleOrderForFkSaleOrderToOrderPatch = {
  client?: InputMaybe<FkOrderToClientInput>;
  clientKey?: InputMaybe<Scalars['String']>;
  discount?: InputMaybe<Scalars['BigFloat']>;
  email?: InputMaybe<Scalars['String']>;
  exportServiceOrders?: InputMaybe<FkExportServiceOrderToOrderInverseInput>;
  homologations?: InputMaybe<FkOrderHomologationToOrderInverseInput>;
  insuranceOrders?: InputMaybe<FkInsuranceOrderToOrderInverseInput>;
  /** Importe que el cliente pagó. */
  paymentAmount?: InputMaybe<Scalars['BigFloat']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  pointOfSale?: InputMaybe<FkOrderToPointOfSaleInput>;
  pointOfSaleKey?: InputMaybe<Scalars['String']>;
  rentalOrders?: InputMaybe<FkRentalOrderToOrderInverseInput>;
  saleOrders?: InputMaybe<FkSaleOrderToOrderInverseInput>;
  status?: InputMaybe<OrderStatus>;
  touristServiceOrders?: InputMaybe<FkTouristServiceOrderToOrderInverseInput>;
};

/** An object where the defined keys will be set on the `order` being updated. */
export type UpdateOrderOnTouristServiceOrderForFkTouristServiceOrderToOrderPatch = {
  client?: InputMaybe<FkOrderToClientInput>;
  clientKey?: InputMaybe<Scalars['String']>;
  discount?: InputMaybe<Scalars['BigFloat']>;
  email?: InputMaybe<Scalars['String']>;
  exportServiceOrders?: InputMaybe<FkExportServiceOrderToOrderInverseInput>;
  homologations?: InputMaybe<FkOrderHomologationToOrderInverseInput>;
  insuranceOrders?: InputMaybe<FkInsuranceOrderToOrderInverseInput>;
  /** Importe que el cliente pagó. */
  paymentAmount?: InputMaybe<Scalars['BigFloat']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  pointOfSale?: InputMaybe<FkOrderToPointOfSaleInput>;
  pointOfSaleKey?: InputMaybe<Scalars['String']>;
  rentalOrders?: InputMaybe<FkRentalOrderToOrderInverseInput>;
  saleOrders?: InputMaybe<FkSaleOrderToOrderInverseInput>;
  status?: InputMaybe<OrderStatus>;
  touristServiceOrders?: InputMaybe<FkTouristServiceOrderToOrderInverseInput>;
};

/** An object where the defined keys will be set on the `person` being updated. */
export type UpdatePersonOnUserForFkAppUserToPersonPatch = {
  firstName?: InputMaybe<Scalars['String']>;
  firstSurname?: InputMaybe<Scalars['String']>;
  middleName?: InputMaybe<Scalars['String']>;
  secondSurname?: InputMaybe<Scalars['String']>;
  users?: InputMaybe<FkAppUserToPersonInverseInput>;
};

/** An object where the defined keys will be set on the `pointOfSale` being updated. */
export type UpdatePointOfSaleOnOrderForFkOrderToPointOfSalePatch = {
  branchOffice?: InputMaybe<FkPointOfSaleToBranchOfficeInput>;
  branchOfficeKey?: InputMaybe<Scalars['String']>;
  code?: InputMaybe<Scalars['Int']>;
  commissionAgentNit?: InputMaybe<Scalars['BigInt']>;
  contractNumber?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  finishDate?: InputMaybe<Scalars['Datetime']>;
  modalityCode?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  orders?: InputMaybe<FkOrderToPointOfSaleInverseInput>;
  pointOfSaleUsers?: InputMaybe<FkPointOfSaleUserToPointOfSaleInverseInput>;
  startDate?: InputMaybe<Scalars['Datetime']>;
  status?: InputMaybe<PointOfSaleStatus>;
  typeCode?: InputMaybe<Scalars['Int']>;
};

/** An object where the defined keys will be set on the `pointOfSale` being updated. */
export type UpdatePointOfSaleOnPointOfSaleForFkPointOfSaleToBranchOfficePatch = {
  branchOffice?: InputMaybe<FkPointOfSaleToBranchOfficeInput>;
  code?: InputMaybe<Scalars['Int']>;
  commissionAgentNit?: InputMaybe<Scalars['BigInt']>;
  contractNumber?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  finishDate?: InputMaybe<Scalars['Datetime']>;
  modalityCode?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  orders?: InputMaybe<FkOrderToPointOfSaleInverseInput>;
  pointOfSaleUsers?: InputMaybe<FkPointOfSaleUserToPointOfSaleInverseInput>;
  startDate?: InputMaybe<Scalars['Datetime']>;
  status?: InputMaybe<PointOfSaleStatus>;
  typeCode?: InputMaybe<Scalars['Int']>;
};

/** An object where the defined keys will be set on the `pointOfSale` being updated. */
export type UpdatePointOfSaleOnPointOfSaleUserForFkPointOfSaleUserToPointOfSalePatch = {
  branchOffice?: InputMaybe<FkPointOfSaleToBranchOfficeInput>;
  branchOfficeKey?: InputMaybe<Scalars['String']>;
  code?: InputMaybe<Scalars['Int']>;
  commissionAgentNit?: InputMaybe<Scalars['BigInt']>;
  contractNumber?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  finishDate?: InputMaybe<Scalars['Datetime']>;
  modalityCode?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  orders?: InputMaybe<FkOrderToPointOfSaleInverseInput>;
  pointOfSaleUsers?: InputMaybe<FkPointOfSaleUserToPointOfSaleInverseInput>;
  startDate?: InputMaybe<Scalars['Datetime']>;
  status?: InputMaybe<PointOfSaleStatus>;
  typeCode?: InputMaybe<Scalars['Int']>;
};

/** An object where the defined keys will be set on the `pointOfSaleUser` being updated. */
export type UpdatePointOfSaleUserOnPointOfSaleUserForFkPointOfSaleUserToPointOfSalePatch = {
  enabled?: InputMaybe<Scalars['Boolean']>;
  pointOfSale?: InputMaybe<FkPointOfSaleUserToPointOfSaleInput>;
  user?: InputMaybe<FkPointOfSaleUserToUserInput>;
  userKey?: InputMaybe<Scalars['String']>;
};

/** An object where the defined keys will be set on the `pointOfSaleUser` being updated. */
export type UpdatePointOfSaleUserOnPointOfSaleUserForFkPointOfSaleUserToUserPatch = {
  enabled?: InputMaybe<Scalars['Boolean']>;
  pointOfSale?: InputMaybe<FkPointOfSaleUserToPointOfSaleInput>;
  pointOfSaleKey?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<FkPointOfSaleUserToUserInput>;
};

/** An object where the defined keys will be set on the `productHomologation` being updated. */
export type UpdateProductHomologationOnProductHomologationForFkProductHomologationToProductPatch = {
  /** [SIN] Codigo de actividad */
  activityCode?: InputMaybe<Scalars['String']>;
  /** [SIN] Codigo de unidad de medida */
  measureUnitCode?: InputMaybe<Scalars['Int']>;
  /** [SIN] Codigo de producto */
  productCode?: InputMaybe<Scalars['Int']>;
  productService?: InputMaybe<FkProductHomologationToProductInput>;
};

/** An object where the defined keys will be set on the `product` being updated. */
export type UpdateProductOnExportServiceOrderDetailForFkExportServiceOrderDetailToProductPatch = {
  code?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  exportServiceOrderDetails?: InputMaybe<FkExportServiceOrderDetailToProductInverseInput>;
  homologations?: InputMaybe<FkProductHomologationToProductInverseInput>;
  insuranceOrderDetails?: InputMaybe<FkInsuranceOrderDetailToProductInverseInput>;
  name?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['BigFloat']>;
  rentalOrderDetails?: InputMaybe<FkRentalOrderDetailToProductInverseInput>;
  saleOrderDetails?: InputMaybe<FkSaleOrderDetailToProductInverseInput>;
  touristServiceOrderDetails?: InputMaybe<FkTouristServiceOrderDetailToProductInverseInput>;
  type?: InputMaybe<ProductType>;
};

/** An object where the defined keys will be set on the `product` being updated. */
export type UpdateProductOnInsuranceOrderDetailForFkInsuranceOrderDetailToProductPatch = {
  code?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  exportServiceOrderDetails?: InputMaybe<FkExportServiceOrderDetailToProductInverseInput>;
  homologations?: InputMaybe<FkProductHomologationToProductInverseInput>;
  insuranceOrderDetails?: InputMaybe<FkInsuranceOrderDetailToProductInverseInput>;
  name?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['BigFloat']>;
  rentalOrderDetails?: InputMaybe<FkRentalOrderDetailToProductInverseInput>;
  saleOrderDetails?: InputMaybe<FkSaleOrderDetailToProductInverseInput>;
  touristServiceOrderDetails?: InputMaybe<FkTouristServiceOrderDetailToProductInverseInput>;
  type?: InputMaybe<ProductType>;
};

/** An object where the defined keys will be set on the `product` being updated. */
export type UpdateProductOnProductHomologationForFkProductHomologationToProductPatch = {
  code?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  exportServiceOrderDetails?: InputMaybe<FkExportServiceOrderDetailToProductInverseInput>;
  homologations?: InputMaybe<FkProductHomologationToProductInverseInput>;
  insuranceOrderDetails?: InputMaybe<FkInsuranceOrderDetailToProductInverseInput>;
  name?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['BigFloat']>;
  rentalOrderDetails?: InputMaybe<FkRentalOrderDetailToProductInverseInput>;
  saleOrderDetails?: InputMaybe<FkSaleOrderDetailToProductInverseInput>;
  touristServiceOrderDetails?: InputMaybe<FkTouristServiceOrderDetailToProductInverseInput>;
  type?: InputMaybe<ProductType>;
};

/** An object where the defined keys will be set on the `product` being updated. */
export type UpdateProductOnRentalOrderDetailForFkRentalOrderDetailToProductPatch = {
  code?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  exportServiceOrderDetails?: InputMaybe<FkExportServiceOrderDetailToProductInverseInput>;
  homologations?: InputMaybe<FkProductHomologationToProductInverseInput>;
  insuranceOrderDetails?: InputMaybe<FkInsuranceOrderDetailToProductInverseInput>;
  name?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['BigFloat']>;
  rentalOrderDetails?: InputMaybe<FkRentalOrderDetailToProductInverseInput>;
  saleOrderDetails?: InputMaybe<FkSaleOrderDetailToProductInverseInput>;
  touristServiceOrderDetails?: InputMaybe<FkTouristServiceOrderDetailToProductInverseInput>;
  type?: InputMaybe<ProductType>;
};

/** An object where the defined keys will be set on the `product` being updated. */
export type UpdateProductOnSaleOrderDetailForFkSaleOrderDetailToProductPatch = {
  code?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  exportServiceOrderDetails?: InputMaybe<FkExportServiceOrderDetailToProductInverseInput>;
  homologations?: InputMaybe<FkProductHomologationToProductInverseInput>;
  insuranceOrderDetails?: InputMaybe<FkInsuranceOrderDetailToProductInverseInput>;
  name?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['BigFloat']>;
  rentalOrderDetails?: InputMaybe<FkRentalOrderDetailToProductInverseInput>;
  saleOrderDetails?: InputMaybe<FkSaleOrderDetailToProductInverseInput>;
  touristServiceOrderDetails?: InputMaybe<FkTouristServiceOrderDetailToProductInverseInput>;
  type?: InputMaybe<ProductType>;
};

/** An object where the defined keys will be set on the `product` being updated. */
export type UpdateProductOnTouristServiceOrderDetailForFkTouristServiceOrderDetailToProductPatch = {
  code?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  exportServiceOrderDetails?: InputMaybe<FkExportServiceOrderDetailToProductInverseInput>;
  homologations?: InputMaybe<FkProductHomologationToProductInverseInput>;
  insuranceOrderDetails?: InputMaybe<FkInsuranceOrderDetailToProductInverseInput>;
  name?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['BigFloat']>;
  rentalOrderDetails?: InputMaybe<FkRentalOrderDetailToProductInverseInput>;
  saleOrderDetails?: InputMaybe<FkSaleOrderDetailToProductInverseInput>;
  touristServiceOrderDetails?: InputMaybe<FkTouristServiceOrderDetailToProductInverseInput>;
  type?: InputMaybe<ProductType>;
};

/** An object where the defined keys will be set on the `rentalOrderDetail` being updated. */
export type UpdateRentalOrderDetailOnRentalOrderDetailForFkRentalOrderDetailToProductPatch = {
  discount?: InputMaybe<Scalars['BigFloat']>;
  price?: InputMaybe<Scalars['BigFloat']>;
  productService?: InputMaybe<FkRentalOrderDetailToProductInput>;
  quantity?: InputMaybe<Scalars['BigFloat']>;
  rentalOrder?: InputMaybe<FkRentalOrderDetailToRentalOrderInput>;
  rentalOrderKey?: InputMaybe<Scalars['String']>;
};

/** An object where the defined keys will be set on the `rentalOrderDetail` being updated. */
export type UpdateRentalOrderDetailOnRentalOrderDetailForFkRentalOrderDetailToRentalOrderPatch = {
  discount?: InputMaybe<Scalars['BigFloat']>;
  price?: InputMaybe<Scalars['BigFloat']>;
  productKey?: InputMaybe<Scalars['String']>;
  productService?: InputMaybe<FkRentalOrderDetailToProductInput>;
  quantity?: InputMaybe<Scalars['BigFloat']>;
  rentalOrder?: InputMaybe<FkRentalOrderDetailToRentalOrderInput>;
};

/** An object where the defined keys will be set on the `rentalOrder` being updated. */
export type UpdateRentalOrderOnRentalOrderDetailForFkRentalOrderDetailToRentalOrderPatch = {
  deletion?: InputMaybe<Scalars['Boolean']>;
  order?: InputMaybe<FkRentalOrderToOrderInput>;
  orderKey?: InputMaybe<Scalars['String']>;
  period?: InputMaybe<Scalars['String']>;
  rentalOrderDetails?: InputMaybe<FkRentalOrderDetailToRentalOrderInverseInput>;
};

/** An object where the defined keys will be set on the `rentalOrder` being updated. */
export type UpdateRentalOrderOnRentalOrderForFkRentalOrderToOrderPatch = {
  deletion?: InputMaybe<Scalars['Boolean']>;
  order?: InputMaybe<FkRentalOrderToOrderInput>;
  period?: InputMaybe<Scalars['String']>;
  rentalOrderDetails?: InputMaybe<FkRentalOrderDetailToRentalOrderInverseInput>;
};

/** An object where the defined keys will be set on the `saleOrderDetail` being updated. */
export type UpdateSaleOrderDetailOnSaleOrderDetailForFkSaleOrderDetailToProductPatch = {
  discount?: InputMaybe<Scalars['BigFloat']>;
  price?: InputMaybe<Scalars['BigFloat']>;
  productService?: InputMaybe<FkSaleOrderDetailToProductInput>;
  quantity?: InputMaybe<Scalars['BigFloat']>;
  saleOrder?: InputMaybe<FkSaleOrderDetailToSaleOrderInput>;
  saleOrderKey?: InputMaybe<Scalars['String']>;
  sinNumeroImei?: InputMaybe<Scalars['String']>;
  sinNumeroSerie?: InputMaybe<Scalars['String']>;
};

/** An object where the defined keys will be set on the `saleOrderDetail` being updated. */
export type UpdateSaleOrderDetailOnSaleOrderDetailForFkSaleOrderDetailToSaleOrderPatch = {
  discount?: InputMaybe<Scalars['BigFloat']>;
  price?: InputMaybe<Scalars['BigFloat']>;
  productKey?: InputMaybe<Scalars['String']>;
  productService?: InputMaybe<FkSaleOrderDetailToProductInput>;
  quantity?: InputMaybe<Scalars['BigFloat']>;
  saleOrder?: InputMaybe<FkSaleOrderDetailToSaleOrderInput>;
  sinNumeroImei?: InputMaybe<Scalars['String']>;
  sinNumeroSerie?: InputMaybe<Scalars['String']>;
};

/** An object where the defined keys will be set on the `saleOrder` being updated. */
export type UpdateSaleOrderOnSaleOrderDetailForFkSaleOrderDetailToSaleOrderPatch = {
  giftCard?: InputMaybe<Scalars['BigFloat']>;
  order?: InputMaybe<FkSaleOrderToOrderInput>;
  orderKey?: InputMaybe<Scalars['String']>;
  saleOrderDetails?: InputMaybe<FkSaleOrderDetailToSaleOrderInverseInput>;
};

/** An object where the defined keys will be set on the `saleOrder` being updated. */
export type UpdateSaleOrderOnSaleOrderForFkSaleOrderToOrderPatch = {
  giftCard?: InputMaybe<Scalars['BigFloat']>;
  order?: InputMaybe<FkSaleOrderToOrderInput>;
  saleOrderDetails?: InputMaybe<FkSaleOrderDetailToSaleOrderInverseInput>;
};

/** An object where the defined keys will be set on the `touristServiceOrderDetail` being updated. */
export type UpdateTouristServiceOrderDetailOnTouristServiceOrderDetailForFkTouristServiceOrderDetailToProductPatch = {
  discount?: InputMaybe<Scalars['BigFloat']>;
  price?: InputMaybe<Scalars['BigFloat']>;
  productService?: InputMaybe<FkTouristServiceOrderDetailToProductInput>;
  quantity?: InputMaybe<Scalars['BigFloat']>;
  sinDetalleHuespedesJson?: InputMaybe<Scalars['String']>;
  sinTipoHabitacionCodigo?: InputMaybe<Scalars['Int']>;
  touristServiceOrder?: InputMaybe<FkTouristServiceOrderDetailToTouristServiceOrderInput>;
  touristServiceOrderKey?: InputMaybe<Scalars['String']>;
};

/** An object where the defined keys will be set on the `touristServiceOrderDetail` being updated. */
export type UpdateTouristServiceOrderDetailOnTouristServiceOrderDetailForFkTouristServiceOrderDetailToTouristServiceOrderPatch = {
  discount?: InputMaybe<Scalars['BigFloat']>;
  price?: InputMaybe<Scalars['BigFloat']>;
  productKey?: InputMaybe<Scalars['String']>;
  productService?: InputMaybe<FkTouristServiceOrderDetailToProductInput>;
  quantity?: InputMaybe<Scalars['BigFloat']>;
  sinDetalleHuespedesJson?: InputMaybe<Scalars['String']>;
  sinTipoHabitacionCodigo?: InputMaybe<Scalars['Int']>;
  touristServiceOrder?: InputMaybe<FkTouristServiceOrderDetailToTouristServiceOrderInput>;
};

/** An object where the defined keys will be set on the `touristServiceOrder` being updated. */
export type UpdateTouristServiceOrderOnTouristServiceOrderDetailForFkTouristServiceOrderDetailToTouristServiceOrderPatch = {
  cantidadHabitaciones?: InputMaybe<Scalars['Int']>;
  cantidadHuespedes?: InputMaybe<Scalars['Int']>;
  cantidadMayores?: InputMaybe<Scalars['Int']>;
  cantidadMenores?: InputMaybe<Scalars['Int']>;
  deletion?: InputMaybe<Scalars['Boolean']>;
  fechaIngresoHospedaje?: InputMaybe<Scalars['Datetime']>;
  giftCard?: InputMaybe<Scalars['BigFloat']>;
  operadorTurismoRazonSocial?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<FkTouristServiceOrderToOrderInput>;
  orderKey?: InputMaybe<Scalars['String']>;
  touristServiceOrderDetails?: InputMaybe<FkTouristServiceOrderDetailToTouristServiceOrderInverseInput>;
};

/** An object where the defined keys will be set on the `touristServiceOrder` being updated. */
export type UpdateTouristServiceOrderOnTouristServiceOrderForFkTouristServiceOrderToOrderPatch = {
  cantidadHabitaciones?: InputMaybe<Scalars['Int']>;
  cantidadHuespedes?: InputMaybe<Scalars['Int']>;
  cantidadMayores?: InputMaybe<Scalars['Int']>;
  cantidadMenores?: InputMaybe<Scalars['Int']>;
  deletion?: InputMaybe<Scalars['Boolean']>;
  fechaIngresoHospedaje?: InputMaybe<Scalars['Datetime']>;
  giftCard?: InputMaybe<Scalars['BigFloat']>;
  operadorTurismoRazonSocial?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<FkTouristServiceOrderToOrderInput>;
  touristServiceOrderDetails?: InputMaybe<FkTouristServiceOrderDetailToTouristServiceOrderInverseInput>;
};

/** An object where the defined keys will be set on the `user` being updated. */
export type UpdateUserOnPointOfSaleUserForFkPointOfSaleUserToUserPatch = {
  email?: InputMaybe<Scalars['String']>;
  person?: InputMaybe<FkAppUserToPersonInput>;
  personKey?: InputMaybe<Scalars['String']>;
  pointOfSaleUsers?: InputMaybe<FkPointOfSaleUserToUserInverseInput>;
};

/** An object where the defined keys will be set on the `user` being updated. */
export type UpdateUserOnUserForFkAppUserToPersonPatch = {
  email?: InputMaybe<Scalars['String']>;
  person?: InputMaybe<FkAppUserToPersonInput>;
  pointOfSaleUsers?: InputMaybe<FkPointOfSaleUserToUserInverseInput>;
};

export type AccountByTenantKeyQueryVariables = Exact<{
  key: Scalars['String'];
}>;


export type AccountByTenantKeyQuery = { __typename?: 'Query', accountByTenantKey?: { __typename?: 'Account', tenantKey?: string | null, tenantCreatedAt?: any | null, tenantNit?: any | null, tenantBusinessName?: string | null, tenantEmail?: string | null, tenantPhoneNumber?: string | null, systemCode?: string | null, delegateToken?: string | null, delegateEnabled?: boolean | null, delegateIssuedAt?: any | null, delegateExpireAt?: any | null } | null };

export type DownloadRegisterFileQueryVariables = Exact<{
  uuid: Scalars['String'];
}>;


export type DownloadRegisterFileQuery = { __typename?: 'Query', downloadRegisterFile: { __typename?: 'File', filename?: string | null, filesize?: any | null, uuid?: string | null, type?: string | null, base64?: string | null } };

export type GetRegisterFilesByTenantKeyQueryVariables = Exact<{
  key: Scalars['String'];
}>;


export type GetRegisterFilesByTenantKeyQuery = { __typename?: 'Query', getRegisterFilesByTenantKey?: { __typename?: 'FilesConnection', nodes: Array<{ __typename?: 'File', filename?: string | null, filesize?: any | null, uuid?: string | null, type?: string | null } | null> } | null };

export type PaginateIndexViewAccountsQueryVariables = Exact<{
  first: Scalars['Int'];
  offset: Scalars['Int'];
  orderBy?: InputMaybe<Array<ViewAccountsOrderBy> | ViewAccountsOrderBy>;
  filter?: InputMaybe<ViewAccountFilter>;
}>;


export type PaginateIndexViewAccountsQuery = { __typename?: 'Query', viewAccounts?: { __typename?: 'ViewAccountsConnection', totalCount: number, nodes: Array<{ __typename?: 'ViewAccount', tenantKey?: string | null, tenantNit?: any | null, tenantBusinessName?: string | null, tenantEmail?: string | null, tenantPhoneNumber?: string | null, systemCode?: string | null, tenantCreatedAt?: any | null } | null> } | null };

export type AuthenticateMutationVariables = Exact<{
  username: Scalars['String'];
  password: Scalars['String'];
}>;


export type AuthenticateMutation = { __typename?: 'Mutation', authenticate?: { __typename?: 'AuthenticatePayload', jwtToken?: any | null } | null };

export type BranchOfficesComboQueryVariables = Exact<{ [key: string]: never; }>;


export type BranchOfficesComboQuery = { __typename?: 'Query', viewBranchOffices?: { __typename?: 'ViewBranchOfficesConnection', nodes: Array<{ __typename?: 'ViewBranchOffice', key?: string | null, code?: number | null, name?: string | null } | null> } | null };

export type AllClientsComboQueryVariables = Exact<{ [key: string]: never; }>;


export type AllClientsComboQuery = { __typename?: 'Query', viewClients?: { __typename?: 'ViewClientsConnection', nodes: Array<{ __typename?: 'ViewClient', key?: string | null, name?: string | null, phoneNumber?: string | null, email?: string | null, documentNumber?: string | null, documentComplement?: string | null, documentTypeDescription?: string | null } | null> } | null };

export type ClientByKeyQueryVariables = Exact<{
  key: Scalars['String'];
}>;


export type ClientByKeyQuery = { __typename?: 'Query', client?: { __typename?: 'Client', key: string, name: string, observation?: string | null, phoneNumber?: string | null, email?: string | null, createdAt: any, updatedAt?: any | null, homologation?: { __typename?: 'ClientHomologation', documentNumber: string, documentComplement?: string | null, documentTypeCode: number, documentTypeDescription: string } | null } | null };

export type CreateClientMutationVariables = Exact<{
  name: Scalars['String'];
  phoneNumber: Scalars['String'];
  email: Scalars['String'];
  observation?: InputMaybe<Scalars['String']>;
  documentNumber: Scalars['String'];
  documentComplement?: InputMaybe<Scalars['String']>;
  documentTypeCode: Scalars['Int'];
  allowInvalidNit: Scalars['Boolean'];
}>;


export type CreateClientMutation = { __typename?: 'Mutation', createClient?: { __typename?: 'CreateClientPayload', client?: { __typename?: 'Client', key: string, name: string, observation?: string | null, phoneNumber?: string | null, email?: string | null, createdAt: any, homologation?: { __typename?: 'ClientHomologation', key: string, documentNumber: string, documentComplement?: string | null, documentTypeCode: number, allowInvalidNit: boolean } | null } | null } | null };

export type PaginateClientsQueryVariables = Exact<{
  first: Scalars['Int'];
  offset: Scalars['Int'];
  orderBy?: InputMaybe<Array<ViewClientsOrderBy> | ViewClientsOrderBy>;
  filter?: InputMaybe<ViewClientFilter>;
}>;


export type PaginateClientsQuery = { __typename?: 'Query', viewClients?: { __typename?: 'ViewClientsConnection', totalCount: number, nodes: Array<{ __typename?: 'ViewClient', key?: string | null, name?: string | null, phoneNumber?: string | null, email?: string | null, createdAt?: any | null, documentNumber?: string | null, documentComplement?: string | null, documentTypeDescription?: string | null } | null> } | null };

export type SearchClientsQueryVariables = Exact<{
  first: Scalars['Int'];
  offset: Scalars['Int'];
  search: Scalars['String'];
  orderBy?: InputMaybe<Array<ViewClientsOrderBy> | ViewClientsOrderBy>;
}>;


export type SearchClientsQuery = { __typename?: 'Query', viewClients?: { __typename?: 'ViewClientsConnection', nodes: Array<{ __typename?: 'ViewClient', key?: string | null, name?: string | null, phoneNumber?: string | null, email?: string | null, documentNumber?: string | null, documentComplement?: string | null, documentTypeDescription?: string | null } | null> } | null };

export type GenerateCufdMutationVariables = Exact<{
  pointOfSaleKey: Scalars['String'];
}>;


export type GenerateCufdMutation = { __typename?: 'Mutation', generateCufd?: { __typename?: 'GenerateCufdPayload', cufd?: { __typename?: 'Cufd', key?: string | null, code?: string | null, controlCode?: string | null, status?: string | null, sendingStatus?: string | null, expireAt?: any | null, createdAt?: any | null } | null } | null };

export type GenerateCuisMutationVariables = Exact<{
  pointOfSaleKey: Scalars['String'];
}>;


export type GenerateCuisMutation = { __typename?: 'Mutation', generateCuis?: { __typename?: 'GenerateCuisPayload', cuis?: { __typename?: 'Cuis', key?: string | null, code?: string | null, status?: string | null, sendingStatus?: string | null, expireAt?: any | null, createdAt?: any | null } | null } | null };

export type PaginateViewCufdCodesQueryVariables = Exact<{
  first: Scalars['Int'];
  offset: Scalars['Int'];
  orderBy?: InputMaybe<Array<ViewCufdCodesOrderBy> | ViewCufdCodesOrderBy>;
  filter?: InputMaybe<ViewCufdCodeFilter>;
}>;


export type PaginateViewCufdCodesQuery = { __typename?: 'Query', viewCufdCodes?: { __typename?: 'ViewCufdCodesConnection', totalCount: number, nodes: Array<{ __typename?: 'ViewCufdCode', key?: string | null, code?: string | null, controlCode?: string | null, branchOfficeCode?: number | null, branchOfficeName?: string | null, pointOfSaleCode?: number | null, pointOfSaleName?: string | null, enabled?: boolean | null, generatedAt?: any | null, expireAt?: any | null, createdAt?: any | null } | null>, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean } } | null };

export type PaginateViewCuisCodesQueryVariables = Exact<{
  first: Scalars['Int'];
  offset: Scalars['Int'];
  orderBy?: InputMaybe<Array<ViewCuisCodesOrderBy> | ViewCuisCodesOrderBy>;
  filter?: InputMaybe<ViewCuisCodeFilter>;
}>;


export type PaginateViewCuisCodesQuery = { __typename?: 'Query', viewCuisCodes?: { __typename?: 'ViewCuisCodesConnection', totalCount: number, nodes: Array<{ __typename?: 'ViewCuisCode', key?: string | null, code?: string | null, branchOfficeCode?: number | null, branchOfficeName?: string | null, pointOfSaleCode?: number | null, pointOfSaleName?: string | null, status?: RequestStatus | null, enabled?: boolean | null, expireAt?: any | null, createdAt?: any | null, updatedAt?: any | null } | null>, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean } } | null };

export type SinGenerateMassiveCufdsMutationVariables = Exact<{
  pointOfSaleCodes: Array<InputMaybe<Scalars['Int']>> | InputMaybe<Scalars['Int']>;
}>;


export type SinGenerateMassiveCufdsMutation = { __typename?: 'Mutation', sinGenerateMassiveCufds?: { __typename?: 'SinGenerateMassiveCufdsPayload', cufds: Array<{ __typename?: 'Cufd', key?: string | null, code?: string | null, controlCode?: string | null, pointOfSaleCode?: number | null, pointOfSaleName?: string | null, expireAt?: any | null } | null> } | null };

export type CreateExportServiceOrderMutationVariables = Exact<{
  discount: Scalars['BigFloat'];
  paymentMethodCode: Scalars['Int'];
  currencyCode: Scalars['Int'];
  exchangeRate: Scalars['BigFloat'];
  activityCode: Scalars['String'];
  sectorDocumentCode: Scalars['Int'];
  invoiceTypeCode: Scalars['Int'];
  emissionTypeCode: Scalars['Int'];
  modalityTypeCode: Scalars['Int'];
  sinDireccionComprador: Scalars['String'];
  sinLugarDestino: Scalars['String'];
  sinCodigoPais: Scalars['Int'];
  sinInformacionAdicional?: InputMaybe<Scalars['String']>;
  exportServiceOrderDetails?: InputMaybe<Array<FkExportServiceOrderDetailToExportServiceOrderExportServiceOrderDetailCreateInput> | FkExportServiceOrderDetailToExportServiceOrderExportServiceOrderDetailCreateInput>;
  clientKey: Scalars['String'];
}>;


export type CreateExportServiceOrderMutation = { __typename?: 'Mutation', createOrder?: { __typename?: 'CreateOrderPayload', order?: { __typename?: 'Order', key: string, quantity: any, subtotal: any, discount: any, total: any, homologation?: { __typename?: 'OrderHomologation', paymentMethodDescription?: string | null } | null, exportServiceOrder?: { __typename?: 'ExportServiceOrder', sinDireccionComprador: string, sinLugarDestino: string, sinCodigoPais: number, sinInformacionAdicional?: string | null, exportServiceOrderDetails: { __typename?: 'ExportServiceOrderDetailsConnection', nodes: Array<{ __typename?: 'ExportServiceOrderDetail', key: string, quantity: number, price: any, subtotal: any, discount: any, total: any, productService?: { __typename?: 'Product', key: string, name?: string | null, description: string } | null } | null> } } | null } | null } | null };

export type DeleteClientMutationVariables = Exact<{
  key: Scalars['String'];
}>;


export type DeleteClientMutation = { __typename?: 'Mutation', deleteClient?: { __typename?: 'DeleteClientPayload', client?: { __typename?: 'Client', key: string, homologation?: { __typename?: 'ClientHomologation', key: string } | null } | null } | null };

export type PaginateDigitalSignaturesQueryVariables = Exact<{
  first: Scalars['Int'];
  offset: Scalars['Int'];
  orderBy?: InputMaybe<Array<ViewDigitalSignaturesOrderBy> | ViewDigitalSignaturesOrderBy>;
  filter?: InputMaybe<ViewDigitalSignatureFilter>;
}>;


export type PaginateDigitalSignaturesQuery = { __typename?: 'Query', viewDigitalSignatures?: { __typename?: 'ViewDigitalSignaturesConnection', totalCount: number, nodes: Array<{ __typename?: 'ViewDigitalSignature', key?: string | null, issuerCountry?: string | null, issuerCommonName?: string | null, issuerOrganization?: string | null, subjectCountry?: string | null, subjectBusinessName?: string | null, subjectNit?: string | null, subjectLegalRepresentative?: string | null, subjectJobTitle?: string | null, subjectDocumentType?: string | null, subjectDocumentNumber?: string | null, subjectDocumentComplement?: string | null, issuedAt?: any | null, expireAt?: any | null, enabled?: boolean | null, revoked?: boolean | null, createdAt?: any | null, updatedAt?: any | null } | null> } | null };

export type UploadDigitalSignatureMutationVariables = Exact<{
  tenantKey: Scalars['String'];
  file: Scalars['Upload'];
  password: Scalars['String'];
}>;


export type UploadDigitalSignatureMutation = { __typename?: 'Mutation', uploadDigitalSignature: { __typename?: 'UploadDigitalSignaturePayload', message: string } };

export type GetParameterQueryVariables = Exact<{
  classifier: Scalars['String'];
  code: Scalars['String'];
}>;


export type GetParameterQuery = { __typename?: 'Query', getParameter?: { __typename?: 'Parameter', value?: string | null } | null };

export type CancelInvoiceMutationVariables = Exact<{
  key: Scalars['String'];
  reasonCode: Scalars['Int'];
}>;


export type CancelInvoiceMutation = { __typename?: 'Mutation', cancelInvoice?: { __typename?: 'CancelInvoicePayload', message?: string | null, invoiceCancellation?: { __typename?: 'InvoiceCancellation', key?: string | null, status?: string | null, sendingStatus?: string | null } | null } | null };

export type DownloadInvoiceQueryVariables = Exact<{
  key: Scalars['String'];
}>;


export type DownloadInvoiceQuery = { __typename?: 'Query', downloadInvoice: { __typename?: 'File', base64?: string | null } };

export type GenerateInvoiceMutationVariables = Exact<{
  orderKey: Scalars['String'];
}>;


export type GenerateInvoiceMutation = { __typename?: 'Mutation', generateInvoice?: { __typename?: 'GenerateInvoicePayload', invoice?: { __typename?: 'Invoice', key?: string | null, number?: number | null, totalAmount?: any | null, totalAmountToPay?: any | null, totalAmountSubjectToIva?: any | null, url?: string | null, status?: string | null, verificationStatus?: string | null, createdAt?: any | null } | null } | null };

export type InvoiceByKeyQueryVariables = Exact<{
  key: Scalars['String'];
}>;


export type InvoiceByKeyQuery = { __typename?: 'Query', invoiceByKey?: { __typename?: 'Invoice', key?: string | null, sectorDocumentCode?: number | null, invoiceTypeCode?: number | null, emissionTypeCode?: number | null, modalityTypeCode?: number | null, businessName?: string | null, documentNumber?: string | null, documentTypeCode?: string | null, documentTypeDescription?: string | null, totalAmount?: any | null, totalAmountToPay?: any | null, totalAmountSubjectToIva?: any | null, currencyCode?: number | null, exchangeRate?: any | null, totalAmountCurrency?: any | null, additionalDiscount?: any | null, number?: number | null, issuedAt?: any | null, cuf?: string | null, url?: string | null, orderKey?: string | null, status?: string | null, verificationStatus?: string | null, createdAt?: any | null } | null };

export type PaginateViewInvoicesQueryVariables = Exact<{
  first: Scalars['Int'];
  offset: Scalars['Int'];
  orderBy?: InputMaybe<Array<ViewInvoicesOrderBy> | ViewInvoicesOrderBy>;
  filter?: InputMaybe<ViewInvoiceFilter>;
}>;


export type PaginateViewInvoicesQuery = { __typename?: 'Query', viewInvoices?: { __typename?: 'ViewInvoicesConnection', totalCount: number, nodes: Array<{ __typename?: 'ViewInvoice', key?: string | null, businessName?: string | null, documentNumber?: string | null, documentTypeCode?: number | null, documentTypeDescription?: string | null, subtotal?: any | null, additionalDiscount?: any | null, totalAmount?: any | null, totalAmountCurrency?: any | null, totalAmountToPay?: any | null, totalAmountSubjectToIva?: any | null, number?: any | null, issuedAt?: any | null, status?: InvoiceStatus | null, verificationStatus?: InvoiceStatus | null, createdAt?: any | null, date?: any | null } | null> } | null };

export type CreateSaleOrderMutationVariables = Exact<{
  pointOfSaleKey: Scalars['String'];
  discount: Scalars['BigFloat'];
  paymentAmount: Scalars['BigFloat'];
  email: Scalars['String'];
  phoneNumber: Scalars['String'];
  paymentMethodCode: Scalars['Int'];
  cardNumber?: InputMaybe<Scalars['BigInt']>;
  currencyCode: Scalars['Int'];
  exchangeRate: Scalars['BigFloat'];
  activityCode: Scalars['String'];
  sectorDocumentCode: Scalars['Int'];
  invoiceTypeCode: Scalars['Int'];
  emissionTypeCode: Scalars['Int'];
  modalityTypeCode: Scalars['Int'];
  giftCard: Scalars['BigFloat'];
  saleOrderDetails?: InputMaybe<Array<FkSaleOrderDetailToSaleOrderSaleOrderDetailCreateInput> | FkSaleOrderDetailToSaleOrderSaleOrderDetailCreateInput>;
  clientKey: Scalars['String'];
}>;


export type CreateSaleOrderMutation = { __typename?: 'Mutation', createOrder?: { __typename?: 'CreateOrderPayload', order?: { __typename?: 'Order', key: string, quantity: any, subtotal: any, discount: any, total: any, paymentAmount: any, changeAmount: any, homologation?: { __typename?: 'OrderHomologation', paymentMethodDescription?: string | null } | null, saleOrder?: { __typename?: 'SaleOrder', giftCard: any, saleOrderDetails: { __typename?: 'SaleOrderDetailsConnection', nodes: Array<{ __typename?: 'SaleOrderDetail', key: string, quantity: any, price: any, subtotal: any, discount: any, total: any, productService?: { __typename?: 'Product', key: string, name?: string | null, description: string } | null } | null> } } | null } | null } | null };

export type DownloadOrdersQueryVariables = Exact<{ [key: string]: never; }>;


export type DownloadOrdersQuery = { __typename?: 'Query', downloadOrders: { __typename?: 'File', filename?: string | null, mimetype?: string | null, encoding?: string | null, filesize?: any | null, uuid?: string | null, type?: string | null, base64?: string | null } };

export type OrderByKeyQueryVariables = Exact<{
  key: Scalars['String'];
}>;


export type OrderByKeyQuery = { __typename?: 'Query', order?: { __typename?: 'Order', key: string, quantity: any, subtotal: any, discount: any, total: any, phoneNumber?: string | null, email?: string | null, status: OrderStatus, createdAt: any, homologation?: { __typename?: 'OrderHomologation', sectorDocumentCode: number, paymentMethodDescription?: string | null } | null, client?: { __typename?: 'Client', key: string, name: string, createdAt: any, phoneNumber?: string | null, email?: string | null, homologation?: { __typename?: 'ClientHomologation', documentNumber: string, documentTypeCode: number, documentTypeDescription: string } | null } | null, invoice?: { __typename?: 'Invoice', key?: string | null, number?: number | null, totalAmount?: any | null, totalAmountToPay?: any | null, totalAmountSubjectToIva?: any | null, url?: string | null, status?: string | null, verificationStatus?: string | null, createdAt?: any | null } | null } | null };

export type PaginateViewOrdersQueryVariables = Exact<{
  first: Scalars['Int'];
  offset: Scalars['Int'];
  orderBy?: InputMaybe<Array<ViewOrdersOrderBy> | ViewOrdersOrderBy>;
  filter?: InputMaybe<ViewOrderFilter>;
}>;


export type PaginateViewOrdersQuery = { __typename?: 'Query', viewOrders?: { __typename?: 'ViewOrdersConnection', totalCount: number, nodes: Array<{ __typename?: 'ViewOrder', key?: string | null, number?: string | null, businessName?: string | null, documentNumber?: string | null, documentTypeCode?: number | null, documentTypeDescription?: string | null, subtotal?: any | null, discount?: any | null, total?: any | null, invoiceIssuedAt?: any | null, invoiceStatus?: InvoiceStatus | null, invoiceVerificationStatus?: InvoiceStatus | null, createdAt?: any | null, date?: any | null } | null> } | null };

export type SaleOrderByOrderKeyQueryVariables = Exact<{
  orderKey: Scalars['String'];
}>;


export type SaleOrderByOrderKeyQuery = { __typename?: 'Query', saleOrderByOrderKey?: { __typename?: 'SaleOrder', giftCard: any, saleOrderDetails: { __typename?: 'SaleOrderDetailsConnection', nodes: Array<{ __typename?: 'SaleOrderDetail', quantity: any, price: any, subtotal: any, discount: any, total: any, productService?: { __typename?: 'Product', key: string, code?: string | null, name?: string | null, description: string } | null } | null> } } | null };

export type PackageByKeyQueryVariables = Exact<{
  key: Scalars['String'];
}>;


export type PackageByKeyQuery = { __typename?: 'Query', packageByKey?: { __typename?: 'Package', key?: string | null, sectorDocumentCode?: number | null, sectorDocumentDescription?: string | null, branchOfficeCode?: number | null, branchOfficeName?: string | null, pointOfSaleCode?: number | null, pointOfSaleName?: string | null, quantity?: number | null, receptionCode?: string | null, status?: string | null, sendingStatus?: string | null, validationStatus?: string | null, validationSendingStatus?: string | null, createdAt?: any | null, updatedAt?: any | null } | null };

export type PaginatePackageInvoicesByKeyQueryVariables = Exact<{
  key: Scalars['String'];
  first: Scalars['Int'];
  offset: Scalars['Int'];
  filter?: InputMaybe<InvoiceFilter>;
}>;


export type PaginatePackageInvoicesByKeyQuery = { __typename?: 'Query', packageInvoices?: { __typename?: 'InvoicesConnection', totalCount: number, nodes: Array<{ __typename?: 'Invoice', key?: string | null, sectorDocumentCode?: number | null, businessName?: string | null, documentNumber?: string | null, documentTypeCode?: string | null, documentTypeDescription?: string | null, totalAmount?: any | null, currencyCode?: number | null, number?: number | null, issuedAt?: any | null, status?: string | null, verificationStatus?: string | null, createdAt?: any | null } | null> } | null };

export type PaginatePackagesQueryVariables = Exact<{
  first: Scalars['Int'];
  offset: Scalars['Int'];
  orderBy?: InputMaybe<Array<ViewPackagesOrderBy> | ViewPackagesOrderBy>;
  filter?: InputMaybe<ViewPackageFilter>;
}>;


export type PaginatePackagesQuery = { __typename?: 'Query', viewPackages?: { __typename?: 'ViewPackagesConnection', totalCount: number, nodes: Array<{ __typename?: 'ViewPackage', key?: string | null, sectorDocumentCode?: number | null, sectorDocumentDescription?: string | null, quantity?: number | null, status?: PackageStatus | null, sendingStatus?: SendingStatus | null, validationStatus?: PackageValidationStatus | null, validationSendingStatus?: SendingStatus | null, createdAt?: any | null, updatedAt?: any | null } | null> } | null };

export type CreatePointOfSaleUserMutationVariables = Exact<{
  pointOfSaleKey: Scalars['String'];
  userKey: Scalars['String'];
}>;


export type CreatePointOfSaleUserMutation = { __typename?: 'Mutation', createPointOfSaleUser?: { __typename?: 'CreatePointOfSaleUserPayload', pointOfSaleUser?: { __typename?: 'PointOfSaleUser', key: string, createdAt: any, updatedAt?: any | null, pointOfSale?: { __typename?: 'PointOfSale', key: string, code: number, name?: string | null, description?: string | null, typeCode: number } | null } | null } | null };

export type CreatePointOfSaleMutationVariables = Exact<{
  name: Scalars['String'];
  description: Scalars['String'];
  branchOfficeCode: Scalars['Int'];
  typeCode: Scalars['Int'];
  modalityCode: Scalars['Int'];
  commissionAgentNit?: InputMaybe<Scalars['BigInt']>;
  contractNumber?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['Datetime']>;
  finishDate?: InputMaybe<Scalars['Datetime']>;
}>;


export type CreatePointOfSaleMutation = { __typename?: 'Mutation', createPointOfSale?: { __typename?: 'CreatePointOfSalePayload', pointOfSale?: { __typename?: 'PointOfSale', key: string } | null } | null };

export type DeletePointOfSaleUserMutationVariables = Exact<{
  key: Scalars['String'];
}>;


export type DeletePointOfSaleUserMutation = { __typename?: 'Mutation', deletePointOfSaleUser?: { __typename?: 'DeletePointOfSaleUserPayload', pointOfSaleUser?: { __typename?: 'PointOfSaleUser', key: string } | null } | null };

export type PointOfSaleByKeyQueryVariables = Exact<{
  key: Scalars['String'];
}>;


export type PointOfSaleByKeyQuery = { __typename?: 'Query', pointOfSale?: { __typename?: 'PointOfSale', key: string, code: number, name?: string | null, description?: string | null, typeCode: number, typeName?: string | null, createdAt: any, cuis?: { __typename?: 'Cuis', code?: string | null, expireAt?: any | null, createdAt?: any | null } | null, cufd?: { __typename?: 'Cufd', code?: string | null, expireAt?: any | null, createdAt?: any | null } | null, branchOffice?: { __typename?: 'BranchOffice', code: number } | null } | null };

export type PointOfSaleComboQueryVariables = Exact<{ [key: string]: never; }>;


export type PointOfSaleComboQuery = { __typename?: 'Query', viewPointOfSales?: { __typename?: 'ViewPointOfSalesConnection', nodes: Array<{ __typename?: 'ViewPointOfSale', key?: string | null, code?: number | null, name?: string | null } | null> } | null };

export type SyncPointsOfSaleMutationVariables = Exact<{
  branchOfficeCode: Scalars['Int'];
}>;


export type SyncPointsOfSaleMutation = { __typename?: 'Mutation', syncPointsOfSale?: { __typename?: 'SyncPointsOfSalePayload', boolean?: boolean | null } | null };

export type CreateProductMutationVariables = Exact<{
  code: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
  type: ProductType;
  price: Scalars['BigFloat'];
  activityCode: Scalars['String'];
  productCode: Scalars['Int'];
  measureUnitCode: Scalars['Int'];
}>;


export type CreateProductMutation = { __typename?: 'Mutation', createProduct?: { __typename?: 'CreateProductPayload', product?: { __typename?: 'Product', key: string, code?: string | null, name?: string | null, description: string, type: ProductType, price: any, createdAt: any, homologation?: { __typename?: 'ProductHomologation', key: string, activityCode: string, productCode: number, measureUnitCode: number } | null } | null } | null };

export type DownloadProductsQueryVariables = Exact<{ [key: string]: never; }>;


export type DownloadProductsQuery = { __typename?: 'Query', downloadProducts: { __typename?: 'File', filename?: string | null, mimetype?: string | null, encoding?: string | null, filesize?: any | null, uuid?: string | null, type?: string | null, base64?: string | null } };

export type PaginateProductsQueryVariables = Exact<{
  first: Scalars['Int'];
  offset: Scalars['Int'];
  orderBy?: InputMaybe<Array<ViewProductsOrderBy> | ViewProductsOrderBy>;
  filter?: InputMaybe<ViewProductFilter>;
}>;


export type PaginateProductsQuery = { __typename?: 'Query', viewProducts?: { __typename?: 'ViewProductsConnection', totalCount: number, nodes: Array<{ __typename?: 'ViewProduct', key?: string | null, code?: string | null, name?: string | null, description?: string | null, price?: any | null, createdAt?: any | null } | null> } | null };

export type ProductByKeyQueryVariables = Exact<{
  key: Scalars['String'];
}>;


export type ProductByKeyQuery = { __typename?: 'Query', product?: { __typename?: 'Product', key: string, code?: string | null, name?: string | null, description: string, type: ProductType, price: any, createdAt: any, updatedAt?: any | null, homologation?: { __typename?: 'ProductHomologation', key: string, activityCode: string, activityDescription: string, productCode: number, productDescription: string, measureUnitCode: number, measureUnitDescription: string, createdAt: any, updatedAt?: any | null } | null } | null };

export type SearchProductsByCodeQueryVariables = Exact<{
  code: Scalars['String'];
}>;


export type SearchProductsByCodeQuery = { __typename?: 'Query', viewProducts?: { __typename?: 'ViewProductsConnection', nodes: Array<{ __typename?: 'ViewProduct', key?: string | null, code?: string | null, name?: string | null, description?: string | null, price?: any | null } | null> } | null };

export type SearchProductsByDescriptionQueryVariables = Exact<{
  description: Scalars['String'];
}>;


export type SearchProductsByDescriptionQuery = { __typename?: 'Query', viewProducts?: { __typename?: 'ViewProductsConnection', nodes: Array<{ __typename?: 'ViewProduct', key?: string | null, code?: string | null, name?: string | null, description?: string | null, price?: any | null } | null> } | null };

export type UpdateProductMutationVariables = Exact<{
  key: Scalars['String'];
  code: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
  type: ProductType;
  price: Scalars['BigFloat'];
  homologationKey: Scalars['String'];
  activityCode: Scalars['String'];
  productCode: Scalars['Int'];
  measureUnitCode: Scalars['Int'];
}>;


export type UpdateProductMutation = { __typename?: 'Mutation', updateProduct?: { __typename?: 'UpdateProductPayload', product?: { __typename?: 'Product', key: string, code?: string | null, name?: string | null, description: string, type: ProductType, price: any, createdAt: any, homologation?: { __typename?: 'ProductHomologation', key: string, activityCode: string, productCode: number, measureUnitCode: number } | null } | null } | null };

export type RegisterUserMutationVariables = Exact<{
  personFirstName: Scalars['String'];
  personMiddleName: Scalars['String'];
  personFirstSurname: Scalars['String'];
  personSecondSurname: Scalars['String'];
  personDocumentNumber: Scalars['String'];
  personPhoneNumber: Scalars['String'];
  tenantNit: Scalars['BigInt'];
  tenantRazonSocial: Scalars['String'];
  tenantSiatUsername: Scalars['String'];
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type RegisterUserMutation = { __typename?: 'Mutation', registerUser: { __typename?: 'RegisterUserPayload', registration?: { __typename?: 'Registration', personFullName?: string | null, username?: string | null, email?: string | null, nit?: string | null, razonSocial?: string | null } | null } };

export type UploadRegisterDocumentsMutationVariables = Exact<{
  token: Scalars['String'];
  invoicePhoneNumber: Scalars['String'];
  invoiceMunicipality: Scalars['String'];
  invoiceAddress: Scalars['String'];
  frontDocumentPhoto: Scalars['Upload'];
  nitDocumentPhoto: Scalars['Upload'];
  frontDocumentSelfie: Scalars['Upload'];
  backDocumentPhoto: Scalars['Upload'];
  certificateRequestPhoto: Scalars['Upload'];
}>;


export type UploadRegisterDocumentsMutation = { __typename?: 'Mutation', uploadRegisterDocuments: { __typename?: 'UploadRegisterDocumentsPayload', frontDocumentPhotoValid: boolean, backDocumentPhotoValid: boolean, frontDocumentSelfieValid: boolean, nitDocumentPhotoValid: boolean, certificateRequestPhotoValid: boolean, jwtToken?: any | null } };

export type ActivateAccountMutationVariables = Exact<{
  token: Scalars['String'];
}>;


export type ActivateAccountMutation = { __typename?: 'Mutation', activateAccount?: { __typename?: 'ActivateAccountPayload', registration?: { __typename?: 'Registration', personFullName?: string | null, personDocumentNumber?: string | null, personJobTitle?: string | null, personPhoneNumber?: string | null, username?: string | null, email?: string | null, nit?: string | null, razonSocial?: string | null, siatUsername?: string | null } | null } | null };

export type SinRegisterSignificantEventMutationVariables = Exact<{
  pointOfSaleCode: Scalars['Int'];
  eventCode: Scalars['Int'];
  description: Scalars['String'];
  startDate: Scalars['Datetime'];
  cafc?: InputMaybe<Scalars['String']>;
}>;


export type SinRegisterSignificantEventMutation = { __typename?: 'Mutation', createSignificantEvent?: { __typename?: 'CreateSignificantEventPayload', significantEvent?: { __typename?: 'SignificantEvent', id?: number | null, key?: string | null, cufd?: string | null, cufdEvent?: string | null, startDate?: any | null, createdAt?: any | null } | null } | null };

export type PaginateSinViewSignificantEventsQueryVariables = Exact<{
  first: Scalars['Int'];
  offset: Scalars['Int'];
  orderBy?: InputMaybe<Array<ViewSignificantEventsOrderBy> | ViewSignificantEventsOrderBy>;
  filter?: InputMaybe<ViewSignificantEventFilter>;
}>;


export type PaginateSinViewSignificantEventsQuery = { __typename?: 'Query', viewSignificantEvents?: { __typename?: 'ViewSignificantEventsConnection', totalCount: number, nodes: Array<{ __typename?: 'ViewSignificantEvent', id?: number | null, key?: string | null, reasonCode?: number | null, reasonDescription?: string | null, description?: string | null, receptionCode?: any | null, startDate?: any | null, finishDate?: any | null, status?: SignificantEventStatus | null, sendingStatus?: SendingStatus | null, sentAt?: any | null, createdAt?: any | null, updatedAt?: any | null } | null> } | null };

export type SendSignificantEventMutationVariables = Exact<{
  key: Scalars['String'];
}>;


export type SendSignificantEventMutation = { __typename?: 'Mutation', sendSignificantEvent?: { __typename?: 'SendSignificantEventPayload', significantEvent?: { __typename?: 'SignificantEvent', id?: number | null, key?: string | null, cufd?: string | null, startDate?: any | null, finishDate?: any | null, receptionCode?: any | null, status?: string | null, sendingStatus?: string | null, createdAt?: any | null, sentAt?: any | null } | null } | null };

export type SignificantEventByKeyQueryVariables = Exact<{
  key: Scalars['String'];
}>;


export type SignificantEventByKeyQuery = { __typename?: 'Query', significantEventByKey?: { __typename?: 'SignificantEvent', id?: number | null, key?: string | null, branchOfficeCode?: number | null, branchOfficeName?: string | null, pointOfSaleCode?: number | null, pointOfSaleName?: string | null, cuis?: string | null, cufd?: string | null, cufdEvent?: string | null, reasonCode?: number | null, reasonDescription?: string | null, description?: string | null, startDate?: any | null, finishDate?: any | null, receptionCode?: any | null, status?: string | null, sendingStatus?: string | null, createdAt?: any | null, sentAt?: any | null, updatedAt?: any | null } | null };

export type PaginateSinActivitiesQueryVariables = Exact<{
  first: Scalars['Int'];
  offset: Scalars['Int'];
}>;


export type PaginateSinActivitiesQuery = { __typename?: 'Query', syncActivities?: { __typename?: 'SyncActivitiesConnection', totalCount: number, nodes: Array<{ __typename?: 'SyncActivity', caebCode?: string | null, description?: string | null, type?: string | null, createdAt?: any | null } | null>, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean } } | null };

export type PaginateSinActivitySectorDocumentsQueryVariables = Exact<{
  first: Scalars['Int'];
  offset: Scalars['Int'];
}>;


export type PaginateSinActivitySectorDocumentsQuery = { __typename?: 'Query', syncActivitySectorDocuments?: { __typename?: 'SyncActivitySectorDocumentsConnection', totalCount: number, nodes: Array<{ __typename?: 'SyncActivitySectorDocument', activityCode?: string | null, sectorDocumentCode?: string | null, sectorDocumentType?: string | null, createdAt?: any | null } | null>, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean } } | null };

export type PaginateSinViewParametersQueryVariables = Exact<{
  type: SyncParameterType;
  first: Scalars['Int'];
  offset: Scalars['Int'];
  orderBy?: InputMaybe<Array<ViewSyncParametersOrderBy> | ViewSyncParametersOrderBy>;
}>;


export type PaginateSinViewParametersQuery = { __typename?: 'Query', viewSyncParameters?: { __typename?: 'ViewSyncParametersConnection', totalCount: number, nodes: Array<{ __typename?: 'ViewSyncParameter', classifierCode?: number | null, description?: string | null, createdAt?: any | null } | null> } | null };

export type PaginateViewPointOfSalesQueryVariables = Exact<{
  first: Scalars['Int'];
  offset: Scalars['Int'];
  orderBy?: InputMaybe<Array<ViewPointOfSalesOrderBy> | ViewPointOfSalesOrderBy>;
  filter?: InputMaybe<ViewPointOfSaleFilter>;
}>;


export type PaginateViewPointOfSalesQuery = { __typename?: 'Query', viewPointOfSales?: { __typename?: 'ViewPointOfSalesConnection', totalCount: number, nodes: Array<{ __typename?: 'ViewPointOfSale', key?: string | null, branchOfficeCode?: number | null, branchOfficeName?: string | null, code?: number | null, name?: string | null, typeCode?: number | null, typeDescription?: string | null, description?: string | null, cuis?: string | null, cufd?: string | null, createdAt?: any | null } | null> } | null };

export type SinActivitiesComboQueryVariables = Exact<{ [key: string]: never; }>;


export type SinActivitiesComboQuery = { __typename?: 'Query', syncActivities?: { __typename?: 'SyncActivitiesConnection', nodes: Array<{ __typename?: 'SyncActivity', caebCode?: string | null, description?: string | null } | null> } | null };

export type SinActivitySectorDocumentsComboQueryVariables = Exact<{ [key: string]: never; }>;


export type SinActivitySectorDocumentsComboQuery = { __typename?: 'Query', syncActivitySectorDocuments?: { __typename?: 'SyncActivitySectorDocumentsConnection', totalCount: number, nodes: Array<{ __typename?: 'SyncActivitySectorDocument', activityCode?: string | null, sectorDocumentCode?: string | null, sectorDocumentType?: string | null, createdAt?: any | null } | null>, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean } } | null };

export type DateTimesQueryVariables = Exact<{
  first: Scalars['Int'];
  offset: Scalars['Int'];
}>;


export type DateTimesQuery = { __typename?: 'Query', syncDatetimes?: { __typename?: 'SyncDatetimesConnection', totalCount: number, nodes: Array<{ __typename?: 'SyncDatetime', value?: string | null, createdAt?: any | null } | null> } | null };

export type InvoiceLegendsQueryVariables = Exact<{
  first: Scalars['Int'];
  offset: Scalars['Int'];
}>;


export type InvoiceLegendsQuery = { __typename?: 'Query', syncInvoiceLegends?: { __typename?: 'SyncInvoiceLegendsConnection', totalCount: number, nodes: Array<{ __typename?: 'SyncInvoiceLegend', activityCode?: string | null, description?: string | null, createdAt?: any | null } | null>, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean } } | null };

export type SinSectorDocumentInvoiceTypeByActivityCodeQueryVariables = Exact<{
  activityCode: Scalars['String'];
}>;


export type SinSectorDocumentInvoiceTypeByActivityCodeQuery = { __typename?: 'Query', sectorDocumentInvoiceTypesByActivityCode?: { __typename?: 'SectorDocumentInvoiceTypesConnection', totalCount: number, nodes: Array<{ __typename?: 'SectorDocumentInvoiceType', sectorDocumentCode?: number | null, description?: string | null, features?: string | null, invoiceTypeCode?: number | null, invoiceTypeDescription?: string | null } | null>, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean } } | null };

export type SinDocumentoSectorTipoFacturaQueryVariables = Exact<{ [key: string]: never; }>;


export type SinDocumentoSectorTipoFacturaQuery = { __typename?: 'Query', sectorDocumentInvoiceTypes?: { __typename?: 'SectorDocumentInvoiceTypesConnection', totalCount: number, nodes: Array<{ __typename?: 'SectorDocumentInvoiceType', sectorDocumentCode?: number | null, description?: string | null, features?: string | null, invoiceTypeCode?: number | null, invoiceTypeDescription?: string | null } | null>, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean } } | null };

export type RequestSyncMutationVariables = Exact<{
  type: SyncRequestType;
  branchOfficeCode: Scalars['Int'];
  pointOfSaleCode: Scalars['Int'];
}>;


export type RequestSyncMutation = { __typename?: 'Mutation', requestSync?: { __typename?: 'RequestSyncPayload', syncRequest?: { __typename?: 'SyncRequest', key?: string | null, type?: string | null, status?: string | null, createdAt?: any | null, createdBy?: number | null, updatedAt?: any | null } | null } | null };

export type PaginateSyncRequestsQueryVariables = Exact<{
  first: Scalars['Int'];
  offset: Scalars['Int'];
  orderBy?: InputMaybe<Array<ViewSyncRequestsOrderBy> | ViewSyncRequestsOrderBy>;
}>;


export type PaginateSyncRequestsQuery = { __typename?: 'Query', viewSyncRequests?: { __typename?: 'ViewSyncRequestsConnection', totalCount: number, nodes: Array<{ __typename?: 'ViewSyncRequest', key?: string | null, type?: SyncRequestType | null, status?: RequestStatus | null, createdAt?: any | null, updatedAt?: any | null } | null> } | null };

export type SyncRequestSummaryQueryVariables = Exact<{ [key: string]: never; }>;


export type SyncRequestSummaryQuery = { __typename?: 'Query', syncRequestsSummary?: { __typename?: 'SyncRequestsConnection', nodes: Array<{ __typename?: 'SyncRequest', key?: string | null, type?: string | null, status?: string | null, createdBy?: number | null, createdAt?: any | null, updatedAt?: any | null } | null> } | null };

export type PaymentMethodInfoByCodeQueryVariables = Exact<{
  code: Scalars['Int'];
}>;


export type PaymentMethodInfoByCodeQuery = { __typename?: 'Query', paymentMethodInfoByCode?: { __typename?: 'PaymentMethodInfo', showGiftCard?: boolean | null, showCardNumber?: boolean | null } | null };

export type SinSectorDocumentsByActividadComboQueryVariables = Exact<{
  code: Scalars['String'];
}>;


export type SinSectorDocumentsByActividadComboQuery = { __typename?: 'Query', sectorDocumentsByActivityCode?: { __typename?: 'SyncParametersConnection', totalCount: number, nodes: Array<{ __typename?: 'SyncParameter', classifierCode?: string | null, description?: string | null, createdAt?: any | null } | null>, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean } } | null };

export type SinVerifyNitOnRegisterQueryVariables = Exact<{
  nit: Scalars['BigFloat'];
}>;


export type SinVerifyNitOnRegisterQuery = { __typename?: 'Query', sinVerifyNitOnRegister: { __typename?: 'SinVerifyNitPayload', isValid: boolean } };

export type SinVerifyNitQueryVariables = Exact<{
  nit: Scalars['BigFloat'];
}>;


export type SinVerifyNitQuery = { __typename?: 'Query', sinVerifyNit: boolean };

export type ViewSyncParametersComboQueryVariables = Exact<{
  type: SyncParameterType;
  orderBy?: InputMaybe<Array<ViewSyncParametersOrderBy> | ViewSyncParametersOrderBy>;
}>;


export type ViewSyncParametersComboQuery = { __typename?: 'Query', viewSyncParameters?: { __typename?: 'ViewSyncParametersConnection', nodes: Array<{ __typename?: 'ViewSyncParameter', classifierCode?: number | null, description?: string | null, createdAt?: any | null } | null> } | null };

export type SinViewPaymentMethodsComboQueryVariables = Exact<{ [key: string]: never; }>;


export type SinViewPaymentMethodsComboQuery = { __typename?: 'Query', viewPaymentMethods?: { __typename?: 'ViewPaymentMethodsConnection', totalCount: number, nodes: Array<{ __typename?: 'ViewPaymentMethod', classifierCode?: number | null, description?: string | null, createdAt?: string | null } | null> } | null };

export type AllSyncProductsQueryVariables = Exact<{ [key: string]: never; }>;


export type AllSyncProductsQuery = { __typename?: 'Query', viewSyncProducts?: { __typename?: 'ViewSyncProductsConnection', nodes: Array<{ __typename?: 'ViewSyncProduct', activityCode?: string | null, code?: number | null, description?: string | null, createdAt?: any | null } | null> } | null };

export type SyncProductsByActivityCodeQueryVariables = Exact<{
  activityCode: Scalars['String'];
}>;


export type SyncProductsByActivityCodeQuery = { __typename?: 'Query', viewSyncProducts?: { __typename?: 'ViewSyncProductsConnection', nodes: Array<{ __typename?: 'ViewSyncProduct', code?: number | null, description?: string | null } | null> } | null };

export type PaginateDelegatedTokensQueryVariables = Exact<{
  first: Scalars['Int'];
  offset: Scalars['Int'];
  orderBy?: InputMaybe<Array<ViewDelegatedTokensOrderBy> | ViewDelegatedTokensOrderBy>;
  filter?: InputMaybe<ViewDelegatedTokenFilter>;
}>;


export type PaginateDelegatedTokensQuery = { __typename?: 'Query', viewDelegatedTokens?: { __typename?: 'ViewDelegatedTokensConnection', totalCount: number, nodes: Array<{ __typename?: 'ViewDelegatedToken', id?: number | null, key?: string | null, sub?: string | null, systemCode?: string | null, nit?: string | null, issuedAt?: any | null, expireAt?: any | null, enabled?: boolean | null, revoked?: boolean | null, createdAt?: any | null, updatedAt?: any | null } | null> } | null };

export type UploadDelegatedTokenMutationVariables = Exact<{
  tenantKey: Scalars['String'];
  token: Scalars['String'];
}>;


export type UploadDelegatedTokenMutation = { __typename?: 'Mutation', uploadDelegatedToken: { __typename?: 'UploadDelegatedTokenPayload', message?: string | null } };

export type UpdateClientMutationVariables = Exact<{
  key: Scalars['String'];
  name: Scalars['String'];
  phoneNumber: Scalars['String'];
  email: Scalars['String'];
  observation?: InputMaybe<Scalars['String']>;
  documentNumber: Scalars['String'];
  documentComplement?: InputMaybe<Scalars['String']>;
  documentTypeCode: Scalars['Int'];
  allowInvalidNit: Scalars['Boolean'];
}>;


export type UpdateClientMutation = { __typename?: 'Mutation', updateClient?: { __typename?: 'UpdateClientPayload', client?: { __typename?: 'Client', key: string, name: string, observation?: string | null, phoneNumber?: string | null, email?: string | null, createdAt: any, homologation?: { __typename?: 'ClientHomologation', key: string, documentNumber: string, documentComplement?: string | null, documentTypeCode: number, allowInvalidNit: boolean } | null } | null } | null };

export type UserByKeyQueryVariables = Exact<{
  key: Scalars['String'];
}>;


export type UserByKeyQuery = { __typename?: 'Query', user?: { __typename?: 'User', key: string, email: string, createdAt: any, updatedAt?: any | null, person?: { __typename?: 'Person', key: string, fullName?: string | null } | null, pointOfSaleUsers: { __typename?: 'PointOfSaleUsersConnection', nodes: Array<{ __typename?: 'PointOfSaleUser', key: string, createdAt: any, updatedAt?: any | null, pointOfSale?: { __typename?: 'PointOfSale', key: string, code: number, name?: string | null, typeCode: number } | null } | null> } } | null };

export type CreateUserMutationVariables = Exact<{
  email: Scalars['String'];
  firstName: Scalars['String'];
  middleName: Scalars['String'];
  firstSurname: Scalars['String'];
  secondSurname: Scalars['String'];
}>;


export type CreateUserMutation = { __typename?: 'Mutation', createUser?: { __typename?: 'CreateUserPayload', user?: { __typename?: 'User', key: string } | null } | null };

export type CurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentUserQuery = { __typename?: 'Query', currentUser?: { __typename?: 'User', key: string, lastTenantKey?: string | null, person?: { __typename?: 'Person', firstName: string, firstSurname: string, fullName?: string | null } | null, tenant?: { __typename?: 'Tenant', key?: string | null, nit?: any | null, businessName?: string | null } | null, permissions: { __typename?: 'PermissionsConnection', nodes: Array<{ __typename?: 'Permission', code: string } | null> }, tenants: { __typename?: 'TenantsConnection', nodes: Array<{ __typename?: 'Tenant', key?: string | null, nit?: any | null, businessName?: string | null } | null> } } | null };

export type CurrentUserAndTenantsQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentUserAndTenantsQuery = { __typename?: 'Query', currentUser?: { __typename?: 'User', key: string, lastTenantKey?: string | null, person?: { __typename?: 'Person', firstName: string, firstSurname: string, fullName?: string | null } | null, tenants: { __typename?: 'TenantsConnection', nodes: Array<{ __typename?: 'Tenant', key?: string | null, nit?: any | null, businessName?: string | null } | null> } } | null };

export type PaginateUsersQueryVariables = Exact<{
  first: Scalars['Int'];
  offset: Scalars['Int'];
  orderBy?: InputMaybe<Array<ViewUsersOrderBy> | ViewUsersOrderBy>;
  filter?: InputMaybe<ViewUserFilter>;
}>;


export type PaginateUsersQuery = { __typename?: 'Query', viewUsers?: { __typename?: 'ViewUsersConnection', totalCount: number, nodes: Array<{ __typename?: 'ViewUser', key?: string | null, username?: string | null, email?: string | null, fullName?: string | null, createdAt?: any | null, updatedAt?: any | null } | null> } | null };

export type UserNotificationSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type UserNotificationSubscription = { __typename?: 'Subscription', userNotification?: { __typename?: 'UserNotificationPayload', notification?: { __typename?: 'Notification', key?: string | null, event?: string | null, content?: string | null } | null } | null };

export const AccountByTenantKeyDocument = gql`
    query AccountByTenantKey($key: String!) {
  accountByTenantKey(key: $key) {
    tenantKey
    tenantCreatedAt
    tenantNit
    tenantBusinessName
    tenantEmail
    tenantPhoneNumber
    systemCode
    delegateToken
    delegateEnabled
    delegateIssuedAt
    delegateExpireAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AccountByTenantKeyGQL extends Apollo.Query<AccountByTenantKeyQuery, AccountByTenantKeyQueryVariables> {
    override document = AccountByTenantKeyDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DownloadRegisterFileDocument = gql`
    query DownloadRegisterFile($uuid: String!) {
  downloadRegisterFile(uuid: $uuid) {
    filename
    filesize
    uuid
    type
    base64
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DownloadRegisterFileGQL extends Apollo.Query<DownloadRegisterFileQuery, DownloadRegisterFileQueryVariables> {
    override document = DownloadRegisterFileDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetRegisterFilesByTenantKeyDocument = gql`
    query GetRegisterFilesByTenantKey($key: String!) {
  getRegisterFilesByTenantKey(key: $key) {
    nodes {
      filename
      filesize
      uuid
      type
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetRegisterFilesByTenantKeyGQL extends Apollo.Query<GetRegisterFilesByTenantKeyQuery, GetRegisterFilesByTenantKeyQueryVariables> {
    override document = GetRegisterFilesByTenantKeyDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PaginateIndexViewAccountsDocument = gql`
    query PaginateIndexViewAccounts($first: Int!, $offset: Int!, $orderBy: [ViewAccountsOrderBy!], $filter: ViewAccountFilter) {
  viewAccounts(first: $first, offset: $offset, orderBy: $orderBy, filter: $filter) {
    nodes {
      tenantKey
      tenantNit
      tenantBusinessName
      tenantEmail
      tenantPhoneNumber
      systemCode
      tenantCreatedAt
    }
    totalCount
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PaginateIndexViewAccountsGQL extends Apollo.Query<PaginateIndexViewAccountsQuery, PaginateIndexViewAccountsQueryVariables> {
    override document = PaginateIndexViewAccountsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AuthenticateDocument = gql`
    mutation authenticate($username: String!, $password: String!) {
  authenticate(input: {username: $username, password: $password}) {
    jwtToken
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AuthenticateGQL extends Apollo.Mutation<AuthenticateMutation, AuthenticateMutationVariables> {
    override document = AuthenticateDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const BranchOfficesComboDocument = gql`
    query BranchOfficesCombo {
  viewBranchOffices {
    nodes {
      key
      code
      name
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class BranchOfficesComboGQL extends Apollo.Query<BranchOfficesComboQuery, BranchOfficesComboQueryVariables> {
    override document = BranchOfficesComboDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AllClientsComboDocument = gql`
    query AllClientsCombo {
  viewClients(orderBy: KEY_ASC) {
    nodes {
      key
      name
      phoneNumber
      email
      documentNumber
      documentComplement
      documentTypeDescription
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AllClientsComboGQL extends Apollo.Query<AllClientsComboQuery, AllClientsComboQueryVariables> {
    override document = AllClientsComboDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ClientByKeyDocument = gql`
    query ClientByKey($key: String!) {
  client(key: $key) {
    key
    name
    observation
    phoneNumber
    email
    createdAt
    updatedAt
    homologation {
      documentNumber
      documentComplement
      documentTypeCode
      documentTypeDescription
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ClientByKeyGQL extends Apollo.Query<ClientByKeyQuery, ClientByKeyQueryVariables> {
    override document = ClientByKeyDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateClientDocument = gql`
    mutation CreateClient($name: String!, $phoneNumber: String!, $email: String!, $observation: String, $documentNumber: String!, $documentComplement: String, $documentTypeCode: Int!, $allowInvalidNit: Boolean!) {
  createClient(
    input: {client: {name: $name, observation: $observation, phoneNumber: $phoneNumber, email: $email, homologations: {create: {documentNumber: $documentNumber, documentComplement: $documentComplement, documentTypeCode: $documentTypeCode, allowInvalidNit: $allowInvalidNit}}}}
  ) {
    client {
      key
      name
      observation
      phoneNumber
      email
      createdAt
      homologation {
        key
        documentNumber
        documentComplement
        documentTypeCode
        allowInvalidNit
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateClientGQL extends Apollo.Mutation<CreateClientMutation, CreateClientMutationVariables> {
    override document = CreateClientDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PaginateClientsDocument = gql`
    query PaginateClients($first: Int!, $offset: Int!, $orderBy: [ViewClientsOrderBy!], $filter: ViewClientFilter) {
  viewClients(first: $first, offset: $offset, orderBy: $orderBy, filter: $filter) {
    nodes {
      key
      name
      phoneNumber
      email
      createdAt
      documentNumber
      documentComplement
      documentTypeDescription
    }
    totalCount
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PaginateClientsGQL extends Apollo.Query<PaginateClientsQuery, PaginateClientsQueryVariables> {
    override document = PaginateClientsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SearchClientsDocument = gql`
    query SearchClients($first: Int!, $offset: Int!, $search: String!, $orderBy: [ViewClientsOrderBy!]) {
  viewClients(
    first: $first
    offset: $offset
    orderBy: $orderBy
    filter: {or: [{documentNumber: {likeInsensitive: $search}}, {name: {likeInsensitive: $search}}]}
  ) {
    nodes {
      key
      name
      phoneNumber
      email
      documentNumber
      documentComplement
      documentTypeDescription
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SearchClientsGQL extends Apollo.Query<SearchClientsQuery, SearchClientsQueryVariables> {
    override document = SearchClientsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GenerateCufdDocument = gql`
    mutation GenerateCufd($pointOfSaleKey: String!) {
  generateCufd(input: {pointOfSaleKey: $pointOfSaleKey}) {
    cufd {
      key
      code
      controlCode
      status
      sendingStatus
      expireAt
      createdAt
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GenerateCufdGQL extends Apollo.Mutation<GenerateCufdMutation, GenerateCufdMutationVariables> {
    override document = GenerateCufdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GenerateCuisDocument = gql`
    mutation GenerateCuis($pointOfSaleKey: String!) {
  generateCuis(input: {pointOfSaleKey: $pointOfSaleKey}) {
    cuis {
      key
      code
      status
      sendingStatus
      expireAt
      createdAt
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GenerateCuisGQL extends Apollo.Mutation<GenerateCuisMutation, GenerateCuisMutationVariables> {
    override document = GenerateCuisDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PaginateViewCufdCodesDocument = gql`
    query PaginateViewCufdCodes($first: Int!, $offset: Int!, $orderBy: [ViewCufdCodesOrderBy!], $filter: ViewCufdCodeFilter) {
  viewCufdCodes(
    first: $first
    offset: $offset
    orderBy: $orderBy
    filter: $filter
  ) {
    nodes {
      key
      code
      controlCode
      branchOfficeCode
      branchOfficeName
      pointOfSaleCode
      pointOfSaleName
      enabled
      generatedAt
      expireAt
      createdAt
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
    }
    totalCount
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PaginateViewCufdCodesGQL extends Apollo.Query<PaginateViewCufdCodesQuery, PaginateViewCufdCodesQueryVariables> {
    override document = PaginateViewCufdCodesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PaginateViewCuisCodesDocument = gql`
    query PaginateViewCuisCodes($first: Int!, $offset: Int!, $orderBy: [ViewCuisCodesOrderBy!], $filter: ViewCuisCodeFilter) {
  viewCuisCodes(
    first: $first
    offset: $offset
    orderBy: $orderBy
    filter: $filter
  ) {
    nodes {
      key
      code
      branchOfficeCode
      branchOfficeName
      pointOfSaleCode
      pointOfSaleName
      status
      enabled
      expireAt
      createdAt
      updatedAt
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
    }
    totalCount
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PaginateViewCuisCodesGQL extends Apollo.Query<PaginateViewCuisCodesQuery, PaginateViewCuisCodesQueryVariables> {
    override document = PaginateViewCuisCodesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SinGenerateMassiveCufdsDocument = gql`
    mutation SinGenerateMassiveCufds($pointOfSaleCodes: [Int]!) {
  sinGenerateMassiveCufds(input: {pointOfSaleCodes: $pointOfSaleCodes}) {
    cufds {
      key
      code
      controlCode
      pointOfSaleCode
      pointOfSaleName
      expireAt
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SinGenerateMassiveCufdsGQL extends Apollo.Mutation<SinGenerateMassiveCufdsMutation, SinGenerateMassiveCufdsMutationVariables> {
    override document = SinGenerateMassiveCufdsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateExportServiceOrderDocument = gql`
    mutation CreateExportServiceOrder($discount: BigFloat!, $paymentMethodCode: Int!, $currencyCode: Int!, $exchangeRate: BigFloat!, $activityCode: String!, $sectorDocumentCode: Int!, $invoiceTypeCode: Int!, $emissionTypeCode: Int!, $modalityTypeCode: Int!, $sinDireccionComprador: String!, $sinLugarDestino: String!, $sinCodigoPais: Int!, $sinInformacionAdicional: String, $exportServiceOrderDetails: [FkExportServiceOrderDetailToExportServiceOrderExportServiceOrderDetailCreateInput!], $clientKey: String!) {
  createOrder(
    input: {order: {discount: $discount, homologations: {create: {paymentMethodCode: $paymentMethodCode, currencyCode: $currencyCode, exchangeRate: $exchangeRate, activityCode: $activityCode, sectorDocumentCode: $sectorDocumentCode, emissionTypeCode: $emissionTypeCode, modalityTypeCode: $modalityTypeCode, invoiceTypeCode: $invoiceTypeCode}}, exportServiceOrders: {create: {sinDireccionComprador: $sinDireccionComprador, sinLugarDestino: $sinLugarDestino, sinCodigoPais: $sinCodigoPais, sinInformacionAdicional: $sinInformacionAdicional, exportServiceOrderDetails: {create: $exportServiceOrderDetails}}}, client: {connectByKey: {key: $clientKey}}}}
  ) {
    order {
      key
      quantity
      subtotal
      discount
      total
      homologation {
        paymentMethodDescription
      }
      exportServiceOrder {
        sinDireccionComprador
        sinLugarDestino
        sinCodigoPais
        sinInformacionAdicional
        exportServiceOrderDetails {
          nodes {
            key
            quantity
            price
            subtotal
            discount
            total
            productService {
              key
              name
              description
            }
          }
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateExportServiceOrderGQL extends Apollo.Mutation<CreateExportServiceOrderMutation, CreateExportServiceOrderMutationVariables> {
    override document = CreateExportServiceOrderDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteClientDocument = gql`
    mutation DeleteClient($key: String!) {
  deleteClient(input: {key: $key}) {
    client {
      key
      homologation {
        key
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteClientGQL extends Apollo.Mutation<DeleteClientMutation, DeleteClientMutationVariables> {
    override document = DeleteClientDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PaginateDigitalSignaturesDocument = gql`
    query PaginateDigitalSignatures($first: Int!, $offset: Int!, $orderBy: [ViewDigitalSignaturesOrderBy!], $filter: ViewDigitalSignatureFilter) {
  viewDigitalSignatures(
    first: $first
    offset: $offset
    orderBy: $orderBy
    filter: $filter
  ) {
    nodes {
      key
      issuerCountry
      issuerCommonName
      issuerOrganization
      subjectCountry
      subjectBusinessName
      subjectNit
      subjectLegalRepresentative
      subjectJobTitle
      subjectDocumentType
      subjectDocumentNumber
      subjectDocumentComplement
      issuedAt
      expireAt
      enabled
      revoked
      createdAt
      updatedAt
    }
    totalCount
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PaginateDigitalSignaturesGQL extends Apollo.Query<PaginateDigitalSignaturesQuery, PaginateDigitalSignaturesQueryVariables> {
    override document = PaginateDigitalSignaturesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UploadDigitalSignatureDocument = gql`
    mutation UploadDigitalSignature($tenantKey: String!, $file: Upload!, $password: String!) {
  uploadDigitalSignature(
    input: {tenantKey: $tenantKey, file: $file, password: $password}
  ) {
    message
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UploadDigitalSignatureGQL extends Apollo.Mutation<UploadDigitalSignatureMutation, UploadDigitalSignatureMutationVariables> {
    override document = UploadDigitalSignatureDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetParameterDocument = gql`
    query GetParameter($classifier: String!, $code: String!) {
  getParameter(classifier: $classifier, code: $code) {
    value
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetParameterGQL extends Apollo.Query<GetParameterQuery, GetParameterQueryVariables> {
    override document = GetParameterDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CancelInvoiceDocument = gql`
    mutation CancelInvoice($key: String!, $reasonCode: Int!) {
  cancelInvoice(input: {key: $key, reasonCode: $reasonCode}) {
    message
    invoiceCancellation {
      key
      status
      sendingStatus
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CancelInvoiceGQL extends Apollo.Mutation<CancelInvoiceMutation, CancelInvoiceMutationVariables> {
    override document = CancelInvoiceDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DownloadInvoiceDocument = gql`
    query DownloadInvoice($key: String!) {
  downloadInvoice(key: $key) {
    base64
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DownloadInvoiceGQL extends Apollo.Query<DownloadInvoiceQuery, DownloadInvoiceQueryVariables> {
    override document = DownloadInvoiceDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GenerateInvoiceDocument = gql`
    mutation GenerateInvoice($orderKey: String!) {
  generateInvoice(input: {orderKey: $orderKey}) {
    invoice {
      key
      number
      totalAmount
      totalAmountToPay
      totalAmountSubjectToIva
      url
      status
      verificationStatus
      createdAt
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GenerateInvoiceGQL extends Apollo.Mutation<GenerateInvoiceMutation, GenerateInvoiceMutationVariables> {
    override document = GenerateInvoiceDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const InvoiceByKeyDocument = gql`
    query InvoiceByKey($key: String!) {
  invoiceByKey(key: $key) {
    key
    sectorDocumentCode
    invoiceTypeCode
    emissionTypeCode
    modalityTypeCode
    businessName
    documentNumber
    documentTypeCode
    documentTypeDescription
    totalAmount
    totalAmountToPay
    totalAmountSubjectToIva
    currencyCode
    exchangeRate
    totalAmountCurrency
    additionalDiscount
    number
    issuedAt
    cuf
    url
    orderKey
    status
    verificationStatus
    createdAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class InvoiceByKeyGQL extends Apollo.Query<InvoiceByKeyQuery, InvoiceByKeyQueryVariables> {
    override document = InvoiceByKeyDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PaginateViewInvoicesDocument = gql`
    query PaginateViewInvoices($first: Int!, $offset: Int!, $orderBy: [ViewInvoicesOrderBy!], $filter: ViewInvoiceFilter) {
  viewInvoices(first: $first, offset: $offset, orderBy: $orderBy, filter: $filter) {
    nodes {
      key
      businessName
      documentNumber
      documentTypeCode
      documentTypeDescription
      subtotal
      additionalDiscount
      totalAmount
      totalAmountCurrency
      totalAmountToPay
      totalAmountSubjectToIva
      number
      issuedAt
      status
      verificationStatus
      createdAt
      date
    }
    totalCount
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PaginateViewInvoicesGQL extends Apollo.Query<PaginateViewInvoicesQuery, PaginateViewInvoicesQueryVariables> {
    override document = PaginateViewInvoicesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateSaleOrderDocument = gql`
    mutation CreateSaleOrder($pointOfSaleKey: String!, $discount: BigFloat!, $paymentAmount: BigFloat!, $email: String!, $phoneNumber: String!, $paymentMethodCode: Int!, $cardNumber: BigInt, $currencyCode: Int!, $exchangeRate: BigFloat!, $activityCode: String!, $sectorDocumentCode: Int!, $invoiceTypeCode: Int!, $emissionTypeCode: Int!, $modalityTypeCode: Int!, $giftCard: BigFloat!, $saleOrderDetails: [FkSaleOrderDetailToSaleOrderSaleOrderDetailCreateInput!], $clientKey: String!) {
  createOrder(
    input: {order: {pointOfSaleKey: $pointOfSaleKey, discount: $discount, paymentAmount: $paymentAmount, phoneNumber: $phoneNumber, email: $email, homologations: {create: {paymentMethodCode: $paymentMethodCode, cardNumber: $cardNumber, currencyCode: $currencyCode, exchangeRate: $exchangeRate, activityCode: $activityCode, sectorDocumentCode: $sectorDocumentCode, invoiceTypeCode: $invoiceTypeCode, emissionTypeCode: $emissionTypeCode, modalityTypeCode: $modalityTypeCode}}, saleOrders: {create: {giftCard: $giftCard, saleOrderDetails: {create: $saleOrderDetails}}}, client: {connectByKey: {key: $clientKey}}}}
  ) {
    order {
      key
      quantity
      subtotal
      discount
      total
      paymentAmount
      changeAmount
      homologation {
        paymentMethodDescription
      }
      saleOrder {
        giftCard
        saleOrderDetails {
          nodes {
            key
            quantity
            price
            subtotal
            discount
            total
            productService {
              key
              name
              description
            }
          }
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateSaleOrderGQL extends Apollo.Mutation<CreateSaleOrderMutation, CreateSaleOrderMutationVariables> {
    override document = CreateSaleOrderDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DownloadOrdersDocument = gql`
    query DownloadOrders {
  downloadOrders {
    filename
    mimetype
    encoding
    filesize
    uuid
    type
    base64
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DownloadOrdersGQL extends Apollo.Query<DownloadOrdersQuery, DownloadOrdersQueryVariables> {
    override document = DownloadOrdersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const OrderByKeyDocument = gql`
    query OrderByKey($key: String!) {
  order(key: $key) {
    key
    quantity
    subtotal
    discount
    total
    phoneNumber
    email
    status
    createdAt
    homologation {
      sectorDocumentCode
      paymentMethodDescription
    }
    client {
      key
      name
      createdAt
      phoneNumber
      email
      homologation {
        documentNumber
        documentNumber
        documentTypeCode
        documentTypeDescription
      }
    }
    invoice {
      key
      number
      totalAmount
      totalAmountToPay
      totalAmountSubjectToIva
      url
      status
      verificationStatus
      createdAt
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class OrderByKeyGQL extends Apollo.Query<OrderByKeyQuery, OrderByKeyQueryVariables> {
    override document = OrderByKeyDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PaginateViewOrdersDocument = gql`
    query PaginateViewOrders($first: Int!, $offset: Int!, $orderBy: [ViewOrdersOrderBy!], $filter: ViewOrderFilter) {
  viewOrders(first: $first, offset: $offset, orderBy: $orderBy, filter: $filter) {
    nodes {
      key
      number
      businessName
      documentNumber
      documentTypeCode
      documentTypeDescription
      subtotal
      discount
      total
      invoiceIssuedAt
      invoiceStatus
      invoiceVerificationStatus
      createdAt
      date
    }
    totalCount
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PaginateViewOrdersGQL extends Apollo.Query<PaginateViewOrdersQuery, PaginateViewOrdersQueryVariables> {
    override document = PaginateViewOrdersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SaleOrderByOrderKeyDocument = gql`
    query SaleOrderByOrderKey($orderKey: String!) {
  saleOrderByOrderKey(orderKey: $orderKey) {
    giftCard
    saleOrderDetails {
      nodes {
        quantity
        price
        subtotal
        discount
        total
        productService {
          key
          code
          name
          description
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SaleOrderByOrderKeyGQL extends Apollo.Query<SaleOrderByOrderKeyQuery, SaleOrderByOrderKeyQueryVariables> {
    override document = SaleOrderByOrderKeyDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PackageByKeyDocument = gql`
    query PackageByKey($key: String!) {
  packageByKey(key: $key) {
    key
    sectorDocumentCode
    sectorDocumentDescription
    branchOfficeCode
    branchOfficeName
    pointOfSaleCode
    pointOfSaleName
    quantity
    receptionCode
    status
    sendingStatus
    validationStatus
    validationSendingStatus
    createdAt
    updatedAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PackageByKeyGQL extends Apollo.Query<PackageByKeyQuery, PackageByKeyQueryVariables> {
    override document = PackageByKeyDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PaginatePackageInvoicesByKeyDocument = gql`
    query PaginatePackageInvoicesByKey($key: String!, $first: Int!, $offset: Int!, $filter: InvoiceFilter) {
  packageInvoices(key: $key, first: $first, offset: $offset, filter: $filter) {
    nodes {
      key
      sectorDocumentCode
      businessName
      documentNumber
      documentTypeCode
      documentTypeDescription
      totalAmount
      currencyCode
      number
      issuedAt
      status
      verificationStatus
      createdAt
    }
    totalCount
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PaginatePackageInvoicesByKeyGQL extends Apollo.Query<PaginatePackageInvoicesByKeyQuery, PaginatePackageInvoicesByKeyQueryVariables> {
    override document = PaginatePackageInvoicesByKeyDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PaginatePackagesDocument = gql`
    query PaginatePackages($first: Int!, $offset: Int!, $orderBy: [ViewPackagesOrderBy!], $filter: ViewPackageFilter) {
  viewPackages(first: $first, offset: $offset, orderBy: $orderBy, filter: $filter) {
    nodes {
      key
      sectorDocumentCode
      sectorDocumentDescription
      quantity
      status
      sendingStatus
      validationStatus
      validationSendingStatus
      createdAt
      updatedAt
    }
    totalCount
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PaginatePackagesGQL extends Apollo.Query<PaginatePackagesQuery, PaginatePackagesQueryVariables> {
    override document = PaginatePackagesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreatePointOfSaleUserDocument = gql`
    mutation CreatePointOfSaleUser($pointOfSaleKey: String!, $userKey: String!) {
  createPointOfSaleUser(
    input: {pointOfSaleUser: {pointOfSaleKey: $pointOfSaleKey, userKey: $userKey, enabled: true}}
  ) {
    pointOfSaleUser {
      key
      createdAt
      updatedAt
      pointOfSale {
        key
        code
        name
        description
        typeCode
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreatePointOfSaleUserGQL extends Apollo.Mutation<CreatePointOfSaleUserMutation, CreatePointOfSaleUserMutationVariables> {
    override document = CreatePointOfSaleUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreatePointOfSaleDocument = gql`
    mutation CreatePointOfSale($name: String!, $description: String!, $branchOfficeCode: Int!, $typeCode: Int!, $modalityCode: Int!, $commissionAgentNit: BigInt, $contractNumber: String, $startDate: Datetime, $finishDate: Datetime) {
  createPointOfSale(
    input: {name: $name, description: $description, branchOfficeCode: $branchOfficeCode, typeCode: $typeCode, modalityCode: $modalityCode, commissionAgentNit: $commissionAgentNit, contractNumber: $contractNumber, startDate: $startDate, finishDate: $finishDate}
  ) {
    pointOfSale {
      key
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreatePointOfSaleGQL extends Apollo.Mutation<CreatePointOfSaleMutation, CreatePointOfSaleMutationVariables> {
    override document = CreatePointOfSaleDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeletePointOfSaleUserDocument = gql`
    mutation DeletePointOfSaleUser($key: String!) {
  deletePointOfSaleUser(input: {key: $key}) {
    pointOfSaleUser {
      key
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeletePointOfSaleUserGQL extends Apollo.Mutation<DeletePointOfSaleUserMutation, DeletePointOfSaleUserMutationVariables> {
    override document = DeletePointOfSaleUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PointOfSaleByKeyDocument = gql`
    query PointOfSaleByKey($key: String!) {
  pointOfSale(key: $key) {
    key
    code
    name
    description
    typeCode
    typeName
    createdAt
    cuis {
      code
      expireAt
      createdAt
    }
    cufd {
      code
      expireAt
      createdAt
    }
    branchOffice {
      code
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PointOfSaleByKeyGQL extends Apollo.Query<PointOfSaleByKeyQuery, PointOfSaleByKeyQueryVariables> {
    override document = PointOfSaleByKeyDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PointOfSaleComboDocument = gql`
    query PointOfSaleCombo {
  viewPointOfSales {
    nodes {
      key
      code
      name
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PointOfSaleComboGQL extends Apollo.Query<PointOfSaleComboQuery, PointOfSaleComboQueryVariables> {
    override document = PointOfSaleComboDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SyncPointsOfSaleDocument = gql`
    mutation SyncPointsOfSale($branchOfficeCode: Int!) {
  syncPointsOfSale(input: {branchOfficeCode: $branchOfficeCode}) {
    boolean
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SyncPointsOfSaleGQL extends Apollo.Mutation<SyncPointsOfSaleMutation, SyncPointsOfSaleMutationVariables> {
    override document = SyncPointsOfSaleDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateProductDocument = gql`
    mutation CreateProduct($code: String!, $name: String!, $description: String!, $type: ProductType!, $price: BigFloat!, $activityCode: String!, $productCode: Int!, $measureUnitCode: Int!) {
  createProduct(
    input: {product: {code: $code, name: $name, description: $description, type: $type, price: $price, homologations: {create: {activityCode: $activityCode, productCode: $productCode, measureUnitCode: $measureUnitCode}}}}
  ) {
    product {
      key
      code
      name
      description
      type
      price
      createdAt
      homologation {
        key
        activityCode
        productCode
        measureUnitCode
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateProductGQL extends Apollo.Mutation<CreateProductMutation, CreateProductMutationVariables> {
    override document = CreateProductDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DownloadProductsDocument = gql`
    query DownloadProducts {
  downloadProducts {
    filename
    mimetype
    encoding
    filesize
    uuid
    type
    base64
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DownloadProductsGQL extends Apollo.Query<DownloadProductsQuery, DownloadProductsQueryVariables> {
    override document = DownloadProductsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PaginateProductsDocument = gql`
    query PaginateProducts($first: Int!, $offset: Int!, $orderBy: [ViewProductsOrderBy!], $filter: ViewProductFilter) {
  viewProducts(first: $first, offset: $offset, orderBy: $orderBy, filter: $filter) {
    nodes {
      key
      code
      name
      description
      price
      createdAt
    }
    totalCount
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PaginateProductsGQL extends Apollo.Query<PaginateProductsQuery, PaginateProductsQueryVariables> {
    override document = PaginateProductsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ProductByKeyDocument = gql`
    query ProductByKey($key: String!) {
  product(key: $key) {
    key
    code
    name
    description
    type
    price
    createdAt
    updatedAt
    homologation {
      key
      activityCode
      activityDescription
      productCode
      productDescription
      measureUnitCode
      measureUnitDescription
      createdAt
      updatedAt
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ProductByKeyGQL extends Apollo.Query<ProductByKeyQuery, ProductByKeyQueryVariables> {
    override document = ProductByKeyDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SearchProductsByCodeDocument = gql`
    query SearchProductsByCode($code: String!) {
  viewProducts(filter: {code: {likeInsensitive: $code}}, first: 10, offset: 0) {
    nodes {
      key
      code
      name
      description
      price
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SearchProductsByCodeGQL extends Apollo.Query<SearchProductsByCodeQuery, SearchProductsByCodeQueryVariables> {
    override document = SearchProductsByCodeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SearchProductsByDescriptionDocument = gql`
    query SearchProductsByDescription($description: String!) {
  viewProducts(
    filter: {description: {likeInsensitive: $description}}
    first: 10
    offset: 0
  ) {
    nodes {
      key
      code
      name
      description
      price
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SearchProductsByDescriptionGQL extends Apollo.Query<SearchProductsByDescriptionQuery, SearchProductsByDescriptionQueryVariables> {
    override document = SearchProductsByDescriptionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateProductDocument = gql`
    mutation UpdateProduct($key: String!, $code: String!, $name: String!, $description: String!, $type: ProductType!, $price: BigFloat!, $homologationKey: String!, $activityCode: String!, $productCode: Int!, $measureUnitCode: Int!) {
  updateProduct(
    input: {key: $key, patch: {code: $code, name: $name, description: $description, type: $type, price: $price, homologations: {updateByKey: {key: $homologationKey, patch: {activityCode: $activityCode, productCode: $productCode, measureUnitCode: $measureUnitCode}}}}}
  ) {
    product {
      key
      code
      name
      description
      type
      price
      createdAt
      homologation {
        key
        activityCode
        productCode
        measureUnitCode
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateProductGQL extends Apollo.Mutation<UpdateProductMutation, UpdateProductMutationVariables> {
    override document = UpdateProductDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RegisterUserDocument = gql`
    mutation RegisterUser($personFirstName: String!, $personMiddleName: String!, $personFirstSurname: String!, $personSecondSurname: String!, $personDocumentNumber: String!, $personPhoneNumber: String!, $tenantNit: BigInt!, $tenantRazonSocial: String!, $tenantSiatUsername: String!, $email: String!, $password: String!) {
  registerUser(
    input: {personFirstName: $personFirstName, personMiddleName: $personMiddleName, personFirstSurname: $personFirstSurname, personSecondSurname: $personSecondSurname, personDocumentNumber: $personDocumentNumber, personPhoneNumber: $personPhoneNumber, tenantNit: $tenantNit, tenantRazonSocial: $tenantRazonSocial, tenantSiatUsername: $tenantSiatUsername, email: $email, password: $password}
  ) {
    registration {
      personFullName
      username
      email
      nit
      razonSocial
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RegisterUserGQL extends Apollo.Mutation<RegisterUserMutation, RegisterUserMutationVariables> {
    override document = RegisterUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UploadRegisterDocumentsDocument = gql`
    mutation UploadRegisterDocuments($token: String!, $invoicePhoneNumber: String!, $invoiceMunicipality: String!, $invoiceAddress: String!, $frontDocumentPhoto: Upload!, $nitDocumentPhoto: Upload!, $frontDocumentSelfie: Upload!, $backDocumentPhoto: Upload!, $certificateRequestPhoto: Upload!) {
  uploadRegisterDocuments(
    token: $token
    invoicePhoneNumber: $invoicePhoneNumber
    invoiceMunicipality: $invoiceMunicipality
    invoiceAddress: $invoiceAddress
    frontDocumentPhoto: $frontDocumentPhoto
    backDocumentPhoto: $backDocumentPhoto
    frontDocumentSelfie: $frontDocumentSelfie
    nitDocumentPhoto: $nitDocumentPhoto
    certificateRequestPhoto: $certificateRequestPhoto
  ) {
    frontDocumentPhotoValid
    backDocumentPhotoValid
    frontDocumentSelfieValid
    nitDocumentPhotoValid
    certificateRequestPhotoValid
    jwtToken
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UploadRegisterDocumentsGQL extends Apollo.Mutation<UploadRegisterDocumentsMutation, UploadRegisterDocumentsMutationVariables> {
    override document = UploadRegisterDocumentsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ActivateAccountDocument = gql`
    mutation ActivateAccount($token: String!) {
  activateAccount(input: {token: $token}) {
    registration {
      personFullName
      personDocumentNumber
      personJobTitle
      personPhoneNumber
      username
      email
      nit
      razonSocial
      siatUsername
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ActivateAccountGQL extends Apollo.Mutation<ActivateAccountMutation, ActivateAccountMutationVariables> {
    override document = ActivateAccountDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SinRegisterSignificantEventDocument = gql`
    mutation SinRegisterSignificantEvent($pointOfSaleCode: Int!, $eventCode: Int!, $description: String!, $startDate: Datetime!, $cafc: String) {
  createSignificantEvent(
    input: {pointOfSaleCode: $pointOfSaleCode, reasonCode: $eventCode, description: $description, startDate: $startDate, cafc: $cafc}
  ) {
    significantEvent {
      id
      key
      cufd
      cufdEvent
      startDate
      createdAt
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SinRegisterSignificantEventGQL extends Apollo.Mutation<SinRegisterSignificantEventMutation, SinRegisterSignificantEventMutationVariables> {
    override document = SinRegisterSignificantEventDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PaginateSinViewSignificantEventsDocument = gql`
    query PaginateSinViewSignificantEvents($first: Int!, $offset: Int!, $orderBy: [ViewSignificantEventsOrderBy!], $filter: ViewSignificantEventFilter) {
  viewSignificantEvents(
    first: $first
    offset: $offset
    orderBy: $orderBy
    filter: $filter
  ) {
    nodes {
      id
      key
      reasonCode
      reasonDescription
      description
      receptionCode
      startDate
      finishDate
      status
      sendingStatus
      sentAt
      createdAt
      updatedAt
    }
    totalCount
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PaginateSinViewSignificantEventsGQL extends Apollo.Query<PaginateSinViewSignificantEventsQuery, PaginateSinViewSignificantEventsQueryVariables> {
    override document = PaginateSinViewSignificantEventsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SendSignificantEventDocument = gql`
    mutation SendSignificantEvent($key: String!) {
  sendSignificantEvent(input: {key: $key}) {
    significantEvent {
      id
      key
      cufd
      startDate
      finishDate
      receptionCode
      status
      sendingStatus
      createdAt
      sentAt
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SendSignificantEventGQL extends Apollo.Mutation<SendSignificantEventMutation, SendSignificantEventMutationVariables> {
    override document = SendSignificantEventDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SignificantEventByKeyDocument = gql`
    query SignificantEventByKey($key: String!) {
  significantEventByKey(key: $key) {
    id
    key
    branchOfficeCode
    branchOfficeName
    pointOfSaleCode
    pointOfSaleName
    cuis
    cufd
    cufdEvent
    reasonCode
    reasonDescription
    description
    startDate
    finishDate
    receptionCode
    status
    sendingStatus
    createdAt
    sentAt
    updatedAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SignificantEventByKeyGQL extends Apollo.Query<SignificantEventByKeyQuery, SignificantEventByKeyQueryVariables> {
    override document = SignificantEventByKeyDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PaginateSinActivitiesDocument = gql`
    query PaginateSinActivities($first: Int!, $offset: Int!) {
  syncActivities(first: $first, offset: $offset) {
    nodes {
      caebCode
      description
      type
      createdAt
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
    }
    totalCount
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PaginateSinActivitiesGQL extends Apollo.Query<PaginateSinActivitiesQuery, PaginateSinActivitiesQueryVariables> {
    override document = PaginateSinActivitiesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PaginateSinActivitySectorDocumentsDocument = gql`
    query PaginateSinActivitySectorDocuments($first: Int!, $offset: Int!) {
  syncActivitySectorDocuments(first: $first, offset: $offset) {
    nodes {
      activityCode
      sectorDocumentCode
      sectorDocumentType
      createdAt
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
    }
    totalCount
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PaginateSinActivitySectorDocumentsGQL extends Apollo.Query<PaginateSinActivitySectorDocumentsQuery, PaginateSinActivitySectorDocumentsQueryVariables> {
    override document = PaginateSinActivitySectorDocumentsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PaginateSinViewParametersDocument = gql`
    query PaginateSinViewParameters($type: SyncParameterType!, $first: Int!, $offset: Int!, $orderBy: [ViewSyncParametersOrderBy!]) {
  viewSyncParameters(
    filter: {type: {equalTo: $type}}
    first: $first
    offset: $offset
    orderBy: $orderBy
  ) {
    nodes {
      classifierCode
      description
      createdAt
    }
    totalCount
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PaginateSinViewParametersGQL extends Apollo.Query<PaginateSinViewParametersQuery, PaginateSinViewParametersQueryVariables> {
    override document = PaginateSinViewParametersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PaginateViewPointOfSalesDocument = gql`
    query PaginateViewPointOfSales($first: Int!, $offset: Int!, $orderBy: [ViewPointOfSalesOrderBy!], $filter: ViewPointOfSaleFilter) {
  viewPointOfSales(
    first: $first
    offset: $offset
    orderBy: $orderBy
    filter: $filter
  ) {
    nodes {
      key
      branchOfficeCode
      branchOfficeName
      code
      name
      typeCode
      typeDescription
      description
      cuis
      cufd
      createdAt
    }
    totalCount
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PaginateViewPointOfSalesGQL extends Apollo.Query<PaginateViewPointOfSalesQuery, PaginateViewPointOfSalesQueryVariables> {
    override document = PaginateViewPointOfSalesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SinActivitiesComboDocument = gql`
    query SinActivitiesCombo {
  syncActivities {
    nodes {
      caebCode
      description
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SinActivitiesComboGQL extends Apollo.Query<SinActivitiesComboQuery, SinActivitiesComboQueryVariables> {
    override document = SinActivitiesComboDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SinActivitySectorDocumentsComboDocument = gql`
    query SinActivitySectorDocumentsCombo {
  syncActivitySectorDocuments {
    nodes {
      activityCode
      sectorDocumentCode
      sectorDocumentType
      createdAt
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
    }
    totalCount
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SinActivitySectorDocumentsComboGQL extends Apollo.Query<SinActivitySectorDocumentsComboQuery, SinActivitySectorDocumentsComboQueryVariables> {
    override document = SinActivitySectorDocumentsComboDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DateTimesDocument = gql`
    query DateTimes($first: Int!, $offset: Int!) {
  syncDatetimes(first: $first, offset: $offset) {
    nodes {
      value
      createdAt
    }
    totalCount
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DateTimesGQL extends Apollo.Query<DateTimesQuery, DateTimesQueryVariables> {
    override document = DateTimesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const InvoiceLegendsDocument = gql`
    query InvoiceLegends($first: Int!, $offset: Int!) {
  syncInvoiceLegends(first: $first, offset: $offset) {
    nodes {
      activityCode
      description
      createdAt
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
    }
    totalCount
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class InvoiceLegendsGQL extends Apollo.Query<InvoiceLegendsQuery, InvoiceLegendsQueryVariables> {
    override document = InvoiceLegendsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SinSectorDocumentInvoiceTypeByActivityCodeDocument = gql`
    query SinSectorDocumentInvoiceTypeByActivityCode($activityCode: String!) {
  sectorDocumentInvoiceTypesByActivityCode(activityCode: $activityCode) {
    nodes {
      sectorDocumentCode
      description
      features
      invoiceTypeCode
      invoiceTypeDescription
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
    }
    totalCount
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SinSectorDocumentInvoiceTypeByActivityCodeGQL extends Apollo.Query<SinSectorDocumentInvoiceTypeByActivityCodeQuery, SinSectorDocumentInvoiceTypeByActivityCodeQueryVariables> {
    override document = SinSectorDocumentInvoiceTypeByActivityCodeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SinDocumentoSectorTipoFacturaDocument = gql`
    query sinDocumentoSectorTipoFactura {
  sectorDocumentInvoiceTypes {
    nodes {
      sectorDocumentCode
      description
      features
      invoiceTypeCode
      invoiceTypeDescription
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
    }
    totalCount
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SinDocumentoSectorTipoFacturaGQL extends Apollo.Query<SinDocumentoSectorTipoFacturaQuery, SinDocumentoSectorTipoFacturaQueryVariables> {
    override document = SinDocumentoSectorTipoFacturaDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RequestSyncDocument = gql`
    mutation RequestSync($type: SyncRequestType!, $branchOfficeCode: Int!, $pointOfSaleCode: Int!) {
  requestSync(
    input: {type: $type, branchOfficeCode: $branchOfficeCode, pointOfSaleCode: $pointOfSaleCode}
  ) {
    syncRequest {
      key
      type
      status
      createdAt
      createdBy
      updatedAt
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RequestSyncGQL extends Apollo.Mutation<RequestSyncMutation, RequestSyncMutationVariables> {
    override document = RequestSyncDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PaginateSyncRequestsDocument = gql`
    query PaginateSyncRequests($first: Int!, $offset: Int!, $orderBy: [ViewSyncRequestsOrderBy!]) {
  viewSyncRequests(first: $first, offset: $offset, orderBy: $orderBy) {
    nodes {
      key
      type
      status
      createdAt
      updatedAt
    }
    totalCount
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PaginateSyncRequestsGQL extends Apollo.Query<PaginateSyncRequestsQuery, PaginateSyncRequestsQueryVariables> {
    override document = PaginateSyncRequestsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SyncRequestSummaryDocument = gql`
    query SyncRequestSummary {
  syncRequestsSummary {
    nodes {
      key
      type
      status
      createdBy
      createdAt
      updatedAt
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SyncRequestSummaryGQL extends Apollo.Query<SyncRequestSummaryQuery, SyncRequestSummaryQueryVariables> {
    override document = SyncRequestSummaryDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PaymentMethodInfoByCodeDocument = gql`
    query PaymentMethodInfoByCode($code: Int!) {
  paymentMethodInfoByCode(code: $code) {
    showGiftCard
    showCardNumber
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PaymentMethodInfoByCodeGQL extends Apollo.Query<PaymentMethodInfoByCodeQuery, PaymentMethodInfoByCodeQueryVariables> {
    override document = PaymentMethodInfoByCodeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SinSectorDocumentsByActividadComboDocument = gql`
    query SinSectorDocumentsByActividadCombo($code: String!) {
  sectorDocumentsByActivityCode(code: $code) {
    nodes {
      classifierCode
      description
      createdAt
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
    }
    totalCount
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SinSectorDocumentsByActividadComboGQL extends Apollo.Query<SinSectorDocumentsByActividadComboQuery, SinSectorDocumentsByActividadComboQueryVariables> {
    override document = SinSectorDocumentsByActividadComboDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SinVerifyNitOnRegisterDocument = gql`
    query SinVerifyNitOnRegister($nit: BigFloat!) {
  sinVerifyNitOnRegister(nit: $nit) {
    isValid
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SinVerifyNitOnRegisterGQL extends Apollo.Query<SinVerifyNitOnRegisterQuery, SinVerifyNitOnRegisterQueryVariables> {
    override document = SinVerifyNitOnRegisterDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SinVerifyNitDocument = gql`
    query SinVerifyNit($nit: BigFloat!) {
  sinVerifyNit(nit: $nit)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SinVerifyNitGQL extends Apollo.Query<SinVerifyNitQuery, SinVerifyNitQueryVariables> {
    override document = SinVerifyNitDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ViewSyncParametersComboDocument = gql`
    query ViewSyncParametersCombo($type: SyncParameterType!, $orderBy: [ViewSyncParametersOrderBy!]) {
  viewSyncParameters(filter: {type: {equalTo: $type}}, orderBy: $orderBy) {
    nodes {
      classifierCode
      description
      createdAt
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ViewSyncParametersComboGQL extends Apollo.Query<ViewSyncParametersComboQuery, ViewSyncParametersComboQueryVariables> {
    override document = ViewSyncParametersComboDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SinViewPaymentMethodsComboDocument = gql`
    query SinViewPaymentMethodsCombo {
  viewPaymentMethods(orderBy: CLASSIFIER_CODE_ASC) {
    nodes {
      classifierCode
      description
      createdAt
    }
    totalCount
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SinViewPaymentMethodsComboGQL extends Apollo.Query<SinViewPaymentMethodsComboQuery, SinViewPaymentMethodsComboQueryVariables> {
    override document = SinViewPaymentMethodsComboDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AllSyncProductsDocument = gql`
    query AllSyncProducts {
  viewSyncProducts {
    nodes {
      activityCode
      code
      description
      createdAt
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AllSyncProductsGQL extends Apollo.Query<AllSyncProductsQuery, AllSyncProductsQueryVariables> {
    override document = AllSyncProductsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SyncProductsByActivityCodeDocument = gql`
    query SyncProductsByActivityCode($activityCode: String!) {
  viewSyncProducts(condition: {activityCode: $activityCode}) {
    nodes {
      code
      description
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SyncProductsByActivityCodeGQL extends Apollo.Query<SyncProductsByActivityCodeQuery, SyncProductsByActivityCodeQueryVariables> {
    override document = SyncProductsByActivityCodeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PaginateDelegatedTokensDocument = gql`
    query PaginateDelegatedTokens($first: Int!, $offset: Int!, $orderBy: [ViewDelegatedTokensOrderBy!], $filter: ViewDelegatedTokenFilter) {
  viewDelegatedTokens(
    first: $first
    offset: $offset
    orderBy: $orderBy
    filter: $filter
  ) {
    nodes {
      id
      key
      sub
      systemCode
      nit
      issuedAt
      expireAt
      enabled
      revoked
      createdAt
      updatedAt
    }
    totalCount
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PaginateDelegatedTokensGQL extends Apollo.Query<PaginateDelegatedTokensQuery, PaginateDelegatedTokensQueryVariables> {
    override document = PaginateDelegatedTokensDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UploadDelegatedTokenDocument = gql`
    mutation UploadDelegatedToken($tenantKey: String!, $token: String!) {
  uploadDelegatedToken(input: {tenantKey: $tenantKey, token: $token}) {
    message
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UploadDelegatedTokenGQL extends Apollo.Mutation<UploadDelegatedTokenMutation, UploadDelegatedTokenMutationVariables> {
    override document = UploadDelegatedTokenDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateClientDocument = gql`
    mutation UpdateClient($key: String!, $name: String!, $phoneNumber: String!, $email: String!, $observation: String, $documentNumber: String!, $documentComplement: String, $documentTypeCode: Int!, $allowInvalidNit: Boolean!) {
  updateClient(
    input: {key: $key, patch: {name: $name, phoneNumber: $phoneNumber, email: $email, observation: $observation, homologations: {updateByClientKey: {clientKey: $key, patch: {documentNumber: $documentNumber, documentComplement: $documentComplement, documentTypeCode: $documentTypeCode, allowInvalidNit: $allowInvalidNit}}}}}
  ) {
    client {
      key
      name
      observation
      phoneNumber
      email
      createdAt
      homologation {
        key
        documentNumber
        documentComplement
        documentTypeCode
        allowInvalidNit
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateClientGQL extends Apollo.Mutation<UpdateClientMutation, UpdateClientMutationVariables> {
    override document = UpdateClientDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UserByKeyDocument = gql`
    query UserByKey($key: String!) {
  user(key: $key) {
    key
    email
    createdAt
    updatedAt
    person {
      key
      fullName
    }
    pointOfSaleUsers {
      nodes {
        key
        createdAt
        updatedAt
        pointOfSale {
          key
          code
          name
          typeCode
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UserByKeyGQL extends Apollo.Query<UserByKeyQuery, UserByKeyQueryVariables> {
    override document = UserByKeyDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateUserDocument = gql`
    mutation CreateUser($email: String!, $firstName: String!, $middleName: String!, $firstSurname: String!, $secondSurname: String!) {
  createUser(
    input: {user: {email: $email, person: {create: {firstName: $firstName, middleName: $middleName, firstSurname: $firstSurname, secondSurname: $secondSurname}}}}
  ) {
    user {
      key
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateUserGQL extends Apollo.Mutation<CreateUserMutation, CreateUserMutationVariables> {
    override document = CreateUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CurrentUserDocument = gql`
    query CurrentUser {
  currentUser {
    key
    lastTenantKey
    person {
      firstName
      firstSurname
      fullName
    }
    tenant {
      key
      nit
      businessName
    }
    permissions {
      nodes {
        code
      }
    }
    tenants {
      nodes {
        key
        nit
        businessName
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CurrentUserGQL extends Apollo.Query<CurrentUserQuery, CurrentUserQueryVariables> {
    override document = CurrentUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CurrentUserAndTenantsDocument = gql`
    query CurrentUserAndTenants {
  currentUser {
    key
    lastTenantKey
    person {
      firstName
      firstSurname
      fullName
    }
    tenants {
      nodes {
        key
        nit
        businessName
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CurrentUserAndTenantsGQL extends Apollo.Query<CurrentUserAndTenantsQuery, CurrentUserAndTenantsQueryVariables> {
    override document = CurrentUserAndTenantsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PaginateUsersDocument = gql`
    query PaginateUsers($first: Int!, $offset: Int!, $orderBy: [ViewUsersOrderBy!], $filter: ViewUserFilter) {
  viewUsers(first: $first, offset: $offset, orderBy: $orderBy, filter: $filter) {
    nodes {
      key
      username
      email
      fullName
      createdAt
      updatedAt
    }
    totalCount
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PaginateUsersGQL extends Apollo.Query<PaginateUsersQuery, PaginateUsersQueryVariables> {
    override document = PaginateUsersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UserNotificationDocument = gql`
    subscription UserNotification {
  userNotification {
    notification {
      key
      event
      content
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UserNotificationGQL extends Apollo.Subscription<UserNotificationSubscription, UserNotificationSubscriptionVariables> {
    override document = UserNotificationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
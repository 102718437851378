import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {ExternalLayoutComponent} from './shared/layout/external/external-layout.component';
import {AuthGuard} from "./guard/auth.guard";

const routes: Routes = [
  {
    path: 'account',
    component: ExternalLayoutComponent,
    loadChildren: () => import ('@modules/account/account.module').then(m => m.AccountModule)
  }, {
    path: 'register',
    component: ExternalLayoutComponent,
    loadChildren: () => import ('@modules/register/register.module').then(m => m.RegisterModule)
  }, {
    path: 'test/:token',
    component: ExternalLayoutComponent,
    loadChildren: () => import ('@modules/test/test.module').then(m => m.TestModule)
  }, {
    path: '',
    loadChildren: () => import ('@modules/home/home.module').then(m => m.HomeModule),
    canActivate: [AuthGuard]
  }, {
    path: '**',
    redirectTo: 'account'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledNonBlocking'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}

import {NgModule} from '@angular/core';
import {ApolloClient, ApolloLink, InMemoryCache, split} from '@apollo/client/core';
import {setContext} from '@apollo/client/link/context';
import {WebSocketLink} from '@apollo/client/link/ws';
import {getMainDefinition} from '@apollo/client/utilities';

import {environment} from '../environments/environment';
import {APOLLO_OPTIONS, ApolloModule} from 'apollo-angular';
import {HttpLink} from 'apollo-angular/http';
import extractFiles from 'extract-files/public/extractFiles.js';

const uri = `${environment.coreApiUrl}/graphql`;
const wsUri = `${environment.coreApiUrlWs}/graphql`;

function getToken(): string {
  const stringToken = sessionStorage.getItem('dux.token');
  if (stringToken == null) return null;

  return stringToken.substring(1, stringToken.length - 1);
}

function getTenantKey(): string {
  const stringTenantKey = sessionStorage.getItem('dux.tenant.key');
  if (stringTenantKey == null) return null;

  return stringTenantKey.substring(1, stringTenantKey.length - 1);
}

export function createApollo(httpLink: HttpLink): ApolloClient<any> {
  const http = httpLink.create({
    uri: uri,
    extractFiles: extractFiles
  });

  const authLink = setContext((_, {headers}) => {
    const token = getToken();
    const tenantKey = getTenantKey();
    const newHeaders= {
      ...headers,
    };

    if (token) {
      newHeaders['Authorization'] = `Bearer ${token}`;
    }
    if (tenantKey) {
      newHeaders['X-Tenant-Key'] = tenantKey;
    }
    return {
      headers: {
        ...newHeaders
      },
    };
  });

  const ws = new WebSocketLink({
    uri: wsUri,
    options: {
      lazy: true,
      reconnect: true,
      connectionParams: {
        authorization: `Bearer ${getToken()}`,
        'X-Tenant-Key': getTenantKey()
      },
    }
  });

  const link = split(
    // split based on operation type
    ({query}) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
      );
    },
    authLink.concat(ws),
    authLink.concat(http),
  );

  // const authMiddleware = new ApolloLink((operation: any, forward: any) => {
  //   operation.setContext({
  //     headers: new HttpHeaders().set(
  //       'Authorization',
  //       `Bearer ${localStorage.getItem('token')}` || '',
  //     ),
  //   });
  //
  //   return forward(operation);
  // });

  return new ApolloClient<any>({
    link: ApolloLink.from([link]),
    cache: new InMemoryCache(),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all'
      },
      query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all'
      }
    }
  });
}

@NgModule({
  exports: [ApolloModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink]
    }
  ]
})
export class GraphqlModule {
}

import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {Subscription} from "rxjs";
import {PrimeNGConfig} from "primeng/api";
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  private subscription: Subscription;
  title = 'dux-web';

  constructor(private router: Router,
              private translate: TranslateService,
              private primeNGConfig: PrimeNGConfig) {
    this.initialize();
    this.router.events.subscribe({
      next: _ => {}
    });
  }

  private initialize() {
    this.translate.addLangs(['es']);
    this.translate.setDefaultLang('es');
    const browserLang = this.translate.getBrowserLang();
    this.translate.use(browserLang.match(/es/) ? browserLang : 'es');

    this.subscription = this.translate.stream('primeng').subscribe(data => {
      this.primeNGConfig.setTranslation(data);
    });
  }
}

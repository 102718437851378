import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {AnimateModule} from "primeng/animate";
import {ButtonModule} from "primeng/button";
import {CalendarModule} from "primeng/calendar";
import {CardModule} from "primeng/card";
import {CheckboxModule} from "primeng/checkbox";
import {DialogModule} from 'primeng/dialog';
import {DividerModule} from "primeng/divider";
import {DropdownModule} from "primeng/dropdown";
import {FileUploadModule} from 'primeng/fileupload';
import {IconFieldModule} from 'primeng/iconfield';
import {InputGroupModule} from "primeng/inputgroup";
import {InputGroupAddonModule} from "primeng/inputgroupaddon";
import {InputIconModule} from 'primeng/inputicon';
import {InputNumberModule} from "primeng/inputnumber";
import {InputTextModule} from "primeng/inputtext";
import {InputTextareaModule} from "primeng/inputtextarea";
import {MessagesModule} from "primeng/messages";
import {RadioButtonModule} from 'primeng/radiobutton';
import {RippleModule} from "primeng/ripple";
import {SelectButtonModule} from "primeng/selectbutton";
import {StyleClassModule} from "primeng/styleclass";
import {TableModule} from "primeng/table";
import {TagModule} from "primeng/tag";
import {ToastModule} from "primeng/toast";
import {ToolbarModule} from "primeng/toolbar";
import {TooltipModule} from "primeng/tooltip";
import {TranslateModule} from '@ngx-translate/core';

import {CufdCodePipe} from "./pipes/cufd-code.pipe";

@NgModule({
  declarations: [
    CufdCodePipe,
    // UpdateTokenModalComponent,
    // UploadDigitalSignatureFormComponent
  ],
  imports: [
    RouterModule,
    FormsModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    InputTextModule,
    InputNumberModule,
    ButtonModule,
    IconFieldModule,
    InputGroupModule,
    InputGroupAddonModule,
    InputTextareaModule,
    MessagesModule,
    FileUploadModule,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    CufdCodePipe,
    // UpdateTokenModalComponent,
    // UploadDigitalSignatureFormComponent,
    //
    AnimateModule,
    TableModule,
    CardModule,
    CalendarModule,
    CheckboxModule,
    DividerModule,
    DropdownModule,
    InputTextModule,
    InputNumberModule,
    ButtonModule,
    DialogModule,
    RadioButtonModule,
    TagModule,
    InputIconModule,
    IconFieldModule,
    InputGroupModule,
    InputGroupAddonModule,
    InputTextareaModule,
    MessagesModule,
    SelectButtonModule,
    ToastModule,
    ToolbarModule,
    TooltipModule,
    FileUploadModule,
    RippleModule,
    StyleClassModule
  ],
})
export class SharedModule {
}

import {ApolloError} from "@apollo/client/errors";

const DEFAULT_ERROR = 'ERROR.DEFAULT';

// export type Message = {
//   description: string,
//   args: any
// }

export class CustomError {
  public code: string;
  public message: string;
  public messages: string[];

  constructor() {
  }

  public static newInstance(code: string, message: string): CustomError {
    const customError = new CustomError();
    customError.code = code;
    customError.message = message;
    return customError;
  }

  public static convert(error: any): CustomError {
    const customError = new CustomError();
    if (error instanceof ApolloError) {
      const apolloError: ApolloError = error;
      if (apolloError.graphQLErrors && apolloError.graphQLErrors.length === 1) {
        const graphqlError = apolloError.graphQLErrors[0];
        customError.message = graphqlError.message;
        customError.code = graphqlError.extensions['code'];
      } else if (apolloError.graphQLErrors.length > 1) {
        customError.messages = apolloError.graphQLErrors.map(graphqlError => graphqlError.message);
      } else if (apolloError.networkError) {
        customError.message = apolloError.networkError.message;
        customError.code = (apolloError.networkError as any).code;
      } else {
        customError.message = DEFAULT_ERROR;
      }
    } else if (error instanceof CustomError) {
      return error;
    } else if (error) {
      customError.message = DEFAULT_ERROR;
    }
    return customError;
  }
}

import {DOCUMENT} from '@angular/common';
import {Component, Inject, OnDestroy, OnInit, PLATFORM_ID} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  templateUrl: 'external-layout.component.html',
})
export class ExternalLayoutComponent implements OnInit, OnDestroy {
  constructor(@Inject(DOCUMENT) private document: Document,
              @Inject(PLATFORM_ID) private platformId: any,
              private router: Router) {
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }
}

import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {DialogService} from 'primeng/dynamicdialog';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {GraphqlModule} from './graphql.module';
import {AuthGuard} from './guard/auth.guard';
import {Interceptor} from './network/interceptor';
import {ExternalLayoutComponent} from './shared/layout/external/external-layout.component';
import {SharedModule} from './shared/shared.module';
import {NgxWebstorageModule} from 'ngx-webstorage';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    ExternalLayoutComponent
  ],
  imports: [
    SharedModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    GraphqlModule,
    NgxWebstorageModule.forRoot({prefix: 'dux', separator: '.', caseSensitive: true}),
    FontAwesomeModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
  ],
  providers: [{
    provide: HTTP_INTERCEPTORS, useClass: Interceptor, multi: true
  }, DialogService, AuthGuard],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {
  }
}

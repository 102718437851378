import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {ExternalLayoutComponent} from './shared/layout/external/external-layout.component';

const routes: Routes = [
  {
    path: 'login',
    component: ExternalLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import ('@modules/auth/auth.module').then(m => m.AuthModule)
      },
    ]
  }, {
    path: 'register',
    component: ExternalLayoutComponent,
    loadChildren: () => import ('@modules/register/register.module').then(m => m.RegisterModule)
  }, {
    path: '',
    loadChildren: () => import ('@modules/home/home.module').then(m => m.HomeModule)
  }, {
    path: '**',
    redirectTo: 'login'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledNonBlocking'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}

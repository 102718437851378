import {Injectable} from '@angular/core';
import {concatMap} from 'rxjs';
import {map} from 'rxjs/operators';
import {AuthenticateGQL, Cufd, CurrentUserAndTenantsGQL, CurrentUserGQL, Permission, Tenant, User} from '@graphql';
import {CustomError} from '@common/custom-error';

import {Apollo} from 'apollo-angular';
import jwtDecode from 'jwt-decode';
import {SessionStorage, SessionStorageService} from 'ngx-webstorage';

@Injectable({providedIn: 'root'})
export class AuthService {
  @SessionStorage('token')
  private mToken: string;
  @SessionStorage('tenant.key')
  private mTenantKey: string;
  @SessionStorage('user')
  private mUser: User;

  constructor(private apollo: Apollo,
              private sessionStorageService: SessionStorageService) {
  }

  public clearSession() {
    this.sessionStorageService.clear();
  }

  public authenticate(username: string, password: string) {
    return new AuthenticateGQL(this.apollo).mutate({username, password})
      .pipe(concatMap(value => {
          if (value.data.authenticate.jwtToken) {
            this.mToken = value.data.authenticate.jwtToken;
            return new CurrentUserAndTenantsGQL(this.apollo).fetch();
          }
          throw CustomError.newInstance('ERROR.AUTHENTICATE.DEFAULT', '');
        })
      )
      .pipe(map(value => {
        this.mUser = value.data.currentUser as User;
        return value.data;
      }));
  }

  public setCufd(sinCufd: Cufd) {
    // TODO
    // const sinPointOfSale = this.mUser?.pointOfSaleUser?.pointOfSale?.pointOfSaleToSin?.sinPointOfSale;
    // if (sinPointOfSale) {
    //   const newUser = this.mUser;
    //   // TODO
    //   // newUser.pointOfSaleUser.pointOfSale.cufd = sinCufd;
    //   this.mUser = newUser;
    // }
  }

  get isAuthenticated(): boolean {
    return this.mToken !== null;
  }

  public getTokenExpirationDate(token: string): Date {
    const decoded: any = jwtDecode(token);
    if (decoded.exp === undefined) return null;

    const date = new Date(0);
    date.setUTCSeconds(decoded.exp);
    return date;
  }

  get isTokenExpired(): boolean {
    if (!this.mToken)
      return true;

    const date = this.getTokenExpirationDate(this.mToken);
    if (date === undefined) {
      this.mToken = null;
      return false;
    }
    const tokenExpiration = date.valueOf();
    const now = new Date().valueOf();
    return (tokenExpiration <= now);
  }

  public currentUser() {
    return new CurrentUserGQL(this.apollo).fetch()
      .pipe(map(response => {
        this.mUser = response.data.currentUser as User;
          return response;
        })
      );
  }

  // public loadPermissions() {
  //   return new AllPermissionsGQL(this.apollo).fetch()
  //     // .pipe(concatMap(value => {
  //     //       if (value.data.authenticate.jwtToken) {
  //     //         this.mToken = value.data.authenticate.jwtToken;
  //     //         console.log(this.mToken);
  //     //         return this.currentUser();
  //     //       }
  //     //       throw CustomError.newInstance(200, 'ERROR.AUTHENTICATION');
  //     //     })
  //     //   )
  //     .pipe(map(value => {
  //       this.mPermissions = value.data.allPermissions.nodes as Permission[];
  //       return true;
  //     }));
  // }

  public set token(value: string) {
    this.mToken = value;
  }

  public get user(): User {
    return this.mUser;
  }

  public set user(value: User) {
    this.mUser = value;
  }

  public get permissions(): Permission[] {
    return this.mUser.permissions.nodes;
  }

  public get tenants(): Tenant[] {
    return this.mUser.tenants.nodes;
  }

  public set tenantKey(key: string) {
    this.mTenantKey = key;
  }

  public get tenantKey() {
    return this.mTenantKey;
  }

  public get currentTenant(): any {
    return this.user.tenant;
  }
}

import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {CustomError} from '@common/custom-error';

@Injectable()
export class Interceptor implements HttpInterceptor {
  constructor() {
  }

  private static addJwt(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`
      }
    });
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      // We use the map operator to change the data from the response
      map(res => {
        // console.log(res);
        // Several HTTP events go through that Observable
        // so we make sure that this is a HTTP response
        if (res instanceof HttpResponse) {
          // Just like for request, we create a clone of the response
          // and make changes to it, then return that clone
          if (res.body.errors && res.body.errors.length > 0) {
            const error = res.body.errors[0];
            throw CustomError.newInstance(error.code, error.message);
          }
          return res;
          // return res.clone({body: [{title: 'Replaced data in interceptor'}]});
        }
        return res;
      })
    );
  }
}
